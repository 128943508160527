import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import useSimpleModal from '@/Components/Modal/useSimpleModal';
import { selectors } from '@/Store';
import externalApplicationLinksConfig from '@/Config/ExternalApplicationLinksConfig';

const deviceRegistrationLimit = {
  title: 'Device Registration Limit',
  content: (
    <p>
      You have reached your annual Device Limit. For more information or to get assistance, please
      refer to our FAQs.
    </p>
  ),
  okText: 'FAQ',
};

const useDevice = () => {
  const { openModal } = useSimpleModal();

  const isDeviceRegistrationLimitExceeded = useSelector(
    selectors.devices.deviceRegistrationLimitExceeded,
  );
  const isNewlyRegisteredDevice = useSelector(selectors.devices.isNewlyRegisteredDevice);
  const isLoading = useSelector(selectors.devices.deviceRegistrationIsLoading);

  const showDeviceRegistrationLimitModal = useCallback(() => {
    openModal({
      ...deviceRegistrationLimit,
      onOk: () => window.open(externalApplicationLinksConfig.faqUrl),
    });
  }, [openModal]);

  return {
    isDeviceRegistrationLimitExceeded,
    isNewlyRegisteredDevice,
    isLoading,
    showDeviceRegistrationLimitModal,
  };
};

export default useDevice;
