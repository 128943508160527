import { useEffect, useState } from 'react';
import { generateDeviceFingerPrint } from '@/Utils/Device';

let isDeviceInfoGenerated = false;

const useDeviceInfo = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (!isDeviceInfoGenerated) {
      generateDeviceFingerPrint().then(() => {
        setIsLoaded(true);
      });
      isDeviceInfoGenerated = true;
    }
  }, []);

  return {
    isDeviceInfoReady: isLoaded,
  };
};

export default useDeviceInfo;
