import { isEmpty, isNil } from 'ramda';

export const getGenres = (genreList, numberOfGenres) => {
  if (!isNil(genreList) && !isEmpty(genreList)) {
    return genreList.slice(0, numberOfGenres).join(', ');
  }
  return '';
};

export const formatGenres = genres =>
  !isNil(genres) && !isEmpty(genres) ? genres.map(genre => genre.title) : [];
