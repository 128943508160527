import dayjs from 'dayjs';

export const parseDateTime = (dateTime: string | dayjs.Dayjs) => dayjs(dateTime).format('h:mm a');

const getTimeRange = (startTime?: string, endTime?: string): string => {
  if (startTime && endTime) {
    return `${parseDateTime(startTime)} - ${parseDateTime(endTime)}`;
  }
  return '';
};

export default getTimeRange;
