import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';

import { ReactComponent as Left } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_chevron_back.svg';
import { ReactComponent as Right } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_chevron_forward.svg';

import Content from '@/Layouts/containers/Content';
import useScrollList, { HandlePaginate } from '@/Hooks/useScrollList';

import styles from './styles.module.scss';

interface Props {
  dataSetId?: string;
  className?: string;
  scrollLeftOnRender?: boolean;
  isExpandable?: boolean;
  initialPaginateIndex?: number;
  handlePaginate?: HandlePaginate;
}

const TilesList: React.FC<Props> = ({
  dataSetId,
  children,
  className,
  scrollLeftOnRender = false,
  isExpandable = false,
  initialPaginateIndex,
  handlePaginate,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollListRef = useRef<HTMLDivElement>(null);

  const {
    isLeftScrollDisabled,
    isRightScrollDisabled,
    onPaginate,
    onInitialLoad,
    resetButtons,
    initScrollButtons,
    resetScrollPosition,
  } = useScrollList(containerRef, scrollListRef, handlePaginate);

  // scroll left on data change
  useEffect(() => {
    if (scrollLeftOnRender) {
      resetScrollPosition();
      // Reset all the buttons availability
      resetButtons();
    }
    // When list items has been changed, eg Search result.
  }, [scrollLeftOnRender, resetButtons, dataSetId, resetScrollPosition]);

  useEffect(() => {
    initScrollButtons();
  }, [children, initScrollButtons]);

  useEffect(() => {
    if (initialPaginateIndex) {
      onInitialLoad(initialPaginateIndex);
    }
  }, [children, initialPaginateIndex, onInitialLoad]);

  return (
    <div
      ref={containerRef}
      className={classnames(styles.tilesContainer, className, {
        [styles.tilesContainerExpandable]: isExpandable,
      })}
      data-testid="scrollable-list-container"
    >
      <Content
        ref={scrollListRef}
        className={classnames(styles.tilesList, {
          [styles.tilesListExpandable]: isExpandable,
        })}
      >
        {children}
      </Content>
      <button
        type="button"
        className={classnames(styles.scrollButton, styles.scrollButtonLeft, {
          [styles.scrollButtonExpandable]: isExpandable,
          [styles.scrollButtonDisabled]: isLeftScrollDisabled,
        })}
        onClick={() => onPaginate(false)}
        data-testid="scroll-left-button"
        name="Tiles scroll left button"
      >
        <Left className={styles.scrollButtonIcon} />
      </button>

      <button
        type="button"
        className={classnames(styles.scrollButton, styles.scrollButtonRight, {
          [styles.scrollButtonExpandable]: isExpandable,
          [styles.scrollButtonDisabled]: isRightScrollDisabled,
        })}
        onClick={() => onPaginate(true)}
        data-testid="scroll-right-button"
        name="Tiles scroll right button"
      >
        <Right className={styles.scrollButtonIcon} />
      </button>
    </div>
  );
};

export default TilesList;
