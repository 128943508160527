import React, { FC } from 'react';
import classnames from 'classnames';

import Icon from '@ant-design/icons';

import { ReactComponent as Play } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_play.svg';
import { ReactComponent as Pause } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_pause.svg';
import { ReactComponent as PlayBack } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_scrubbing_back.svg';
import { ReactComponent as PlayForward } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_scrubbing_forward.svg';

import styles from './styles.module.scss';

export interface Props {
  isShow?: boolean;

  isLivePlayer?: boolean;
  durationJump: number;
  isPlaying: boolean;
  onIsPlayingChange: (newIsPlaying: boolean) => void;
  onPlayBack: () => void;
  onPlayForward: () => void;
}

const PlaybackPanel: FC<Props> = ({
  durationJump,
  isPlaying,
  onIsPlayingChange,
  onPlayBack,
  onPlayForward,
}) => {
  return (
    <div className={styles.playbackPanel}>
      <button
        type="button"
        className={classnames(styles.controlButton, styles.playBackBtn)}
        onClick={onPlayBack}
        title="Backward (←)"
        data-testid="video-player-playback-back"
        name="Player playback back button"
      >
        <Icon className={styles.buttonIcon} component={PlayBack as FC} />
        <span className={classnames(styles.durationJump, styles.durationJumpBack)}>
          {durationJump}
        </span>
      </button>
      <button
        type="button"
        className={classnames(styles.controlButton, styles.playBtn)}
        onClick={() => onIsPlayingChange(!isPlaying)}
        title={`${isPlaying ? 'Pause' : 'Play'} (k)`}
        data-testid="video-player-playback-play-pause"
        name="PLayer playback play/pause button"
      >
        <Icon className={styles.buttonIcon} component={isPlaying ? Pause : Play} />
      </button>
      <button
        type="button"
        className={classnames(styles.controlButton, styles.playForwardBtn)}
        onClick={onPlayForward}
        title="Forward (→)"
        data-testid="video-player-playback-forward"
        name="Player playback forward button"
      >
        <Icon className={styles.buttonIcon} component={PlayForward as FC} />
        <span className={classnames(styles.durationJump, styles.durationJumpForward)}>
          {durationJump}
        </span>
      </button>
    </div>
  );
};

export default PlaybackPanel;
