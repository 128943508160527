import { Modal, Button } from 'antd';
import React from 'react';

export const wrongAlbumModal = onOk =>
  Modal.info({
    title: 'Listening to something else?',
    content: (
      <div>
        <p>Looks like new album is playing. Please switch to a native Spotify device</p>
      </div>
    ),
    onOk,
    onCancel: onOk,
  });

export const notPremiumAccountModal = () =>
  Modal.info({
    title: 'Got Spotify Premium?',
    content: (
      <div>
        <p>In order to be able to play soundtracks you need premium spotify subscription</p>
        <Button onClick={() => window.open('https://www.spotify.com/nz/premium/', '_blank')}>
          GET PREMIUM
        </Button>
      </div>
    ),
  });
