import React, { useEffect, useRef } from 'react';

const useCurrent = <T>(state: T): React.MutableRefObject<T> => {
  const ref = useRef<T>(state);

  useEffect(() => {
    ref.current = state;
  }, [state]);

  return ref;
};

export default useCurrent;
