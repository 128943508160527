import React, { useState, useCallback } from 'react';
import classnames from 'classnames';
import { Button } from 'antd';
import Icon from '@ant-design/icons';

import { ReactComponent as Logo } from '@skytvnz/sky-app-style/lib/assets/svg/logos/logo_skygo_app.color.svg';
import { ReactComponent as Close } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_close.svg';

import useMedia from '@/Hooks/useMedia';
import { IS_ANDROID, IS_IOS } from '@/Utils/Browser';
import { ANDROID_APP_LINK, IOS_APP_LINK } from '@/Utils/StoreLinks';

import styles from './styles.module.scss';

const AppLink: React.FC = () => {
  const { isMediaM } = useMedia();
  const [isShow, setIsShow] = useState(true);

  const onClose = useCallback(event => {
    event.preventDefault();
    setIsShow(false);
  }, []);

  return isShow && !isMediaM && (IS_ANDROID || IS_IOS) ? (
    <a href={IS_ANDROID ? ANDROID_APP_LINK : IOS_APP_LINK}>
      <div className={styles.appLink}>
        <div className={styles.col}>
          <Logo className={styles.appIcon} />
          <div className={classnames(styles.appInfo, styles.appTitle)}>
            <span>Sky Go</span>
            <span className={styles.appDescription}>
              Get - on {IS_IOS ? 'App' : 'Google Play'} Store
            </span>
          </div>
        </div>
        <div className={styles.col}>
          <span className={classnames(styles.appText, styles.appTitle)}>Open</span>
          <Button
            type="link"
            className={styles.closeButton}
            onClick={onClose}
            data-testid="app-link-close-button"
            name="App link close button"
          >
            <Icon className={styles.closeIcon} component={Close as React.FC} />
          </Button>
        </div>
      </div>
    </a>
  ) : null;
};

export default AppLink;
