import React, { FC } from 'react';
import classnames from 'classnames';
import { Button } from 'antd';
import Icon from '@ant-design/icons';

import { ReactComponent as Live } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_live.color.svg';

import styles from './styles.module.scss';
import { MediaInfo } from '../../VideoTypes';

interface Props {
  mediaInfo: MediaInfo;
  isLivePlayer?: boolean;
  isLive?: boolean;
  isShow?: boolean;
  onLiveTagClick: () => void;
  hasPlayerPlaybackDataLoaded?: boolean;
}

const TitlePanel: FC<Props> = ({
  mediaInfo: { title = '', secondaryTitle = '', logoUrl, channelName, timeRange },
  isLivePlayer,
  isLive,
  onLiveTagClick,
  hasPlayerPlaybackDataLoaded,
}) => {
  const videoTitle = `${title}${secondaryTitle ? `: ${secondaryTitle}` : ''}`;
  return (
    <div className={classnames(styles.titlePanel, { [styles.isLive]: isLive })}>
      {logoUrl && (
        <span className={styles.logoContainer}>
          <img src={logoUrl} alt={channelName} data-testid="video-player-video-channel-logo" />
        </span>
      )}
      <div className={styles.titleSection}>
        <h3 className={styles.title} title={videoTitle} data-testid="video-player-video-title">
          {videoTitle}
        </h3>
      </div>
      {timeRange && (
        <span className={styles.slotTime} data-testid="video-player-video-slot-time">
          {timeRange}
        </span>
      )}
      {hasPlayerPlaybackDataLoaded && isLivePlayer && isLive && (
        <span className={styles.liveTag}>
          <Icon className={styles.liveIcon} component={Live} />
          LIVE
        </span>
      )}

      {hasPlayerPlaybackDataLoaded && isLivePlayer && !isLive && (
        <Button
          onClick={onLiveTagClick}
          type="primary"
          className={classnames(styles.liveBtn, {
            'testclass-video-player-video-live-tag-islive': isLive,
          })}
          data-testid="video-player-video-live-tag"
          name="Player video Live tag"
        >
          WATCH LIVE
        </Button>
      )}
    </div>
  );
};

export default TitlePanel;
