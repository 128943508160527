import React, { useEffect, useMemo } from 'react';
import { isNil } from 'ramda';
import Fade from 'react-reveal/Fade';

import { Movie, Show } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import { utils } from '@/Store';
import { WhatsOnTVTabType } from '@skytvnz/sky-app-store/lib/types/enums/WhatsOnTVTabType';
import ViewingContextButton from '@/Pages/Detail/WhatsOnTv/ViewingContext/ViewingContextButton';
import RecentEpisodeTile from '@/Pages/Detail/Tiles/RecentEpisodeTile';
import UpcomingTile from '@/Pages/Detail/Tiles/UpcomingTile';
import Content from '@/Layouts/containers/Content';
import TilesList from '@/Components/TilesList';
import { useSlotSubscriptions } from '@/Hooks/useSubscription';
import { isLiveSlotEndingSoon } from '@/Utils/Slots';

import styles from './styles.module.scss';

interface Props {
  brand: Show | Movie;
}

const ViewingContext: React.FC<Props> = props => {
  const { brand } = props;
  const getRequiredSubscriptions = useSlotSubscriptions();
  const [viewingContext, setViewingContext] = React.useState('');

  const defaultWhatsOnTVTab = utils.title.getDefaultWhatsOnTVTab(brand);
  useEffect(() => {
    setViewingContext(defaultWhatsOnTVTab);
  }, [defaultWhatsOnTVTab]);

  const hasRecentShows =
    brand.__typename === 'Show' && !isNil(brand.episodes) && brand.episodes.length > 0;

  const isSport = utils.title.isSport(brand);

  const hasUpcoming = !isNil(brand.slots) && brand.slots.length > 0;

  const handleClick = (selected: WhatsOnTVTabType) => {
    setViewingContext(selected);
  };

  const isDisplayRecentShows = viewingContext === WhatsOnTVTabType.Recent && hasRecentShows;

  const isDisplayUpcomingShows = viewingContext === WhatsOnTVTabType.Upcoming && hasUpcoming;

  const slots = useMemo(() => {
    if (hasUpcoming) {
      if (isLiveSlotEndingSoon(brand.slots[0]) && brand.slots.length > 1) {
        return brand.slots.slice(1);
      }
      return brand.slots;
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand.slots]);

  return (
    <>
      <Content className={styles.viewingContextContainer}>
        {hasRecentShows && (
          <ViewingContextButton
            isSelected={viewingContext === WhatsOnTVTabType.Recent}
            onClick={handleClick}
            viewingContext={WhatsOnTVTabType.Recent}
          />
        )}
        {hasUpcoming && (
          <ViewingContextButton
            isSelected={viewingContext === WhatsOnTVTabType.Upcoming}
            onClick={handleClick}
            viewingContext={WhatsOnTVTabType.Upcoming}
          />
        )}
      </Content>
      {isDisplayRecentShows && (
        <Fade>
          <TilesList>
            {brand.__typename === 'Show' &&
              brand.episodes.map(episode => {
                return (
                  <RecentEpisodeTile
                    key={`item: ${episode?.id}`}
                    episode={episode}
                    brandId={brand.id}
                    isSport={isSport}
                  />
                );
              })}
          </TilesList>
        </Fade>
      )}
      {isDisplayUpcomingShows && (
        <Fade>
          <TilesList>
            {slots.map((slot, index) => {
              const requiredSubscriptions = getRequiredSubscriptions(slot);
              const analyticsParams = {
                contentTitle: utils.analytics.getSegmentSlotTitleWithBrand(
                  slot,
                  isSport,
                  brand.title,
                ),
                genres: utils.analytics.getSegmentBrandGenres(brand),
                channel: utils.analytics.getSegmentSlotChannel(slot?.channel),
              };
              return (
                <UpcomingTile
                  key={`item: ${slot?.channel}-${index + 1}`}
                  slot={slot}
                  index={index}
                  genre={brand.primaryGenres[0] ? brand.primaryGenres[0].title : ''}
                  requiredSubscriptions={requiredSubscriptions}
                  origin="Upcoming"
                  analyticsParams={analyticsParams}
                  isSport={isSport}
                  brandTitle={brand.title}
                  contentType={utils.analytics.getContentTypeByBrand(brand)}
                />
              );
            })}
          </TilesList>
        </Fade>
      )}
    </>
  );
};

export default ViewingContext;
