import React, { FC, useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import useAuth from '@/Hooks/useAuth';
import redirectToLogin from '@/Utils/redirectToLogin';

const PrivateRoute: FC<RouteProps> = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }
    redirectToLogin(true);
  }, [isAuthenticated]);

  return (
    <Route
      render={props =>
        // eslint-disable-next-line react/jsx-props-no-spreading
        isAuthenticated === true && Component ? <Component {...props} /> : null
      }
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};

export default PrivateRoute;
