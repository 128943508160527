import React, { FC } from 'react';

import { Button } from 'antd';
import Icon from '@ant-design/icons';
import classnames from 'classnames';
import { ReactComponent as Plus } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_plus.svg';
import { ReactComponent as Tick } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_tick.svg';
import styles from './styles.module.scss';

interface Props {
  onMyList: boolean;
  onToggle: any;
}

const MyListButton: FC<Props> = props => {
  const { onMyList, onToggle } = props;

  return (
    <Button
      className={classnames(styles.heroButton, styles.borderlessButton)}
      type="link"
      data-testid="hero-banner-mylist-button"
      name="Hero banner MyList button"
      onClick={onToggle}
    >
      <Icon
        className={styles.heroButtonIcon}
        component={onMyList ? (Tick as React.FC) : (Plus as React.FC)}
      />
      <span className={styles.heroButtonText}>
        {onMyList ? 'ADDED TO WATCHLIST' : 'MY WATCHLIST'}
      </span>
    </Button>
  );
};

export default MyListButton;
