import React, { FC, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';

import PinInputField from './PinInputField';

import styles from './styles.module.scss';

export interface Props {
  pin: string[];
  pinTotal: number;
  onPinChange(pin: string[]): void;
  className?: string;
  error?: string | boolean;
}

const PinInputControl: FC<Props> = ({ className, pin, pinTotal, onPinChange, error }) => {
  const [activePinIndex, setActivePinIndex] = useState(0);

  const handelSinglePinChange = useCallback(
    (value: string, pinIndex: number) => {
      const newPin = [...pin];
      newPin[pinIndex] = value;

      onPinChange(newPin);
    },
    [onPinChange, pin],
  );

  const handelPinDelete = useCallback(
    (pinIndex: number) => {
      if (pinIndex > 0) {
        handelSinglePinChange('', pinIndex - 1);
        setActivePinIndex(pinIndex - 1);
      }
    },
    [handelSinglePinChange],
  );

  useEffect(() => {
    // Make next Pin field as active input to be focus
    let newIndex = pin.findIndex(singlePin => !singlePin);
    if (newIndex < 0) {
      newIndex = pinTotal;
    }
    setActivePinIndex(newIndex);
  }, [pin, pinTotal]);

  return (
    <div className={classnames(styles.pinInputControl, className)}>
      <div className={styles.inputPins}>
        {pin.map((value, index) => (
          <PinInputField
            // eslint-disable-next-line react/no-array-index-key
            key={`pin-${index}`}
            value={value}
            index={index}
            isFocus={activePinIndex === index}
            onChange={handelSinglePinChange}
            onDelete={handelPinDelete}
          />
        ))}
      </div>
      {error && (
        <span className={styles.inputError} data-testid="pin-control-error">
          {error}
        </span>
      )}
    </div>
  );
};

export default PinInputControl;
