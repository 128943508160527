import React, { FC } from 'react';
import classnames from 'classnames';

import { Button } from 'antd';
import buttonStyle from '@/Components/Button/styles.module.scss';
import Content from '@/Layouts/containers/Content';
import useHistoryBack from '@/Hooks/useHistoryBack';
import styles from './styles.module.scss';

export const defaultErrorTitle = 'Whoops, we’re having some problems';
export const defaultErrorSubTitle = 'Looks like something went wrong, please try again later';

interface Props {
  title?: string;
  subTitle?: string;
  buttonText?: string;
  onButtonClick?: () => void;
  secondButtonText?: string;
  onSecondButtonClick?: () => void;
  className?: string;
}
const ErrorMessage: FC<Props> = ({
  buttonText,
  onButtonClick,
  secondButtonText,
  onSecondButtonClick,
  title,
  subTitle,
  className = '',
}) => {
  const historyBack = useHistoryBack();
  const handleButtonClick = onButtonClick || historyBack;

  return (
    <Content className={className}>
      <div className={styles.errorMessageContainer} data-testid="error-message">
        <h1 className={styles.header} data-testid="error-message-header">
          {title || defaultErrorTitle}
        </h1>
        <p className={styles.messageContent} data-testid="error-message-text">
          {subTitle || defaultErrorSubTitle}
        </p>
        <Button
          type="primary"
          className={classnames(buttonStyle.controlButton, buttonStyle.primary, styles.button)}
          onClick={handleButtonClick}
          data-testid="error-message-button"
          name="Error message button"
        >
          {buttonText || 'BACK TO HOMEPAGE'}
        </Button>
        {secondButtonText && (
          <Button
            type="primary"
            className={classnames(buttonStyle.controlButton, buttonStyle.primary, styles.button)}
            onClick={onSecondButtonClick}
            data-testid="error-message-button"
            name="Error message button"
          >
            {secondButtonText}
          </Button>
        )}
      </div>
    </Content>
  );
};
export default ErrorMessage;
