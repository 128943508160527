import { useSelector } from 'react-redux';
import { selectors } from '@/Store';

const useBrowseData = (collectionId: string) => {
  const collectionIsLoading = useSelector(selectors.collections.isLoading);
  const collection = useSelector(selectors.collections.getCollection)(collectionId);
  const selectedFilters = useSelector(selectors.collections.getCollectionSelectedFilters)(
    collectionId,
  );
  const genresOptions = useSelector(selectors.collections.getGenreFilterOptions)(collectionId);
  const hasLoadingError = useSelector(selectors.collections.error);

  return {
    collectionIsLoading,
    collection,
    selectedFilters,
    genresOptions,
    hasLoadingError,
  };
};

export default useBrowseData;
