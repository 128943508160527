import React from 'react';
import classnames from 'classnames';
import Content from '@/Layouts/containers/Content';
import { Button } from 'antd';
import { ReactComponent as All } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_all.svg';
import styles from './styles.module.scss';

interface Props {
  isExpanded: boolean;
  expandAlbum: any;
}

const SeeAll: React.FC<Props> = props => {
  const { isExpanded, expandAlbum } = props;
  return (
    <Content className={styles.spotifySeeAll}>
      <Button
        type="link"
        className={classnames(styles.linkBtn, styles.seeAllBtn)}
        onClick={expandAlbum}
      >
        <All className={styles.footerBtnIcon} />
        <span className={styles.footerBtnText}>{isExpanded ? 'HIDE' : 'SEE ALL'}</span>
      </Button>
    </Content>
  );
};

export default SeeAll;
