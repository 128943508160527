import { Maybe, WatchProgress } from '@skytvnz/sky-app-store/lib/types/graph-ql';

import { convertDurationToSeconds } from './DurationFormatter';

const calculateProgress = (duration?: string, watchProgress?: Maybe<WatchProgress>): number => {
  if (watchProgress && duration) {
    return watchProgress.complete
      ? 100
      : (100 / convertDurationToSeconds(duration)) *
          convertDurationToSeconds(watchProgress.position);
  }
  return 0;
};

export default calculateProgress;
