import React, { FC, useRef, useEffect } from 'react';
import { isEmpty, isNil } from 'ramda';
import classnames from 'classnames';

import { ImageAssetType } from '@skytvnz/sky-app-store/lib/types/enums/ImageAssetType';

import Progress from '@/Components/Progress';
import HoverUpgradeToWatch from '@/Pages/Detail/Tiles/HoverUpgradeToWatch';
import { formatRating } from '@/Utils/Rating';
import { getVideoImg } from '@/Utils/VideoImage';

import LiveTag from '@/Components/LiveTag';
import { DrawerContentItem } from '../../../../VideoTypes';

import styles from './styles.module.scss';

interface ContentItemProps {
  isSelected?: boolean;
  isLiveChannel?: boolean;
  content: DrawerContentItem;
  onSelect?: (id: string) => void;
}

const ContentItem: FC<ContentItemProps> = ({
  content: { id, image, title, subTitle, rating, synopsis, progress, requiredSubscriptions, live },
  isLiveChannel = false,
  isSelected = false,
  onSelect,
}) => {
  const subscriptionsRequired = !isNil(requiredSubscriptions) && !isEmpty(requiredSubscriptions);

  const selectedContentRef = useRef<any>();

  useEffect(() => {
    if (isSelected) {
      selectedContentRef?.current?.scrollIntoView({ block: 'center' });
    }
  }, [isSelected]);
  return (
    <li>
      <button
        type="button"
        className={classnames(styles.contentItem, {
          [styles.selected]: isSelected,
          [styles.isLiveChannel]: isLiveChannel,
        })}
        onClick={() => onSelect?.(id)}
        data-testid="video-player-drawer-content-item"
        name="Player drawer content item"
        ref={selectedContentRef}
      >
        <span className={styles.contentImage}>
          <img alt={title} src={getVideoImg(ImageAssetType.ContentTileVertical, image)} />
          <Progress
            className={styles.progress}
            percent={progress}
            dataTestIdOuter="progress-bar"
            dataTestIdInner="progress-bar-play"
          />
          {subscriptionsRequired && <HoverUpgradeToWatch className={styles.upgradeHove} />}
        </span>
        <div className={styles.information}>
          <p className={styles.title} data-testid="video-player-drawer-content-item-title">
            {live && <LiveTag className={styles.liveTag} />} {title}
          </p>
          <p className={styles.subTitle}>
            {subTitle}
            {rating && <span className={styles.rating}>{formatRating(rating)}</span>}
          </p>
          <p className={styles.synopsis}>{synopsis}</p>
        </div>
      </button>
    </li>
  );
};

export default ContentItem;
