// TODO:
// Later, could be potentially changed to data driven events using Redux pattern
// like an app level global store (state+reducer)

import EventEmitter from 'events';

export enum AppEvent {
  SpotifyPlayerPlayingChange = 'SpotifyPlaying',
  VideoPlayerPlayingChange = 'VideoPlaying',
}

const AppEventBus = new EventEmitter.EventEmitter();

export default AppEventBus;
