import qs from 'query-string';

export function parseURLParams(searchOrHashString: string) {
  return qs.parse(searchOrHashString);
}

export function getSearchParams() {
  return parseURLParams(window.location.search);
}

export function getHashParams() {
  return parseURLParams(window.location.hash);
}
