import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { parseURLParams } from '@/Utils/URLParams';

const useQueryString = () => {
  const location = useLocation();
  return useMemo(() => parseURLParams(location.search), [location.search]);
};

export default useQueryString;
