import React, { FC } from 'react';

import Icon from '@ant-design/icons';
import classnames from 'classnames';

import { ReactComponent as SubtitlesIcon } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_subtitles.svg';
import { ReactComponent as SettingsIcon } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_settings.svg';

import styles from './styles.module.scss';

export interface Props {
  isShow?: boolean;
}

const SettingsPanel: FC<Props> = () => {
  // return null;
  // // eslint-disable-next-line no-unreachable
  return (
    <div className={classnames(styles.settingsPanel)}>
      <button
        type="button"
        className={classnames(styles.controlButton, styles.audioSubtitlesBtn)}
        title="Audio &amp; Subtitles"
        data-testid="video-player-audio-subtitles"
        name="Player audio/subtitles button"
      >
        <Icon className={styles.buttonIcon} component={SubtitlesIcon} />
        <span>Audio &amp; Subtitles</span>
      </button>
      <button
        type="button"
        className={classnames(styles.controlButton, styles.settingsBtn)}
        title="Settings"
        data-testid="video-player-settings"
        name="Player settings"
      >
        <Icon className={styles.buttonIcon} component={SettingsIcon} />
      </button>
    </div>
  );
};

export default SettingsPanel;
