import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  testid?: string;
}

const Heading: React.FC<Props> = ({ children, className, testid }) => {
  return (
    <h2 className={classnames(styles.heading, className)} data-testid={testid}>
      {children}
    </h2>
  );
};

export default Heading;
