import { useGesture } from 'react-use-gesture';

const useHorizontalDrag = (
  onDragEnd: (isForward: boolean) => void,
  elementRef,
  isEnabled = true,
) => {
  const swipeAndDrag = useGesture(
    {
      onDragEnd: state => {
        const {
          direction: [dx],
        } = state;

        const d = Math.round(dx);
        if (d !== 0) {
          onDragEnd(d < 0);
        }
      },
      onWheelEnd: ({ direction: [dx] }) => {
        const d = Math.round(dx);
        if (d !== 0) {
          onDragEnd(d > 0);
        }
      },
    },
    {
      domTarget: elementRef,
      enabled: isEnabled,
    },
  );

  return { swipeAndDrag };
};

export default useHorizontalDrag;
