export interface ResolveExecution<T> {
  promise: Promise<T>;
  load: Function;
}

type MetaResolve = <T>() => ResolveExecution<T>;

export const createResolve: MetaResolve = <T>() => {
  const resolveExecution: ResolveExecution<T> = {
    promise: new Promise(() => {}),
    load: () => {},
  };
  resolveExecution.promise = new Promise<T>(resolve => {
    resolveExecution.load = resolve;
  });

  return resolveExecution;
};

const MetaLoader = async <T>(
  playerLoader: Promise<void>,
  metaResolve: ResolveExecution<T>,
): Promise<T> => {
  const [pendedResult] = await Promise.all([metaResolve.promise, playerLoader]);
  return pendedResult;
};

export default MetaLoader;
