import React, { FC, useCallback } from 'react';
import Button from '@/Components/Button';
import { useSubscriptionModal } from '@/Hooks/useSubscription';
import useAuth from '@/Hooks/useAuth';
import redirectToLogin from '@/Utils/redirectToLogin';
import { recordRemoteRecordInitialized } from '@/Analytics/RemoteRecord';
import { useRemoteRecord } from '@/Components/RemoteRecordModal';
import {
  Maybe,
  SkySubscription,
  LinearSlot,
  LinearChannel,
} from '@skytvnz/sky-app-store/lib/types/graph-ql';
import { utils } from '@/Store';

interface RecordButtonProps {
  slot?: Maybe<LinearSlot>;
  channel?: Maybe<LinearChannel>;
  requiredSubscriptions: Array<Maybe<SkySubscription>> | undefined;
  subscriptionAnalyticParams: any;
  remoteRecordAnalyticParams: any;
  isSecordary?: boolean;
  testId?: string;
  className?: string;
  brandTitle?: string;
  isSport?: boolean;
}

const RecordButton: FC<RecordButtonProps> = ({
  slot,
  channel,
  requiredSubscriptions,
  subscriptionAnalyticParams,
  remoteRecordAnalyticParams,
  isSecordary,
  testId,
  className,
  brandTitle,
  isSport,
}) => {
  const showUpgradeSubscriptionModal = useSubscriptionModal();
  const auth = useAuth();
  const openRemoteRecord = useRemoteRecord();
  const isLive = utils.slot.isSlotOnAir(slot || undefined);
  const isRecordingAvailable = utils.slot.isRecordable(slot || undefined);

  const upgradeSubscription = useCallback(() => {
    showUpgradeSubscriptionModal(requiredSubscriptions, subscriptionAnalyticParams, true);
  }, [showUpgradeSubscriptionModal, requiredSubscriptions, subscriptionAnalyticParams]);

  const remoteRecord = useCallback(() => {
    if (auth?.isAuthenticated) {
      openRemoteRecord(
        slot || undefined,
        channel || undefined,
        remoteRecordAnalyticParams,
        brandTitle,
        isSport,
      );
      recordRemoteRecordInitialized();
    } else {
      redirectToLogin();
    }
  }, [openRemoteRecord, brandTitle, isSport, slot, channel, remoteRecordAnalyticParams, auth]);

  return isRecordingAvailable ? (
    <Button
      isLive={isLive}
      isSecordary={isSecordary}
      onClick={requiredSubscriptions ? upgradeSubscription : remoteRecord}
      text={requiredSubscriptions ? 'Upgrade to Record' : 'Record'}
      testId={testId}
      className={className}
    />
  ) : null;
};

export default RecordButton;
