import { init as initApm } from '@elastic/apm-rum';
import pkg from '../../package.json';

const APMAgent = {
  init: () =>
    initApm({
      serviceName: pkg.name.split('/')[1],
      serverUrl: process.env.ELASTIC_APM_SERVER_URL,
      serviceVersion: pkg.version,
      distributedTracingOrigins: [process.env.ELASTIC_APM_TRACING_ORIGIN as string],
      environment: process.env.ENV,

      /*
      Sample the amount of data gathered from the Prod env
      as we don't need to store tens of millions of transactions.
      0.1 means that we'll receive 10% of transactions, this is
      ample for general performance monitoring considering the
      volume coming from the web. And can change when actually using it.
      (see https://www.elastic.co/guide/en/apm/agent/rum-js/current/performance-tuning.html#performance-sampling)
      */
      transactionSampleRate: process.env.ENV === 'PRODUCTION' ? 0.1 : 1.0,
      // Disable all http-request transactions for now. These are 99.3% of all transactions(actual)
      // https://www.elastic.co/guide/en/apm/agent/rum-js/current/configuration.html#disable-instrumentations
      // disableInstrumentations: ['xmlhttprequest', 'fetch'],
      // Ignoring all third party from the APM
      ignoreTransactions: [
        new RegExp('.+youbora.+'),
        new RegExp('.+split.io.+'),
        new RegExp('.+expressplay.+'),
        new RegExp('.+boltdns.+'),
        new RegExp('.+segment.+'),
        new RegExp('.+akamaized.+'),
        new RegExp('.+sentry.io.+'),
        new RegExp('.+oauth+'),
      ],
      // Limiting the number of events to 5 per minute
      eventsLimit: 5,
    }),
};

export default APMAgent;
