import React from 'react';
import classNames from 'classnames';
import Icon from '@ant-design/icons';

import { ReactComponent as Play } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_play.svg';

import styles from './styles.module.scss';

const HoverUpgradeToWatch = ({ className = '' }) => {
  return (
    <div
      className={classNames(styles.upgradeToWatch, className)}
      role="presentation"
      data-testid="hover-upgrade-to-watch"
    >
      <div
        className={classNames(styles.upgradeToWatchCallToAction)}
        role="presentation"
        data-testid="hover-upgrade-to-watch-call-to-action"
      >
        <div className={classNames(styles.upgradePlayIcon, styles.playTag)}>
          <Icon component={Play as React.FC} />
        </div>
        <div className={styles.upgradeToWatchText}>UPGRADE TO WATCH</div>
      </div>
    </div>
  );
};

export default HoverUpgradeToWatch;
