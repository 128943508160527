import { Collection, Maybe } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import { isNil } from 'ramda';
import React, { FC } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

interface ControllingChannelProps {
  channels: Array<Maybe<Collection>>;
  isHome: boolean;
}

const ControllingChannel: FC<ControllingChannelProps> = ({ channels, isHome }) => {
  return (
    !isNil(channels) &&
    channels.length > 0 && (
      <div
        className={classnames(
          styles.controllingChannelSection,
          isHome && styles.controllingChannelMedia,
        )}
        data-testid="controlling-channel-section"
      >
        {channels.map((channel, index) => (
          <span
            className={styles.controllingChannelImgContainer}
            data-testid={`controlling-channel-logo-${index}`}
          >
            <img
              className={styles.controllingChannelImg}
              src={channel?.logoImage.uri}
              alt={channel?.title}
            />
          </span>
        ))}
      </div>
    )
  );
};

export default ControllingChannel;
