import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

export enum BackgroundType {
  Default = 'default',
  Simple = 'simple',
}

interface Props {
  type: BackgroundType;
}

const Background: React.FC<Props> = ({ children, type = BackgroundType.Default }) => {
  return <div className={classnames(styles.bg, { [styles[type]]: true })}>{children}</div>;
};

export default Background;
