import React from 'react';
import { Carousel as AntCarousel } from 'antd';
import styles from './styles.module.scss';

interface Props {
  className?: string;
  duration?: number;
  children: any;
}

const Carousel: React.FC<Props> = ({ children, className, duration = 5 }) => (
  <AntCarousel
    className={className}
    autoplay
    autoplaySpeed={duration * 1000}
    speed={2000}
    waitForAnimate={false}
    pauseOnDotsHover
    fade
    dots={{ className: styles.dots }}
  >
    {children}
  </AntCarousel>
);

export default Carousel;
