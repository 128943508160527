import { isNil } from 'ramda';
import { WatchProgress } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import { convertDurationToSeconds } from './DurationFormatter';

const getPlayStartTime = (watchProgress?: WatchProgress): number => {
  if (!isNil(watchProgress?.complete)) {
    return watchProgress?.complete ? 0 : convertDurationToSeconds(watchProgress?.position);
  }
  return 0;
};

export default getPlayStartTime;
