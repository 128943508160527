import React, { FC, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import pluralize from 'pluralize';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface SlotProgressProps {
  slotStart?: any;
  slotEnd?: any;
  channelTitle?: string;
  alwaysVisible?: boolean;
  progressBarWidth?: any;
}

const SlotProgress: FC<SlotProgressProps> = ({
  slotStart,
  slotEnd,
  channelTitle,
  alwaysVisible = false,
  progressBarWidth,
}) => {
  const [currentTime, setCurrentTime] = useState(dayjs());

  useEffect(() => {
    if (currentTime.isBefore(dayjs(slotEnd))) {
      const intervalId = setInterval(() => {
        const newCurrentTime = dayjs();
        if (newCurrentTime.isBefore(dayjs(slotEnd))) {
          setCurrentTime(newCurrentTime);
        } else {
          setCurrentTime(dayjs(slotEnd));
          clearInterval(intervalId);
        }
      }, 60 * 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
    return undefined;
  }, [slotEnd, currentTime]);

  const secondsLeft = dayjs(slotEnd).diff(currentTime, 'second');
  const secondsTotal = dayjs(slotEnd).diff(dayjs(slotStart), 'second');
  const hrs = secondsLeft > 3600 ? pluralize('hr', Math.floor(secondsLeft / 3600), true) : null;
  const mins = pluralize('min', Math.floor((secondsLeft % 3600) / 60), true);

  const formattedChannelTitle = channelTitle ? ` on ${channelTitle}` : '';
  const remaining = useMemo(() => {
    if (secondsLeft <= 0) {
      return 'Finished';
    }
    if (secondsLeft > 0 && secondsLeft < 60) {
      return 'Ending';
    }
    return `${hrs ? `${hrs} ` : ''}${mins} remaining${formattedChannelTitle}`;
  }, [formattedChannelTitle, hrs, mins, secondsLeft]);
  const percentage = Math.min(100, 100 - (100 / secondsTotal) * secondsLeft);

  const showProgressBar = useMemo(() => {
    if (alwaysVisible) {
      return true;
    }
    return (
      slotStart &&
      slotEnd &&
      currentTime &&
      dayjs(currentTime).isAfter(dayjs(slotStart)) &&
      (dayjs(currentTime).isBefore(dayjs(slotEnd)) || dayjs(currentTime).isSame(dayjs(slotEnd)))
    );
  }, [alwaysVisible, currentTime, slotEnd, slotStart]);

  return (
    <>
      {showProgressBar && (
        <div className={styles.playbackProgress} data-testid="slot-details-playback-progress">
          <span data-testid="slot-details-playback-progress-text">{remaining}</span>
          <div className={classNames(styles.progressBarOuter, progressBarWidth)}>
            <span
              className={styles.progressBarInner}
              style={{ width: `${percentage}%` }}
              data-testid="slot-details-playback-progress-bar"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SlotProgress;
