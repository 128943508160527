import React, { FC, useMemo } from 'react';
import classnames from 'classnames';
import { LiveStatus } from '@skytvnz/sky-app-store/lib/types/enums/LiveStatus';
import { LinearSlot } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import { utils } from '@/Store';
import Lottie from 'lottie-react';
import styles from './styles.module.scss';
import LiveTagAnimationData from './LiveTagAnimationData.json';

export interface LiveTagProps {
  liveStatus?: LiveStatus;
  testId?: string;
  className?: string;
  slot?: LinearSlot;
}

const LiveTag: FC<LiveTagProps> = ({ className, testId, slot }) => {
  const computedliveStatus = useMemo(() => utils.slot.getSportLiveStatusByTime(slot), [slot]);

  return computedliveStatus === LiveStatus.NOT_LIVE ? null : (
    <span data-testid={testId ?? 'slot-live-tag'} className={classnames(styles.liveTag, className)}>
      {computedliveStatus === LiveStatus.LIVE && (
        <Lottie
          animationData={LiveTagAnimationData}
          loop
          autoPlay
          initialSegment={[33, 125]}
          className={styles.liveAnimation}
          style={{ height: 26, width: 26 }}
        />
      )}
      LIVE
    </span>
  );
};

export default LiveTag;
