import { subtitleLanguageChanged } from '@/Analytics/Segment';

const recordAnalyticsForTextTrackChange = (
  label: string,
  title: string,
  channelId?: string,
  slotId?: string,
) => {
  subtitleLanguageChanged({
    subtitle: label ?? 'Off',
    title,
    channel_id: channelId,
    slot_id: slotId ?? 'NA',
  });
};
export default recordAnalyticsForTextTrackChange;
