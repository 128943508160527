import {
  remoteRecordAccountLinkDismissed,
  remoteRecordDismissed,
  remoteRecordInitiated,
  remoteRecordScheduled,
  deviceUpgradeDismissed,
  deviceUpgradeEncountered,
  deviceUpgradePursued,
} from '@/Analytics/Segment';

export const recordRemoteRecordInitialized = () => {
  remoteRecordInitiated();
};

export const recordRemoteRecordDismissed = (
  categoryName: string | undefined,
  channel: string | undefined,
  contentId: string | undefined,
  contentTitle: string | undefined,
  season: number | undefined,
  episode: number | undefined,
  genre: string[] | undefined,
  contentType: string,
  viewName: string,
  livestream: boolean | undefined,
) => {
  remoteRecordDismissed({
    category_name: categoryName,
    channel,
    content_id: contentId,
    content_title: contentTitle,
    season,
    episode,
    genre,
    content_type: contentType,
    view_name: viewName,
    livestream,
  });
};

export const recordRemoteRecordScheduled = (
  categoryName: string | undefined,
  channel: string | undefined,
  contentId: string | undefined,
  contentTitle: string | undefined,
  season: number | undefined,
  episode: number | undefined,
  genre: string[] | undefined,
  contentType: string,
  viewName: string,
  recordType: string,
  deviceName: string | undefined,
  setTopBoxID: string | undefined,
  daysFromToday: number | undefined,
  weekdayName: string | undefined,
  livestream: boolean | undefined,
) => {
  remoteRecordScheduled({
    category_name: categoryName,
    channel,
    content_id: contentId,
    content_title: contentTitle,
    season,
    episode,
    genre,
    content_type: contentType,
    view_name: viewName,
    record_type: recordType,
    device_name: deviceName,
    settopbox_id: setTopBoxID,
    days_from_today: daysFromToday,
    weekday_name: weekdayName,
    livestream,
  });
};

export const recordDeviceUpgradeDismissed = (
  deviceName: (string | undefined)[],
  deviceId: (string | undefined)[],
) => {
  deviceUpgradeDismissed({
    device_name: deviceName,
    settopbox_id: deviceId,
  });
};

export const recordDeviceUpgradeEncountered = (
  deviceName: (string | undefined)[],
  deviceId: (string | undefined)[],
) => {
  deviceUpgradeEncountered({
    device_name: deviceName,
    settopbox_id: deviceId,
  });
};

export const recordRemoteRecordAccountLinkDismissed = (
  deviceName: (string | undefined)[],
  deviceId: (string | undefined)[],
) => {
  remoteRecordAccountLinkDismissed({
    device_name: deviceName,
    settopbox_id: deviceId,
  });
};

export const recordDeviceUpgradePursued = (
  deviceName: (string | undefined)[],
  deviceId: (string | undefined)[],
) => {
  deviceUpgradePursued({
    device_name: deviceName,
    settopbox_id: deviceId,
  });
};
