import { Rating, Classification } from '@skytvnz/sky-app-store/lib/types/graph-ql';

export const formatClassification = (classification?: Classification) => {
  return classification ? classification.replace(/[^0-9a-z]/gi, '') : '';
};

export const joinAdvisories = advisories => {
  return advisories ? advisories.join('') : '';
};

export const formatRating = (rating?: Rating) => {
  return rating?.classification
    ? `${formatClassification(rating.classification)}${joinAdvisories(rating.advisories)}`
    : '';
};
