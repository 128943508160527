import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { withTransaction } from '@elastic/apm-rum-react';
import { isNil } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import useIsNewHistory from '@/Hooks/useIsNewHistory';
import Preloader from '@/Layouts/containers/Preloader';
import { usePageHeader } from '@/Components/AppHeader/AppHeader';
import pageViewedAnalytics from '@/Analytics/Page';
import Content from '@/Layouts/containers/Content';
import { actions, selectors } from '@/Store';
import CollectionTable from '@/Components/CollectionTable';
import ErrorMessage from '@/Components/Message';
import ContentType from '@/Utils/ContentTypes';
import styles from './styles.module.scss';

const Collection: React.FC = () => {
  const { collectionId = '' } = useParams<any>();

  const isNewHistory = useIsNewHistory();

  const dispatch = useDispatch();

  const collectionIsLoading = useSelector(selectors.collections.isLoading);
  const collection = useSelector(selectors.collections.getCollection)(collectionId);

  useEffect(() => {
    if (isNewHistory || isNil(collection)) {
      dispatch(actions.collections.fetchHomeCollection(collectionId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewHistory]);

  const pageTitle = `Browse ${collection?.title ? collection?.title : ''}`;
  usePageHeader({
    title: pageTitle,
    description: `Discover the best of Sky, watch your favourite ${pageTitle} anytime, anywhere in New Zealand.`,
  });
  useEffect(() => {
    if (pageTitle) {
      pageViewedAnalytics('Collection');
    }
  }, [pageTitle]);

  return (
    <Preloader isLoading={collectionIsLoading}>
      {isNil(collection) ? (
        <ErrorMessage />
      ) : (
        <Content>
          <span className={styles.collectionName} data-testid="collection-heading">
            {collection.title}
          </span>
          <CollectionTable collection={collection} type={ContentType.Collection} />
        </Content>
      )}
    </Preloader>
  );
};

export default withTransaction('Collection', 'component')(Collection);
