import { contentCardClicked } from '@/Analytics/Segment';

const contentCardClickedAnalytics = (
  id: string,
  title: string,
  page: string,
  contentPosition: number,
  name: string,
  type: string,
  contentIds: any[] | undefined,
  orientation: string,
) => {
  contentCardClicked({
    content_id: id,
    content_position: contentPosition,
    content_title: title,
    content_type: type,
    category_name: name,
    contains: contentIds,
    style: orientation,
    view_name: page,
  });
};

export default contentCardClickedAnalytics;
