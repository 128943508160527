import qs from 'query-string';
import { generatePath } from 'react-router-dom';

import RoutePath from '@/Routes/RoutePath';
import ContentType from '@/Utils/ContentTypes';

export const PLAYER_REFERRER_QUERY_KEY = 'playerReferrer';

export const PLAYER_REFERRER = {
  LIVE_TV: 'Live TV',
  LINEAR_SLOT: 'Linear Slot',
};

export const PLAYER_MODE = {
  linearSlot: 'LinearSlot',
};

export const getCurrentRoute = () =>
  `${window.location.pathname}${window.location.search}${window.location.hash}`;

export const getViewPage = (history: any) => {
  if (history.location.pathname === '/') {
    return 'Home';
  }

  if (
    history.location.pathname.indexOf('/show/') !== -1 ||
    history.location.pathname.indexOf('/movie/') !== -1
  ) {
    return 'Content Details';
  }

  if (history.location.pathname.indexOf('/tv-guide') !== -1) {
    return 'TV Guide';
  }

  if (history.location.pathname.indexOf('/live-tv') !== -1) {
    return 'Live TV';
  }

  return '';
};

export const getWatchPath = (
  history: any,
  brandId: string,
  episodeId?: string,
  playbackOrigin?: string,
) => {
  const params: any = {
    brandId,
  };
  if (episodeId) params.episodeId = episodeId;

  const path = qs.stringifyUrl({
    url: generatePath(RoutePath.watch, params),
    query: {
      playbackOrigin,
      playerReferrer: getViewPage(history),
    },
  });

  return path;
};

export const getDetailPathByType = (
  type: ContentType,
  id: string,
  query?: any,
  episodeid?: string,
): string => {
  let link = '';

  switch (type) {
    case ContentType.BrowseCollection:
      link = generatePath(RoutePath.browseCollection, {
        collectionId: id,
      });
      break;
    case ContentType.LinearChannel:
      link = qs.stringifyUrl({
        url: generatePath(RoutePath.live, { channelId: id }),
        query: {
          playerReferrer: PLAYER_REFERRER.LIVE_TV,
        },
      });
      break;
    case ContentType.Collection:
      link = generatePath(RoutePath.collection, { collectionId: id });
      break;
    case ContentType.Movie:
      link = generatePath(RoutePath.movie, { brandId: id });
      break;
    case ContentType.Show:
      link = generatePath(RoutePath.show, { brandId: id });
      break;
    case ContentType.Episode:
      link = generatePath(RoutePath.watch, { brandId: id, episodeId: episodeid });
      break;
    default:
      break;
  }

  if (query) {
    link = qs.stringifyUrl({
      url: link,
      query,
    });
  }

  return link;
};
