import youbora from 'youboralib';
import 'youbora-adapter-videojs';

// This adapter overloads the Youbora Videojs adapter as we've experienced issues (null pointer errors) with some of it's unused functionality.
const YouboraVideoJsAdapter = youbora.adapters.Videojs.extend({
  loadAdsAdapter() {
    // no-op
  },
  stop() {
    // We`ve experienced some issues when the user leaves to player screen before all the necessary resources is loaded,
    // the reason is a timer that youbora runs, even leaving the player this timer doesnt stop, causing isssues, joinPolling.stop() avoid that behaviour
    this.joinPolling?.stop();
  },
});

export default YouboraVideoJsAdapter;
