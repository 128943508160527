import {
  recordProfileCreated,
  recordProfileDeleted,
  recordProfileNameChanged,
} from '@/Analytics/CustomerProfile';
import { actions, hooks, selectors } from '@/Store';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

const useCustomerProfile = () => {
  const dispatch = useDispatch();
  const isProfileLoading = hooks.useSelector(selectors.customer.accountIsLoading);
  const customerProfiles = hooks.useSelector(selectors.customer.getCustomerProfiles);
  const selectedProfile = hooks.useSelector(selectors.customer.selectedProfile);
  const defaultProfile = useMemo(() => {
    return customerProfiles?.find(profile => profile.isDefault === true);
  }, [customerProfiles]);

  const addNewProfile = useCallback(
    profileName => {
      dispatch(actions.customer.createCustomerProfile(profileName));
      recordProfileCreated(profileName);
    },
    [dispatch],
  );

  const updateProfile = useCallback(
    (profileId, profileName) => {
      dispatch(actions.customer.updateCustomerProfile(profileId, profileName));
      recordProfileNameChanged(profileName);
    },
    [dispatch],
  );

  const deleteProfile = useCallback(
    profileId => {
      const profileName = customerProfiles?.find(profile => profile.id === profileId).name;
      dispatch(actions.customer.deleteCustomerProfile(profileId));
      recordProfileDeleted(profileName);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  return {
    isProfileLoading,
    customerProfiles,
    selectedProfile,
    addNewProfile,
    updateProfile,
    deleteProfile,
    defaultProfile,
  };
};

export default useCustomerProfile;
