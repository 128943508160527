import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { recordRemoteRecordScheduled } from '@/Analytics/RemoteRecord';
import {
  LinearChannel,
  LinearSlot,
  Maybe,
  RecordType,
  Episode,
} from '@skytvnz/sky-app-store/lib/types/graph-ql';

import { actions, selectors, utils } from '@/Store';
import DropDown from '@/Components/DropDown';
import { DropDownItem } from '@/Components/DropDown/DropDown';
import SlotProgress from '@/Components/SlotProgress';
import useSimpleModal from '@/Components/Modal/useSimpleModal';
import { getViewPage } from '@/Utils/PageLocation';
import Button from '@/Components/Button';
import ContentType from '@/Utils/ContentTypes';

import styles from './styles.module.scss';

const SEND_REQUEST_TITLE = 'Request sent';

const SEND_REQUEST_FAILED_TITLE = 'Something went wrong';
const SEND_REQUEST_FAILED_CONTENT =
  'Your Remote Record request has failed, please try again later.';

const createModalContent = (slotName = '', deviceName = '') =>
  `Your request ${
    slotName ? ` for ${slotName} ` : ``
  } has been sent for recording to your ${deviceName} box.`;

export interface ModalParameters {
  categoryName: string | undefined;
  channelName: string | undefined;
  contentTitle: string | undefined;
  history: any;
  genres: string[];
  weekday: string | undefined;
  daysFromToday: number | undefined;
  contentType: string | undefined;
}
export interface Props {
  slot?: LinearSlot;
  channel?: LinearChannel;
  closeModal: any;
  analyticsParams?: ModalParameters;
  // Storybook props:
  decodersSelector?: any;
  brandTitle?: string;
  isSport?: boolean;
}

const RemoteRecordModal: React.FC<Props> = ({
  slot,
  channel,
  closeModal,
  analyticsParams,
  decodersSelector = useSelector,
  brandTitle,
  isSport,
}) => {
  const dispatch = useDispatch();
  const { openModal } = useSimpleModal();
  const decoders = decodersSelector(selectors.remoteRecord.decoders);

  const devicesList: DropDownItem[] = useMemo(
    () =>
      decoders?.reduce((devices, decoder) => {
        if (decoder.recordCapable) {
          devices.push({
            label: decoder.name || decoder.id,
            value: decoder.id,
          });
        }
        return devices;
      }, [] as any),
    [decoders],
  );
  const [decoderId, setDecoderId] = useState<string>();
  const [selectedDecoder, setSelectedDecoder] = useState<Maybe<DropDownItem>>(null);

  const isShow = slot?.programme?.__typename === ContentType.Episode;
  const isSlotLive = utils.slot.isSlotOnAir(slot);

  const onSelectDevice = useCallback((selectedDevice: DropDownItem) => {
    setSelectedDecoder(selectedDevice);
    setDecoderId(selectedDevice?.value);
  }, []);

  const getRecordTitle = useCallback(
    (recordType: RecordType) => {
      if (recordType === RecordType.Series)
        return (slot?.programme as Episode)?.show?.title ?? brandTitle;
      return utils.slot.getSlotTitleWithBrand(
        slot,
        isSport ?? utils.slot.isSport(slot),
        (slot?.programme as Episode)?.show?.title ?? brandTitle,
        false,
        false,
      );
    },
    [slot, brandTitle, isSport],
  );
  const onRecord = useCallback(
    async (recordType: RecordType) => {
      const recordSeries = recordType === RecordType.Series;
      const res = await dispatch(
        actions.remoteRecord.requestRemoteRecord(
          recordSeries,
          slot?.start,
          channel?.id,
          selectedDecoder?.value,
        ),
      );

      if (res.error) {
        openModal({
          title: SEND_REQUEST_FAILED_TITLE,
          content: SEND_REQUEST_FAILED_CONTENT,
        });
      } else {
        recordRemoteRecordScheduled(
          analyticsParams?.categoryName,
          analyticsParams?.channelName,
          slot?.programme?.id,
          analyticsParams?.contentTitle,
          utils.analytics.getSlotSeasonNumber(slot),
          utils.analytics.getSlotEpisodeNumber(slot, analyticsParams?.contentType === 'Sport'),
          analyticsParams?.genres,
          analyticsParams?.contentType || '',
          getViewPage(analyticsParams?.history),
          recordType
            ? `${recordType.charAt(0).toUpperCase() + recordType.slice(1).toLocaleLowerCase()}${
                isSlotLive ? ' - Partial' : ''
              }`
            : '',
          selectedDecoder?.label,
          selectedDecoder?.value,
          analyticsParams?.daysFromToday,
          analyticsParams?.weekday,
          slot?.live,
        );
        openModal({
          title: SEND_REQUEST_TITLE,
          content: createModalContent(getRecordTitle(recordType), selectedDecoder?.label),
          onOk: closeModal,
          onClose: closeModal,
        });
      }
    },
    [
      dispatch,
      slot,
      channel,
      getRecordTitle,
      selectedDecoder,
      openModal,
      closeModal,
      analyticsParams,
      isSlotLive,
    ],
  );

  useEffect(() => {
    if (devicesList && devicesList.length > 0) {
      setDecoderId(devicesList[0].value);
      setSelectedDecoder(devicesList[0]);
    }
  }, [decoders, devicesList]);

  return (
    <>
      <div className={styles.modalContent} data-testid="modal-content">
        <DropDown
          options={devicesList}
          selectedValue={selectedDecoder?.value}
          onChange={onSelectDevice}
          fontColor="#000"
          backgroundColor="#DCDFE1"
          focusBackgroundColor="rgba(255, 255, 255, 0.6)"
          className={styles.dropdownItems}
        />
        <div className={styles.selectedSmartcard}>Smartcard N. {decoderId}</div>
      </div>
      {isSlotLive && (
        <SlotProgress
          data-testid="record-slot-progress"
          slotStart={slot?.start}
          slotEnd={slot?.end}
          channelTitle={slot?.channel?.title}
        />
      )}
      <div className={styles.modalButtonRowContainer} data-testid="record-buttons-container">
        {slot?.recordOptions?.map(recordOption => {
          const notAiringText = isShow ? ' EPISODE' : '';
          const episodeText = isSlotLive ? 'PARTIALLY' : notAiringText;
          return (
            recordOption && (
              <Button
                className={styles.modalButton}
                key={recordOption}
                testId={`record-button-${recordOption.toLowerCase()}`}
                onClick={() => onRecord(recordOption)}
                text={`RECORD ${
                  recordOption === RecordType.Series ? 'SERIES' : episodeText
                }`.trim()}
              />
            )
          );
        })}
      </div>
    </>
  );
};

export default RemoteRecordModal;
