import {
  videoContentStopped,
  videoContentStarted,
  // Type
  VideoContentStarted,
  VideoContentStopped,
} from '@/Analytics/Segment';

export enum VideoEventType {
  Started,
  Playing,
  Paused,
  Resumed,
  Stopped,
  Interrupted,
  SeekStarted,
  SeekCompleted,
  BufferStarted,
  BufferCompleted,
}

export interface VideoContentEventProps extends VideoContentStarted, VideoContentStopped {}

export const recordContentEvent = (type: VideoEventType, props: VideoContentEventProps) => {
  switch (type) {
    case VideoEventType.Started: {
      videoContentStarted(props as VideoContentStarted);
      break;
    }
    case VideoEventType.Stopped: {
      videoContentStopped(props as VideoContentStopped);
      break;
    }
    default: {
      break;
    }
  }
};
