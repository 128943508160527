import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button } from 'antd';
import classnames from 'classnames';
import { parse, toSeconds } from 'iso8601-duration';
import { PlaybackMeta } from '@skytvnz/sky-app-store/lib/types/models/PlaybackMeta';
import { ReactComponent as SkipPlay } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_skip_intro.svg';
import Icon from '@ant-design/icons/lib/components/Icon';
import useSkipIntroAutoPlay from '@/Hooks/useSkipIntroAutoPlay';
import Analytics from '@/Analytics/SkipIntroAutoPlay';
import useMedia from '@/Hooks/useMedia';
import LoadingButton from './LoadingButton/LoadingButton';
import { THRESHOLD_TIME, SKIP_INTO, NEXT_EPISODE, WATCH_CREDITS } from './Constants';

import styles from './styles.module.scss';

export interface SkipIntroAutoplayProps {
  currentTime: number;
  onCurrentTimeChanged: (currentTime: number) => void;
  playBackMeta?: PlaybackMeta;
  brandId?: string;
  episodeId?: string;
  isControlPanalShow: boolean;
  hasVideoEnded: boolean;
  videoLength: number;
  setHasAutoPlayOnEnd: (state: boolean) => void;
  setHasVideoEnded: (state: boolean) => void;
  showAutoPlay: boolean;
  setShowAutoPlay: (state: boolean) => void;
  setAnimationStatus: (state: boolean) => void;
  playNextEpisode: (id: string) => void;
}

const isoTimeToSeconds = (time: string) => (time ? toSeconds(parse(time)) : -1);

const SkipIntroAutoplay: FC<SkipIntroAutoplayProps> = ({
  currentTime,
  onCurrentTimeChanged,
  playBackMeta,
  brandId,
  episodeId,
  isControlPanalShow,
  videoLength,
  setHasAutoPlayOnEnd,
  showAutoPlay,
  hasVideoEnded,
  setShowAutoPlay,
  playNextEpisode,
  setAnimationStatus,
  setHasVideoEnded,
}) => {
  const [showSkipInto, setShowSkipIntro] = useState<boolean>(false);
  const [watchCredits, setWatchCredits] = useState<boolean>(false);

  const { episode, hasNextEpisode, nextEpisodeTitle, episodesLeft } = useSkipIntroAutoPlay(
    brandId,
    episodeId,
  );

  const skipIntroStartPosition = isoTimeToSeconds(playBackMeta?.asset?.skipIntro?.startPosition);
  const skipIntroEndPosition = isoTimeToSeconds(playBackMeta?.asset?.skipIntro?.endPosition);
  const autoPlayStartPosition = isoTimeToSeconds(playBackMeta?.asset?.upNext?.startPosition);

  const recordAnalytics = useCallback(
    buttonType => {
      switch (buttonType) {
        case SKIP_INTO:
          Analytics.recordSkipIntroSelected(episode?.asset?.id, episode?.title);
          break;
        case WATCH_CREDITS:
          Analytics.recordWatchCreditsSelected(episode?.asset?.id, episode?.title);
          break;
        case NEXT_EPISODE:
          Analytics.recordNextEpisodeSelected(episode?.asset?.id, episode?.title);
          break;
        default:
          break;
      }
    },
    [episode],
  );

  useEffect(() => {
    if (skipIntroStartPosition < currentTime && currentTime < skipIntroEndPosition) {
      setShowSkipIntro(true);
    } else {
      setShowSkipIntro(false);
    }

    if (
      hasNextEpisode &&
      autoPlayStartPosition > 0 &&
      ((currentTime >= autoPlayStartPosition && !watchCredits) || (hasVideoEnded && watchCredits))
    ) {
      setShowAutoPlay(true);
    } else {
      setShowAutoPlay(false);
    }
  }, [
    currentTime,
    hasNextEpisode,
    watchCredits,
    skipIntroStartPosition,
    skipIntroEndPosition,
    autoPlayStartPosition,
    setShowAutoPlay,
    hasVideoEnded,
    videoLength,
  ]);

  const onSkipIntroClick = () => {
    onCurrentTimeChanged(skipIntroEndPosition);
    recordAnalytics(SKIP_INTO);
  };

  const onWatchCreditsClick = () => {
    setWatchCredits(true);
    setHasAutoPlayOnEnd(true);
    recordAnalytics(WATCH_CREDITS);
  };

  const onNextEpisodeClick = () => {
    playNextEpisode(episode?.nextEpisode?.id);
    setHasVideoEnded(false);
    recordAnalytics(NEXT_EPISODE);
  };

  const { isMediaM } = useMedia();

  return (
    <div className={styles.skipIntroWrapper}>
      {showSkipInto && (
        <Button
          type="primary"
          data-testid="skip-into-button-test"
          className={
            currentTime <= skipIntroStartPosition + THRESHOLD_TIME || isControlPanalShow
              ? styles.show
              : styles.hide
          }
          onClick={onSkipIntroClick}
        >
          <Icon component={SkipPlay} />
          {SKIP_INTO}
        </Button>
      )}

      {showAutoPlay && (
        <div className={classnames(styles.autoPlay, styles.withNoControls)}>
          {isMediaM && <span data-testid="next-episode-title-test">{nextEpisodeTitle}</span>}
          <div className={styles.autoPlayButtons}>
            {!watchCredits && (
              <Button
                className={styles.outlined}
                onClick={onWatchCreditsClick}
                data-testid="watch-credits-button-test"
              >
                {WATCH_CREDITS}
              </Button>
            )}
            <LoadingButton
              duration={THRESHOLD_TIME}
              onEventTrigger={onNextEpisodeClick}
              testId="next-episode-button-test"
              hasAnimation={!watchCredits}
              setAnimationStatus={setAnimationStatus}
            >
              <Icon component={SkipPlay} />
              {NEXT_EPISODE}
            </LoadingButton>
          </div>
          {episodesLeft && isMediaM && <span data-testid="episodes-left-test">{episodesLeft}</span>}
        </div>
      )}
    </div>
  );
};

export default SkipIntroAutoplay;
