import React, { useEffect } from 'react';
import useAuth from '@/Hooks/useAuth';
import { getLoginUrl } from '@/Utils/redirectToLogin';
import { getSearchParams } from '@/Utils/URLParams';
import RoutePath from '@/Routes/RoutePath';
import useSimpleModal from '@/Components/Modal/useSimpleModal';

const DEFAULT_ERROR_TITLE = "Sorry, we're having trouble signing you in";
const DEFAULT_ERROR_MESSAGE = 'Looks like something went wrong, please try again later';
const AUTH0_ERROR_MSG_MAPPING = {
  'user is not verified':
    'You need to verify your account in order to use Sky Go. Please check your email.',
};

// TODO Currently it handle auth notification only, in the future, we can extend it to handle more.
const AuthNotification: React.FC = () => {
  const { openModal } = useSimpleModal();
  const { error, signOut } = useAuth();
  useEffect(() => {
    if (error) {
      const errorDesc = getSearchParams().error_description;
      const authError = Array.isArray(errorDesc) ? errorDesc[0] : errorDesc;
      openModal({
        title: DEFAULT_ERROR_TITLE,
        content: AUTH0_ERROR_MSG_MAPPING[authError || ''] || DEFAULT_ERROR_MESSAGE,
        okText: 'Try again',
        cancelable: true,
        cancelText: 'Continue',
        onOk: () => {
          // Due to the limitation of auth0, it cannot clear the session unless redirecting to the auth domain.
          // More details are in the issue: https://github.com/auth0/auth0.js/issues/618
          // Here we have to go to auth domain to logout and redirect back to login page.
          signOut(getLoginUrl(), true);
        },
        onClose: () => {
          signOut(RoutePath.home, true);
        },
        onCancel: () => {
          signOut(RoutePath.home, true);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return null;
};

export default AuthNotification;
