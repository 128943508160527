import { searchResultReturned, searchNoResultReturned, ResultsSetItem } from '@/Analytics/Segment';
import { Title } from '@skytvnz/sky-app-store/lib/types/graph-ql';

export const searchResultsAnalytics = (results: Title[], searchTerms: string) => {
  const data: ResultsSetItem[] = (results || []).map(x => ({
    content_id: x.id,
    content_title: x.title,
  }));
  searchResultReturned({
    results_set: data,
    query: searchTerms,
  });
};

export const zeroResultsSearchAnalytics = (searchTerms: string) => {
  searchNoResultReturned({
    query: searchTerms,
  });
};
