import React, { FC } from 'react';
import classnames from 'classnames';

import Icon from '@ant-design/icons';

import { ReactComponent as Fullscreen } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_fullscreen.svg';
import { ReactComponent as FullscreenExit } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_fullscreen_exit.svg';

import styles from './styles.module.scss';

export interface Props {
  isShow?: boolean;

  isFullScreen: boolean;
  onFullScreenClick: (newIsFullScreen: boolean) => void;
}

const FullscreenPanel: FC<Props> = ({ isFullScreen, onFullScreenClick }) => {
  return (
    <div className={styles.fullscreenPanel}>
      <button
        type="button"
        className={classnames(styles.controlButton, styles.fullscreenBtn)}
        onClick={() => onFullScreenClick(!isFullScreen)}
        title={`${isFullScreen ? 'Exit full screen' : 'Full screen'} (f)`}
        data-testid="video-player-fullscreen"
        name="Player full screen button"
      >
        <Icon
          className={styles.buttonIcon}
          component={isFullScreen ? FullscreenExit : Fullscreen}
        />
      </button>
    </div>
  );
};

export default FullscreenPanel;
