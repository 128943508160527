import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import Background, { BackgroundType } from '@/Layouts/containers/Background';
import Header from '@/Components/Header';
import Footer from '@/Components/Footer';
import AppLink from '@/Components/AppLink';

import styles from './styles.module.scss';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
};

const Default: React.FC<InferProps<typeof propTypes>> = ({ children }) => {
  // const { selectedProfile } = useCustomerProfile();
  // const { isAuthenticated } = useAuth();

  return (
    <Background type={BackgroundType.Default}>
      <Header />
      <AppLink />
      <div className={styles.contentWrapper}>{children}</div>
      <Footer />
    </Background>
  );
};

Default.propTypes = propTypes;
Default.defaultProps = defaultProps;

export default Default;
