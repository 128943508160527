import React, { useRef } from 'react';
import { isNil } from 'ramda';

import { Maybe, Episode } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import usePersistCallback from '@/Hooks/usePersistCallback';
import useCurrent from '@/Hooks/useCurrent';
import TileSize from '@/Components/Rail/Tiles/TileSize';
import SkeletonTile from '@/Components/Rail/Tiles/MediaTile/SkeletonTile';
import TilesList from '@/Components/TilesList';
import EpisodeTile from '../../Tiles';

import styles from './styles.module.scss';

interface Props {
  brandId: string;
  episodes: Maybe<Episode>[] | undefined;
  isSport: boolean;
  playbackOrigin: string;
  selectedSeasonId?: string;
  hasNextPage?: boolean;
  hasError?: boolean;
  isTilesLoading?: boolean;
  tilesLoadHandler?: () => void;
}

const Episodes: React.FC<Props> = props => {
  const {
    episodes,
    brandId,
    isSport,
    playbackOrigin,
    selectedSeasonId,
    isTilesLoading = false,
    hasNextPage = false,
    hasError = false,
    tilesLoadHandler,
  } = props;
  const totalItems = useCurrent(episodes);
  const positionIndexRef = useRef(0);

  // TODO merge this with Components/Rail
  const onPaginate = usePersistCallback(
    (positionIndex: number, pageSize: number, isForward: boolean) => {
      positionIndexRef.current = positionIndex;
      if (!isForward || isNil(totalItems.current) || isTilesLoading || !hasNextPage) {
        return;
      }
      if (positionIndex + pageSize * 2 >= totalItems.current?.length) {
        tilesLoadHandler?.();
      }
    },
  );

  return !isNil(episodes) && episodes.length > 0 ? (
    <TilesList
      className={styles.episodeList}
      isExpandable
      dataSetId={selectedSeasonId}
      scrollLeftOnRender
      handlePaginate={onPaginate}
    >
      {episodes.map(episode => (
        <EpisodeTile
          key={`episode: ${episode?.id}`}
          episode={episode}
          brandId={brandId}
          tileTitle={!isSport ? `${episode?.number}. ${episode?.title}` : `${episode?.title}`}
          playbackOrigin={playbackOrigin}
        />
      ))}
      {hasNextPage && !hasError && (
        <SkeletonTile key={`tile-${episodes?.length}`} size={TileSize.Small} />
      )}
    </TilesList>
  ) : null;
};

export default Episodes;
