import React from 'react';
import { Character, Maybe } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import { getCharactersText } from '@skytvnz/sky-app-store/lib/utils/Title';

import styles from './styles.module.scss';

interface Props {
  cast: Maybe<Character>[] | undefined;
}

const Cast: React.FC<Props> = props => {
  const { cast } = props;
  const castText = getCharactersText(cast);
  return cast ? (
    <div className={styles.castContainer} data-testid="cast-container">
      <span className={styles.castTitle}>Cast: </span>
      <span title={castText}>{castText}</span>
    </div>
  ) : null;
};

export default Cast;
