import qs from 'query-string';
import history from '@/Routes/History';
import RoutePath from '@/Routes/RoutePath';

export const getLoginUrl = (isRegister = false) => {
  const currentUrl = window.location.pathname + window.location.search;
  return qs.stringifyUrl({
    url: isRegister ? RoutePath.register : RoutePath.login,
    query: {
      callbackUrl: currentUrl,
    },
  });
};

const redirectToLogin = (isReplace = false) => {
  const loginUrl = getLoginUrl();
  if (isReplace) {
    history.replace(loginUrl);
  } else {
    history.push(loginUrl);
  }
};

export default redirectToLogin;
