import React from 'react';
import Icon from '@ant-design/icons';

import { ReactComponent as Play } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_play.svg';
import { ReactComponent as InstantReplay } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_instant_replay.color.svg';
import useVideoPlayer from '@/Hooks/useVideoPlayer';
import VideoImage from '@/Components/VideoImage';
import Progress from '@/Components/Progress';
import { getFullSeasonEpisodeText } from '@/Utils/ShowFormatter';
import calculateProgress from '@/Utils/CalculateProgress';
import { ImageAssetType } from '@skytvnz/sky-app-store/lib/types/enums/ImageAssetType';
import { Maybe, Episode } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import HoverUpgradeToWatch from '@/Pages/Detail/Tiles/HoverUpgradeToWatch';
import { useVodRequiredSubscriptions } from '@/Hooks/useSubscription';

import styles from './styles.module.scss';

interface Props {
  episode: Maybe<Episode>;
  brandId: string;
  isRecent?: boolean;
  playbackOrigin?: string;
}

const EpisodeTileImage: React.FC<Props> = props => {
  const { openAndPlay } = useVideoPlayer();
  const { episode, brandId, isRecent, playbackOrigin } = props;

  const requiredSubscriptions = useVodRequiredSubscriptions()(brandId, episode?.id);

  return (
    <div className={styles.episodeImageContainer}>
      <button
        type="button"
        className={styles.episodeImageButton}
        title={`Watch ${episode?.title}`}
        data-testid={`play-button-for-episode-${episode?.id}`}
        name={`Episode ${episode?.id} play button`}
        onClick={() => {
          if (episode) openAndPlay(brandId, episode?.id, playbackOrigin);
        }}
      >
        <div className={styles.episodeImageHoverContainer}>
          <VideoImage
            className={styles.episodeImage}
            lazyload
            src={episode?.image?.uri}
            type={ImageAssetType.ContentTileHorizontal}
            alt={`${episode?.title}: ${getFullSeasonEpisodeText(
              episode?.season?.number,
              episode?.number,
            )}`}
            data-testid={`episode-image-episode-nr-${episode?.number}`}
            icon={episode?.asset?.instantReplay && InstantReplay}
          />
          <div className={styles.episodeImageButtonsContainer}>
            <span className={styles.playTag}>
              <Icon component={Play as React.FC} />
            </span>
          </div>
          {episode?.watchProgress && (
            <Progress
              className={styles.progressBar}
              percent={calculateProgress(episode?.duration, episode?.watchProgress)}
              dataTestIdOuter={`episode-progress-bar-outer-${episode?.number}`}
              dataTestIdInner={`episode-progress-bar-inner-${episode?.number}`}
            />
          )}
        </div>
        {requiredSubscriptions && <HoverUpgradeToWatch />}
      </button>
      {isRecent && <span className={styles.newEpisodeTag}>NEW EPISODE</span>}
    </div>
  );
};
export default EpisodeTileImage;
