import React, { FC } from 'react';
import classnames from 'classnames';

import Spinner from '@/Components/Spinner';

import styles from './styles.module.scss';

interface PreloaderProps {
  isLoading: boolean;
  className?: string;
}

const Preloader: FC<PreloaderProps> = ({ children, isLoading, className }) => (
  <>
    {isLoading && <Spinner className={classnames(styles.loading, className)} />}
    <div
      className={classnames({
        [styles.hidden]: isLoading,
      })}
      data-testid="preload-content"
    >
      {children}
    </div>
  </>
);

export default Preloader;
