import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { Button, Input } from 'antd';
import Icon from '@ant-design/icons';
import debounce from 'lodash.debounce';

import { ReactComponent as SearchIcon } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_search.svg';
import { ReactComponent as CloseIcon } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_close.svg';
import useQueryString from '@/Hooks/useQueryString';

import RoutePath from '@/Routes/RoutePath';

import styles from './styles.module.scss';

export const SEARCH_QUERY_KEY = 'query';
export const useSearchQuery = () => useQueryString()[SEARCH_QUERY_KEY] || '';

const isCurrentSearchPage = (pathname?: string) => pathname === RoutePath.search;

interface Props {
  className?: string;
}

/**
 * Expected behavior of the Search Input:
 * In other pages:
 * - click the search icon, then expand the search input;
 * - typing in search input & press enter, then go to search page;
 * - when typing more than 3 chars and stop typing, it will automatic trigger search
 * - click the close button, clear the search input value & hide it;
 * - once search input expand, click other place, then search input should hide; (? should clear the input value ?)
 * In Search page:
 * - keep typing the search input & press enter, should keep input expand;
 * - click the close button, cancel the search, then hide the search input & go back to previous page. (if no previous history then back to home page);
 * - click the other place of the page, the search input should not hide;
 * - go to other pages (click home tab or history backward), should hide & clear the search input;
 * @param props
 */
const SearchInput: React.FC<Props> = props => {
  const { className } = props;

  const history = useHistory();
  const location = useLocation();

  const [showSearchBar, setShowSearchBar] = useState(false);
  const inputRef = useRef<Input>(null);
  const [query, setQuery] = useState('');
  const searchQuery = useSearchQuery() as string;

  // Provide keyword to start new search
  const handleStartSearch = useCallback(
    (searchKeyword: string) => {
      if (searchKeyword) {
        const searchPathName = `${RoutePath.search}?${SEARCH_QUERY_KEY}=${encodeURIComponent(
          searchKeyword,
        )}`;
        if (isCurrentSearchPage(location.pathname)) {
          history.replace(searchPathName);
        } else {
          history.push(searchPathName);
        }
      }
    },
    [history, location.pathname],
  );

  // Cancel current search
  const handleCancelSearch = useCallback(() => {
    // if there is no browser history, go back to home page.
    if (history.length <= 1) {
      history.replace('/');
    } else if (isCurrentSearchPage(location.pathname)) {
      // Only in search page do hstory action
      history.goBack();
    }
  }, [history, location.pathname]);

  const debouncedStartSearch = useCallback(
    debounce((searchKeyword: string) => {
      if (searchKeyword.length >= 3) {
        handleStartSearch(searchKeyword);
      }
    }, 500),
    [handleStartSearch],
  );

  const handleInputChanged = event => {
    setQuery(event.target.value);
    debouncedStartSearch(event.target.value);
  };

  const handleEnterPressed = () => {
    handleStartSearch(query);

    inputRef.current?.blur();
  };

  const handleSearchIconClicked = () => {
    handleStartSearch(query);
  };

  const handleCancelClicked = () => {
    setQuery('');
    setShowSearchBar(false);
    handleCancelSearch();
  };

  const handleInputBlur = useCallback(() => {
    // If not in search page, blur the input will hidden the search bar
    if (!isCurrentSearchPage(location.pathname)) {
      setShowSearchBar(false);
    }
  }, [location.pathname]);

  const openSearchBar = () => {
    setQuery(searchQuery);
    setShowSearchBar(true);

    inputRef.current?.focus();
  };

  useEffect(() => {
    // Initial the search query from url
    if (isCurrentSearchPage(location.pathname)) {
      setQuery(searchQuery);
    }
    setShowSearchBar(!!searchQuery);
  }, [searchQuery, location.pathname]);

  return (
    <div className={classnames(styles.searchContainer, className)} data-testid="search-component">
      <div
        className={classnames(styles.searchBarContainer, {
          [styles.searchBarContainerExpanded]: showSearchBar,
        })}
        data-testid="search-bar-container"
      >
        <Button
          type="link"
          className={classnames(styles.headerBtn, styles.searchBarSearchBtn)}
          onClick={handleSearchIconClicked}
          data-testid="search-button-perform"
          name="Search button perform"
        >
          <Icon component={SearchIcon} />
        </Button>
        <Input
          ref={inputRef}
          size="large"
          placeholder="Title"
          className={styles.searchInput}
          onChange={handleInputChanged}
          onPressEnter={handleEnterPressed}
          onBlur={handleInputBlur}
          data-testid="search-field-input"
          value={query}
          maxLength={100}
        />
        <Button
          type="link"
          className={classnames(styles.headerBtn, styles.searchBarCancelBtn, {
            [styles.hidden]: !query,
          })}
          onClick={handleCancelClicked}
          data-testid="search-button-cancel"
          name="Search button cancel"
        >
          <Icon component={CloseIcon} />
        </Button>
      </div>
      <Button
        type="link"
        onClick={openSearchBar}
        className={classnames(styles.headerBtn, styles.searchBtn, {
          [styles.hidden]: showSearchBar,
        })}
        data-testid="header-search"
        name="Header search"
      >
        <Icon component={SearchIcon} />
      </Button>
    </div>
  );
};

export default SearchInput;
