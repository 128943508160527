const formatLayout = (layout: string) => {
  if (layout) {
    const result = layout.split('_');
    return result.length >= 2
      ? result[1].charAt(0).toUpperCase() + result[1].slice(1).toLowerCase()
      : layout.charAt(0).toUpperCase() + layout.slice(1).toLowerCase();
  }
  return layout;
};

export default formatLayout;
