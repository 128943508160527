/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
// import shaka from 'shaka-player';
import { PlaybackAsset, PlayerControllerFactory } from './PlayerTypes';
import { transformShakaLivePlayback } from '../DRM';
import { updatePlaybackSource } from './PlaybackSourceUtils';
import './Subtitle.css';

const LIVE_TOLERANCE = 30;
const LICENSE_TYPE = 2;
const CONTENT_TYPE = 'application/octet-stream';
/**
 * Factory for generating a player controller with the tech spec of Shaka Player
 * @param player
 * @param videoElement
 */
const ShakaControllerFactory: PlayerControllerFactory = (
  player,
  videoElement: HTMLVideoElement,
  autoPlay = false,
  accessToken,
) => {
  const playerController = {
    videoEl: videoElement,
    focus: () => {},
    playsInline: () => {},
    readyState: () => 1,

    loadMeta: async (playbackAsset: PlaybackAsset, startPosition?: string) => {
      const source = transformShakaLivePlayback(playbackAsset);

      // Update the source to start from initial time if mentioned
      // i.e. https://xxx/yyy.mpd?start=2024-05-27T09%3A50%3A00Z
      if (startPosition && source?.src) {
        source.src = updatePlaybackSource(source.src, startPosition);
      }

      const { src, drm } = source;

      if (player && src && drm) {
        player.configure({
          drm,
        });
        player.getNetworkingEngine().registerRequestFilter((type, request) => {
          if (type === LICENSE_TYPE) {
            // eslint-disable-next-line dot-notation
            request.headers['Authorization'] = `Bearer ${accessToken}`;
            request.headers['Content-Type'] = CONTENT_TYPE;
          }
        });
        try {
          await player.load(src);
          if (autoPlay) {
            await videoElement?.play();
          }
        } catch (error) {
          /**  Ignoring Load Interrupted related erros to go to Sentry as there is nothing we can do to avoid them.
          More details on https://skynz.atlassian.net/browse/SAAA-4666
          Codes:
          7000 - LOAD_INTERRUPTED (Shaka Player)
          20 - The play() request was interrupted by a new load request (Chromium Browsers)
          0 - The fetching process for the media resource was aborted by the user agent at the user's request
          **/
          if (error.code === 7000 || error.code === 20 || error.code === 0) {
            // eslint-disable-next-line no-console
            console.error(error);
            return undefined;
          }
          throw error;
        }
      }
    },
    subTitle: (selectedTrack?: any) => {
      const tracks = player.getTextTracks();
      const trackSelected = tracks?.find(track => track?.label === selectedTrack?.label);
      if (trackSelected) {
        player.setTextTrackVisibility(true);
        player.selectTextTrack(trackSelected);
      } else player.setTextTrackVisibility(false);
    },
    dispose: () => player.destroy(),

    volume: (volume?: number) => {
      if (volume && videoElement) {
        videoElement.volume = volume;
      } else {
        return videoElement?.volume || 0;
      }
    },

    currentTime: (currentTime?: number) => {
      if (currentTime && videoElement) {
        videoElement.currentTime = currentTime;
      } else {
        return videoElement?.currentTime || 0;
      }
    },

    videoHeight: () => {},
    autoplay: () => {},

    muted: (muted?: boolean) => {
      if (muted !== undefined && videoElement) {
        videoElement.muted = muted;
      } else {
        return videoElement?.muted;
      }
    },

    play: async () => videoElement?.play(),
    pause: () => videoElement?.pause(),
    duration: () => videoElement?.duration,

    buffered: () => {
      const buffered = videoElement?.buffered;
      if (player.isLive()) {
        // const liveBuffered = shaka.media.TimeRangesUtils.getBufferedInfo(buffered);
        // return liveBuffered.map(({ start, end }) => {
        //   return new TimeRanges();
        // });
      }
      return buffered;
    },

    seekToLiveEdge: () => {
      const { end } = player.seekRange();
      if (videoElement) {
        videoElement.currentTime = end;
      }
    },

    getLiveCurrentTime: () => {
      const { end } = player.seekRange();
      return end;
    },

    getLiveWindowDuration: () => {
      const { start, end } = player.seekRange();
      return Math.floor(end - start);
    },

    getLiveStartOffset: () => {
      const { start } = player.seekRange();
      return start;
    },

    isAtLiveEdge: (tolerance?: number) => {
      const { end } = player.seekRange();
      return (
        player.isLive() &&
        end - (playerController.currentTime() || 0) <= (tolerance || LIVE_TOLERANCE)
      );
    },
  };
  return playerController;
};

export default ShakaControllerFactory;
