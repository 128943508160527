import React, { FC, useCallback, useMemo } from 'react';
import Icon from '@ant-design/icons';
import { useSelector } from 'react-redux';

import { ReactComponent as Play } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_play.svg';
import { ReactComponent as Replay } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_replay.svg';

import { SkySubscription, Maybe } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import { ImageAssetType } from '@skytvnz/sky-app-store/lib/types/enums/ImageAssetType';

import { selectors } from '@/Store';
import { defaultErrorSubTitle, defaultErrorTitle } from '@/Components/Message/ErrorMessage';
import useHistoryBack from '@/Hooks/useHistoryBack';
import VideoImage from '@/Components/VideoImage';
import sessionId from '@/Utils/SessionId';
import useLinkAccount, { AccountType } from '@/Hooks/useLinkAccount';
import {
  createSubscriptionsModalContent,
  getTitleFromSubscription,
  LINK_ACCOUNT_TEXT,
  getLinkOrSubscribeSubTitle,
  LINK_OR_SUBSCRIBE_TITLE,
  SUBSCRIBE_SKY_TEXT,
  SUBSCRIPTIONS_MODAL_TITLE,
  UPGRADE_TEXT,
  useUserSubscriptions,
} from '@/Hooks/useSubscription';
import externalApplicationLinksConfig from '@/Config/ExternalApplicationLinksConfig';
import {
  recordLinkAccountOrUpgradeEncountered,
  recordUpgradeEncountered,
  recordUpgradeMessageDismissed,
} from '@/Analytics/SubscriptionUpgrade';
import recordConcurrencyError from '@/Analytics/ConcurrencyLimitError';
import Content from '@/Layouts/containers/Content';

import usePersistCallback from '@/Hooks/usePersistCallback';
import BackButton from '../ControlPanel/BackPanel/BackButton';
import ErrorState from '../ErrorState';
import { YouboraPlayerConfig } from '../Core/YouboraPlayerConfig';
import ParentalControlPanel, {
  ParentalControlPanelProps,
} from '../ParentalControlPanel/ParentalControlPanel';

import styles from './styles.module.scss';

interface PlayerContainerProps {
  isParentalForbidden?: boolean;
  subscriptions?: Maybe<SkySubscription>[];
  channelId?: string;
  playbackOrigin?: string;
  isLivePlayer?: boolean;
  isPlaybackError: boolean;
  defaultBackgroundImage?: Maybe<string>;
  youboraPlayerConfiguration?: YouboraPlayerConfig;
  isConcurrencyLimitReached: boolean;
  onPlaybackRetry: () => void;
  children: any;
}

const PlayerContainer: FC<PlayerContainerProps & Omit<ParentalControlPanelProps, 'isNewSetup'>> = ({
  isParentalForbidden,
  subscriptions,
  channelId,
  playbackOrigin,
  isLivePlayer,
  isPlaybackError,
  defaultBackgroundImage,
  youboraPlayerConfiguration,
  isConcurrencyLimitReached,
  onPlaybackRetry,
  playerReferrer,
  contentClassification,
  onParentalResolved,
  children,
}) => {
  const handleGoBackButtonOnError = useHistoryBack();
  // Entitlement subscription check
  const userSubscriptions = useUserSubscriptions();
  const { isAccountLinked } = useLinkAccount();

  // Content error
  const isDeviceRegistrationLimitExceeded = useSelector(
    selectors.devices.deviceRegistrationLimitExceeded,
  );

  const isError =
    isPlaybackError ||
    isDeviceRegistrationLimitExceeded ||
    isConcurrencyLimitReached ||
    subscriptions;

  const createGenericError = usePersistCallback(() => {
    return {
      title: defaultErrorTitle,
      subTitle: defaultErrorSubTitle,
      primaryButtonText: 'retry',
      onClick: onPlaybackRetry,
    };
  });

  const createRegistrationError = usePersistCallback(() => {
    return {
      title: "Sorry, you've already reached your device limit",
      subTitle: 'You can manage your Sky Go devices in Settings under “My Stuff”',
      primaryButtonText: 'Manage my settings',
      onClick: () => {
        window.open(externalApplicationLinksConfig.faqUrl);
      },
    };
  });

  const createSubscriptionsError = usePersistCallback(
    (playerConfiguration: YouboraPlayerConfig, subs: Maybe<SkySubscription>[]) => {
      const analyticParams = {
        categoryName: playbackOrigin || 'External',
        contentId: (isLivePlayer ? channelId : playerConfiguration.content_id) || '',
        contentTitle: playerConfiguration.content_title || playerConfiguration.content_program,
        contentType: playerConfiguration.content_type,
        viewName: isLivePlayer ? 'Live TV' : 'Content Details',
        subscription: getTitleFromSubscription(userSubscriptions),
        subscriptionRequired: getTitleFromSubscription(subs),
        channelName: playerConfiguration.content_channel
          ? [playerConfiguration.content_channel]
          : undefined,
      };
      if (!isAccountLinked(AccountType.SkyDth)) {
        recordLinkAccountOrUpgradeEncountered(
          analyticParams.categoryName,
          analyticParams.contentId,
          analyticParams.contentTitle,
          analyticParams.contentType,
          analyticParams.viewName,
          analyticParams.subscription,
          analyticParams.subscriptionRequired,
          analyticParams.channelName,
        );
        return {
          title: LINK_OR_SUBSCRIBE_TITLE,
          subTitle: getLinkOrSubscribeSubTitle(),
          primaryButtonText: LINK_ACCOUNT_TEXT,
          secondaryButtonText: SUBSCRIBE_SKY_TEXT,
          onClick: () => {
            window.open(externalApplicationLinksConfig.linkAccountUrl);
          },
          onClickSecondary: () => {
            window.open(externalApplicationLinksConfig.subscribeUrl);
          },
        };
      }

      recordUpgradeEncountered(
        analyticParams.categoryName,
        analyticParams.contentId,
        analyticParams.contentTitle,
        analyticParams.contentType,
        analyticParams.viewName,
        analyticParams.subscription,
        analyticParams.subscriptionRequired,
        analyticParams.channelName,
      );

      return {
        title: SUBSCRIPTIONS_MODAL_TITLE,
        subTitle: createSubscriptionsModalContent(subs),
        primaryButtonText: UPGRADE_TEXT,
        primaryButtonIcon: <Icon component={Play as FC} />,
        onClick: () => {
          window.open(externalApplicationLinksConfig.upgradeSubscriptionUrl);
        },
      };
    },
  );

  const createConcurrencyError = usePersistCallback(() => {
    recordConcurrencyError(
      (isLivePlayer ? channelId : youboraPlayerConfiguration?.content_id) || '',
      sessionId,
    );

    return {
      title: 'Sorry, you’re already watching elsewhere',
      subTitle: 'Please stop streaming on other devices to continue watching here.',
      primaryButtonText: 'Try Again',
      primaryButtonIcon: <Icon component={Replay as FC} />,
      onClick: onPlaybackRetry,
    };
  });

  const errorMsg = useMemo(() => {
    if (isPlaybackError) {
      return createGenericError();
    }
    if (isDeviceRegistrationLimitExceeded) {
      return createRegistrationError();
    }
    if (subscriptions && youboraPlayerConfiguration?.content_id) {
      return createSubscriptionsError(youboraPlayerConfiguration, subscriptions);
    }
    if (isConcurrencyLimitReached) {
      return createConcurrencyError();
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDeviceRegistrationLimitExceeded,
    isConcurrencyLimitReached,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    subscriptions?.length,
    youboraPlayerConfiguration,
    isPlaybackError,
    createConcurrencyError,
    createGenericError,
    createRegistrationError,
    createSubscriptionsError,
  ]);

  const handleGoBack = useCallback(() => {
    handleGoBackButtonOnError();

    if (subscriptions) {
      recordUpgradeMessageDismissed(
        getTitleFromSubscription(userSubscriptions),
        getTitleFromSubscription(subscriptions),
      );
    }
  }, [handleGoBackButtonOnError, subscriptions, userSubscriptions]);

  return isError || isParentalForbidden === true ? (
    <div className={styles.container} data-testid="player-container">
      <Content className={styles.panel} testid="player-error">
        {defaultBackgroundImage && (
          <VideoImage
            className={styles.background}
            src={defaultBackgroundImage}
            type={ImageAssetType.HeroLandingWide}
            data-testid="video-image"
          />
        )}
        <div className={styles.mask} />

        <div className={styles.content}>
          <BackButton
            className={styles.backBtn}
            onClick={handleGoBack}
            testId="player-error-state-back"
          />

          {isError
            ? // eslint-disable-next-line react/jsx-props-no-spreading
              errorMsg && <ErrorState {...errorMsg} />
            : isParentalForbidden === true && (
                <ParentalControlPanel
                  onParentalResolved={onParentalResolved}
                  contentClassification={contentClassification}
                  playerReferrer={playerReferrer}
                />
              )}
        </div>
      </Content>
    </div>
  ) : (
    children
  );
};

export default PlayerContainer;
