import { contentCardClicked } from '@/Analytics/Segment';

const cardClickAnalytics = (
  url: string,
  title: string,
  page: string,
  contentPosition: number,
  { railTitle, railStyle, railContains, railContentType },
) => {
  contentCardClicked({
    content_id: url,
    content_title: title,
    view_name: page,
    content_position: contentPosition,
    contains: railContains,
    style: railStyle,
    category_name: railTitle,
    content_type: railContentType,
  });
};

export default cardClickAnalytics;
