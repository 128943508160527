import React, { useCallback } from 'react';

import {
  COLLECTION_FILTER_SORT,
  COLLECTION_FILTER_SUBSCRIPTION,
  COLLECTION_FILTER_VIEWINGCONTEXT,
} from '@skytvnz/sky-app-store/lib/modules/collections/constants';
import { selectors } from '@/Store';
import DropDown from '@/Components/DropDown/DropDown';
import useBrowseData from './useBrowseData';
import styles from './styles.module.scss';

const subscriptionFilterOptions = selectors.collections.getSubscriptionFilterOptions();
const subscriptionFilterName = subscriptionFilterOptions.name;
const subscriptionOptions = subscriptionFilterOptions.options.map(({ id, title }) => ({
  label: title,
  value: id,
}));

const viewingContextFilterOptions = selectors.collections.getViewingContextFilterOptions(true);
const viewingContextFilterName = viewingContextFilterOptions.name;
const viewingContextOptions = viewingContextFilterOptions.options.map(({ id, title }) => ({
  label: title,
  value: id,
}));

const sortFilterOptions = selectors.collections.getSortOptions();
const sortFilterName = sortFilterOptions.name;
const sortOptions = sortFilterOptions.options.map(({ id, title }) => ({
  label: title,
  value: id,
}));

interface Props {
  collectionId: string;
  enabledFilters: Array<string>;
  onSelectFilter: (filterType: string, filterValue: string, title: string) => void;
}

const BrowseFilters: React.FC<Props> = ({ collectionId, enabledFilters, onSelectFilter }) => {
  const { selectedFilters } = useBrowseData(collectionId);

  const selectedSubscription = selectedFilters?.[subscriptionFilterName];
  const selectedViewingContext = selectedFilters?.[viewingContextFilterName];
  const selectedSort = selectedFilters?.[sortFilterName];

  const selectFilterHandler = useCallback(
    filterType => selectedOption =>
      onSelectFilter(filterType, selectedOption.value, selectedOption.label),
    [onSelectFilter],
  );

  const isSubscriptionFilterEnabled = enabledFilters.includes(COLLECTION_FILTER_SUBSCRIPTION);
  const isViewingContextFilterEnabled = enabledFilters.includes(COLLECTION_FILTER_VIEWINGCONTEXT);
  const isSortFilterEnabled = enabledFilters.includes(COLLECTION_FILTER_SORT);

  return (
    <div className={styles.filterContainer} data-testid="browse-collection-filters">
      {isSubscriptionFilterEnabled && (
        <DropDown
          className={styles.filterOption}
          options={subscriptionOptions}
          selectedValue={selectedSubscription}
          onChange={selectFilterHandler(subscriptionFilterName)}
        />
      )}
      {isViewingContextFilterEnabled && (
        <DropDown
          testId="viewing-context-filters"
          className={styles.filterOption}
          options={viewingContextOptions}
          selectedValue={selectedViewingContext}
          onChange={selectFilterHandler(viewingContextFilterName)}
        />
      )}
      {isSortFilterEnabled && (
        <DropDown
          testId="sort-filters"
          className={styles.filterOption}
          options={sortOptions}
          selectedValue={selectedSort}
          onChange={selectFilterHandler(sortFilterName)}
        />
      )}
    </div>
  );
};

export default BrowseFilters;
