import { usePageHeader } from '@/Components/AppHeader/AppHeader';
import useSimpleModal from '@/Components/Modal/useSimpleModal';
import React, { useEffect } from 'react';

const DeviceLogout: React.FC = () => {
  usePageHeader({
    title: 'Device Authorization Logout',
  });

  const { openModal } = useSimpleModal();

  useEffect(() => {
    openModal({
      title: `Logout Successful`,
      content:
        'Please close this window and press OK on your remote to regenerate the QR Code on your TV and retry QR code scan.',
      closable: false,
      showOkButton: false,
      cancelable: false,
    });
  }, [openModal]);
  return null;
};

export default DeviceLogout;
