import { useLocation } from 'react-router-dom';

import { isHomePagesPath } from '@/Routes/RoutePath';

const useIsHomePages = () => {
  const location = useLocation();

  return isHomePagesPath(location.pathname);
};

export default useIsHomePages;
