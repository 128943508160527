import dayjs from 'dayjs';
import { LinearSlot } from '@skytvnz/sky-app-store/lib/types/graph-ql';

// eslint-disable-next-line import/prefer-default-export
export const isLiveSlotEndingSoon = (slot: LinearSlot | null): boolean => {
  if (slot?.start && slot?.end) {
    const now = dayjs();
    return now.isAfter(slot?.start) && now.isBefore(slot?.end) && now.diff(slot?.end, 'm') > -5;
  }
  return false;
};
