import React from 'react';
import dayjs from 'dayjs';

import styles from '@/Pages/Detail/Tiles/styles.module.scss';
import { Maybe } from '@skytvnz/sky-app-store/lib/types/graph-ql/index';
import classNames from 'classnames';

interface Props {
  id: number | null | undefined;
  startDate?: Maybe<Date>;
  endDate?: Maybe<Date>;
  channelName?: string;
  className?: string;
}

const Availability: React.FC<Props> = ({ id, startDate, endDate, channelName, className }) => {
  const availabilityInDays = endDate && dayjs(endDate).diff(dayjs(), 'day');
  const availabilityFromDate = startDate && dayjs(startDate).format('ddd, D MMM, h:mm a');

  return (
    <>
      {availabilityFromDate && (
        <p
          className={classNames(className, styles.availability)}
          data-testid={`availability-slot-${id}`}
        >
          {`${availabilityFromDate}${channelName ? ` on ${channelName}` : ''}`}
        </p>
      )}
      {endDate && (
        <p className={styles.availability} data-testid={`episode-availability-episode-nr-${id}`}>
          {`Available for ${availabilityInDays} more days`}
        </p>
      )}
    </>
  );
};

export default Availability;
