import React, { UIEventHandler, Ref } from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  onScroll?: UIEventHandler;
  ref?: Ref<HTMLDivElement>;
  testid?: string;
}

const Content: React.FC<Props> = React.forwardRef(
  ({ className, children, onScroll, testid }, ref) => {
    return (
      <div
        className={classnames(styles.content, className)}
        ref={ref}
        onScroll={onScroll}
        data-testid={testid}
      >
        {children}
      </div>
    );
  },
);

export default Content;
