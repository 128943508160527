import React from 'react';
import Content from '@/Layouts/containers/Content';
import Heading from '@/Components/Heading';
import ViewingContext from '@/Pages/Detail/WhatsOnTv/ViewingContext';
import { isNil } from 'ramda';
import { Movie, Show } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import styles from './styles.module.scss';

interface Props {
  brand: Show | Movie;
}

const WhatsOnTv: React.FC<Props> = props => {
  const { brand } = props;
  const isDisplaySection =
    (brand && !isNil(brand.slots) && brand.slots.length > 0) ||
    (brand.__typename === 'Show' && !isNil(brand.episodes) && brand.episodes.length > 0);

  return isDisplaySection ? (
    <div id="whats-on-tv" className={styles.whatsOnTvContainer} data-testid="whats-on-tv">
      <Content>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <Heading testid="whats-on-tv-heading">What's On TV</Heading>
      </Content>
      <ViewingContext brand={brand} />
    </div>
  ) : null;
};

export default WhatsOnTv;
