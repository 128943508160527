import React, { useMemo } from 'react';
import { Avatar as AntAvatar, Badge } from 'antd';

import { ReactComponent as EditIcon } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_pencil_edit.svg';
import defaultProfileSVG from '@skytvnz/sky-app-style/lib/assets/svg/backgrounds/icon_default_profile.color.svg';
import classNames from 'classnames';
import Icon from '@ant-design/icons/lib/components/Icon';
import useMedia from '@/Hooks/useMedia';
import styles from './styles.module.scss';

interface Props {
  size: number;
  isEdit?: boolean;
  title?: string;
  className?: string;
  profileImage?: string;
  isActive?: boolean;
  onClick?: () => void;
}

const Avatar: React.FC<Props> = props => {
  const {
    size,
    isEdit,
    title,
    className,
    profileImage = defaultProfileSVG,
    isActive,
    onClick,
  } = props;
  const { isMediaM } = useMedia();

  const editLink = useMemo(() => {
    return (
      <Icon
        component={EditIcon}
        style={{ top: `50%`, right: `50%`, fontSize: `${!isMediaM ? 24 : 48}px`, color: '#fff' }}
      />
    );
  }, [isMediaM]);

  return (
    <div
      className={classNames(styles.avatarContainer, isActive && styles.active, className)}
      data-testid="avatar-container"
      role="button"
      onClick={onClick}
      aria-hidden="true"
    >
      {isEdit ? (
        <Badge count={editLink} data-testid="avatar-edit">
          <AntAvatar shape="circle" size={size} data-testid="avatar-image" src={profileImage} />
        </Badge>
      ) : (
        <AntAvatar shape="circle" size={size} src={profileImage} data-testid="avatar-image" />
      )}
      {title && (
        <div className={styles.avatarTitle} data-testid="avatar-title">
          {title}
        </div>
      )}
    </div>
  );
};
export default Avatar;
