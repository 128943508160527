import React, { FC, useState, useCallback, useMemo, useEffect } from 'react';
import { ReactComponent as SubtitlesIcon } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_subtitle.svg';
import { ReactComponent as TickIcon } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_tick.svg';
import classnames from 'classnames';
import usePlayerSettings from '@/Hooks/usePlayerSettings';
import Icon from '@ant-design/icons/lib/components/Icon';
import useMedia from '@/Hooks/useMedia';
import recordAnalyticsForTextTrackChange from '@/Analytics/Subtitle';
import { Panel } from './Constant';
import styles from './styles.module.scss';

interface Props {
  player?: HTMLVideoElement;
  isShow?: boolean;
  showPanel: boolean;
  onClick?: () => void;
  assetId: string;
  activePanel: string;
  setActivePanel: (panel: string) => void;
  onSubTitleSelection: (value?: any) => void;
  trackingInfo: {
    title?: string;
    channelId?: string;
    slotId?: string;
  };
}

const CaptionsPanel: FC<Props> = ({
  showPanel,
  setActivePanel,
  assetId,
  onSubTitleSelection,
  trackingInfo,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedSubtitle, setSelectedSubtitle] = useState<string>('Off');
  const { selectedTextTrack, subtitleSectionData, handleTextTrackChange } = usePlayerSettings(
    assetId,
  );

  useEffect(() => {
    onSubTitleSelection(selectedTextTrack);
  }, [selectedTextTrack, onSubTitleSelection]);

  // Timer to hide the panel after 5 seconds of inactivity
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (showModal) {
      timer = setTimeout(() => {
        setShowModal(false);
        setActivePanel(Panel.NO_PANEL);
      }, 5000);
    }

    // Clear the timer on interaction or when the component unmounts
    return () => clearTimeout(timer);
  }, [showModal, setActivePanel]);

  const handleToggleModal = useCallback(() => {
    setShowModal(prev => !prev);
    setActivePanel(Panel.CAPTIONS_PANEL);
  }, [setActivePanel]);

  const handleSubtitleSelect = useCallback(
    (item: any) => {
      setSelectedSubtitle(item.label);
      handleTextTrackChange(item.value);
      recordAnalyticsForTextTrackChange(
        item.label,
        trackingInfo.title,
        trackingInfo.channelId,
        trackingInfo.slotId,
      );
      // Hide the modal after a button is clicked after 400ms
      setTimeout(() => {
        setActivePanel(Panel.NO_PANEL);
        setShowModal(false);
      }, 400);
    },
    [setActivePanel, handleTextTrackChange, trackingInfo],
  );

  const MyModal = useMemo(
    () => (
      <div
        className={classnames(styles.modal, {
          [styles.visible]: showPanel,
        })}
      >
        <p className={styles.subtitle}>SUBTITLES</p>
        {subtitleSectionData?.data?.map(item => (
          <button
            key={item.label}
            type="button"
            name={`${item.label}Btn`}
            onClick={() => handleSubtitleSelect(item)}
            className={classnames(styles.modalBtn, {
              [styles.activeModalBtn]: selectedSubtitle === item.label,
            })}
          >
            {item.selected && <Icon className={styles.tickIcon} component={TickIcon} />}
            {item.label}
          </button>
        ))}
      </div>
    ),
    [handleSubtitleSelect, selectedSubtitle, showPanel, subtitleSectionData],
  );

  const { isMediaM } = useMedia();

  return (
    <div
      className={styles.captionsPanel}
      onMouseMove={() => setShowModal(true)} // Reset timer on mouse move
    >
      <button
        type="button"
        title="Subtitles"
        data-testid="captions-sub-titles"
        className={classnames(styles.controlButton, {
          [styles.boldText]: showPanel,
        })}
        onClick={handleToggleModal}
      >
        <Icon className={styles.buttonIcon} component={SubtitlesIcon} />
        {isMediaM && <span data-text="Subtitles">Subtitles</span>}
      </button>
      {MyModal}
    </div>
  );
};

export default CaptionsPanel;
