import React from 'react';
import classnames from 'classnames';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

interface Props {
  key: number;
  index: number;
  date: dayjs.Dayjs;
  onClick?: (date: dayjs.Dayjs) => void;
  isSelected: boolean;
  isFirst: boolean;
  isLast: boolean;
}

const DateItem: React.FC<Props> = props => {
  const { index, date, isSelected, isFirst, isLast, onClick } = props;

  const title = () => {
    if (isFirst) {
      return 'Today';
    }

    if (index === 1) {
      return 'Tomorrow';
    }

    return date.format('dddd');
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={classnames(
        styles.dateItem,
        { [styles.selected]: isSelected },
        { [styles.first]: isFirst },
        { [styles.last]: isLast },
      )}
      data-testid={`date-item-${index}`}
      onClick={() => onClick?.(date)}
    >
      <span className={classnames(styles.weekday)} data-testid="date-item-weekday">
        {title()}
      </span>
      <span className={classnames(styles.date)}>{date.format('D MMM')}</span>
    </div>
  );
};

export default DateItem;
