import { repeat } from 'ramda';

export const PIN_INPUT_NUMBER = 4;

export const initialPin = repeat('', PIN_INPUT_NUMBER);

export enum CreatePinStage {
  create,
  confirm,
}

export enum EnterPinStage {
  enter,
  locked,
  reset,
}
