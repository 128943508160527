import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNil, equals } from 'ramda';

import { selectors, actions } from '@/Store';
import AppEventBus, { AppEvent } from '@/Utils/AppEventBus';
import Content from '@/Layouts/containers/Content';

import useLinkAccount, { AccountType } from '@/Hooks/useLinkAccount';
import useMedia from '@/Hooks/useMedia';
import { Spotify as SpotifyAnalytics } from '@/Analytics';

import { Season } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import { notPremiumAccountModal, wrongAlbumModal } from './Modals';
import Footer from './Footer';
import Tracks from './Tracks';
import Login from './Login';
import SeeAll from './SeeAll';

import styles from './styles.module.scss';

const PLAYER_STATE_CHECK_INTERVAL = 1000;

const hasValue = value => {
  return !isNil(value) && !equals(value, '') && !equals(value, {});
};

interface Props {
  brandId: string;
  // provide either selectedSeason (state) or selectedAlbumId for movies
  selectedSeason?: Season | null | undefined;
  selectedAlbumId?: string | null | undefined;
}

// TODO - Implement revamped analytics for spotify when doing https://skynz.atlassian.net/browse/SAAA-5038.
const Soundtrack: React.FC<Props> = props => {
  const { selectedSeason, brandId } = props;
  const dispatch = useDispatch();
  const token = useSelector(selectors.spotify.accessToken);
  const remotePlayerState = useSelector(selectors.spotify.playerState);
  const profile = useSelector(selectors.spotify.profile);

  const [isWrongAlbumModalEnabled, setIsWrongAlbumModalEnabled] = useState(false);
  const [isPlayerReady, setPlayerReady] = useState(false);
  const player = useRef<Spotify.SpotifyPlayer | null>();
  const [deviceId, setDeviceId] = useState(null);
  const [isExpanded, setExpanded] = useState(false);

  const { selectedAlbumId } = props;
  const selectedId = !isNil(selectedAlbumId) ? selectedAlbumId : selectedSeason?.soundtrack?.id;

  const album = useSelector(selectors.spotify.getAlbum)(selectedId);
  const isAlbumSaved = useSelector(selectors.spotify.getIsAlbumSaved)(selectedId);

  const [isRewindInProgress, setIsRewindInProgress] = useState(false);
  const [currentTrack, setCurrentTrack] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackProgress, setPlaybackProgress] = useState(0);

  const { linkAccount } = useLinkAccount();
  const isPremium = useCallback(() => profile?.product === 'premium', [profile]);
  const { isMediaM } = useMedia();

  const redirectToSpotify = useCallback(() => {
    SpotifyAnalytics.recordLogin(brandId);
    linkAccount(AccountType.Spotify);
  }, [linkAccount, brandId]);

  const seek = useCallback(
    async seekToPc => {
      const playerState = await player.current?.getCurrentState();
      if (!isNil(playerState)) {
        const seekToMs = (playerState?.duration / 100) * seekToPc;
        setIsRewindInProgress(true);
        await dispatch(actions.spotify.seek(Math.round(seekToMs)));
        setIsRewindInProgress(false);
      }
    },
    [dispatch],
  );

  const reconnectPlayer = useCallback(async () => {
    const playerState = await player.current?.getCurrentState();
    if (isNil(playerState)) {
      await player.current?.connect();
      let reconnectingPlayerState: any;
      while (isNil(reconnectingPlayerState)) {
        // eslint-disable-next-line no-await-in-loop
        reconnectingPlayerState = await player.current?.getCurrentState();
      }
    }
  }, []);

  const playPause = useCallback(
    async (spotifyUri?) => {
      if (!hasValue(token)) {
        redirectToSpotify();
      } else if (!isPremium()) {
        notPremiumAccountModal();
      } else {
        const isPause = (isPlaying && currentTrack === spotifyUri) || isNil(spotifyUri);
        AppEventBus.emit(AppEvent.SpotifyPlayerPlayingChange, isPause);
        if (isPause) {
          dispatch(actions.spotify.pause());
          // Send analytics
          SpotifyAnalytics.recordPause(brandId, spotifyUri);
        } else if (deviceId && !isNil(selectedId)) {
          await reconnectPlayer();
          await dispatch(actions.spotify.transferPlayback(deviceId));
          const data =
            !isPlaying && currentTrack === spotifyUri
              ? {}
              : {
                  context_uri: `spotify:album:${selectedId}`,
                  offset: { uri: spotifyUri },
                };
          dispatch(actions.spotify.play(data));
          // Send analytics
          SpotifyAnalytics.recordPlay(brandId, spotifyUri);
        }
      }
    },
    [
      token,
      isPremium,
      redirectToSpotify,
      isPlaying,
      currentTrack,
      deviceId,
      selectedId,
      dispatch,
      reconnectPlayer,
      brandId,
    ],
  );

  const isPlayingWrongAlbum = useCallback(() => {
    if (remotePlayerState && remotePlayerState.is_playing) {
      const { uri } = remotePlayerState.item;
      if (hasValue(uri) && !isNil(album) && !isEmpty(album)) {
        return !hasValue(
          album.tracks.map(track => track.spotifyUri).find(trackUri => trackUri === uri),
        );
      }
    }
    return false;
  }, [album, remotePlayerState]);

  const videoPlayListener = useCallback(
    playing => playing && dispatch(actions.spotify.pause()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onStateChanged = useCallback(state => {
    if (state !== null) {
      const { current_track: track } = state.track_window;
      const trackUri = track.linked_from?.uri || track.uri;
      setIsPlaying(!state.paused);
      setCurrentTrack(trackUri);
      setPlaybackProgress((100 / state.duration) * state.position);
      AppEventBus.emit(AppEvent.SpotifyPlayerPlayingChange, !state.paused);
    } else {
      // state was null, user might have swapped to another device
    }
  }, []);

  const playerEvents = useCallback(
    spotifyPlayer => {
      // problem setting up the player
      spotifyPlayer.on('initialization_error', e => {
        // eslint-disable-next-line no-console
        console.error(e);
        setPlayerReady(false);
      });
      // problem authenticating the user.
      spotifyPlayer.on('authentication_error', e => {
        // eslint-disable-next-line no-console
        console.error(e);
        setPlayerReady(false);
        // TODO repace below action with setError
        // dispatch(actions.spotify.setToken(null));
      });
      // only premium accounts can use the API
      spotifyPlayer.on('account_error', e => {
        // eslint-disable-next-line no-console
        console.error(e);
        setPlayerReady(false);
      });
      // loading/playing the track failed for some reason
      spotifyPlayer.on('playback_error', e => {
        // eslint-disable-next-line no-console
        console.error(e);
      });

      // Playback status updates
      spotifyPlayer.on('player_state_changed', onStateChanged);

      // Ready
      spotifyPlayer.on('ready', async data => {
        await dispatch(actions.spotify.transferPlayback(data.device_id));
        await dispatch(actions.spotify.fetchPlayerState());
        setDeviceId(data.device_id);
        setPlayerReady(true);
        // Ready event is fired whenever a player is reconnected. So remove the existing listener and add new one.
        AppEventBus.removeListener(AppEvent.VideoPlayerPlayingChange, videoPlayListener);
        // Spotify player will be disconnect when unmounting SoundTrack comp;
        // So event emitter is unnecessary here, but keep here just in case if Spotify player becoming a global instance
        AppEventBus.on(AppEvent.VideoPlayerPlayingChange, videoPlayListener);
      });
    },
    [dispatch, onStateChanged, videoPlayListener],
  );

  const loadSoundtracks = useCallback(
    albumId => {
      if (hasValue(token)) {
        dispatch(actions.spotify.fetchIsSavedAlbum(albumId));
      }
      setExpanded(false);
    },
    [dispatch, token],
  );

  const saveAlbum = useCallback(
    async albumId => {
      if (!isNil(isAlbumSaved) && !isAlbumSaved) {
        await dispatch(actions.spotify.saveAlbum(albumId));
        SpotifyAnalytics.recordAddAlbum(brandId);

        dispatch(actions.spotify.fetchIsSavedAlbum(albumId));
      } else {
        await dispatch(actions.spotify.removeAlbum(albumId));
        SpotifyAnalytics.recordRemoveAlbum(brandId);
        dispatch(actions.spotify.fetchIsSavedAlbum(albumId));
      }
    },
    [isAlbumSaved, dispatch, brandId],
  );

  const disconnectSpotifyPlayer = useCallback(async () => {
    const currentRemotePlayerState = await player.current?.getCurrentState();
    if (!isNil(currentRemotePlayerState)) {
      AppEventBus.removeListener(AppEvent.VideoPlayerPlayingChange, videoPlayListener);
      player.current?.removeListener('player_state_changed', onStateChanged);
      player.current?.disconnect();
    }
    player.current = null;
  }, [videoPlayListener, onStateChanged]);

  // Will be invoked first time on laucnhing details page and subsequently for every spotiy token refresh
  const createSpotifyPlayer = useCallback(
    async accessToken => {
      // If player already exists, disconnect player and remove the listener from App eventbus
      await disconnectSpotifyPlayer();

      const spotifyPlayer = new window.Spotify.Player({
        name: 'SkyOne Web Player',
        getOAuthToken: cb => {
          cb(accessToken);
        },
      });

      playerEvents(spotifyPlayer);
      spotifyPlayer.connect();
      player.current = spotifyPlayer;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // Initial load of album
  useEffect(() => {
    if (!isNil(selectedId)) {
      loadSoundtracks(selectedId);
    }
  }, [loadSoundtracks, selectedId]);

  // Update local player state with updated remote player state
  useEffect(() => {
    if (isPlayingWrongAlbum()) {
      playPause();
      if (!isWrongAlbumModalEnabled) {
        setIsWrongAlbumModalEnabled(true);
        wrongAlbumModal(() => setIsWrongAlbumModalEnabled(false));
      }
    }
    setPlaybackProgress(
      remotePlayerState?.item?.duration_ms && remotePlayerState?.progress_ms
        ? (100 / remotePlayerState.item.duration_ms) * remotePlayerState.progress_ms
        : 0,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remotePlayerState]);

  // Start remote player state polling when local player initiates playback
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isPlayerReady) {
      const interval = setInterval(() => {
        if (!isRewindInProgress && isPlaying) {
          dispatch(actions.spotify.fetchPlayerState());
        }
      }, PLAYER_STATE_CHECK_INTERVAL);

      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlayerReady, isPlaying]);

  // Load spotify SDK whenever a new token is found
  useEffect(() => {
    if (hasValue(token) && !isNil(window.Spotify)) {
      createSpotifyPlayer(token);
    }
    return () => {
      disconnectSpotifyPlayer();
    };
  }, [createSpotifyPlayer, disconnectSpotifyPlayer, dispatch, token]);

  // Fetch spotify profile whenever a token is created
  useEffect(() => {
    if (hasValue(token) && !hasValue(profile)) {
      dispatch(actions.spotify.fetchProfile());
    }
  }, [dispatch, profile, token]);

  return !isNil(selectedId) && !isNil(album) && isMediaM ? (
    <>
      <Content>
        <h2 className={styles.titleHeader} data-testid="soundtrack-heading">
          Soundtracks
        </h2>
      </Content>
      {!hasValue(token) && <Login redirectToSpotify={redirectToSpotify} />}
      <SeeAll expandAlbum={() => setExpanded(!isExpanded)} isExpanded={isExpanded} />

      <Tracks
        album={album}
        currentTrack={currentTrack}
        playbackProgress={playbackProgress}
        onPlayPause={playPause}
        isPlayBtnEnabled={!hasValue(token) || isPlayerReady}
        isPlaying={isPlaying}
        isExpanded={isExpanded}
        onSeek={seek}
        isRewindInProgress={isRewindInProgress}
      />
      {!isNil(selectedId) && (
        <Footer
          albumId={selectedId}
          onAlbumSave={saveAlbum}
          isAlbumSaved={isAlbumSaved}
          isLoggedIn={hasValue(token)}
        />
      )}
    </>
  ) : null;
};

export default Soundtrack;
