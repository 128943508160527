import React from 'react';
import { useToggle, useLockBodyScroll } from 'react-use';
import { NavLink, Link, generatePath } from 'react-router-dom';
import classnames from 'classnames';
import { Button } from 'antd';
import Icon from '@ant-design/icons';

import { Logo, Back, Menu, Close } from 'Assets/all';
import RoutePath from '@/Routes/RoutePath';
import useAuth from '@/Hooks/useAuth';
import { getLoginUrl } from '@/Utils/redirectToLogin';
import Content from '@/Layouts/containers/Content';
import { useGlobalSlotView } from '@/Pages/TVGuide/SlotDetails/useSlotDetails';
import useIsHomePages from '@/Hooks/useIsHomePages';
import useHistoryBack from '@/Hooks/useHistoryBack';
import SearchInput from './SearchInput';

import styles from './styles.module.scss';

import ProfileDropDown from '../DropDown/ProfileDropDown';
import UserSplitTreatments from '../FeatureToggle/UserSplitTreatments';

const PROFILE_FEATURE_NAME = 'customer-profiles-web';

const Header: React.FC = () => {
  const { isAuthenticated, signOut } = useAuth();
  const [, setGlobalSlotView] = useGlobalSlotView();

  const isHomePages = useIsHomePages();

  const [showMobileNav, toggleShowMobileNav] = useToggle(false);

  const loginUrl = getLoginUrl();
  const registerUrl = getLoginUrl(true);

  useLockBodyScroll(showMobileNav);

  const handleMobileBackClick = useHistoryBack();

  const handleMobileNavClick = () => {
    toggleShowMobileNav();
  };

  const handleNavLinkClick = () => {
    toggleShowMobileNav(false);
    setGlobalSlotView(null);
  };

  return (
    <UserSplitTreatments featureNames={[PROFILE_FEATURE_NAME]}>
      {({ treatments, isReady }) => {
        const profileTrat = treatments[PROFILE_FEATURE_NAME]?.treatment;
        if (isReady) {
          return profileTrat !== 'on' ? (
            <header className={styles.appHeader}>
              <Content className={styles.contentOld}>
                {!isHomePages ? (
                  <Button
                    type="link"
                    className={classnames(
                      styles.headerBtn,
                      styles.mobileBackBtn,
                      styles.mobileOnlyBtn,
                    )}
                    onClick={handleMobileBackClick}
                    data-testid="header-mobile-back-btn"
                    name="Header mobile back button"
                  >
                    <Icon component={Back} />
                  </Button>
                ) : null}
                <span className={styles.appLogo}>
                  <Link to="/" data-testid="header-logo">
                    <Logo className={styles.appLogoIcon} />
                  </Link>
                </span>
                <nav
                  className={classnames(styles.nav, { [styles.navMobileShow]: showMobileNav })}
                  data-testid="header-nav"
                >
                  <NavLink
                    exact
                    to={RoutePath.home}
                    className={styles.navLink}
                    activeClassName={styles.active}
                    data-testid="header-home"
                    onClick={handleNavLinkClick}
                  >
                    HOME
                  </NavLink>
                  <NavLink
                    to={`${generatePath(RoutePath.live)}?playerReferrer=Live TV`}
                    className={styles.navLink}
                    activeClassName={styles.active}
                    data-testid="header-live"
                    onClick={handleNavLinkClick}
                  >
                    WATCH TV
                  </NavLink>
                  <NavLink
                    to={generatePath(RoutePath.tvGuide)}
                    className={styles.navLink}
                    activeClassName={styles.active}
                    data-testid="header-tv-guide"
                    onClick={handleNavLinkClick}
                  >
                    TV GUIDE
                  </NavLink>
                  <NavLink
                    to={generatePath(RoutePath.browse)}
                    className={styles.navLink}
                    activeClassName={styles.active}
                    data-testid="header-browse"
                    onClick={handleNavLinkClick}
                  >
                    BROWSE
                  </NavLink>
                  <NavLink
                    to={generatePath(RoutePath.about)}
                    className={styles.navLink}
                    activeClassName={styles.active}
                    data-testid="header-about"
                    onClick={handleNavLinkClick}
                  >
                    About
                  </NavLink>

                  {isAuthenticated ? (
                    <NavLink
                      to="#"
                      onClick={() => signOut()}
                      className={classnames(styles.navLink, styles.mobileOnlyBtn)}
                      data-testid="header-mobile-logout"
                    >
                      LOGOUT
                    </NavLink>
                  ) : (
                    <>
                      <NavLink
                        to={registerUrl}
                        onClick={handleNavLinkClick}
                        className={classnames(styles.navLink, styles.mobileOnlyBtn)}
                        data-testid="header-mobile-register"
                      >
                        REGISTER
                      </NavLink>
                      <NavLink
                        to={loginUrl}
                        onClick={handleNavLinkClick}
                        className={classnames(styles.navLink, styles.mobileOnlyBtn)}
                        data-testid="header-mobile-login"
                      >
                        SIGN IN
                      </NavLink>
                    </>
                  )}
                </nav>
                <SearchInput className={styles.searchPanelOld} />
                {isAuthenticated ? (
                  <NavLink
                    to="#"
                    onClick={() => signOut()}
                    className={classnames(styles.navLink, styles.authBtn)}
                    data-testid="header-logout"
                  >
                    LOGOUT
                  </NavLink>
                ) : (
                  <>
                    <NavLink
                      to={registerUrl}
                      onClick={handleNavLinkClick}
                      className={classnames(styles.navLink, styles.authBtn)}
                      data-testid="header-register"
                    >
                      REGISTER
                    </NavLink>
                    <NavLink
                      to={loginUrl}
                      onClick={handleNavLinkClick}
                      className={classnames(styles.navLink, styles.authBtn)}
                      data-testid="header-login"
                    >
                      SIGN IN
                    </NavLink>
                  </>
                )}
                <Button
                  type="link"
                  className={classnames(
                    styles.headerBtn,
                    styles.mobileNavBtnOld,
                    styles.mobileOnlyBtn,
                  )}
                  onClick={handleMobileNavClick}
                  data-testid="header-mobile-nav-btn"
                  name="Header mobile nav button"
                >
                  <Icon component={showMobileNav ? Close : Menu} />
                </Button>
              </Content>
            </header>
          ) : (
            <header className={styles.appHeader}>
              <Content className={styles.content}>
                <span className={styles.appLogo}>
                  <Link to="/" data-testid="header-logo">
                    <Logo className={styles.appLogoIcon} />
                  </Link>
                </span>
                <nav
                  className={classnames(styles.nav, { [styles.navMobileShow]: showMobileNav })}
                  data-testid="header-nav"
                >
                  <NavLink
                    exact
                    to={RoutePath.home}
                    className={styles.navLink}
                    activeClassName={styles.active}
                    data-testid="header-home"
                    onClick={handleNavLinkClick}
                  >
                    HOME
                  </NavLink>
                  <NavLink
                    to={`${generatePath(RoutePath.live)}?playerReferrer=Live TV`}
                    className={styles.navLink}
                    activeClassName={styles.active}
                    data-testid="header-live"
                    onClick={handleNavLinkClick}
                  >
                    WATCH TV
                  </NavLink>
                  <NavLink
                    to={generatePath(RoutePath.tvGuide)}
                    className={styles.navLink}
                    activeClassName={styles.active}
                    data-testid="header-tv-guide"
                    onClick={handleNavLinkClick}
                  >
                    TV GUIDE
                  </NavLink>
                  <NavLink
                    to={generatePath(RoutePath.browse)}
                    className={styles.navLink}
                    activeClassName={styles.active}
                    data-testid="header-browse"
                    onClick={handleNavLinkClick}
                  >
                    BROWSE
                  </NavLink>
                  <NavLink
                    to={generatePath(RoutePath.about)}
                    className={styles.navLink}
                    activeClassName={styles.active}
                    data-testid="header-about"
                    onClick={handleNavLinkClick}
                  >
                    About
                  </NavLink>
                  {!isAuthenticated && (
                    <>
                      <NavLink
                        to={registerUrl}
                        onClick={handleNavLinkClick}
                        className={classnames(styles.navLink, styles.mobileOnlyBtn)}
                        data-testid="header-mobile-register"
                      >
                        REGISTER
                      </NavLink>
                      <NavLink
                        to={loginUrl}
                        onClick={handleNavLinkClick}
                        className={classnames(styles.navLink, styles.mobileOnlyBtn)}
                        data-testid="header-mobile-login"
                      >
                        SIGN IN
                      </NavLink>
                    </>
                  )}
                </nav>
                <SearchInput className={styles.searchPanel} />
                <ProfileDropDown className={classnames(styles.mobileProfileLink)} />
                {isAuthenticated ? (
                  <span
                    className={classnames(styles.navLink, styles.authBtn, styles.profileLink)}
                    data-testid="header-profile"
                  >
                    <ProfileDropDown className={styles.profileDropDown} />
                  </span>
                ) : (
                  <>
                    <NavLink
                      to={registerUrl}
                      onClick={handleNavLinkClick}
                      className={classnames(styles.navLink, styles.authBtn)}
                      data-testid="header-register"
                    >
                      REGISTER
                    </NavLink>
                    <NavLink
                      to={loginUrl}
                      onClick={handleNavLinkClick}
                      className={classnames(styles.navLink, styles.authBtn)}
                      data-testid="header-login"
                    >
                      SIGN IN
                    </NavLink>
                  </>
                )}
                <Button
                  type="link"
                  className={classnames(
                    styles.headerBtn,
                    styles.mobileNavBtn,
                    styles.mobileOnlyBtn,
                  )}
                  onClick={handleMobileNavClick}
                  data-testid="header-mobile-nav-btn"
                  name="Header mobile nav button"
                >
                  <Icon component={showMobileNav ? Close : Menu} />
                </Button>
              </Content>
            </header>
          );
        }
        return null;
      }}
    </UserSplitTreatments>
  );
};

export default Header;
