import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { getCurrentRoute } from '@/Utils/PageLocation';

const useHistoryBack = (fallbackPreviousRoute = '/') => {
  const history = useHistory();

  return useCallback(() => {
    // have history states
    if (history.length > 1) {
      history.goBack();
      return;
    }

    // no history state
    // store the current route directly from url
    const currentRoute = getCurrentRoute();
    // simulate a go back
    history.replace(fallbackPreviousRoute);
    history.push(currentRoute);
    history.goBack();
  }, [fallbackPreviousRoute, history]);
};

export default useHistoryBack;
