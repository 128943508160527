import { skipIntroSelected, watchCreditSelected, nextEpisodeSelected } from '@/Analytics/Segment';

const recordSkipIntroSelected = (assetId: string, title: string) => {
  skipIntroSelected({
    asset_id: assetId,
    title,
  });
};
const recordWatchCreditsSelected = (assetId: string, title: string) => {
  watchCreditSelected({
    asset_id: assetId,
    title,
  });
};
const recordNextEpisodeSelected = (assetId: string, title: string) => {
  nextEpisodeSelected({
    asset_id: assetId,
    title,
  });
};
export default { recordSkipIntroSelected, recordWatchCreditsSelected, recordNextEpisodeSelected };
