import React, { useState, useEffect, useCallback, FC } from 'react';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { setAccessTokenProviderFunction } from '@skytvnz/sky-app-store';
import { AppId } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import history from '@/Routes/History';
import Sentry from '@/Utils/Sentry';
import auth0Config from '@/Config/Auth0Config';
import { recordSignIn, recordUserIdentity } from '@/Analytics/Auth';
import Preloader from '@/Layouts/containers/Preloader';
import useUserResource from '@/Hooks/useUserResource';
import useAuth, { getUserContext } from '@/Hooks/useAuth';
import { actions, selectors } from '@/Store';
import { useDispatch, useSelector } from 'react-redux';
import useCustomerProfile from '@/Hooks/useCustomerProfile';
import { recordProfileSelected } from '@/Analytics/CustomerProfile';

const onRedirectCallback = (appState?) => {
  // Add and Remove login event after analytics data captured
  const redirectPath = appState?.returnTo || window.location.pathname;
  document.addEventListener<any>(
    'login',
    () => {
      history.replace(redirectPath);
    },
    { once: true },
  );
};

const AuthWrapper = ({ children }) => {
  const { isLoading, user, isAuthenticated } = useAuth0();
  const { loadToken } = useAuth();
  const dispatch = useDispatch();
  const [authLoading, setAuthLoading] = useState(true);
  const accountNumber = useSelector(selectors.auth.getDecodeConfig)(
    auth0Config.accountNumberClaimNameSpace,
  );

  const { selectedProfile, defaultProfile: mainProfile } = useCustomerProfile();

  useEffect(() => {
    dispatch(actions.auth.fetchConfig(AppId.SkyGoWeb));
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      setAccessTokenProviderFunction(loadToken as any);
    }
  }, [loadToken, isAuthenticated]);

  const recordAuthenticate = useCallback(() => {
    if (selectedProfile) {
      Sentry.identify(user, selectedProfile.id);
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const isAuthenticatedRedirect = urlParams.has('code');
    // Only after a successful login callback
    if (isAuthenticatedRedirect) {
      recordSignIn(user.email);
      // add this event to make sure the redirection happens after segment events sent
      const loginEvent = new CustomEvent<any>('login');
      document.dispatchEvent(loginEvent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, accountNumber, selectedProfile?.id]);

  const updateUser = useCallback(async () => {
    const userInfo = user;
    // Set user data
    dispatch(actions.auth.setUser(userInfo));

    // Fetch all available profiles for the logged user from the backend
    const customerProfiles = await dispatch(actions.customer.getCustomerAccount());
    if (customerProfiles?.payload?.customer?.profiles.length === 1) {
      dispatch(
        actions.customer.setSelectedProfileId(
          customerProfiles?.payload?.customer?.profiles?.[0].id,
        ),
      );
    }

    // Set spotify access token if available
    const spotifyAccessToken = userInfo?.[auth0Config.spotifyClaimNameSpace]?.access_token;
    if (spotifyAccessToken) {
      dispatch(actions.spotify.setAccessToken(spotifyAccessToken));
    }

    return userInfo;
  }, [dispatch, user]);

  const fetchUserResource = useUserResource();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    // After authenticating validation
    (async () => {
      if (isAuthenticated) {
        await loadToken();
        await fetchUserResource();
        await updateUser();
      }
      setAuthLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (isAuthenticated && !authLoading) {
      recordAuthenticate();
    }
  }, [isAuthenticated, authLoading, recordAuthenticate]);

  const recordSelectProfile = useCallback(() => {
    if (selectedProfile) {
      recordProfileSelected(selectedProfile?.name);
    }
  }, [selectedProfile]);

  useEffect(() => {
    const userContext = getUserContext(user);
    if (isAuthenticated && !authLoading) {
      recordUserIdentity(
        {
          email: user.email,
          account_id: user.sub,
          profile_id: selectedProfile?.id || mainProfile?.id,
          profile_name: selectedProfile?.name || mainProfile?.name,
          registration_date: userContext?.created_at,
          dth_account_number: accountNumber || undefined,
        },
        recordSelectProfile,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProfile, authLoading]);

  return !authLoading ? children : <Preloader isLoading />;
};

const AuthProvider: FC = ({ children }) => {
  return (
    <Auth0Provider
      domain={auth0Config.customDomain}
      clientId={auth0Config.clientId}
      audience={auth0Config.audience}
      useRefreshTokens
      cacheLocation="localstorage"
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <AuthWrapper>{children}</AuthWrapper>
    </Auth0Provider>
  );
};

export default AuthProvider;
