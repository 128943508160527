import useMedia from '@/Hooks/useMedia';

import { HALF_HOUR_WIDTH, HALF_HOUR_WIDTH_MOBILE, CELL_MARGIN } from '../constants';

export const useTimeWidthUnit = () => {
  const { isMediaM } = useMedia();
  const widthUnit = (isMediaM ? HALF_HOUR_WIDTH : HALF_HOUR_WIDTH_MOBILE) + CELL_MARGIN;

  return widthUnit;
};

export default useTimeWidthUnit;
