import React, { FC, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import classnames from 'classnames';
import styles from '../styles.module.scss';

export interface TimerProps {
  onTimerFinished(): void;
  timeout: number;
}

const calculateTimeRemaining = timeout => {
  const remainingMinutes = dayjs(timeout).diff(dayjs(), 'minute');
  const timeLeft = {
    // As we are not displaying seconds we show the 1 minute longer for the user
    // to avoid showing 0 minutes.
    minutes: remainingMinutes < 1 ? 1 : remainingMinutes,
  };
  return timeLeft;
};

const Timer: FC<TimerProps> = ({ onTimerFinished, timeout }) => {
  const [remainingTime, setRemainingTime] = useState(calculateTimeRemaining(timeout));

  useEffect(() => {
    const timer = setTimeout(() => {
      if (dayjs(timeout).isAfter(dayjs())) {
        setRemainingTime(calculateTimeRemaining(timeout));
      } else if (timeout) {
        setRemainingTime({ minutes: 0 });
        onTimerFinished();
      }
    }, 30000);
    return () => clearTimeout(timer);
  });

  return (
    <span data-testid="countdown-timer" className={classnames(styles.countDownText)}>
      {remainingTime.minutes} min
    </span>
  );
};

export default Timer;
