import {
  profileCreated,
  profileSelected,
  profileDeleted,
  profileNameChanged,
} from '@/Analytics/Segment';

export const recordProfileSelected = (profile_name: string) => {
  profileSelected({ profile_name });
};

export const recordProfileCreated = (profile_name: string) => {
  profileCreated({ profile_name });
};

export const recordProfileNameChanged = (profile_name: string) => {
  profileNameChanged({ profile_name });
};

export const recordProfileDeleted = (profile_name: string) => {
  profileDeleted({ profile_name });
};
