import { useEffect, useCallback, useRef } from 'react';
import debounce from 'lodash.debounce';

import usePersistCallback from './usePersistCallback';

export type ScrollHandler = (props: { top: number; bottom: number }) => void;

interface Props {
  scrollOffset?: number;
  scrollToEndHandler?: () => void;
  scrollHandler?: ScrollHandler;
  wait?: number;
}

const useScroll: (props: Props) => any = ({
  scrollOffset = 0,
  scrollToEndHandler,
  scrollHandler,
  wait = 200,
}) => {
  const clientNodeRef = useRef<any>();

  // The solution is refer from https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  // Because keeping node ref has some issue when navigate back to previous page
  const nodeRef = useCallback(node => {
    if (node) {
      clientNodeRef.current = node;
    }
  }, []);

  const handle = usePersistCallback(
    debounce(() => {
      const clientRect = clientNodeRef.current?.getBoundingClientRect();
      if (clientRect?.bottom <= window.innerHeight + scrollOffset) {
        scrollToEndHandler?.();
      }

      if (scrollHandler) {
        const { top, bottom } = clientRect;
        scrollHandler({ top, bottom });
      }
    }, wait),
  );

  useEffect(() => {
    window.addEventListener('scroll', handle);

    return () => {
      window.removeEventListener('scroll', handle);
    };
  }, [handle]);

  return {
    nodeRef,
  };
};

export default useScroll;
