import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ApmRoute } from '@elastic/apm-rum-react';
import Login from '@/Pages/Login';
import LinkAccountCallback from '@/Pages/LinkAccountCallback';

import Home from '@/Pages/Home';
import About from '@/Pages/About';
import Redirect from '@/Pages/Redirect';
import Watch from '@/Pages/Watch';
import Search from '@/Pages/Search';
import LiveTV from '@/Pages/LiveTV';
import NotFound from '@/Pages/NotFound';
import Collection from '@/Pages/Collection';
import ShowDetail from '@/Pages/Detail/ShowDetail';
import MovieDetail from '@/Pages/Detail/MovieDetail';
import TVGuide from '@/Pages/TVGuide/TVGuide';
import Browse, { BrowseCollection } from '@/Pages/Browse';
import DeviceLogin from '@/Pages/DeviceLogin';
import DeviceLogout from '@/Pages/DeviceLogout';

import ManageProfiles from '@/Pages/CustomerProfile/ManageProfiles';
import UserSplitTreatments from '@/Components/FeatureToggle/UserSplitTreatments';
import PrivateRoute from './PrivateRoute';
import RoutePath from './RoutePath';

const PROFILE_FEATURE_NAME = 'customer-profiles-web';

const Routes: React.FC = () => (
  <UserSplitTreatments featureNames={[PROFILE_FEATURE_NAME]}>
    {({ treatments, isReady }) => {
      const profileTrat = treatments[PROFILE_FEATURE_NAME]?.treatment;
      if (isReady) {
        return profileTrat !== 'on' ? (
          <Switch>
            {/* Util pages */}
            <ApmRoute exact path={RoutePath.register} component={Login} />
            <ApmRoute exact path={RoutePath.login} component={Login} />

            <PrivateRoute path={RoutePath.watch} component={Watch} />
            <PrivateRoute path={RoutePath.live} component={LiveTV} />
            <PrivateRoute path={RoutePath.linkAccountCallback} component={LinkAccountCallback} />

            <Route exact path={RoutePath.home} component={Home} />
            <Route exact path={RoutePath.about} component={About} />
            <Route exact path={RoutePath.redirect} component={Redirect} />
            <Route path={RoutePath.show} component={ShowDetail} />
            <Route path={RoutePath.movie} component={MovieDetail} />
            <Route path={RoutePath.collection} component={Collection} />
            <Route path={RoutePath.search} component={Search} />
            <Route path={RoutePath.tvGuide} component={TVGuide} />
            <Route path={RoutePath.browseCollection} component={BrowseCollection} />
            <Route path={RoutePath.browse} component={Browse} />
            <Route path={RoutePath.deviceLogin} component={DeviceLogin} />
            <Route path={RoutePath.deviceLogout} component={DeviceLogout} />
            {/* Must be the last one */}
            <ApmRoute path="*" component={NotFound} />

            {/* Dev Demo pages
                <PrivateRoute exact path={RoutePath.logout} component={Logout} />
                <PrivateRoute path="/demo/layout" component={LayoutDemo} />
                <PrivateRoute path="/demo/watch" component={WatchDemo} />
            */}
          </Switch>
        ) : (
          <Switch>
            {/* Util pages */}
            <ApmRoute exact path={RoutePath.register} component={Login} />
            <ApmRoute exact path={RoutePath.login} component={Login} />

            <PrivateRoute path={RoutePath.watch} component={Watch} />
            <PrivateRoute path={RoutePath.live} component={LiveTV} />
            <PrivateRoute path={RoutePath.linkAccountCallback} component={LinkAccountCallback} />

            <Route exact path={RoutePath.home} component={Home} />
            <Route exact path={RoutePath.about} component={About} />
            <Route exact path={RoutePath.redirect} component={Redirect} />
            <Route path={RoutePath.show} component={ShowDetail} />
            <Route path={RoutePath.movie} component={MovieDetail} />
            <Route path={RoutePath.collection} component={Collection} />
            <Route path={RoutePath.search} component={Search} />
            <Route path={RoutePath.tvGuide} component={TVGuide} />
            <Route path={RoutePath.browseCollection} component={BrowseCollection} />
            <Route path={RoutePath.browse} component={Browse} />
            <Route path={RoutePath.deviceLogin} component={DeviceLogin} />
            <Route path={RoutePath.deviceLogout} component={DeviceLogout} />
            <Route exact path={RoutePath.profile} component={ManageProfiles} />
            {/* Must be the last one */}
            <ApmRoute path="*" component={NotFound} />

            {/* Dev Demo pages
                <PrivateRoute exact path={RoutePath.logout} component={Logout} />
                <PrivateRoute path="/demo/layout" component={LayoutDemo} />
                <PrivateRoute path="/demo/watch" component={WatchDemo} />
            */}
          </Switch>
        );
      }
      return null;
    }}
  </UserSplitTreatments>
);

export default Routes;
