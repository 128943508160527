import { Button } from 'antd';
import classnames from 'classnames';
import React, { FC } from 'react';

import { ReactComponent as Record } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_record.svg';
import { ReactComponent as RecordSeries } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_record_series.svg';
import styles from './styles.module.scss';

interface RecordButtonProps {
  isLive?: boolean;
  isSecordary?: boolean;
  onClick(): void;
  text?: string;
  className?: string;
  testId?: string;
}

const RecordButton: FC<RecordButtonProps> = ({
  isLive,
  onClick,
  text,
  isSecordary,
  className,
  testId,
}) => (
  <Button
    type="primary"
    className={classnames(
      styles.controlButton,
      { [styles.primary]: !isSecordary && !isLive },
      className,
    )}
    onClick={onClick}
    data-testid={testId || 'record-button'}
    name={testId || 'Record button'}
  >
    {text === `RECORD SERIES` ? (
      <RecordSeries className={classnames(styles.controlButtonIcon, styles.recordIcon)} />
    ) : (
      <Record className={classnames(styles.controlButtonIcon, styles.recordIcon)} />
    )}
    <span className={styles.controlButtonSpan}>{text ? `${text}` : 'Record'}</span>
  </Button>
);

export default RecordButton;
