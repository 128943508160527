/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createGlobalState, useFullscreen, useLockBodyScroll, useToggle } from 'react-use';
import classnames from 'classnames';
import { F, is, isEmpty, isNil } from 'ramda';
import debounce from 'lodash.debounce';

import { PlaybackMeta } from '@skytvnz/sky-app-store/lib/types/models/PlaybackMeta';

import { actions } from '@/Store';
import AppEventBus, { AppEvent } from '@/Utils/AppEventBus';
import { numberIn0To1 } from '@/Utils/NumberInRange';
import AspectRatioBox, { AspectRatio } from '@/Layouts/containers/AspectRatioBox';
import sessionId from '@/Utils/SessionId';
import usePersistCallback from '@/Hooks/usePersistCallback';

import { YouboraPlayerConfig } from '@/Components/VideoPlayer/Core/YouboraPlayerConfig';
import { PLAYER_MODE, getViewPage } from '@/Utils/PageLocation';
import { useHistory } from 'react-router-dom';
import { useSubscriptionModal } from '@/Hooks/useSubscription';

import BrightCovePlayer from './Core/BrightCovePlayer';
import ShakaPlayer from './Core/ShakaPlayer';
import { PlayerController } from './Core/PlayerTypes';
import ControlPanel from './ControlPanel';
import { DrawerCommonProps, DrawerContentItem, MediaInfo } from './VideoTypes';
import { isBrowserWithWidevineSupport } from './DRM';
import SkipIntroPanel from './SkipIntroAutoplayPanel';

import styles from './styles.module.scss';

export const PLAYER_USER_INACTIVE_TIMEOUT = 5000; // in ms
export const PLAYER_DURATION_JUMP = 10; // in seconds
export const PLAYER_VOLUME_DEFAULT = 1;
export const PLAYER_VOLUME_UNMUTE = 0.05;
export const PLAYER_VOLUME_JUMP = 0.05;

const useGlobalVolume = createGlobalState<number>(PLAYER_VOLUME_DEFAULT);
const useGlobalMute = createGlobalState<boolean>(false);

// Props can be used by outside
export interface VideoProps {
  className?: string;
  startTime?: number;
  autoPlay?: boolean;
  fullWindow?: boolean;
  lockPageScrollOnFullWindow?: boolean;
  exitFullWindowOnPlayEnd?: boolean;
  pauseOnExitFullWindow?: boolean;
  onExitFullWindow?: () => void | boolean;
  startPosition?: string;
}

// Props only can be used by Live and Vod player
// TODO: do not pass the `player` instance to outside.
// Define a data interface and pass the pure data to outside to consume.
interface Props extends VideoProps, Partial<DrawerCommonProps> {
  mediaInfo: MediaInfo;
  playbackMeta?: PlaybackMeta;
  isLivePlayer?: boolean;
  isShowResourceButton?: boolean;
  isConcurrencyLimitReached?: boolean;
  assetId?: string;
  isSport?: boolean;
  onPlayerLoad?: () => void;
  onPlaybackDataLoad?: () => void;
  onVideoFirstPlay?: (player: PlayerController) => void;
  onVideoPlay?: (player: PlayerController) => void;
  onVideoPlaying?: (player: PlayerController) => void;
  onVideoPause?: (player: PlayerController) => void;
  onVideoResume?: (player: PlayerController) => void;
  onVideoEnd?: (player: PlayerController, isPlaying: boolean) => void;
  onTimeUpdate?: (player: PlayerController) => void;
  onLiveEndUpdate?: (player: PlayerController) => void;
  onPlaybackPositionChange: (player: PlayerController) => void;
  youboraPlayerConfiguration?: YouboraPlayerConfig;
  playerReferrer?: string;
  playerMode?: string;
  watchFromStart?: boolean;
  startPosition?: string;
  brandId?: string;
  episodeId?: string;
  onAutoPlayNextEpisode: (episodeId: string) => void;
}

const isDASH = isBrowserWithWidevineSupport();

const VideoPlayer: FC<Props> = props => {
  const {
    // Player props for outside
    className,
    startTime,
    autoPlay = false,
    fullWindow = false,
    lockPageScrollOnFullWindow = false,
    exitFullWindowOnPlayEnd = false,
    pauseOnExitFullWindow = false,
    onExitFullWindow,

    // Player props for inside
    mediaInfo,
    playbackMeta,
    isLivePlayer = false,
    isShowResourceButton = false,
    isSport = false,
    isConcurrencyLimitReached = false,

    // Drawer props for inside
    drawerTabs,
    drawerContentList,
    isDrawerContentLoading,
    selectedTabId,
    selectedContentId,
    onDrawerTabChange,
    onDrawerContentSelect,
    onDrawerOpen,

    // Player events for inside
    onPlayerLoad,
    onVideoPlaying,
    onPlaybackDataLoad,
    onVideoFirstPlay,
    onVideoPlay,
    onVideoPause,
    onVideoEnd,
    onTimeUpdate,
    onPlaybackPositionChange,
    youboraPlayerConfiguration,
    playerReferrer,
    playerMode,
    watchFromStart = false,
    startPosition,

    // Skip Intro Autoplay Props
    brandId,
    episodeId,
    onAutoPlayNextEpisode,
  } = props;
  const shouldUseShakaPlayer = isDASH && isLivePlayer;
  const dispatch = useDispatch();

  const playbackMetaRef = useRef<PlaybackMeta>();
  const autoPlayRef = useRef<boolean>();
  const hasFirstPlayedRef = useRef<boolean>(false);
  const hasFirstTimeUpdatedRef = useRef<boolean>(false);
  const lastSeekableEndRef = useRef<number>();

  // For controlling fullscreen
  const videoPlayerContainerRef = useRef<HTMLDivElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const isFullWindowRef = useRef(false);

  // For initializing player core
  const playerRef = useRef<PlayerController>();
  const hasPlayerPlaybackDataLoaded = useRef<boolean>(false);

  // player core state
  const [isLive, setIsLive] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useGlobalMute();
  const [volume, setVolume] = useGlobalVolume();
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [bufferRanges, setBufferRanges] = useState<TimeRanges>();
  const [isSeeking, setIsSeeking] = useState(false);
  const [hasAnimationEnded, setAnimationStatus] = useState(true);
  const [hasVideoEnded, setHasVideoEnded] = useState(false);

  // skipIntro AutoPlay state
  const [showAutoPlay, setShowAutoPlay] = useState<boolean>(false);
  const [hasAutoPlayOnEnd, setHasAutoPlayOnEnd] = useState(false);
  const hasAutoPlayOnEndRef = useRef(hasAutoPlayOnEnd);
  const hasAnimationEndedRef = useRef(hasAnimationEnded);
  useEffect(() => {
    hasAutoPlayOnEndRef.current = hasAutoPlayOnEnd;
    hasAnimationEndedRef.current = hasAnimationEnded;
  }, [hasAutoPlayOnEnd, hasAnimationEnded]);

  // player container state
  const [isFullWindow, setIsFullWindow] = useState(fullWindow);
  const [shouldBodyScrollLock, setShouldBodyScrollLock] = useState(
    lockPageScrollOnFullWindow && fullWindow,
  );
  useLockBodyScroll(lockPageScrollOnFullWindow && shouldBodyScrollLock);

  const [isFullScreen, toggleIsFullScreen] = useToggle(false);
  useFullscreen(videoPlayerContainerRef, isFullScreen, {
    video: videoRef,
    onClose: () => {
      toggleIsFullScreen(false);
    },
  });
  const assetId = isLivePlayer ? playbackMeta?.id : playbackMeta?.mediaAssetId;
  const [isInteracting, toggleIsInteracting] = useToggle(false);
  const debounceToggleIsInteracting = useCallback(
    debounce(toggleIsInteracting, PLAYER_USER_INACTIVE_TIMEOUT),
    [],
  );
  useEffect(() => {
    return () => {
      debounceToggleIsInteracting.cancel();
    };
  }, [debounceToggleIsInteracting]);

  const stopPlayback = usePersistCallback(() => {
    const id = isLivePlayer ? playbackMeta?.id : playbackMeta?.mediaAssetId;
    // Only stopPlayback once playbackMeta is loaded
    if (id) {
      dispatch(actions.playback.stopPlayback(id, isLivePlayer, sessionId, true));
    }
  });

  useEffect(() => {
    window.addEventListener('beforeunload', stopPlayback);

    return () => {
      stopPlayback();
      window.removeEventListener('beforeunload', stopPlayback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIsInteractingChange = useCallback(
    newIsInteracting => {
      if (newIsInteracting) {
        debounceToggleIsInteracting.cancel();
        toggleIsInteracting(true);
      } else {
        debounceToggleIsInteracting(false);
      }
    },
    [debounceToggleIsInteracting, toggleIsInteracting],
  );

  const togglePlayerAutoplay = useCallback((auto?: boolean) => {
    const player = playerRef.current;

    if (!player) {
      return;
    }

    const newAutoPlay = auto ?? !player.autoplay();
    player.autoplay(newAutoPlay && 'any');
  }, []);

  const togglePlayerMute = useCallback((mute?: boolean) => {
    const player = playerRef.current;

    if (!player) {
      return;
    }

    const newIsMuted = mute ?? !player.muted();
    player.muted(newIsMuted);
    setIsMuted(newIsMuted);

    if (!newIsMuted && player.volume() === 0) {
      player.volume(PLAYER_VOLUME_UNMUTE);
      setVolume(PLAYER_VOLUME_UNMUTE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setPlayerVolume = useCallback((newVolume: number) => {
    const player = playerRef.current;

    if (!player) {
      return;
    }

    const adjustedNewVolume = numberIn0To1(Math.floor(newVolume * 100) / 100);
    player.volume(adjustedNewVolume);
    setVolume(adjustedNewVolume);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVolumeUp = useCallback(() => {
    setPlayerVolume((volume || 0) + PLAYER_VOLUME_JUMP);
  }, [setPlayerVolume, volume]);

  const handelVolumeDown = useCallback(() => {
    setPlayerVolume((volume || 0) - PLAYER_VOLUME_JUMP);
  }, [setPlayerVolume, volume]);

  const handelVolumeChange = useCallback(
    (newVolume: number) => {
      if (newVolume === 0) {
        togglePlayerMute(true);
      } else {
        togglePlayerMute(false);
      }
      setPlayerVolume(newVolume);
    },
    [setPlayerVolume, togglePlayerMute],
  );

  const getPlayerCurrentTime = useCallback(() => {
    const player = playerRef.current;
    if (!player) {
      return 0;
    }
    return player.currentTime() - (isLivePlayer ? player.getLiveStartOffset() : 0);
  }, [isLivePlayer]);

  const refreshCurrentTime = useCallback(() => {
    const player = playerRef.current;

    if (!player) {
      return;
    }

    const playerCurrentTime = getPlayerCurrentTime();
    setCurrentTime(playerCurrentTime);
  }, [getPlayerCurrentTime]);

  const playerPlay = useCallback(async () => {
    const player = playerRef.current;
    if (!player) {
      return;
    }

    await player.play();
    // When live resume the player, reset the progress bar to live current time
    refreshCurrentTime();

    setIsPlaying(true);
  }, [refreshCurrentTime]);

  const playerPause = useCallback(() => {
    const player = playerRef.current;

    if (!player) {
      return;
    }

    player.pause();

    setIsPlaying(false);
  }, []);

  const handleIsPlayingChange = useCallback(
    (newIsPlaying: boolean) => {
      if (newIsPlaying) {
        playerPlay();
      } else {
        playerPause();
      }
    },
    [playerPause, playerPlay],
  );

  const playerPlayTo = useCallback(
    newTime => {
      const player = playerRef.current;

      if (!player) {
        return;
      }
      if (isSeeking) return;
      player.currentTime(newTime + (isLivePlayer ? player.getLiveStartOffset() : 0));
      setCurrentTime(newTime);

      onPlaybackPositionChange(player);
    },
    [onPlaybackPositionChange, isLivePlayer, isSeeking],
  );

  const playerPlayBack = useCallback(() => {
    const player = playerRef.current;

    if (!player) {
      return;
    }

    const playerCurrentTime = getPlayerCurrentTime();

    let newTime = playerCurrentTime - PLAYER_DURATION_JUMP;
    if (newTime < 0) {
      newTime = 0;
    }
    playerPlayTo(newTime);
  }, [getPlayerCurrentTime, playerPlayTo]);

  const playerSubTitle = (track?: any) => {
    const player = playerRef.current;
    if (!player) {
      return;
    }
    player.subTitle(track);
  };
  const playerPlayForward = useCallback(() => {
    const player = playerRef.current;

    if (!player) {
      return;
    }

    const playerCurrentTime = getPlayerCurrentTime();
    const videoDuration = isLivePlayer ? player.getLiveWindowDuration() : player.duration();

    let newTime = playerCurrentTime + PLAYER_DURATION_JUMP;
    if (newTime > videoDuration) {
      newTime = videoDuration;
    }
    playerPlayTo(newTime);
  }, [getPlayerCurrentTime, isLivePlayer, playerPlayTo]);

  const handleLiveTagClick = useCallback(() => {
    const player = playerRef.current;
    if (!player) {
      return;
    }
    // If player is not live, then back to live position
    if (!isLive) {
      player.seekToLiveEdge();

      const videoTime = player.currentTime() - player.getLiveStartOffset();
      setCurrentTime(videoTime);

      onPlaybackPositionChange(player);
    }
  }, [isLive, onPlaybackPositionChange]);

  const onWatchFromStart = useCallback(() => {
    const player = playerRef.current;
    if (!player) {
      return;
    }
    const exactStartTime = player.getLiveStartOffset();
    player.currentTime(exactStartTime);
    setIsLive(false);
    setCurrentTime(player.getLiveWindowDuration() - player.getLiveStartOffset());
    onPlaybackPositionChange(player);
  }, [onPlaybackPositionChange]);

  const toggleFullScreen = useCallback(
    (fullScreen?: boolean) => {
      if (fullScreen !== undefined) {
        toggleIsFullScreen(fullScreen);
      } else {
        toggleIsFullScreen();
      }
    },
    [toggleIsFullScreen],
  );

  const enterFullWindow = useCallback(() => {
    setIsFullWindow(true);
    isFullWindowRef.current = true;
    setShouldBodyScrollLock(true);
    // Set the page body to the same color as the player background,
    // to make phones with notch won't see the default blue color on sides
    document.body.classList.add(styles.bodyBackgroundColor);
  }, [setShouldBodyScrollLock]);

  const onExit = useCallback(() => {
    if (pauseOnExitFullWindow) {
      playerPause();
    }

    const player = playerRef.current;
    if (player) {
      onVideoEnd?.(player, isPlaying);
    }

    document.body.classList.remove(styles.bodyBackgroundColor);
    setShouldBodyScrollLock(false);
    const prevent = onExitFullWindow?.() === false;
    if (!prevent) {
      setIsFullWindow(false);
      isFullWindowRef.current = false;
    }
    stopPlayback();
  }, [isPlaying, onExitFullWindow, onVideoEnd, pauseOnExitFullWindow, playerPause, stopPlayback]);

  const handlePlayerPlaybackDataLoad = usePersistCallback(async () => {
    const player = playerRef.current;
    if (!player) {
      return;
    }

    // When it is linear slot and watch from start then start playback from start offset
    if (playerMode === PLAYER_MODE.linearSlot && isLivePlayer && watchFromStart) {
      onWatchFromStart();
      // Set the start time after meta loaded, before start playing.
      // so that the player can directly buffer from the start time
    } else if (is(Number, startTime)) {
      onPlaybackDataLoad?.();
      const exactStartTime = isLivePlayer
        ? player.getLiveCurrentTime() - (startTime || 0)
        : startTime;
      player.currentTime(exactStartTime);
    }
    hasPlayerPlaybackDataLoaded.current = true;
  });

  const handlePlayerFirstPlay = useCallback(async () => {
    const player = playerRef.current;
    if (!player) {
      return;
    }

    // To ensure the player currentTime has been update after playing
    const { current: hasFirstPlayed } = hasFirstPlayedRef;
    if (hasFirstPlayed === false) {
      onVideoFirstPlay?.(player);
      hasFirstPlayedRef.current = true;
    }

    // Reset the volume back to default, in some case it may be muted by autoplay error
    setPlayerVolume(volume || 0);
  }, [onVideoFirstPlay, setPlayerVolume, volume]);

  const handlePlayerVideoEnd = useCallback(async () => {
    setHasVideoEnded(true);
    if (!isConcurrencyLimitReached && playerRef.current && hasAnimationEndedRef.current) {
      onVideoEnd?.(playerRef.current, isPlaying);

      if (exitFullWindowOnPlayEnd && !hasAutoPlayOnEndRef.current) {
        onExitFullWindow?.();
      }
    }
    setIsPlaying(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConcurrencyLimitReached, onVideoEnd, exitFullWindowOnPlayEnd, onExitFullWindow]);

  // autoPlay changed
  useEffect(() => {
    autoPlayRef.current = autoPlay;
    if (playerRef.current) {
      togglePlayerAutoplay(autoPlay);
    }
  }, [autoPlay, togglePlayerAutoplay]);

  // fullWindow changed
  useEffect(() => {
    if (fullWindow) {
      enterFullWindow();
    } else {
      onExitFullWindow?.();
    }
  }, [enterFullWindow, onExitFullWindow, fullWindow]);

  // videoPlayback fetched, load it into player
  useEffect(() => {
    playbackMetaRef.current = playbackMeta;

    if (playbackMeta) {
      // Reset the state for the next video
      hasFirstPlayedRef.current = false;
      hasFirstTimeUpdatedRef.current = false;
      // setIsPlaying(false);
      setIsSeeking(false);
      setIsLive(false);
      setCurrentTime(0);
    }
  }, [playbackMeta]);

  useEffect(() => {
    // Only affect to VOD
    // Set duration here to show the duration on UI even before user start playing.
    // Live has `0`in playback response, will be re-calculated after start playing
    setDuration(mediaInfo.duration ?? 0); // duration in response is in milliseconds, player requires seconds
  }, [mediaInfo.duration]);

  /**
   * <------ Common Player events START ------>
   */

  const onPlayerPause = usePersistCallback(() => {
    if (playerRef.current) {
      onVideoPause?.(playerRef.current);
      setIsPlaying(false);
    }
  });

  const onPlayerPlay = usePersistCallback(() => {
    // Pause the playing Spotify when auto-play or resume.
    AppEventBus.emit(AppEvent.VideoPlayerPlayingChange, true);

    if (playerRef.current) {
      const { current: hasFirstPlayed } = hasFirstPlayedRef;
      if (hasFirstPlayed === true) {
        // only execute onVideoPlay if it's not the first play.
        onVideoPlay?.(playerRef.current);
      }
    }
    setIsPlaying(true);
  });

  const onPlayerTimeUpdate = usePersistCallback(() => {
    const player = playerRef.current;
    if (!player) {
      return;
    }
    // Ignore the 0 currentTime for live, live current time won't be 0.
    if (isLivePlayer && player.currentTime() <= 0) {
      return;
    }

    const { current: hasFirstTimeUpdated } = hasFirstTimeUpdatedRef;
    if (!hasFirstTimeUpdated) {
      hasFirstTimeUpdatedRef.current = true;

      handlePlayerFirstPlay();
      refreshCurrentTime();
    }

    if (isLivePlayer) {
      // For live stream only, set live window duration & update event
      const liveCurrentTime = player.getLiveCurrentTime();

      if (!lastSeekableEndRef.current || lastSeekableEndRef.current !== liveCurrentTime) {
        lastSeekableEndRef.current = liveCurrentTime;
        setDuration(player.getLiveWindowDuration());
        // For linear slot start is fixed and end is dynamic. Need to keep on updating the controls currect time
        if (playerMode === PLAYER_MODE.linearSlot) {
          const videoTime = player.currentTime() - player.getLiveStartOffset();
          setCurrentTime(videoTime);
        }
      }
      // When live set the live indicator status
      setIsLive(player.isAtLiveEdge());
    } else {
      refreshCurrentTime();
    }

    onTimeUpdate?.(player);
  });

  const onPlayerBuffering = usePersistCallback(() => {
    setBufferRanges(playerRef.current?.buffered() as TimeRanges);
  });

  const onPlayerSeeking = usePersistCallback(() => {
    setIsSeeking(true);
  });

  const onPlayerSeeked = usePersistCallback(() => {
    setIsSeeking(false);
  });

  const onPlayerError = usePersistCallback(() => {
    if (playbackMeta) playerRef.current?.reload?.(playbackMeta);
  });

  const onPlayerLoaded = usePersistCallback((playerController: PlayerController) => {
    // The video tag
    playerRef.current = playerController;
    videoRef.current = playerController.videoEl;
    onPlayerLoad?.();

    // Set state initial values
    togglePlayerAutoplay(autoPlayRef.current);
    setPlayerVolume(volume || 0);
    togglePlayerMute(isMuted);
  });

  const onPlayerUnload = useCallback(() => {
    playerRef.current = undefined;
  }, []);

  // We'll keep these event handlers in case we'd like to wire some logic into it:
  const onPlayerWaiting = useCallback(() => {}, []);

  const onPlayerPlaying = usePersistCallback(() => {
    if (playerRef.current) {
      onVideoPlaying?.(playerRef.current);
    }
    setIsPlaying(true);
  });

  // <------ Common Player events END ------>

  const playerEventProps = {
    onPlayerLoaded,
    onPlayerUnload,
    onEnded: handlePlayerVideoEnd,
    onDataLoaded: handlePlayerPlaybackDataLoad,
    onPlay: onPlayerPlay,
    onPause: onPlayerPause,
    onWaiting: onPlayerWaiting,
    onPlaying: onPlayerPlaying,
    onSeeking: onPlayerSeeking,
    onSeeked: onPlayerSeeked,
    onTimeUpdate: onPlayerTimeUpdate,
    onError: onPlayerError,
  };

  const history = useHistory();
  const showUpgradeSubscriptionModal = useSubscriptionModal();

  const handleDrawerContentSelection = useCallback(
    (id, selectedTab, drawerItem: DrawerContentItem) => {
      if (!isNil(drawerItem.requiredSubscriptions) && !isEmpty(drawerItem.requiredSubscriptions)) {
        showUpgradeSubscriptionModal(
          drawerItem.requiredSubscriptions,
          drawerItem.analyticsParams && {
            ...drawerItem.analyticsParams,
            categoryName: selectedTab?.title,
            viewName: getViewPage(history),
          },
        );
        return;
      }
      hasPlayerPlaybackDataLoaded.current = false;
      onDrawerContentSelect?.(id, selectedTab, drawerItem);
    },
    [onDrawerContentSelect, showUpgradeSubscriptionModal, history],
  );

  const playNextEpisode = useCallback(
    id => {
      hasPlayerPlaybackDataLoaded.current = false;
      onAutoPlayNextEpisode(id);
    },
    [onAutoPlayNextEpisode],
  );
  return (
    <AspectRatioBox
      className={classnames(styles.videoPlayer, { [styles.fullWindow]: isFullWindow }, className)}
      ratio={isFullWindow ? AspectRatio.RatioFree : AspectRatio.Ratio16by9}
      ref={videoPlayerContainerRef}
      testid="video-player"
    >
      <div className={styles.videoContainer}>
        {shouldUseShakaPlayer ? (
          <ShakaPlayer
            className={styles.videoWrapper}
            autoPlay={autoPlay}
            playbackMeta={playbackMeta}
            youboraPlayerConfig={youboraPlayerConfiguration}
            startPosition={startPosition}
            {...playerEventProps}
          />
        ) : (
          <BrightCovePlayer
            className={classnames(styles.videoWrapper, { [styles.showAutoPlay]: showAutoPlay })}
            playbackMeta={playbackMeta}
            isLivePlayer={isLivePlayer}
            onBuffering={onPlayerBuffering}
            youboraPlayerConfig={youboraPlayerConfiguration}
            startPosition={startPosition}
            {...playerEventProps}
          />
        )}
        {!showAutoPlay && (
          <ControlPanel
            isLivePlayer={isLivePlayer}
            isLive={isLive}
            assetId={assetId ?? ''}
            onSubTitleSelection={playerSubTitle}
            isShow={!isPlaying || isInteracting}
            isSport={isSport}
            onIsInteractingChange={handleIsInteractingChange}
            mediaInfo={mediaInfo}
            drawerTabs={drawerTabs}
            drawerContentList={drawerContentList}
            isDrawerContentLoading={isDrawerContentLoading}
            selectedTabId={selectedTabId}
            selectedContentId={selectedContentId}
            onDrawerTabChange={onDrawerTabChange}
            onDrawerContentSelect={handleDrawerContentSelection}
            onDrawerOpen={onDrawerOpen}
            onBackButtonClick={onExit}
            isShowBackButton={fullWindow}
            isSeeking={isSeeking}
            bufferRanges={bufferRanges}
            currentTime={currentTime}
            duration={duration}
            onCurrentTimeChanging={F}
            onCurrentTimeChanged={playerPlayTo}
            durationJump={PLAYER_DURATION_JUMP}
            isPlaying={isPlaying}
            onIsPlayingChange={handleIsPlayingChange}
            onPlayBack={playerPlayBack}
            onPlayForward={playerPlayForward}
            isMuted={isMuted}
            onIsMutedChange={togglePlayerMute}
            volume={volume || 0}
            onVolumeChange={handelVolumeChange}
            onVolumeUp={handleVolumeUp}
            onVolumeDown={handelVolumeDown}
            isShowResourceButton={isShowResourceButton}
            isFullScreen={isFullScreen}
            onFullScreenChange={toggleFullScreen}
            onLiveTagClick={handleLiveTagClick}
            playerReferrer={playerReferrer}
            playerMode={playerMode}
            hasPlayerPlaybackDataLoaded={hasPlayerPlaybackDataLoaded.current}
          />
        )}
        {!shouldUseShakaPlayer && hasPlayerPlaybackDataLoaded.current && (
          <SkipIntroPanel
            currentTime={currentTime}
            onCurrentTimeChanged={playerPlayTo}
            playBackMeta={playbackMeta}
            brandId={brandId}
            episodeId={episodeId}
            isControlPanalShow={!isPlaying || isInteracting}
            videoLength={duration}
            setHasAutoPlayOnEnd={setHasAutoPlayOnEnd}
            showAutoPlay={showAutoPlay}
            hasVideoEnded={hasVideoEnded}
            setShowAutoPlay={setShowAutoPlay}
            setHasVideoEnded={setHasVideoEnded}
            playNextEpisode={playNextEpisode}
            setAnimationStatus={setAnimationStatus}
          />
        )}
      </div>
    </AspectRatioBox>
  );
};

export default memo(VideoPlayer);
