import React, { useLayoutEffect, useRef, useState, useEffect, useCallback } from 'react';

import { Fn } from 'react-use-gesture/dist/types';
import { useGesture } from 'react-use-gesture';
import usePersistCallback from './usePersistCallback';

const calculateNextOffset = (
  scrollingElement: HTMLElement,
  currentScrollPosition: number,
  isForward: boolean,
) => {
  const nodeStyles = window.getComputedStyle(scrollingElement);
  const paddingLeft = parseFloat(nodeStyles.paddingLeft);
  const paddingRight = parseFloat(nodeStyles.paddingRight);
  const listWidth = parseFloat(nodeStyles.width) - paddingLeft - paddingRight;

  const { children } = scrollingElement;
  const { offsetWidth: childWidth } = children[0] as HTMLElement;
  const pageSize = Math.floor(listWidth / childWidth);
  const targetPosition = currentScrollPosition + (isForward ? 1 : -1) * listWidth;

  let nextPosition = 0;

  for (let index = 0; index < children.length; index += 1) {
    const child = children[index] as HTMLElement;
    const { offsetLeft, offsetWidth } = child;
    nextPosition = offsetLeft - paddingLeft;
    const offset = isForward ? nextPosition + offsetWidth : nextPosition;

    if (offset >= targetPosition) {
      break;
    }
  }

  if (isForward) {
    const lastChild = children[children.length - 1] as HTMLElement;
    const { marginRight } = window.getComputedStyle(lastChild);
    const { offsetLeft, offsetWidth } = lastChild;
    const lastChildOffset = offsetLeft - paddingLeft + offsetWidth + parseFloat(marginRight);

    // Total items width is less than whole list width
    if (listWidth > lastChildOffset) {
      nextPosition = 0;
    } else if (nextPosition + listWidth > lastChildOffset) {
      nextPosition = lastChildOffset - listWidth;
    }
  }

  return {
    pageSize,
    nextPosition,
    currentItemIndex: Math.floor(nextPosition / childWidth),
  };
};

export type HandlePaginate = (startIndex: number, pageSize: number, isForward: boolean) => void;

const useScrollList = (
  containerElement: React.MutableRefObject<any>,
  scrollingElement: React.MutableRefObject<any>,
  handlePaginate?: HandlePaginate,
) => {
  const currentScrollPosition = useRef(0);

  const [isRightScrollDisabled, setRightScrollDisabled] = useState(false);
  const [isLeftScrollDisabled, setLeftScrollDisabled] = useState(true);

  const initScrollButtons = useCallback(
    (shouldReset?: boolean) => {
      if (scrollingElement.current) {
        const { clientWidth: scrollListWidth } = scrollingElement.current;
        const scrollItems = scrollingElement.current.children;
        const lastScrollItem = scrollItems[scrollItems.length - 1];
        const lastOffset = lastScrollItem
          ? lastScrollItem.offsetLeft + lastScrollItem.offsetWidth
          : 0;

        setLeftScrollDisabled(shouldReset || currentScrollPosition.current <= 1);
        setRightScrollDisabled(lastOffset <= currentScrollPosition.current + scrollListWidth);
      }
    },
    [scrollingElement],
  );

  const resetButtons = useCallback(() => {
    initScrollButtons(true);
  }, [initScrollButtons]);

  const onPaginate = usePersistCallback((isForward: boolean) => {
    const { current: scrollList } = scrollingElement;
    if (scrollList) {
      // scroll buttons are only available for mobile
      const { nextPosition, currentItemIndex, pageSize } = calculateNextOffset(
        scrollList,
        currentScrollPosition.current,
        isForward,
      );

      if (scrollList?.style) {
        scrollList.style.transform = `translate3d(${-nextPosition}px, 0, 0)`;
      }
      currentScrollPosition.current = nextPosition;

      handlePaginate?.(currentItemIndex, pageSize, isForward);
      initScrollButtons();
    }
  });

  const onInitialLoad = usePersistCallback(initialPaginateIndex => {
    const { current: scrollList } = scrollingElement;
    const { children } = scrollList;
    if (initialPaginateIndex > 0 && children[initialPaginateIndex]) {
      const nodeStyles = window.getComputedStyle(scrollList);
      const paddingLeft = parseFloat(nodeStyles.paddingLeft);
      const { offsetLeft } = children[initialPaginateIndex] as HTMLElement;
      const nextPosition = offsetLeft - paddingLeft;

      currentScrollPosition.current = nextPosition;
      if (scrollList?.style) {
        scrollList.style.transform = `translate3d(${-nextPosition}px, 0, 0)`;
      }
    }
  });

  const resetScrollPosition = usePersistCallback(() => {
    currentScrollPosition.current = 0;
    const { current: scrollList } = scrollingElement;
    if (scrollList?.style) {
      scrollList.style.transform = `translate3d(0px, 0, 0)`;
    }
  });

  const swipeAndDrag = useGesture(
    {
      onDragStart: () => {},
      onDrag: ({ movement: [x] }) => {
        const { current: scrollList } = scrollingElement;
        const translatePosition = currentScrollPosition.current - x;

        if (scrollList?.style) {
          scrollList.style.transform = `translate3d(${-translatePosition}px, 0, 0)`;
        }
      },
      onDragEnd: ({ movement: [x], direction }) => {
        const { current: scrollList } = scrollingElement;
        if (scrollList?.style) {
          if (direction[0] * x > 0) {
            onPaginate(x < 0);
          } else {
            scrollList.style.transform = `translate3d(${-currentScrollPosition.current}px, 0, 0)`;
          }
        }
      },
      onWheelEnd: ({ direction: [dx] }) => {
        const d = Math.round(dx);
        if (d !== 0) {
          onPaginate(d > 0);
        }
      },
    },
    {
      domTarget: containerElement,
    },
  );

  useEffect(() => swipeAndDrag() as Fn, [swipeAndDrag]);

  useLayoutEffect(() => {
    initScrollButtons(true);
  }, [initScrollButtons]);

  return {
    isRightScrollDisabled,
    isLeftScrollDisabled,
    onPaginate,
    resetButtons,
    initScrollButtons,
    onInitialLoad,
    resetScrollPosition,
  };
};

export default useScrollList;
