/* eslint-disable react/no-array-index-key */
import classnames from 'classnames';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { ReactComponent as Left } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_chevron_back.svg';
import { ReactComponent as Right } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_chevron_forward.svg';
import dayjs from 'dayjs';
import DateItem from './DateItem';
import styles from './styles.module.scss';

interface Props {
  selectedDate: dayjs.Dayjs;
  dateList: dayjs.Dayjs[];
  onClick?: (date: dayjs.Dayjs) => void;
}

const DateSelector: React.FC<Props> = props => {
  const { selectedDate, dateList, onClick } = props;

  const currentTranslatePosition = useRef(0);
  const containerElement = useRef<HTMLDivElement>(null);
  const scrollingElement = useRef<HTMLDivElement>(null);

  const [isRightScrollDisabled, setRightScrollDisabled] = useState(false);
  const [isLeftScrollDisabled, setLeftScrollDisabled] = useState(true);

  const initScrollButtons = () => {
    const containerWidth = containerElement.current?.clientWidth;
    const scrollListWidth = scrollingElement.current?.clientWidth;

    if (scrollListWidth && containerWidth) {
      if (currentTranslatePosition.current === 0) {
        setLeftScrollDisabled(true);
        setRightScrollDisabled(
          containerWidth - currentTranslatePosition.current >= scrollListWidth,
        );
      } else {
        setLeftScrollDisabled(false);
        if (scrollingElement.current) {
          setRightScrollDisabled(
            containerWidth - currentTranslatePosition.current >= scrollListWidth,
          );
        }
      }
    }
  };

  const buttonScrollHandler = (direction: number) => {
    if (scrollingElement.current && containerElement.current) {
      const onePageWidth = containerElement.current?.clientWidth;
      const scrollListWidth = scrollingElement.current?.clientWidth;

      // scroll buttons are only available for mobile
      const originalTranslate = currentTranslatePosition.current;
      let newTranslate = 0;

      if (direction > 0) {
        // go right
        const calculatedTranslate = originalTranslate - onePageWidth;
        newTranslate =
          Math.abs(calculatedTranslate) + onePageWidth > scrollListWidth
            ? originalTranslate - (scrollListWidth - Math.abs(calculatedTranslate))
            : calculatedTranslate;
      } else {
        // go left
        const calculatedTranslate = originalTranslate + onePageWidth;
        newTranslate = calculatedTranslate > 0 ? 0 : calculatedTranslate; // scroll at most to 0
      }
      currentTranslatePosition.current = newTranslate;
      scrollingElement.current.style.transform = `translate3d(${newTranslate}px, 0, 0)`;

      initScrollButtons();
    }
  };

  const handleResize = () => {
    // reset translate when resizing from a paged view to desktop size
    if (
      scrollingElement.current &&
      containerElement.current &&
      containerElement.current?.clientWidth >= scrollingElement.current?.clientWidth
    ) {
      currentTranslatePosition.current = 0;
      scrollingElement.current.style.transform = `translate3d(${0}px, 0, 0)`;
    }

    initScrollButtons();
  };

  useLayoutEffect(() => {
    initScrollButtons();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <div
      ref={containerElement}
      className={classnames(styles.dateSelectorContainer)}
      data-testid="date-selector-container"
    >
      <button
        type="button"
        className={classnames(styles.scrollButton, styles.scrollButtonLeft, {
          [styles.scrollButtonDisabled]: isLeftScrollDisabled,
        })}
        onClick={() => buttonScrollHandler(-1)}
        data-testid="scroll-left-button"
        name="Date selector scroll left button"
      >
        <Left className={styles.scrollButtonIcon} />
      </button>
      <div
        ref={scrollingElement}
        className={classnames(styles.dateSelectorScrollContainer)}
        data-testid="date-selector-scroll-container"
      >
        {dateList.map(
          (date, index) =>
            date && (
              <DateItem
                key={index}
                index={index}
                date={date}
                isSelected={date.isSame(selectedDate, 'day')}
                isFirst={dateList[0].isSame(date, 'day')}
                isLast={dateList[dateList.length - 1].isSame(date, 'day')}
                onClick={onClick}
              />
            ),
        )}
      </div>
      <button
        type="button"
        className={classnames(styles.scrollButton, styles.scrollButtonRight, {
          [styles.scrollButtonDisabled]: isRightScrollDisabled,
        })}
        onClick={() => buttonScrollHandler(1)}
        data-testid="scroll-right-button"
        name="Date selector scroll right button"
      >
        <Right className={styles.scrollButtonIcon} />
      </button>
    </div>
  );
};

export default DateSelector;
