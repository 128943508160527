import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNil } from 'ramda';
import { withTransaction } from '@elastic/apm-rum-react';

import { actions, selectors, utils } from '@/Store';
import Rail from '@/Components/Rail';
import Heading from '@/Components/Heading';
import Content from '@/Layouts/containers/Content';
import { useSearchQuery } from '@/Components/Header/SearchInput';
import { Search as searchAnalytics } from '@/Analytics';
import TileType from '@/Components/Rail/Tiles/TileType';
import { usePageHeader } from '@/Components/AppHeader/AppHeader';
import pageViewedAnalytics from '@/Analytics/Page';

import { GroupLayout } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import styles from './styles.module.scss';

const trackSearchAnalytics = (searchResultIsLoading, searchResult, searchQuery) => {
  if (searchResultIsLoading) {
    return;
  }
  if (!isNil(searchResult)) {
    let analyticsRes: any = [];
    searchResult.forEach(groupResult => {
      analyticsRes = analyticsRes.concat(groupResult.contentPage.content);
    });
    if (!isEmpty(analyticsRes)) {
      searchAnalytics.searchResultsAnalytics(analyticsRes, searchQuery);
    } else {
      searchAnalytics.zeroResultsSearchAnalytics(searchQuery);
    }
  }
};

const Search: React.FC = () => {
  const searchQuery = useSearchQuery();
  const dispatch = useDispatch();
  const query = useSelector(selectors.search.query);
  const searchResult = useSelector(selectors.search.result);
  const searchResultIsLoading = useSelector(selectors.search.resultIsLoading);
  const { data: popularShows, isLoading: popularIsLoading } = useSelector(selectors.search.popular);
  const { data: similarShows, isLoading: similarIsLoading } = useSelector(selectors.search.similar);
  const hasResult = !isNil(searchResult) && !isEmpty(searchResult);

  useEffect(() => {
    // TODO clarify with PO on page structure and popular rail curation
    // dispatch(actions.search.fetchPopular(popularRailUrl));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const trackSearch = async () => {
      const results = await dispatch(actions.search.search(searchQuery));
      trackSearchAnalytics(false, results.payload.search?.groupResults, searchQuery);
    };
    trackSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const pageTitle = Array.isArray(searchQuery) ? searchQuery[0] : searchQuery;
  usePageHeader({
    title: pageTitle,
  });
  useEffect(() => {
    pageViewedAnalytics('Search');
  }, []);

  return (
    <div className={styles.search}>
      {searchResultIsLoading && (
        <Rail
          heading={`Results for "${query}"`}
          type={TileType.Media}
          assets={[]}
          layout={GroupLayout.RailLandscape}
          isLoading={searchResultIsLoading}
          viewName="Search"
        />
      )}
      {!searchResultIsLoading && hasResult && (
        <>
          <Content>
            <Heading
              testid="search-result-heading"
              className={styles.header}
            >{`Results for "${query}"`}</Heading>
          </Content>
          {searchResult?.map(groupResult => (
            <Rail
              key={groupResult.id}
              heading={groupResult.title}
              type={TileType.Media}
              assets={utils.pagination.getContent(groupResult.contentPage)}
              layout={GroupLayout.RailLandscape}
              isLoading={searchResultIsLoading}
              viewName="Search"
            />
          ))}
        </>
      )}
      {!searchResultIsLoading && !isNil(query) && !hasResult && (
        <Content>
          <Heading>No Results for &ldquo;{query}&rdquo;</Heading>
        </Content>
      )}

      {(similarIsLoading || (!isNil(similarShows) && !isEmpty(similarShows))) && (
        <Rail
          heading="Similar shows & movies"
          type={TileType.Media}
          assets={similarShows.reverse()}
          layout={GroupLayout.RailLandscape}
          isLoading={similarIsLoading}
          viewName="Search"
        />
      )}

      {!isNil(popularShows) && !isEmpty(popularShows) && (
        <Rail
          heading="Trending now"
          type={TileType.Media}
          assets={popularShows}
          layout={GroupLayout.RailLandscape}
          isLoading={popularIsLoading}
          viewName="Search"
        />
      )}
    </div>
  );
};

export default withTransaction('Search', 'component')(Search);
