import React, { FC, memo, useMemo } from 'react';
import classnames from 'classnames';

import Preloader from '@/Layouts/containers/Preloader';
import TabsList from '@/Components/TabsList';

import TabItem from './TabItem';
import ContentItem from './ContentItem';
import { DrawerCommonProps } from '../../../VideoTypes';

import styles from './styles.module.scss';

interface Props extends DrawerCommonProps {
  isShow?: boolean;
  isLivePlayer: boolean;
}

const SelectorDrawer: FC<Props> = ({
  isShow = true,
  isLivePlayer,
  drawerTabs,
  drawerContentList,
  isDrawerContentLoading = false,
  selectedTabId,
  selectedContentId,
  onDrawerTabChange,
  onDrawerContentSelect,
}) => {
  const selectedTabIndex = useMemo(() => drawerTabs.findIndex(tab => tab.id === selectedTabId), [
    drawerTabs,
    selectedTabId,
  ]);
  const selectedTab = drawerTabs[selectedTabIndex];

  return (
    <div
      className={classnames(styles.drawerContainer, { [styles.show]: isShow })}
      data-testid="video-player-selector-draw-container"
    >
      <TabsList
        isShow={isShow}
        className={styles.tabs}
        buttonClassName={styles.scrollButton}
        selectedTabIndex={selectedTabIndex}
      >
        {drawerTabs.map(tab => (
          <TabItem
            key={tab.id}
            id={tab.id}
            isSelected={tab.id === selectedTabId}
            onClick={onDrawerTabChange}
          >
            {tab.title}
          </TabItem>
        ))}
      </TabsList>
      <div className={styles.content}>
        <Preloader isLoading={isDrawerContentLoading} className={styles.drawerSpinner}>
          <ul className={styles.contentList}>
            {drawerContentList.map(
              content =>
                content && (
                  <ContentItem
                    isLiveChannel={isLivePlayer}
                    key={content.id}
                    content={content}
                    isSelected={selectedContentId === content.id}
                    onSelect={id => onDrawerContentSelect?.(id, selectedTab, content)}
                  />
                ),
            )}
          </ul>
        </Preloader>
      </div>
    </div>
  );
};

export default memo(SelectorDrawer);
