enum ContentType {
  Show = 'Show',
  Episode = 'Episode',
  Movie = 'Movie',
  LinearChannel = 'LinearChannel',
  SportsFixture = 'SportsFixture',
  Collection = 'Collection',
  BrowseCollection = 'BrowseCollection',
  LinearSlot = 'LinearSlot',
}

export default ContentType;
