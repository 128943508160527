import React, { FC } from 'react';
import classnames from 'classnames';

import BackButton from './BackButton';

import styles from './styles.module.scss';

export interface Props {
  isShow?: boolean;
  onBackButtonClick: () => void;
}

const BackPanel: FC<Props> = ({ isShow = false, onBackButtonClick }) => {
  return (
    <div className={classnames(styles.backPanel, { [styles.backPanelHide]: !isShow })}>
      <BackButton onClick={onBackButtonClick} testId="video-player-navigate-back" />
    </div>
  );
};

export default BackPanel;
