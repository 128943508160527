import React, { FC, forwardRef, Ref } from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

interface Props {
  row?: number;
  rowGap?: boolean;
  className?: string;
  ref?: Ref<HTMLDivElement>;
  testid?: string;
}

const Grid: FC<Props> = forwardRef(
  ({ children, className, testid, row = 1, rowGap = false }, ref) => {
    return (
      <div
        className={classnames(
          styles.grid,
          { [styles.gridAutoRowGap]: rowGap, [styles[`gridRow${row}`]]: true },
          className,
        )}
        ref={ref}
        data-testid={testid}
      >
        {children}
      </div>
    );
  },
);

export default Grid;
