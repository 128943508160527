import React, { useCallback, useMemo, useState } from 'react';
import useCustomerProfile from '@/Hooks/useCustomerProfile';
import { Button, Input } from 'antd';
import classnames from 'classnames';
import buttonStyle from '@/Components/Button/styles.module.scss';
import { ReactComponent as Delete } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_delete.svg';
import defaultProfileSVG from '@skytvnz/sky-app-style/lib/assets/svg/backgrounds/icon_default_profile.color.svg';
import Icon from '@ant-design/icons';
import useMedia from '@/Hooks/useMedia';
import { ADD_PROFILE } from './constants';
import Avatar from '../Avatar';

import styles from './styles.module.scss';
import useSimpleModal from '../Modal/useSimpleModal';

const DELETE_PROFILE_TITLE = 'Are you sure you want to delete this profile?';
const DELETE_PROFILE_CONTENT =
  'All their Watchlists, Continue Watching and personalisation will be deleted.';
const DELETE_PROFILE_OK_BUTTON = 'YES, DELETE';
const DELETE_PROFILE_CANCEL_BUTTON = 'NO, KEEP IT';
const MAX_INPUT_LENGTH = 12;

export interface CustomerProfileItem {
  id?: string;
  name?: string;
  avatar?: string;
  isDefault?: boolean;
}
export interface ManageProfileProps {
  selectedCustomerProfile?: CustomerProfileItem;
  onClose: () => void;
}

const ManageProfile: React.FC<ManageProfileProps> = manageProfileProps => {
  const { selectedCustomerProfile, onClose } = manageProfileProps;
  const { addNewProfile, updateProfile, deleteProfile } = useCustomerProfile();
  const [profileName, setProfileName] = useState<string | undefined>(selectedCustomerProfile?.name);
  const { openModal } = useSimpleModal();
  const { isMediaM } = useMedia();
  const isAddProfile = selectedCustomerProfile?.id === ADD_PROFILE;

  const selectedProfile = useMemo(() => {
    return isAddProfile
      ? { name: '', id: ADD_PROFILE, avatar: defaultProfileSVG, isDefault: false }
      : selectedCustomerProfile;
  }, [isAddProfile, selectedCustomerProfile]);

  const saveProfileHandler = useCallback(() => {
    if (isAddProfile) {
      addNewProfile(profileName);
    } else {
      updateProfile(selectedProfile?.id, profileName);
    }
    onClose();
  }, [addNewProfile, isAddProfile, onClose, profileName, selectedProfile, updateProfile]);

  const deleteProfileHandler = useCallback(() => {
    openModal({
      title: DELETE_PROFILE_TITLE,
      content: DELETE_PROFILE_CONTENT,
      okText: DELETE_PROFILE_OK_BUTTON,
      cancelable: true,
      cancelText: DELETE_PROFILE_CANCEL_BUTTON,
      closable: isMediaM,
      onClose: () => {},
      onCancel: () => {},
      onOk: () => {
        deleteProfile(selectedProfile?.id);
        onClose();
      },
    });
  }, [deleteProfile, onClose, openModal, selectedProfile, isMediaM]);

  const handleInputChanged = event => {
    setProfileName(event.target.value);
  };
  return (
    <div className={styles.manageProfilesContainer}>
      <div className={styles.manageProfilesTitle} data-testid="manage-profiles-title">
        {isAddProfile ? 'Add Profile' : 'Edit Profile'}
      </div>
      <div className={styles.manageProfileForm}>
        <Avatar
          size={!isMediaM ? 60 : 140}
          isActive
          profileImage={selectedProfile?.avatar}
          className={styles.editAvatar}
        />
        <Input
          size="large"
          placeholder={selectedProfile?.name}
          className={classnames(
            styles.customerProfileInput,
            profileName && profileName?.length > MAX_INPUT_LENGTH && styles.hasError,
          )}
          onChange={handleInputChanged}
          autoComplete="false"
          data-testid="customer-profile-input"
          defaultValue={selectedProfile?.name}
          autoFocus
        />
        <p
          className={classnames(
            styles.inputCount,
            profileName && profileName?.length > MAX_INPUT_LENGTH && styles.hasErrorCount,
          )}
          data-testid="customer-profile-input-count"
        >
          {profileName?.length || 0} / {MAX_INPUT_LENGTH}
        </p>
        <div className={styles.manageProfileFooter}>
          <Button
            className={classnames(styles.profileButton, buttonStyle.primary)}
            data-testid="customer-profile-save-button"
            name="Customer Profile Save button"
            onClick={
              (profileName && profileName?.length > MAX_INPUT_LENGTH) || profileName?.length === 0
                ? e => e.preventDefault()
                : saveProfileHandler
            }
          >
            <span className={styles.profileButtonText}>SAVE</span>
          </Button>
          <Button
            className={classnames(styles.profileButton, styles.borderlessButton)}
            data-testid="customer-profile-cancel-button"
            name="Customer Profile Cancel button"
            onClick={onClose}
          >
            <span className={styles.profileButtonText}>CANCEL</span>
          </Button>
        </div>

        {!isAddProfile && (
          <div className={styles.footerText}>
            Changes will apply across all your devices.
            {selectedProfile?.isDefault && (
              <>
                <br />
                Account holder’s profile can’t be deleted.
              </>
            )}
          </div>
        )}
      </div>
      {!selectedProfile?.isDefault && !isAddProfile && (
        <Button
          className={classnames(styles.deleteButton)}
          data-testid="customer-profile-delete-button"
          name="Customer Profile Delete button"
          onClick={deleteProfileHandler}
        >
          <Icon
            className={styles.deleteButtonIcon}
            component={Delete as React.FC}
            data-testid="customer-profile-delete-button-icon"
          />
          <span>Delete Profile</span>
        </Button>
      )}
    </div>
  );
};

export default ManageProfile;
