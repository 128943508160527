import React from 'react';
import { isEmpty, isNil } from 'ramda';
import { withTransaction } from '@elastic/apm-rum-react';
import { BrandType } from '@skytvnz/sky-app-store/lib/types/enums/BrandType';

import { actions, utils } from '@/Store';
import Preloader from '@/Layouts/containers/Preloader';
import ShowHeroBanner from '@/Components/HeroBanner/ShowHeroBanner';
import Synopsis from '@/Components/Synopsis';
import Seasons from '@/Pages/Detail/Seasons';
import { PageType } from '@/Components/HeroBanner';
import useBrandDetails from '@/Hooks/useBrandDetails';
import Soundtrack from '@/Pages/Detail/Soundtrack';
import WhatsOnTv from '@/Pages/Detail/WhatsOnTv';
import useMedia from '@/Hooks/useMedia';
import ControllingChannel from '@/Components/HeroBanner/ControllingChannel';
import ErrorMessage from '@/Components/Message';
import synopsisStyles from '@/Components/Synopsis/styles.module.scss';
import Heading from '@/Components/Heading';
import Content from '@/Layouts/containers/Content';

import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';
import Episodes from './Seasons/Episodes';

const ShowDetail: React.FC = () => {
  const {
    brand: show,
    isSport,
    image,
    imageType,
    selectedSeason,
    brandId,
    brandIsLoading,
    onMyListToggle,
    highlights,
    onHighlightsPaginate,
  } = useBrandDetails('ShowDetail');
  const dispatch = useDispatch();
  const { isMediaM } = useMedia();

  const selectedSeasonChanged = season => {
    dispatch(actions.titles.selectSeason(brandId, season.id));
  };
  const hasEpisodes =
    show?.__typename === BrandType.Show &&
    !isNil(show?.seasons) &&
    !isEmpty(show.seasons) &&
    !isNil(show.seasons[0]?.episodes) &&
    !isEmpty(show.seasons[0]?.episodes);

  const highlightsContent = utils.pagination.getContent(highlights);
  const hasHighlights = !isNil(highlightsContent) && !isEmpty(highlightsContent);

  return (
    <Preloader isLoading={isNil(show) && brandIsLoading}>
      {!isNil(show) && show.__typename === BrandType.Show ? (
        <>
          <ShowHeroBanner
            className={styles.heroBanner}
            show={show}
            image={image}
            imageType={imageType}
            pageType={PageType.Detail}
            onMyListToggle={onMyListToggle}
          />
          {!isMediaM && (
            <Content className={synopsisStyles.synopsis}>
              <Synopsis synopsis={show.synopsis} cast={show.defaultEpisode?.characters} />
            </Content>
          )}
          {!isMediaM && <ControllingChannel channels={show.brands} />}
          {/* <Trailers />*/}

          <WhatsOnTv brand={show} />
          {hasHighlights && (
            <div data-testid="highlight">
              <Content>
                <Heading testid="highlight-heading">Mini Highlights</Heading>
              </Content>
              <Episodes
                episodes={highlightsContent}
                brandId={brandId}
                isSport={isSport}
                playbackOrigin="Content Mini Highlights"
                isTilesLoading={utils.pagination.isLoading(highlights)}
                hasError={utils.pagination.isLoadingError(highlights)}
                hasNextPage={utils.pagination.hasNextPage(highlights)}
                tilesLoadHandler={onHighlightsPaginate}
              />
            </div>
          )}
          {hasEpisodes && (
            <Seasons
              seasons={show.seasons}
              selectedSeason={selectedSeason}
              brandId={brandId}
              onSeasonChange={selectedSeasonChanged}
              isSport={isSport}
            />
          )}

          <Soundtrack selectedSeason={selectedSeason} brandId={show.id} />
          {/* <Similar />*/}
        </>
      ) : (
        <ErrorMessage />
      )}
    </Preloader>
  );
};

export default withTransaction('ShowDetail', 'component')(ShowDetail);
