import {
  spotifySoundtrackAddedToLibrary,
  spotifyAccountLinked,
  spotifyAccountLinkInitiated,
  spotifySoundtrackPaused,
  spotifySoundtrackPlayed,
  spotifySoundtrackRemovedFromLibrary,
} from '@/Analytics/Segment';

// TODO - Implement revamped analytics for spotify when doing https://skynz.atlassian.net/browse/SAAA-5038.
// Actual segment analytic events has more attributes than whats implemented here. Make all neccessary changes

export const recordAddAlbum = (contentAssetId: string) => {
  spotifySoundtrackAddedToLibrary({
    content_id: contentAssetId,
  });
};

export const recordRemoveAlbum = (contentAssetId: string) => {
  spotifySoundtrackRemovedFromLibrary({
    content_id: contentAssetId,
  });
};

export const recordPause = (contentAssetId: string | undefined, trackId: string) => {
  spotifySoundtrackPaused({
    content_id: contentAssetId,
    track_id: trackId,
  });
};

export const recordPlay = (contentAssetId: string, trackId: string) => {
  spotifySoundtrackPlayed({
    content_id: contentAssetId,
    track_id: trackId,
  });
};

export const recordLogin = (contentAssetId: string | undefined) => {
  spotifyAccountLinkInitiated({
    content_id: contentAssetId,
  });
};

export const recordLinkAccount = () => {
  spotifyAccountLinked();
};
