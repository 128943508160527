import parseMs from 'parse-ms';
import { parse, toSeconds } from 'iso8601-duration';
import { isNil } from 'ramda';

export const formatDuration = (durationInSeconds: number | null | undefined) => {
  if (isNil(durationInSeconds)) return '0 min';
  const hrs = Math.floor(durationInSeconds / 3600);
  const mins = Math.floor((durationInSeconds % 3600) / 60);
  if (mins === 0) {
    return `${hrs}h`;
  }
  if (hrs > 0) {
    return `${hrs}h ${mins} min`;
  }
  return `${mins} min`;
};

export const convertDurationToSeconds = (duration?: string) =>
  duration ? toSeconds(parse(duration)) : 0;

export const convertDuration = (duration: string | undefined | null) => {
  if (duration) {
    const durationInSeconds = convertDurationToSeconds(duration);
    return durationInSeconds > 0 ? formatDuration(durationInSeconds) : null;
  }
  return null;
};

export const toPlayTime = (durationInSeconds: number, showNegativeSignForZero = false): string => {
  const duration = durationInSeconds || 0;

  const sign = duration < 0 || (duration === 0 && showNegativeSignForZero) ? '-' : '';

  const { hours, minutes, seconds } = parseMs(Math.abs(duration) * 1000);
  const hasHours = hours > 0;

  const toTimeDigit = (n: number, pad: boolean): string =>
    pad ? n.toString().padStart(2, '0') : n.toString();

  const arr = [hasHours && hours, toTimeDigit(minutes, hasHours), toTimeDigit(seconds, true)];

  return sign + arr.filter(i => i !== false).join(':');
};
