import React, { FC, useCallback } from 'react';
import { Button } from 'antd';
import classnames from 'classnames';
import Icon from '@ant-design/icons';
import { parse, toSeconds } from 'iso8601-duration';

import { ReactComponent as Play } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_play.svg';
import { BrandType } from '@skytvnz/sky-app-store/lib/types/enums/BrandType';
import {
  Show,
  Movie,
  Maybe,
  Episode,
  LinearSlot,
  VodAsset,
} from '@skytvnz/sky-app-store/lib/types/graph-ql';

import useVideoPlayer from '@/Hooks/useVideoPlayer';
import useLivePlayer from '@/Hooks/useLivePlayer';
import { LOGIN_OR_REGISTER_TEXT, UPGRADE_TEXT } from '@/Hooks/useSubscription';
import useAuth from '@/Hooks/useAuth';
import { utils } from '@/Store';

import styles from './styles.module.scss';

export interface PlayButtonProps {
  brand: Movie | Show;
  isEntitled: boolean;
  isHome: boolean;
  isVod: boolean;
  videoContent?: Maybe<Episode | LinearSlot | VodAsset>;
  isIconOnly?: boolean;
}

const getPlayButtonText = (watchProgress, text?) => {
  let playText = '';
  if (watchProgress && watchProgress.complete === true) {
    playText = 'Replay';
  } else if (watchProgress && toSeconds(parse(watchProgress.position)) > 0) {
    playText = 'Resume';
  } else {
    playText = `Watch${text ? '' : ' Now'}`;
  }

  const primaryText = text ? `${playText} ` : playText;
  const secondaryText = text || '';

  return (
    <span>
      {primaryText}
      {secondaryText && <span className={styles.playButtonSeasonEpisodeText}>{secondaryText}</span>}
    </span>
  );
};

const PlayButton: FC<PlayButtonProps> = ({
  brand,
  isEntitled,
  isHome,
  isIconOnly = false,
  isVod,
  videoContent,
}) => {
  const auth = useAuth();

  const { openAndPlay: openVODPlayer } = useVideoPlayer();
  const { openAndPlay: openLivePlayer } = useLivePlayer();

  const brandType = brand.__typename;

  const getOrigin = useCallback(() => {
    return isHome ? 'Hero' : 'Content Hero';
  }, [isHome]);

  let buttonText: any = 'Watch';

  if (!auth.isAuthenticated) {
    buttonText = LOGIN_OR_REGISTER_TEXT;
  } else if (!isEntitled) {
    buttonText = UPGRADE_TEXT;
  } else if (!isVod) {
    // TODO the text could move to app-store instead.
    buttonText = getPlayButtonText('', '');
  } else if (brandType === BrandType.Movie) {
    buttonText = getPlayButtonText((brand as Movie).watchProgress, '');
  } else if (brandType === BrandType.Show) {
    buttonText = utils.title.getSeasonEpisodeNumber(
      (brand as Show).defaultEpisode?.season?.number,
      (brand as Show).defaultEpisode?.number,
    );
  }

  const playVideo = useCallback(() => {
    if (isVod) {
      openVODPlayer(
        brand.id,
        brandType === BrandType.Show ? (videoContent as Episode)?.id : undefined,
        getOrigin(),
        isHome,
      );
    } else {
      openLivePlayer(
        (videoContent as LinearSlot)?.channel,
        videoContent as LinearSlot,
        getOrigin(),
        0,
        undefined,
        isHome,
      );
    }
  }, [brand, brandType, openVODPlayer, openLivePlayer, isHome, isVod, videoContent, getOrigin]);

  return (
    <Button
      className={classnames(styles.heroButton, { [styles.iconButton]: isIconOnly })}
      type={isIconOnly ? 'link' : 'primary'}
      onClick={playVideo}
      data-testid="hero-banner-play-button"
      name="Hero banner play button"
    >
      <Icon
        className={styles.heroButtonIcon}
        component={Play as React.FC}
        data-testid="hero-banner-play-button-icon"
      />
      {!isIconOnly && (
        <span className={styles.heroButtonText} data-testid="hero-banner-play-button-text">
          {buttonText}
        </span>
      )}
    </Button>
  );
};

export default PlayButton;
