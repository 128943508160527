import React, { FC } from 'react';

import { Classification, ParentalControl } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import { selectors } from '@/Store';
import { useSelector } from 'react-redux';
import CreatePinPanel from './CreatePinPanel';
import EnterPinPanel from './EnterPinPanel';

export interface ParentalControlPanelProps {
  playerReferrer?: string;
  contentClassification?: Classification;
  onParentalResolved(): void;
}

const ParentalControlPanel: FC<ParentalControlPanelProps> = ({
  playerReferrer,
  contentClassification,
  onParentalResolved,
}) => {
  const parentalControl = useSelector(selectors.parentalPin.parentalControl);

  return parentalControl === ParentalControl.NotSet ? (
    <CreatePinPanel
      onPinCreated={onParentalResolved}
      contentClassification={contentClassification}
      playerReferrer={playerReferrer}
    />
  ) : (
    <EnterPinPanel
      onPinEntered={onParentalResolved}
      playerReferrer={playerReferrer}
      contentClassification={contentClassification}
    />
  );
};

export default ParentalControlPanel;
