import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

interface Props {
  id: string;
  isSelected: boolean;
  onClick?: (id: string) => void;
}

const TabItem: React.FC<Props> = ({ children, id, isSelected, onClick, ...props }) => {
  return (
    <div className={styles.tabItem}>
      <button
        type="button"
        className={classnames(styles.tabButton, { [styles.selected]: isSelected })}
        onClick={() => onClick?.(id)}
        data-testid="video-player-drawer-tab-item"
        name="Player  drawer tab item"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </button>
    </div>
  );
};

export default TabItem;
