import React, { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isNil } from 'ramda';

import { LinearChannel, LinearSlot } from '@skytvnz/sky-app-store/lib/types/graph-ql';

import { selectors, utils } from '@/Store';
import useSimpleModal from '@/Components/Modal/useSimpleModal';
import {
  recordRemoteRecordDismissed,
  recordDeviceUpgradeEncountered,
  recordDeviceUpgradeDismissed,
  recordDeviceUpgradePursued,
} from '@/Analytics/RemoteRecord';
import { getViewPage } from '@/Utils/PageLocation';
import externalApplicationLinksConfig from '@/Config/ExternalApplicationLinksConfig';
import RemoteRecordModal, { ModalParameters } from './RemoteRecordModal';

const MODAL_TITLE = 'Please choose device to record:';

const NOT_SUPPORTED_TITLE = 'Remote Record not supported';
const NOT_SUPPORTED_CONTENT = (
  <p>
    Looks like your Sky Box does not support Remote Record. To record your favourite movies and tv
    shows you will need to upgrade your Sky Box in your account on <strong>sky.co.nz</strong>.
    Please contact us if you need help.
  </p>
);
const OK_TEXT = 'Manage my account';

/**
 * Remote Record Modal Hooks function
 */
const useRemoteRecord = () => {
  const slotRef = useRef<LinearSlot>();
  const analyticsParamsRef = useRef<ModalParameters>();
  const channelRef = useRef<LinearChannel>();
  const brandTitleRef = useRef<string>();
  const isSportRef = useRef<boolean>();
  const decoders = useSelector(selectors.remoteRecord.decoders);

  const isDecodersNotCapable = useMemo(
    () => isNil(decoders) || isEmpty(decoders) || !decoders.some(decoder => decoder.recordCapable),
    [decoders],
  );

  const { openModal } = useSimpleModal();
  const { openModal: openRemoteRecordModal } = useSimpleModal(props => (
    <RemoteRecordModal
      slot={slotRef.current}
      brandTitle={brandTitleRef.current}
      isSport={isSportRef.current}
      channel={channelRef.current}
      analyticsParams={analyticsParamsRef.current}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  ));

  const openRemoteRecord = useCallback(
    (
      slot?: LinearSlot,
      channel?: LinearChannel,
      params?: ModalParameters,
      brandTitle?: string,
      isSport?: boolean,
    ) => {
      const decodersNames = decoders?.map(decoder => (decoder.name ? decoder.name : undefined));
      const decodersIds = decoders?.map(decoder => (decoder.id ? decoder.id : undefined));

      // in case none of the set top box is capable
      if (isDecodersNotCapable) {
        recordDeviceUpgradeEncountered(decodersNames, decodersIds);
        openModal({
          title: NOT_SUPPORTED_TITLE,
          content: NOT_SUPPORTED_CONTENT,
          okText: OK_TEXT,
          cancelable: true,
          onClose: () => {
            recordDeviceUpgradeDismissed(decodersNames, decodersIds);
          },
          onCancel: () => {
            recordDeviceUpgradeDismissed(decodersNames, decodersIds);
          },
          onOk: () => {
            recordDeviceUpgradePursued(decodersNames, decodersIds);
            window.open(externalApplicationLinksConfig.myAccount);
          },
        });
        return;
      }

      slotRef.current = slot;
      analyticsParamsRef.current = params;
      channelRef.current = channel;
      brandTitleRef.current = brandTitle;
      isSportRef.current = isSport;

      openRemoteRecordModal({
        title: MODAL_TITLE,
        onClose: () =>
          recordRemoteRecordDismissed(
            params?.categoryName,
            params?.channelName,
            slot?.programme?.id,
            params?.contentTitle,
            utils.analytics.getSlotSeasonNumber(slot),
            utils.analytics.getSlotEpisodeNumber(slot, params?.contentType === 'Sport'),
            params?.genres,
            params?.contentType,
            getViewPage(params?.history),
            slot?.live,
          ),
      });
    },
    [isDecodersNotCapable, decoders, openModal, openRemoteRecordModal],
  );

  return openRemoteRecord;
};

export default useRemoteRecord;
