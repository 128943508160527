import React, { FC } from 'react';
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import classnames from 'classnames';

import { ReactComponent as GoBack } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_back.svg';

import styles from './styles.module.scss';

interface BackButtonProps {
  testId: string;
  onClick(): void;
  title?: string;
  className?: string;
}

const BackButton: FC<BackButtonProps> = ({ onClick, testId, title, className }) => (
  <Button
    className={classnames(styles.controlButton, styles.backBtn, className)}
    onClick={onClick}
    data-testid={testId}
    name={testId}
    title={title}
  >
    <Icon className={styles.buttonIcon} component={GoBack as FC} />
  </Button>
);

export default BackButton;
