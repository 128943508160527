import React, { useEffect, useRef } from 'react';
import dayjs from 'dayjs';

import { CELL_MARGIN, MINUTES_PER_UNIT } from '@/Pages/TVGuide/constants';

import usePersistCallback from '@/Hooks/usePersistCallback';
import { useTimeWidthUnit } from '../SlotsGrid/useTimeWidthUnit';

import styles from './styles.module.scss';

interface Props {
  initialTime: dayjs.Dayjs;
  scrollTimeOffset: number;
  currentTime: dayjs.Dayjs;
}

const TimeMarker: React.FC<Props> = ({ initialTime, scrollTimeOffset, currentTime }) => {
  const markerElement = useRef<HTMLDivElement>(null);

  const widthUnit = useTimeWidthUnit();

  // Calculate arrow position
  const markerPosition = usePersistCallback(() => {
    if (markerElement.current?.style) {
      const slotDurationInSeconds = currentTime.diff(dayjs(initialTime), 'second');
      const widthC = widthUnit * (slotDurationInSeconds / 60 / MINUTES_PER_UNIT) - CELL_MARGIN;
      markerElement.current.style.transform = `translateX(${widthC - scrollTimeOffset}px)`;
      markerElement.current.style.zIndex = widthC < scrollTimeOffset ? `-100` : `2`;
    }
  });

  // Update the marker postion on scroll or current time state change
  useEffect(() => {
    markerPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime, scrollTimeOffset]);

  return (
    <div>
      <div className={styles.markerContainer} ref={markerElement}>
        <div className={styles.arrow} />
      </div>
    </div>
  );
};

export default TimeMarker;
