import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { qrCodeScanned } from '@/Analytics/Segment';
import useQueryString from '@/Hooks/useQueryString';
import { IS_IOS } from '@/Utils/Browser';
import RoutePath from '@/Routes/RoutePath';
import { ANDROID_APP_LINK, IOS_APP_LINK } from '@/Utils/StoreLinks';

export const QUERY_REDIRECT_TO = 'to';
export const REDIRECT_TO_APP_STORE = 'appStore';

const Redirect: React.FC = () => {
  const history = useHistory();
  const query = useQueryString();
  useEffect(() => {
    if (query[QUERY_REDIRECT_TO] === REDIRECT_TO_APP_STORE) {
      const redirectLink = IS_IOS ? IOS_APP_LINK : ANDROID_APP_LINK;
      qrCodeScanned();
      window.location.replace(redirectLink);
    } else {
      history.replace(RoutePath.home);
    }
  }, [history, query]);
  return null;
};

export default Redirect;
