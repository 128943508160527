import { signedIn, signedOut } from '@/Analytics/Segment';

export const recordUserIdentity = (
  props: {
    email: string;
    account_id: string;
    profile_id: string;
    profile_name: string;
    registration_date: string;
    dth_account_number: string;
  },
  callbackFn?: () => void,
) => {
  window.analytics.identify(props.profile_id, props, callbackFn);
};

export const recordSignIn = async (email: string) => {
  signedIn({ email });
};

export const recordSignOut = async (email: string) => {
  signedOut({ email });
};
