import * as jose from 'jose';
import React, { useEffect, useState } from 'react';

import { usePageHeader } from '@/Components/AppHeader/AppHeader';
import useSimpleModal from '@/Components/Modal/useSimpleModal';
import auth0Config from '@/Config/Auth0Config';
import { getSearchParams } from '@/Utils/URLParams';

const Actions = {
  continue: 'continue',
  logoff: 'logoff',
};
const secret = new TextEncoder().encode(auth0Config.skyboxAuthKey);

const getIcomsAccountWithoutPrefix = icomsAccountNumber => {
  if (icomsAccountNumber && icomsAccountNumber.includes(':')) {
    return icomsAccountNumber.split(':')[1];
  }
  return icomsAccountNumber;
};

const getUnlinkedAccountText = email => (
  <p>
    Your email <strong>{email}</strong> is not linked to your Sky Subscription account.
    <br />
    <br />
    Please <strong>Logout</strong> and
    <br />
    <br />
    Visit <strong>account.sky.co.nz</strong> and link your Sky Subscription account. Or use a
    different login for an already linked account for activating your Sky device.
  </p>
);

const getLinkedAccountText = (email, icomsAccountNumber) => (
  <p>
    Your email <strong>{email}</strong> is linked with Sky Subscription account number{' '}
    {icomsAccountNumber}
    <br />
    <br />
    Press <strong>Continue</strong> if this account number is correct.
    <br />
    <br />
    Press <strong>Logout</strong> to activate with a different account.
  </p>
);

const redirectToAuth0 = async (action, auth0State, jwtPayload) => {
  if (action) {
    const returnToken = await new jose.SignJWT({ action, state: auth0State })
      .setProtectedHeader({ alg: 'HS256', typ: 'JWT' })
      .setIssuedAt()
      .setSubject(jwtPayload.sub)
      .setAudience(jwtPayload.aud)
      .setIssuer(jwtPayload.iss)
      .setExpirationTime('5m')
      .sign(secret);
    window.location.replace(
      `${jwtPayload.continue_uri}/?state=${auth0State}&session_token=${returnToken}`,
    );
  }
};

const DeviceLogin: React.FC = () => {
  usePageHeader({
    title: 'Device Authorization',
  });

  const [jwtPayload, setJwtPayload] = useState<any>(undefined);
  const [tokenError, setTokenError] = useState<boolean>(false);

  const { openModal } = useSimpleModal();
  const auth0State = getSearchParams().state;

  useEffect(() => {
    jose
      .jwtVerify(getSearchParams().session_token as string, secret)
      .then(result => setJwtPayload(result.payload))
      .catch(() => {
        setTokenError(true);
      });
  }, []);

  useEffect(() => {
    if (tokenError) {
      openModal({
        title: `Error`,
        content:
          'Unexpected error occurred. Please close this web page and retry activating your Sky device with a new QR code',
        closable: false,
        showOkButton: false,
        cancelable: false,
      });
    }
  }, [openModal, tokenError]);

  useEffect(() => {
    if (jwtPayload) {
      if (jwtPayload.sky_account_status === 'unlinked') {
        if (jwtPayload.testAccount === false) {
          openModal({
            title: `Hi ${jwtPayload.display_name ? jwtPayload.display_name : ''},`,
            content: getUnlinkedAccountText(jwtPayload.email),
            okText: 'Logout',
            closable: false,
            cancelable: false,
            onOk: () => {
              redirectToAuth0(Actions.logoff, auth0State, jwtPayload);
            },
          });
        } else {
          openModal({
            title: `Hi ${jwtPayload.display_name ? jwtPayload.display_name : ''},`,
            content: getUnlinkedAccountText(jwtPayload.email),
            okText: 'Logout',
            closable: false,
            cancelable: true,
            cancelText: 'Continue Anyway',
            onOk: () => {
              redirectToAuth0(Actions.logoff, auth0State, jwtPayload);
            },
            onCancel: () => {
              redirectToAuth0(Actions.continue, auth0State, jwtPayload);
            },
          });
        }
      } else {
        openModal({
          title: `Hi ${jwtPayload.display_name ? jwtPayload.display_name : ''},`,
          content: getLinkedAccountText(
            jwtPayload.email,
            getIcomsAccountWithoutPrefix(jwtPayload.icoms_accountNumber),
          ),
          okText: 'Continue',
          closable: false,
          cancelable: true,
          cancelText: 'Logout',
          onOk: () => {
            redirectToAuth0(Actions.continue, auth0State, jwtPayload);
          },
          onCancel: () => {
            redirectToAuth0(Actions.logoff, auth0State, jwtPayload);
          },
        });
      }
    }
  }, [auth0State, jwtPayload, openModal]);

  return null;
};

export default DeviceLogin;
