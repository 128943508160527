import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { NavLink, useHistory, useLocation, generatePath, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withTransaction } from '@elastic/apm-rum-react';
import RoutePath from '@/Routes/RoutePath';
import classnames from 'classnames';
import { isEmpty, isNil } from 'ramda';
import qs from 'query-string';

import { ReactComponent as Right } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_chevron_forward.svg';

import {
  browseFilterSelected,
  browseGenreSelected,
  browseMoreContentSelected,
  browseResetFiltersSelected,
} from '@/Analytics/Segment';

import { actions, selectors } from '@/Store';
import useMedia from '@/Hooks/useMedia';
import { usePageHeader } from '@/Components/AppHeader/AppHeader';
import useIsLoaded from '@/Hooks/useIsLoaded';
import useScroll from '@/Hooks/useScroll';
import useQueryString from '@/Hooks/useQueryString';
import usePersistCallback from '@/Hooks/usePersistCallback';
import useIsNewHistory from '@/Hooks/useIsNewHistory';
import Preloader from '@/Layouts/containers/Preloader';
import Content from '@/Layouts/containers/Content';
import CollectionTable, {
  REFER_COLLECTION_ID_SEARCH_KEY,
  REFER_COLLECTION_NAME_SEARCH_KEY,
} from '@/Components/CollectionTable';
import ErrorMessage from '@/Components/Message';
import TabSelector, { TabButton } from '@/Components/TabSelector';
import headerStyles from '@/Components/Header/styles.module.scss';
import ContentType from '@/Utils/ContentTypes';
import {
  COLLECTION_FILTER_GENRE,
  COLLECTION_FILTER_SORT,
  COLLECTION_FILTER_SUBSCRIPTION,
  COLLECTION_FILTER_VALUE_MYSUBSCRIPTION,
  COLLECTION_FILTER_VIEWINGCONTEXT,
} from '@skytvnz/sky-app-store/lib/modules/collections/constants';
import { browseMoreFiltersValues } from '@skytvnz/sky-app-store/lib/modules/collections/actions';
import BrowseFilters from './BrowseFilters';
import useBrowseData from './useBrowseData';

import styles from './styles.module.scss';

const BrowseCollection: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isNewHistory = useIsNewHistory();

  const { collectionId = '' } = useParams<any>();
  const query = useQueryString();
  const referCollectionName = query[REFER_COLLECTION_NAME_SEARCH_KEY] as string;
  const referCollectionId = query[REFER_COLLECTION_ID_SEARCH_KEY] as string;

  const [isScrollLoading, setScrollLoading] = useState(false);
  const [genreSeletorKey, setGenreSelectorKey] = useState(1);

  const { isMediaXl, isMediaL, isMediaM, isMediaS } = useMedia();
  let initialPageSize = 2 * 6;
  if (isMediaXl) {
    initialPageSize = 6 * 6;
  } else if (isMediaL) {
    initialPageSize = 5 * 6;
  } else if (isMediaM) {
    initialPageSize = 4 * 6;
  } else if (isMediaS) {
    initialPageSize = 3 * 6;
  }
  const {
    collectionIsLoading,
    collection,
    selectedFilters,
    genresOptions,
    hasLoadingError,
  } = useBrowseData(collectionId);
  const authUser = useSelector(selectors.auth.user);

  const isWholeCollectionLoading = collectionIsLoading && !isScrollLoading;
  const isNilCollection = isNil(collection);
  const isInitialLoading = isNilCollection && collectionIsLoading && !isScrollLoading;
  const isLoaded = useIsLoaded(collectionIsLoading);
  const isLoadedError = isNilCollection && isLoaded;

  const genresFilterName = genresOptions?.name;
  const genreFilterKey = selectedFilters?.[genresFilterName];

  const collectionName = collection?.title;

  const firstLink = referCollectionId
    ? `${generatePath(RoutePath.browseCollection, {
        collectionId: referCollectionId,
      })}?title=${referCollectionName}`
    : generatePath(RoutePath.browse);

  const enabledFilters = [COLLECTION_FILTER_GENRE];
  // TODO this is a temp solution for now to check the title, we'll move these settings to BE later.
  if (authUser) {
    enabledFilters.push(COLLECTION_FILTER_SUBSCRIPTION);
  }
  if (collectionName !== 'Channels') {
    enabledFilters.push(COLLECTION_FILTER_VIEWINGCONTEXT);
    enabledFilters.push(COLLECTION_FILTER_SORT);
  }

  const fetchCollection = usePersistCallback(async (isLoadMore = false) => {
    if (isLoadMore) {
      setScrollLoading(true);
    }
    await dispatch(
      actions.collections.fetchCollection(
        collectionId,
        initialPageSize,
        isLoadMore,
        enabledFilters,
      ),
    );
    if (isLoadMore) {
      setScrollLoading(false);
    }
  });

  const isGenreSelected = useCallback(selectedId => selectedId === genreFilterKey, [
    genreFilterKey,
  ]);

  function retrieveFilterNameByType(filterType: string): string {
    switch (filterType) {
      case COLLECTION_FILTER_SUBSCRIPTION:
        return 'Availability';
      case COLLECTION_FILTER_VIEWINGCONTEXT:
        return 'Ways to Watch';
      case COLLECTION_FILTER_SORT:
        return 'Sort by';
      default:
        return '';
    }
  }

  const setUrlParams = useCallback(
    filters => {
      const urlObject = {
        url: location.pathname,
        query: {
          ...query,
          ...filters,
        },
      };
      const watchPageUrl = qs.stringifyUrl(urlObject);
      history.replace(watchPageUrl);
    },
    [history, location.pathname, query],
  );

  const resetGenreFilterPosition = () => {
    // set an unique key to forced render the TabSelector
    setGenreSelectorKey(dayjs().unix());
  };

  const clearUrlFilters = useCallback(() => {
    browseResetFiltersSelected();
    const urlObject = {
      url: location.pathname,
      query: {
        ...query,
        [COLLECTION_FILTER_GENRE]: undefined,
        [COLLECTION_FILTER_SORT]: undefined,
        [COLLECTION_FILTER_SUBSCRIPTION]: undefined,
        [COLLECTION_FILTER_VALUE_MYSUBSCRIPTION]: undefined,
        [COLLECTION_FILTER_VIEWINGCONTEXT]: undefined,
      },
    };
    const watchPageUrl = qs.stringifyUrl(urlObject);
    history.replace(watchPageUrl);
    resetGenreFilterPosition();
  }, [history, location.pathname, query]);

  const selectFilterHandler = useCallback(
    (filterType: string, filterValue: string, text?: string) => {
      const watchPageUrl = qs.stringifyUrl({
        url: location.pathname,
        query: {
          ...query,
          [filterType]: filterValue,
        },
      });
      history.replace(watchPageUrl);
      if (filterType !== COLLECTION_FILTER_GENRE && text !== undefined) {
        browseFilterSelected({
          collection_name: collectionName,
          filter_name: retrieveFilterNameByType(filterType),
          filter_value: text,
        });
      }
    },
    [collectionName, history, location.pathname, query],
  );

  const setBrowseMoreFilters = useCallback(() => {
    browseMoreContentSelected();
    setUrlParams(browseMoreFiltersValues);
    resetGenreFilterPosition();
  }, [setUrlParams]);

  const selectGenreHandler = useCallback(
    (selectedId: string, text?: string) => {
      if (isGenreSelected(selectedId)) {
        return;
      }
      if (text !== undefined) {
        browseGenreSelected({ collection_name: collectionName, genre: text });
      }

      selectFilterHandler(genresFilterName, selectedId, text);
    },
    [isGenreSelected, selectFilterHandler, genresFilterName, collectionName],
  );

  // Vertical Paginate
  const paginateCollection = usePersistCallback(() => {
    if (collection && !collectionIsLoading) {
      const { pageInfo } = collection?.contentPage;
      if (pageInfo.hasNextPage) {
        fetchCollection(true);
      }
    }
  });

  const { nodeRef } = useScroll({
    scrollToEndHandler: paginateCollection,
    scrollOffset: 50,
  });

  useEffect(() => {
    const filterNames = [
      COLLECTION_FILTER_GENRE,
      COLLECTION_FILTER_SORT,
      COLLECTION_FILTER_SUBSCRIPTION,
      COLLECTION_FILTER_VALUE_MYSUBSCRIPTION,
      COLLECTION_FILTER_VIEWINGCONTEXT,
    ];
    if (isNilCollection || isNewHistory) {
      const isQueryContainsFilter = filterNames.some(name => !!query[name]);
      if (!isQueryContainsFilter) {
        dispatch(actions.collections.clearCollectionFilters(collectionId));
      } else {
        dispatch(
          actions.collections.setCollectionFilters(collectionId, {
            ...selectedFilters,
            ...query,
          }),
        );
      }
      fetchCollection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId, dispatch, fetchCollection, query]);

  // Title & Analytics
  const pageTitle = `Browse ${collection?.title ? collection?.title : ''}`;
  usePageHeader({
    title: pageTitle,
    description: `Discover the best of Sky, watch your favourite ${pageTitle} anytime, anywhere in New Zealand.`,
  });

  const isEmptyCollection = isEmpty(collection?.contentPage?.content);

  return (
    <div data-testid="collection-container">
      <Content>
        <div className={styles.browseHeaderSection}>
          <NavLink
            to={firstLink}
            className={classnames(headerStyles.navLink, styles.parentTitle)}
            data-testid="browse-title"
          >
            {referCollectionName || 'BROWSE ALL'}
          </NavLink>
          <Right className={styles.rightIcon} />
          <h1 className={styles.title} data-testid="browse-collection-title">
            {collection?.title}
          </h1>
        </div>
        {!isInitialLoading && !isLoadedError && (
          <>
            <TabSelector key={genreSeletorKey}>
              {genresOptions?.options?.map(genreOption => (
                <TabButton
                  key={`browse_${collectionId}_${genreOption.id}`}
                  id={genreOption.id}
                  text={genreOption.title}
                  isSelected={isGenreSelected(genreOption.id)}
                  onClick={selectGenreHandler}
                  testId={`browse-genre-${genreOption.title}`}
                />
              ))}
            </TabSelector>
            <BrowseFilters
              collectionId={collectionId}
              enabledFilters={enabledFilters}
              onSelectFilter={selectFilterHandler}
            />
          </>
        )}
      </Content>
      <div ref={nodeRef}>
        <Preloader isLoading={isWholeCollectionLoading}>
          {isLoadedError ? (
            <ErrorMessage
              className={styles.errorMsg}
              buttonText="RELOAD"
              onButtonClick={() => {
                window.location.reload();
              }}
            />
          ) : (
            <>
              {isEmptyCollection ? (
                <>
                  {selectedFilters?.subscription === COLLECTION_FILTER_VALUE_MYSUBSCRIPTION ? (
                    <ErrorMessage
                      title="More to watch on Sky"
                      subTitle="Sorry, there is no content available based on your Sky subscription package. Browse what’s available and upgrade to watch it."
                      className={styles.errorMsg}
                      buttonText="BROWSE MORE CONTENT"
                      onButtonClick={setBrowseMoreFilters}
                    />
                  ) : (
                    <ErrorMessage
                      title="No content available"
                      subTitle="Sorry, there is no content available based on your selection criteria. Please reset the filters and continue browsing."
                      className={styles.errorMsg}
                      buttonText="RESET FILTERS"
                      onButtonClick={clearUrlFilters}
                    />
                  )}
                </>
              ) : (
                <Content>
                  <CollectionTable
                    collection={collection}
                    referCollectionId={collectionId}
                    referCollectionName={collection?.title}
                    type={ContentType.BrowseCollection}
                  />
                  {!isScrollLoading && hasLoadingError ? (
                    <ErrorMessage
                      className={styles.errorMsg}
                      buttonText="RELOAD"
                      onButtonClick={paginateCollection}
                    />
                  ) : (
                    <Preloader
                      isLoading={collection?.contentPage?.pageInfo?.hasNextPage}
                      className={styles.paginateLoading}
                    />
                  )}
                </Content>
              )}
            </>
          )}
        </Preloader>
      </div>
    </div>
  );
};

export default withTransaction('BrowseCollection', 'component')(BrowseCollection);
