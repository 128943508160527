import React, { FC, useCallback, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Button } from 'antd';

import {
  Classification,
  PinValidationResult,
  PinValidationFailure,
} from '@skytvnz/sky-app-store/lib/types/graph-ql';
import {
  parentalPinRequired,
  parentalPinResetRequested,
  parentalPinEntered,
  accountLocked,
} from '@/Analytics/Segment';

import { actions, selectors } from '@/Store';
import Preloader from '@/Layouts/containers/Preloader';
import { formatClassification } from '@/Utils/Rating';
import useMedia from '@/Hooks/useMedia';
import useHistoryBack from '@/Hooks/useHistoryBack';
import { Typename } from '@skytvnz/sky-app-store/lib/types/enums/Typename';
import PinInputControl from './PinInputControl/PinInputControl';
import Timer from './Timer';
import { EnterPinStage, initialPin, PIN_INPUT_NUMBER } from './ParentalTypes';

import styles from './styles.module.scss';

export interface EnterPinPanelProps {
  onPinEntered(): void;
  contentClassification?: Classification;
  playerReferrer?: string;
  isLoading?: boolean;
}

const EnterPinPanel: FC<EnterPinPanelProps> = ({
  onPinEntered,
  contentClassification,
  playerReferrer,
}) => {
  const handleGoBack = useHistoryBack();
  const parentalPinValidation = useSelector(selectors.parentalPin.parentalPinValidation);
  const pinValidationStatus = useSelector(selectors.parentalPin.pinValidationStatus);

  const dispatch = useDispatch();
  const { isMediaM } = useMedia();

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const [isSubmitting, setSubmitting] = useState(false);
  const [isPinCompleted, setPinCompleted] = useState(true);
  const [pinError, setPinError] = useState(false);
  const [pin, setPin] = useState(initialPin);
  const [stage, setStage] = useState<EnterPinStage>(EnterPinStage.enter);
  const [lockTimeout, setLockTimeout] = useState(0);
  const [isTryAgainAvailable, setIsTryAgainAvailable] = useState(false);
  const [isAccountLocked, setIsAccountLocked] = useState(pinValidationStatus?.timeoutEnd != null);

  const numberOfAttempts = parentalPinValidation
    ? (parentalPinValidation.pinValidationResult as PinValidationResult)?.remainingAttempts
    : 0;

  const onTimerFinished = useCallback(() => {
    setIsTryAgainAvailable(true);
    setIsAccountLocked(false);
    setPinError(false);
    setSubmitting(false);
    setPin(initialPin);
  }, []);

  const ACCOUNT_IS_LOCKED_CONTENT = isTryAgainAvailable ? (
    <>
      Please try again now or reset your Sky Go PIN through <strong> sky.co.nz </strong>
    </>
  ) : (
    <>
      3 incorrect PIN entries have locked your account for{' '}
      <Timer onTimerFinished={onTimerFinished} timeout={lockTimeout} />. {isMediaM ? <br /> : ''}
      Please try again later, or reset your Sky Go PIN through <strong> sky.co.nz </strong>
    </>
  );

  const RATING_CONTENT = contentClassification
    ? `This content is rated as ${formatClassification(contentClassification)}.`
    : `This content is unrated.`;

  let title = '';
  if (stage === EnterPinStage.enter) {
    title = 'Parental PIN';
  } else if (stage === EnterPinStage.locked) {
    title = 'Locked Account';
  } else {
    title = 'Reset PIN request sent';
  }

  const handlePinEntering = useCallback(
    async (pinCode: string) => {
      // Call API request
      const response: any = await dispatch(actions.parentalPin.validateParentalPin(pinCode));

      if (response.error) {
        setSubmitting(false);
      } else if (response?.payload?.validateParentalPin?.isValid) {
        parentalPinEntered({
          view_name: playerReferrer,
        });
        // Call upper component that PIN is created
        onPinEntered();
      } else {
        const locked =
          response?.payload?.validateParentalPin?.__typename === Typename.PinValidationFailure;
        setIsAccountLocked(locked);
        if (locked) {
          accountLocked({
            view_name: playerReferrer,
          });
        }
        setPinError(true);
        setSubmitting(false);
        setPin(initialPin);
      }
    },
    [dispatch, onPinEntered, playerReferrer],
  );

  const handlePinSubmit = useCallback(
    async event => {
      event.preventDefault();

      // If pin is only completed
      if (isPinCompleted) {
        setSubmitting(true);
        if (stage === EnterPinStage.enter) {
          const pinCode = pin.join('');
          // PIN creating process
          handlePinEntering(pinCode);
        } else {
          setPinError(true);
          setPin(initialPin);
        }
      }
    },
    [handlePinEntering, pin, isPinCompleted, stage],
  );

  const handlePinChange = useCallback(newPin => {
    setPin(newPin);
    if (newPin.every(Boolean)) {
      submitButtonRef.current?.focus();
    }
  }, []);

  const handleForgotPin = useCallback(async () => {
    setStage(EnterPinStage.reset);
    setIsAccountLocked(false);
    parentalPinResetRequested({
      view_name: playerReferrer,
    });
    const reset = await dispatch(actions.parentalPin.resetParentalPin());
    if (reset.payload != null) {
      // Updates the parental settings from where we check if the account is still locked
      await dispatch(actions.parentalPin.fetchParentalSettings());
    }
  }, [playerReferrer, dispatch]);

  const handleTryAgain = useCallback(async () => {
    if (isTryAgainAvailable) {
      await dispatch(actions.parentalPin.fetchParentalSettings());
      setStage(EnterPinStage.enter);
      setIsTryAgainAvailable(false);
    }
  }, [isTryAgainAvailable, dispatch]);

  useEffect(() => {
    setPinCompleted(pin.every(Boolean));
  }, [pin]);

  useEffect(() => {
    // Updates the parental settings from where we check if the account is now locked
    setIsAccountLocked(pinValidationStatus?.timeoutEnd != null);
  }, [pinValidationStatus]);

  useEffect(() => {
    if (isAccountLocked) {
      setStage(EnterPinStage.locked);

      setLockTimeout(
        (parentalPinValidation.pinValidationResult as PinValidationFailure)?.timeoutEnd
          ? (parentalPinValidation.pinValidationResult as PinValidationFailure)?.timeoutEnd
          : pinValidationStatus?.timeoutEnd,
      );
    }
  }, [isAccountLocked, parentalPinValidation, pinValidationStatus]);

  useEffect(() => {
    if (!isAccountLocked && stage !== EnterPinStage.reset) {
      parentalPinRequired({
        view_name: playerReferrer,
      });
    }
  }, [playerReferrer, isAccountLocked, stage]);

  return (
    <form className={styles.pinFormPanel} onSubmit={handlePinSubmit}>
      <h1 data-testid="enter-pin-panel-title">{title}</h1>
      <div className={styles.enterPindescription}>
        <p data-testid="enter-pin-text-container">
          {stage === EnterPinStage.enter &&
            `${RATING_CONTENT} Please enter your Parental Control PIN.`}
          {stage === EnterPinStage.locked && ACCOUNT_IS_LOCKED_CONTENT}
          {stage === EnterPinStage.reset &&
            `A Reset PIN request has been sent to your email. Follow the instructions to reset your PIN.`}
        </p>
      </div>
      {stage === EnterPinStage.enter && (
        <PinInputControl
          className={styles.control}
          pin={pin}
          pinTotal={PIN_INPUT_NUMBER}
          onPinChange={handlePinChange}
          error={
            pinError &&
            `Incorrect PIN. Please try again. You have ${numberOfAttempts} attempts remaining`
          }
        />
      )}
      <div className={styles.buttonsContainer}>
        {stage === EnterPinStage.enter && (
          <Button
            ref={submitButtonRef}
            className={classnames(styles.button, {
              [styles.buttonDisabled]: !isPinCompleted,
            })}
            type="primary"
            htmlType="submit"
            data-testid="parental-control-verify-button"
          >
            ENTER
          </Button>
        )}
        {stage === EnterPinStage.enter && (
          <Button
            className={classnames(styles.forgotPinButton)}
            type="primary"
            data-testid="parental-control-forgot-pin--button"
            onClick={handleForgotPin}
          >
            FORGOT MY PIN
          </Button>
        )}
        {stage === EnterPinStage.locked && (
          <Button
            className={classnames(styles.tryAgainButton, {
              [styles.buttonDisabled]: !isTryAgainAvailable,
            })}
            type="primary"
            data-testid="parental-control-try-again-button"
            onClick={handleTryAgain}
          >
            RETRY
          </Button>
        )}
        {stage === EnterPinStage.locked && (
          <Button
            className={classnames(styles.resetPinButton)}
            type="primary"
            data-testid="parental-control-reset-pin-button"
            onClick={handleForgotPin}
          >
            RESET PIN
          </Button>
        )}
        {stage === EnterPinStage.reset && (
          <Button
            ref={submitButtonRef}
            className={classnames(styles.okButton)}
            type="primary"
            onClick={handleGoBack}
            data-testid="parental-control-ok-button"
          >
            OKAY
          </Button>
        )}
      </div>
      <Preloader isLoading={isSubmitting} />
    </form>
  );
};

export default EnterPinPanel;
