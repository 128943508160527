import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ParentalControl } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import { actions } from '@/Store';

const useParentalForbidden = (mediaAsset, isParentalRestrictionResolved) => {
  const dispatch = useDispatch();

  // Need to check if the whole Parental Setting is disable, otherwise don't need to add restriction logic
  // Set the default as true to avoid unnecessary fetchPlaybackMeta
  const [isParentalRestriction, setParentalRestriction] = useState<boolean>();
  const isParentalForbidden = isParentalRestriction && !isParentalRestrictionResolved;

  useEffect(() => {
    if (mediaAsset) {
      (async () => {
        let isParentalControl = false;

        if (mediaAsset?.hasParentalRestriction) {
          const parentalControlRes = await dispatch(actions.parentalPin.fetchParentalSettings());

          if (!parentalControlRes?.error) {
            const parentalControl =
              parentalControlRes.payload?.user?.parentalSettings?.parentalControl;

            isParentalControl =
              parentalControl === ParentalControl.Enabled ||
              parentalControl === ParentalControl.NotSet;
          }
        }
        setParentalRestriction(mediaAsset?.hasParentalRestriction && isParentalControl);
      })();
    } else {
      setParentalRestriction(undefined);
    }
  }, [dispatch, mediaAsset]);

  return isParentalForbidden;
};

export default useParentalForbidden;
