import React from 'react';

import { Season, Maybe } from '@skytvnz/sky-app-store/lib/types/graph-ql/index';

import Content from '@/Layouts/containers/Content';
import Heading from '@/Components/Heading';

import SeasonSelector from '../SeasonSelector';
import { SeasonItem } from '../types';
import Episodes from './Episodes';

import styles from './styles.module.scss';

interface Props {
  seasons: Maybe<Season>[];
  selectedSeason: Season | null | undefined;
  brandId: string;
  onSeasonChange: (season: Season) => void;
  isSport: boolean;
}

const Seasons: React.FC<Props> = props => {
  const { seasons, selectedSeason, onSeasonChange, brandId, isSport } = props;

  const selectedSeasonChanged = season => {
    onSeasonChange(season);
  };

  const title = isSport ? 'Highlights, Replays & Shows' : 'All Episodes';
  const playbackOrigin = isSport ? 'Content Highlights, Replays & Shows' : 'Content All Episodes';

  return (
    <div
      id="seasons-and-episodes"
      className={styles.seasonsAndEpisodesContainer}
      data-testid="seasons-and-episodes"
    >
      <Content>
        <Heading testid="seasons-and-episodes-heading">{title}</Heading>
        <SeasonSelector
          seasons={seasons}
          selectedSeason={selectedSeason}
          itemType={SeasonItem.tvShow}
          onSeasonChange={selectedSeasonChanged}
        />
      </Content>
      <Episodes
        episodes={selectedSeason?.episodes}
        brandId={brandId}
        isSport={isSport}
        playbackOrigin={playbackOrigin}
        selectedSeasonId={selectedSeason?.id}
      />
    </div>
  );
};

export default Seasons;
