import React, { FC, useEffect, useCallback, useRef } from 'react';
import Icon from '@ant-design/icons';
import classnames from 'classnames';

import { ReactComponent as EpisodesIcon } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_episodes.svg';
import { DrawerCommonProps } from '../../VideoTypes';
import SelectorDrawer from './SelectorDrawer';

import styles from './styles.module.scss';
import { Panel } from '../CaptionsPanel/Constant';

export interface Props extends DrawerCommonProps {
  isShow?: boolean;
  isDisabled?: boolean;
  isSport?: boolean;
  onActiveChange?: (active: boolean) => void;
  isLivePlayer?: boolean;
  activePanel: string;
  setActivePanel: any;
  showPanel: boolean;
}

const ResourcePanel: FC<Props> = ({
  isShow = false,
  isDisabled = false,
  isSport = false,
  isLivePlayer = false,
  drawerTabs,
  drawerContentList,
  isDrawerContentLoading,
  selectedTabId,
  selectedContentId,
  onDrawerTabChange,
  onDrawerContentSelect,
  showPanel,
  setActivePanel,
}) => {
  const inactivityTimer = useRef<NodeJS.Timeout | null>(null);

  const resetInactivityTimer = useCallback(() => {
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current);
    }

    if (showPanel) {
      inactivityTimer.current = setTimeout(() => {
        setActivePanel(Panel.NO_PANEL);
      }, 5000);
    }
  }, [showPanel, setActivePanel]);

  useEffect(() => {
    resetInactivityTimer();

    return () => {
      if (inactivityTimer.current) {
        clearTimeout(inactivityTimer.current);
      }
    };
  }, [resetInactivityTimer]);

  const toggleDrawer = useCallback(() => {
    setActivePanel(Panel.RESOURCE_PANEL);
  }, [setActivePanel]);

  let buttonTitle = '';
  if (isSport) {
    buttonTitle = 'View More';
  } else {
    buttonTitle = isLivePlayer ? 'Channels' : 'Seasons & Episodes';
  }

  return (
    <div
      className={classnames(styles.resourcePanel, { [styles.hide]: !isShow })}
      onMouseMove={resetInactivityTimer} // Reset timer on mouse move
    >
      <div className={classnames(styles.drawerPanel)}>
        <button
          type="button"
          className={classnames(styles.controlButton, styles.drawerBtn)}
          title={buttonTitle}
          data-testid="video-player-resources-drawer-button"
          name="Player resources drawer button"
          onClick={toggleDrawer}
          disabled={isDisabled}
        >
          <Icon className={styles.buttonIcon} component={EpisodesIcon} />
          <span data-text={buttonTitle}>{buttonTitle}</span>
        </button>
        <SelectorDrawer
          isShow={showPanel && !isDisabled}
          isLivePlayer={isLivePlayer}
          drawerTabs={drawerTabs}
          drawerContentList={drawerContentList}
          isDrawerContentLoading={isDrawerContentLoading}
          selectedTabId={selectedTabId}
          selectedContentId={selectedContentId}
          onDrawerTabChange={onDrawerTabChange}
          onDrawerContentSelect={onDrawerContentSelect}
        />
      </div>
    </div>
  );
};

export default ResourcePanel;
