import React from 'react';

import MediaTile, { Props } from '@/Components/Rail/Tiles/MediaTile';

const MediaTileInstance: React.FC<Props> = props => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MediaTile showTextOnHover {...props} />;
};

export default MediaTileInstance;
