import React, { FC } from 'react';
import { Button } from 'antd';

import { ErrorProps } from '../VideoTypes';

import styles from './styles.module.scss';

const ErrorState: FC<ErrorProps> = ({
  title,
  subTitle,
  primaryButtonText,
  primaryButtonIcon,
  secondaryButtonText,
  secondaryButtonIcon,
  onClick,
  onClickSecondary,
}) => {
  return (
    <div className={styles.errorMessage} data-testid="player-error-message">
      <h1 className={styles.message} data-testid="player-error-title">
        {title}
      </h1>
      <div className={styles.details} data-testid="player-error-subTitle">
        {subTitle}
      </div>
      <div className={styles.buttonRowContainer}>
        {primaryButtonText && (
          <Button
            type="primary"
            className={styles.button}
            data-testid="player-primary-error-button"
            name="Player primary error button"
            onClick={onClick}
          >
            {primaryButtonIcon && <span className={styles.buttonIcon}>{primaryButtonIcon}</span>}
            <span className={styles.buttonText}>{primaryButtonText}</span>
          </Button>
        )}
        {secondaryButtonText && (
          <Button
            type="primary"
            className={styles.button}
            data-testid="player-secondary-error-button"
            name="Player secondary error button"
            onClick={onClickSecondary}
          >
            {secondaryButtonIcon && (
              <span className={styles.buttonIcon}>{secondaryButtonIcon}</span>
            )}
            <span className={styles.buttonText}>{secondaryButtonText}</span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default ErrorState;
