/* eslint-disable import/prefer-default-export */
import { isNil } from 'ramda';

export const getFullSeasonEpisodeText = (seasonNumber?: any, episodeNumber?: any) => {
  if (!isNil(seasonNumber) && !isNil(episodeNumber)) {
    return `Season ${String(seasonNumber).padStart(1, '0')} Episode ${String(
      episodeNumber,
    ).padStart(1, '0')}`;
  }
  return '';
};
