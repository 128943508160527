import React, { useEffect } from 'react';
import RoutePath from '@/Routes/RoutePath';
import { useLocation } from 'react-router-dom';
import { getSearchParams } from '@/Utils/URLParams';

import useAuth from '@/Hooks/useAuth';

const Login: React.FC = () => {
  const location = useLocation();
  const auth = useAuth();

  useEffect(() => {
    if (location.pathname === RoutePath.login) {
      auth.login(getSearchParams().callbackUrl);
    } else if (location.pathname === RoutePath.register) {
      auth.register(getSearchParams().callbackUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Login;
