import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { ReactComponent as SpotifyLogo } from '@skytvnz/sky-app-style/lib/assets/svg/logos/icon_spotify.svg';
import classnames from 'classnames';
import Content from '@/Layouts/containers/Content';
import styles from './styles.module.scss';

const propTypes = {
  redirectToSpotify: PropTypes.func.isRequired,
};

const Login: React.FC<InferProps<typeof propTypes>> = props => {
  const { redirectToSpotify } = props;

  return (
    <Content className={styles.spotifyLogin}>
      <span className={styles.loginMessage}>
        Do you have a Premium Spotify account? Enjoy soundtracks from your favourite shows & movies
      </span>
      <div className={styles.loginActions}>
        <button
          type="button"
          className={styles.loginBtn}
          data-testid="soundtrack-login-button"
          name="Spotify login button"
          onClick={redirectToSpotify}
        >
          LOGIN
        </button>
        <SpotifyLogo className={classnames(styles.spotifyLogo, styles.spotifyLogoLogin)} />
      </div>
    </Content>
  );
};

export default Login;
