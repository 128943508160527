import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty, isNil } from 'ramda';
import { useSelector } from 'react-redux';

import { selectors, utils } from '@/Store';
import { getViewPage, getWatchPath } from '@/Utils/PageLocation';

import { useSubscriptionModal, useVodRequiredSubscriptions } from './useSubscription';
import useDevice from './useDevice';

// TODO: use <Link> to watch page url directly instead of this hook
const useVideoPlayer = () => {
  const history = useHistory();
  const device = useDevice();

  const getMovie = useSelector(selectors.titles.getMovie);
  const getBrand = useSelector(selectors.titles.getBrand);
  const showUpgradeSubscriptionModal = useSubscriptionModal();
  const getRequiredSubscriptions = useVodRequiredSubscriptions();

  const openAndPlay = useCallback(
    (
      brandId: string,
      episodeId?: string,
      playbackOrigin?: string,
      ignoreEntitlementCheck?: boolean,
    ) => {
      // Device registration check
      if (device.isDeviceRegistrationLimitExceeded) {
        device.showDeviceRegistrationLimitModal();
        return;
      }

      // Entitlement subscription check
      const requiredSubscriptions = getRequiredSubscriptions(brandId, episodeId);

      if (
        !ignoreEntitlementCheck &&
        !isNil(requiredSubscriptions) &&
        !isEmpty(requiredSubscriptions)
      ) {
        let analyticsParams: any = {
          categoryName: playbackOrigin,
          viewName: getViewPage(history),
        };

        if (episodeId) {
          const brand = getBrand(brandId);
          analyticsParams = {
            ...analyticsParams,
            contentId: episodeId,
            contentTitle: brand?.title,
            contentType: utils.analytics.getContentTypeByBrand(brand),
            channelName: utils.analytics.getSegmentControllingChannel(brand),
          };
        } else {
          const movie = getMovie(brandId);

          analyticsParams = {
            ...analyticsParams,
            contentId: brandId,
            contentTitle: movie?.title,
            contentType: utils.analytics.getContentTypeByBrand(movie),
            channelName: utils.analytics.getSegmentControllingChannel(movie),
          };
        }
        showUpgradeSubscriptionModal(requiredSubscriptions, analyticsParams);
        return;
      }

      const path = getWatchPath(history, brandId, episodeId, playbackOrigin);

      history.push(path);
    },
    [device, getRequiredSubscriptions, history, showUpgradeSubscriptionModal, getMovie, getBrand],
  );

  return { openAndPlay };
};

export default useVideoPlayer;
