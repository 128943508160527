import React from 'react';

import TabsList from '@/Components/TabsList';

import styles from './styles.module.scss';

const TabSelector: React.FC = ({ children }) => (
  <TabsList className={styles.tabsList} buttonClassName={styles.scrollButton}>
    {children}
  </TabsList>
);

export default TabSelector;
