import React, { useEffect } from 'react';
import { Button } from 'antd';
import classnames from 'classnames';
import { ReactComponent as Close } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_close.svg';
import styles from './styles.module.scss';

export enum ButtonStyle {
  PRIMARY,
  DEFAULT,
}

interface CustomStyles {
  modal?: any;
  modalBody?: any;
  modalTitle?: any;
  modalContent?: any;
  okButtonStyle?: ButtonStyle;
  cancelButtonStyle?: ButtonStyle;
}

export interface ModalProps {
  title?: string;
  content?: string | React.ReactNode;
  okText?: string;
  onOk?: any;
  okButtonStyle?: ButtonStyle;
  showOkButton?: boolean;
  cancelText?: string;
  cancelButtonStyle?: ButtonStyle;
  cancelable?: boolean;
  onCancel?: any;
  closable?: boolean;
  onClose?: any;
  children?: React.ReactElement;
  customStyle?: CustomStyles;
}

const SimpleModal: React.FC<ModalProps> = modalProps => {
  const {
    title,
    content,
    okText = 'Ok',
    onOk,
    showOkButton = true,
    cancelable = false,
    cancelText = 'Cancel',
    onCancel,
    closable = true,
    onClose,
    children,
    customStyle = {
      okButtonStyle: ButtonStyle.PRIMARY,
      cancelButtonStyle: ButtonStyle.DEFAULT,
    },
  } = modalProps;

  useEffect(() => {
    const handleEsc = event => {
      if (event.keyCode === 27) {
        onClose();
      }
    };
    if (closable) {
      document.addEventListener('keydown', handleEsc);
    }

    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [closable, onClose]);

  return (
    <div className={styles.modalMask}>
      <div className={classnames(styles.modal, customStyle.modal)} data-testid="modal">
        {closable && (
          <div className={styles.modalHeader}>
            <Close className={styles.closeIcon} data-testid="modal-close-icon" onClick={onClose} />
          </div>
        )}
        <div className={classnames(styles.modalBody, customStyle.modalBody)}>
          {title && (
            <h2
              className={classnames(styles.modalTitle, customStyle.modalTitle)}
              data-testid="modal-title"
            >
              {title}
            </h2>
          )}
          {children || (
            <>
              <div
                className={classnames(styles.modalContent, customStyle.modalContent)}
                data-testid="modal-content"
              >
                {content}
              </div>
              <div className={styles.modalButtonRowContainer}>
                {showOkButton && (
                  <Button
                    type={
                      customStyle?.okButtonStyle === ButtonStyle.PRIMARY ? 'primary' : 'default'
                    }
                    className={
                      customStyle?.okButtonStyle === ButtonStyle.PRIMARY
                        ? styles.modalButton
                        : classnames(styles.modalCancelButton, styles.modalButton)
                    }
                    data-testid="modal-ok-button"
                    name="Modal OK button"
                    onClick={onOk}
                  >
                    <span className={styles.buttonText}>{okText}</span>
                  </Button>
                )}
                {cancelable && (
                  <Button
                    type={
                      customStyle?.cancelButtonStyle === ButtonStyle.PRIMARY ? 'primary' : 'default'
                    }
                    className={
                      customStyle?.cancelButtonStyle === ButtonStyle.PRIMARY
                        ? styles.modalButton
                        : classnames(styles.modalCancelButton, styles.modalButton)
                    }
                    data-testid="modal-cancel-button"
                    name="Modal Cancel button"
                    onClick={onCancel}
                  >
                    <span className={styles.buttonText}>{cancelText}</span>
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SimpleModal;
