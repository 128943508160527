import { hooks, selectors } from '@/Store';

const useSkipIntroAutoPlay = (brandId?: string, episodeId?: string) => {
  const getShowEpisode = hooks.useSelector(selectors.titles.getShowEpisode);
  const episode = getShowEpisode(brandId, episodeId);
  const nextEpisode = getShowEpisode(brandId, episode?.nextEpisode?.id);
  const seasons = hooks.useSelector(selectors.titles.getSeasons)(brandId);
  const episodeSeason = seasons?.filter(s => s?.number === episode?.season?.number)[0];
  const episodeIndex = episodeSeason?.episodes?.findIndex(e => e?.number === episode.number);
  const episodesLeftInSeason = episodeSeason?.episodes?.length - (episodeIndex + 1);

  const hasNextEpisode: boolean = nextEpisode;
  const nextEpisodeTitle = nextEpisode
    ? `Next: S${nextEpisode?.season?.number}:E${nextEpisode?.number} • ${nextEpisode?.title}`
    : undefined;
  const episodesLeft =
    episodesLeftInSeason > 0
      ? `${episodesLeftInSeason} more episode${episodesLeftInSeason > 1 ? 's' : ''} left`
      : undefined;
  return { episode, hasNextEpisode, nextEpisodeTitle, episodesLeft };
};
export default useSkipIntroAutoPlay;
