import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useModal } from 'react-modal-hook';
import SimpleModal, { ModalProps } from '@/Components/Modal/SimpleModal';

const useSimpleModal = (Component?: React.FunctionComponent<any>) => {
  const [isVisible, setVisible] = useState(false);

  const modalInputRef = useRef<ModalProps>();

  const onClose = useCallback(() => setVisible(false), []);

  const modalAction = useCallback(
    action => () => {
      action?.();
      onClose();
    },
    [onClose],
  );

  const [showModal, hideModal] = useModal(
    () => (
      <SimpleModal
        title={modalInputRef.current?.title || ''}
        content={modalInputRef.current?.content || ''}
        okText={modalInputRef.current?.okText}
        onOk={modalAction(modalInputRef.current?.onOk)}
        showOkButton={modalInputRef.current?.showOkButton}
        cancelable={modalInputRef.current?.cancelable}
        cancelText={modalInputRef.current?.cancelText}
        cancelButtonStyle={modalInputRef.current?.cancelButtonStyle}
        onCancel={modalAction(modalInputRef.current?.onCancel)}
        closable={modalInputRef.current?.closable}
        onClose={modalAction(modalInputRef.current?.onClose)}
      >
        {Component && <Component closeModal={onClose} />}
      </SimpleModal>
    ),
    [Component],
  );

  const openModal = useCallback((modalProps: ModalProps) => {
    modalInputRef.current = {
      ...modalProps,
    };
    setVisible(true);
  }, []);

  useEffect(() => {
    if (isVisible) {
      showModal();
    } else {
      hideModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return { openModal };
};

export default useSimpleModal;
