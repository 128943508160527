import React, { FC, useRef, ImgHTMLAttributes, useState, useEffect } from 'react';
import { useIntersection } from 'react-use';
import classnames from 'classnames';

import { ImageAssetType } from '@skytvnz/sky-app-store/lib/types/enums/ImageAssetType';

import blankImg from '@/Resources/images/blank.gif';

import { getDefaultVideoImage, getVideoImg } from '@/Utils/VideoImage';
import Icon from '@ant-design/icons/lib/components/Icon';
import { OverlayImage, OverlayPosition } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import styles from './styles.module.scss';

interface Props extends Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'> {
  src: string;
  type: ImageAssetType;
  lazyload?: boolean;
  whiteBackground?: boolean;
  icon?: boolean | object;
  overlayImage?: OverlayImage;
}

const VideoImage: FC<Props> = ({
  // component props
  src,
  type,
  lazyload = false,
  whiteBackground = false,
  icon,
  overlayImage,
  // img props
  alt,
  onError,
  className,
  ...imgProps
}) => {
  const imageSrc = getVideoImg(type, src);
  const imgRef = useRef<HTMLImageElement>(null);
  // use a <img> with a 1px transparent blank image in src
  // instead of a "no src" <img> as the initial
  // to avoid some browsers displaying "broken image" for "no src" <img>
  const [imgSrc, setImgSrc] = useState(lazyload ? blankImg : imageSrc);
  const intersection = useIntersection(imgRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });
  const isIntersecting = intersection?.isIntersecting;
  useEffect(() => {
    if (!lazyload || isIntersecting) {
      setImgSrc(imageSrc);
    }
  }, [isIntersecting, lazyload, imageSrc]);

  const getPosition = (position: OverlayPosition) => {
    switch (position) {
      case OverlayPosition.TopLeft:
        return { top: '8px', left: '8px' };
      case OverlayPosition.Center:
        return { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' };
      case OverlayPosition.BottomLeft:
        return { bottom: '8px', left: '8px' };
      case OverlayPosition.BottomRight:
        return { bottom: '8px', right: '8px' };
      default:
        return null;
    }
  };
  const createImg = (imgClassName?: string) => (
    <>
      <img
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...imgProps}
        ref={imgRef}
        src={imgSrc}
        alt={alt}
        draggable={false}
        className={imgClassName}
        onError={e => {
          if (imgRef.current) {
            imgRef.current.src = getDefaultVideoImage(type);
            if (onError) {
              onError.call(imgRef, e);
            }
          }
        }}
      />

      {(icon || overlayImage?.uri) && (
        <div className={styles.overlayImagesGroup}>
          {icon && (
            <Icon
              component={icon as React.FC}
              data-testid="sky-instant-replay-icon"
              className={styles.instantReplayIcon}
            />
          )}
          {overlayImage?.uri && !getPosition(overlayImage?.position) && (
            <img
              className={styles.skyOpenBadge}
              src={overlayImage?.uri}
              data-testid="sky-open-badge-img"
              alt="sky open"
            />
          )}
        </div>
      )}

      {overlayImage?.uri && getPosition(overlayImage?.position) && (
        <img
          className={styles.skyOpenBadge}
          style={{ position: 'absolute', ...getPosition(overlayImage?.position) }}
          src={overlayImage?.uri}
          data-testid="sky-open-badge-img"
          alt="sky open"
        />
      )}
    </>
  );

  return whiteBackground ? (
    <span className={classnames(styles.whiteBackground, className)}>{createImg()}</span>
  ) : (
    createImg(className)
  );
};

export default VideoImage;
