import React, { useEffect } from 'react';

import useLinkAccount from '@/Hooks/useLinkAccount';
import Preloader from '@/Layouts/containers/Preloader';

const LinkAccountCallback: React.FC = () => {
  const { linkAccountRedirectCallback } = useLinkAccount();

  // Process redirect callback
  useEffect(() => {
    linkAccountRedirectCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Preloader isLoading />;
};

export default LinkAccountCallback;
