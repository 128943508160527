import React from 'react';
import { SplitTreatments } from '@splitsoftware/splitio-react';
import { isNil, isEmpty } from 'ramda';
import { useSelector } from 'react-redux';
import { selectors } from '@/Store';

interface Props {
  featureNames: Array<string>;
  children?: any;
}

/**
 * Convert user attributes with below structure
 *     [
          {
              "name": "newSkyGoPlatform",
              "value": "optIn"
          },
          {
              "name": "accountId",
              "value": "auth0|63444fdf-a1cd-4d2e-983e-ae61a6a9c8fe"
          }
        ]
    to
    {
      "newSkyGoPlatform": "optIn",
      "accountId" : "auth0|63444fdf-a1cd-4d2e-983e-ae61a6a9c8fe"
    }
*/
export const buildSplitIORequest = (userAttributes, isAnonymousUser = false) => {
  const initialAttributes = {
    platform: 'web',
    versionNumber: process.env.APP_VERSION,
  };

  if (isAnonymousUser) {
    return { ...initialAttributes, isAnonymous: true };
  }

  return !isNil(userAttributes) && !isEmpty(userAttributes)
    ? userAttributes.reduce((acc, cur) => ({ ...acc, [cur.name]: cur.value }), initialAttributes)
    : initialAttributes;
};

const UserSplitTreatments: React.FC<Props> = ({ children, featureNames }) => {
  const user = useSelector(selectors.auth.user);
  const userAttributes = useSelector(selectors.userAttributes.attributes);

  return (
    <SplitTreatments
      names={featureNames}
      attributes={buildSplitIORequest(userAttributes, isNil(user))}
    >
      {children}
    </SplitTreatments>
  );
};

export default UserSplitTreatments;
