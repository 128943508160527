import { findIndex } from 'ramda';
import {
  contentCardClicked,
  contentAddedToMyList,
  contentRemovedFromMyList,
} from '@/Analytics/Segment';

export const recordDetailsButtonClick = (
  id: string,
  title: string,
  type: string,
  page: string,
  heroesIdList?: Array<string>,
) => {
  const heroIndex = heroesIdList ? findIndex(i => id === i, heroesIdList) : 0;
  contentCardClicked({
    content_id: id,
    content_title: title,
    view_name: page,
    content_type: type,
    category_name: 'Hero',
    style: 'Landscape',
    content_position: heroIndex + 1,
    contains: heroesIdList,
  });
};

export const recordMyListAdd = (id: string, title: string) => {
  contentAddedToMyList({ content_id: id, content_title: title });
};

export const recordMyListRemove = (id: string, title: string) => {
  contentRemovedFromMyList({ content_id: id, content_title: title });
};
