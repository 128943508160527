import React, { FC } from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

interface Props {
  span?: number;
  spanS?: number;
  spanM?: number;
  spanL?: number;
  className?: string;
  testid?: string;
}

const Cell: FC<Props> = ({
  children,
  className,
  testid,
  span = 1,
  spanS = undefined,
  spanM = undefined,
  spanL = undefined,
}) => {
  return (
    <div
      className={classnames(
        styles.cell,
        {
          [styles[`cell${span}`]]: true,
          [styles[`cellS${spanS}`]]: spanS,
          [styles[`cellM${spanM}`]]: spanM,
          [styles[`cellL${spanL}`]]: spanL,
        },
        className,
      )}
      data-testid={testid}
    >
      {children}
    </div>
  );
};

export default Cell;
