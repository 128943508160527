import React, { FC, memo, useMemo } from 'react';
import dayjs from 'dayjs';

import { useTimeWidthUnit } from '@/Pages/TVGuide/SlotsGrid/useTimeWidthUnit';
import { parseDateTime } from '@/Utils/TimeFormat';

import { MINUTES_PER_UNIT } from '../constants';

import styles from './styles.module.scss';

interface TimeBlockProps {
  blockTime: dayjs.Dayjs;
  initialTime: dayjs.Dayjs;
}

const TimeBlock: FC<TimeBlockProps> = props => {
  const { blockTime, initialTime } = props;
  const widthUnit = useTimeWidthUnit();
  const left = useMemo(
    () => widthUnit * (blockTime.diff(initialTime, 'second') / 60 / MINUTES_PER_UNIT),
    [widthUnit, blockTime, initialTime],
  );

  return (
    <div className={styles.timeline} style={{ left }}>
      <span className={styles.title}>{parseDateTime(blockTime)}</span>
    </div>
  );
};

export default memo(TimeBlock);
