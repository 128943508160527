import React from 'react';
import { useHistory } from 'react-router';
import classnames from 'classnames';
import { Maybe, SkySubscription } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import { utils } from '@/Store';
import TileSynopsis from '@/Pages/Detail/Seasons/Episodes/TileSynopsis';
import Availability from '@/Pages/Detail/Seasons/Episodes/Availability';
import { formatClassification, joinAdvisories } from '@/Utils/Rating';
import RecordButton from '@/Components/RecordButton';
import { getViewPage } from '@/Utils/PageLocation';
import LiveTag from '@/Components/LiveTag';

import styles from './styles.module.scss';

interface Props {
  slot: any;
  index: number;
  genre: string | null;
  requiredSubscriptions: Array<Maybe<SkySubscription>> | undefined;
  origin: string;
  analyticsParams: any;
  isSport: boolean;
  contentType?: string;
  brandTitle?: string;
}

const UpcomingTile: React.FC<Props> = props => {
  const {
    slot,
    index,
    genre,
    requiredSubscriptions,
    origin,
    analyticsParams,
    contentType,
    isSport,
    brandTitle,
  } = props;
  const history = useHistory();

  return (
    <div className={styles.upcomingTile} data-testid={`upcoming-tile-${index}`}>
      <p
        className={styles.upcomingTitle}
        data-testid={`episode-title-nr-${slot?.programme.number}`}
      >
        {!isSport &&
          utils.title.getSeasonEpisodeNumber(
            slot?.programme.season?.number,
            slot?.programme.number,
          )}
        {!isSport && slot?.programme.number && ` • `}
        {`${slot?.programme.title}`}
      </p>
      <p
        className={classnames([styles.upcomingSubtitle, styles.subtitle])} // TODO: get the CSS updates that Long is working on.
        data-testid={`slot-duration-slot-nr-${slot?.programme.number}`}
      >
        <span>
          {slot?.channel?.title && `${slot?.channel?.title}`}
          {slot?.channel?.number && ` • ${slot?.channel?.number}`}
          {genre && ` • ${genre}`}
          {slot?.rating?.classification &&
            slot?.rating?.classification.length > 0 &&
            ` • ${formatClassification(slot?.rating?.classification)}`}
          {slot?.rating?.advisories &&
            slot?.rating?.advisories.size > 0 &&
            ` • ${joinAdvisories(slot?.rating?.advisories)}`}
        </span>
      </p>
      <div className={styles.availabilityContainer}>
        <LiveTag className={styles.liveTag} slot={slot} />
        <Availability id={slot.programme.number} endDate={null} startDate={slot.start} />
      </div>
      <TileSynopsis text={slot.programme.synopsis} id={slot.programme.number} />
      <div className={styles.tileButtonContainer}>
        <RecordButton
          isSecordary
          slot={slot}
          channel={slot.channel}
          requiredSubscriptions={requiredSubscriptions}
          brandTitle={brandTitle}
          isSport={isSport}
          subscriptionAnalyticParams={{
            categoryName: 'Upcoming',
            contentId: slot?.programme?.id,
            contentTitle: analyticsParams.contentTitle,
            contentType,
            viewName: getViewPage(history),
            channelName: analyticsParams.channel,
          }}
          remoteRecordAnalyticParams={{
            categoryName: origin,
            contentTitle: analyticsParams.contentTitle,
            history,
            genres: analyticsParams.genres,
            contentType,
            channelName: analyticsParams.channel?.[0],
          }}
        />
      </div>
    </div>
  );
};

export default UpcomingTile;
