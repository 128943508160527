import { useMedia as useMediaQuery } from 'react-use';
import styleVars from 'Styles/variables';

const useMedia = () => {
  // According to the Mobile First rule. We consider it is mobile screen by default.
  // so we don't check `isMediaXs`, only check if it is a larger screen.
  // Leave the next line commented out.
  const isMediaXs = useMediaQuery(`(min-width: ${styleVars.sizeLayoutBreakpointXs})`);
  const isMediaS = useMediaQuery(`(min-width: ${styleVars.sizeLayoutBreakpointS})`);
  const isMediaM = useMediaQuery(`(min-width: ${styleVars.sizeLayoutBreakpointM})`);
  const isMediaL = useMediaQuery(`(min-width: ${styleVars.sizeLayoutBreakpointL})`);
  const isMediaXl = useMediaQuery(`(min-width: ${styleVars.sizeLayoutBreakpointXl})`);

  return {
    isMediaXs,
    isMediaS,
    isMediaM,
    isMediaL,
    isMediaXl,
  };
};

export default useMedia;
