/* eslint-disable no-param-reassign */
import { PlaybackAsset, PlayerControllerFactory } from './PlayerTypes';
import { transformEMEPlayback } from '../DRM';
import { updatePlaybackSource } from './PlaybackSourceUtils';

const LIVE_TOLERANCE = 30;

/**
 * Factory for generating a player controller with the tech spec of Shaka Player
 * @param player
 * @param videoElement
 */
const BrightCoveControllerFactory: PlayerControllerFactory = (
  player,
  videoElement,
  isLivePlayer = false,
  accessToken,
) => {
  const playerController = {
    videoEl: videoElement,
    focus: () => player.focus(),
    playsInline: () => player.playsinline(),
    readyState: () => player.readyState(),

    loadMeta: async (playbackAsset: PlaybackAsset, startPosition?: string) => {
      const playSource = transformEMEPlayback(playbackAsset, accessToken);

      // Update the source to start from initial time if mentioned
      // i.e. https://xxx/yyy.mpd?start=2024-05-27T09%3A50%3A00Z
      if (isLivePlayer && startPosition && playSource?.src) {
        playSource.src = updatePlaybackSource(playSource.src, startPosition);
      }

      // transformVideoResponse is needed to play properly in safari
      player.catalog.transformVideoResponse(playSource);
      player.catalog.load(playSource);
    },
    subTitle: (selectedTrack?: any) => {
      player.ready(() => {
        const tracks = player.textTracks().tracks_;
        for (let i = 0; i < tracks.length; i += 1) {
          tracks[i].mode = 'disabled';
          if (
            (tracks[i].kind === 'subtitles' || tracks[i].kind === 'captions') &&
            tracks[i].language === selectedTrack?.language
          ) {
            tracks[i].mode = 'showing';
          } else {
            tracks[i].mode = 'disabled';
          }
        }
      });
    },
    dispose: () => player?.dispose(),
    volume: (volume?: number) => player.volume(volume),
    currentTime: (currentTime?: number) => player?.currentTime(currentTime),

    videoHeight: () => player.videoHeight(),
    autoplay: (autoplay?: boolean) => player.autoplay(autoplay),

    muted: (muted?: boolean) => player.muted(muted),
    play: async () => player.play(),
    pause: () => player.pause(),
    duration: () => player.duration(),
    buffered: () => player.buffered(),

    seekToLiveEdge: () => {
      player.liveTracker?.seekToLiveEdge();
    },

    getLiveCurrentTime: () => {
      return player.liveTracker?.liveCurrentTime();
    },

    getLiveWindowDuration: () => {
      const livePlayer = player.liveTracker;
      return livePlayer && Math.floor(livePlayer.seekableEnd() - livePlayer.seekableStart());
    },

    getLiveStartOffset: () => {
      const livePlayer = player.liveTracker;
      return livePlayer && livePlayer.seekableStart() + livePlayer.pastSeekEnd();
    },

    isAtLiveEdge: (tolerance?: number) => {
      return (
        Math.abs(playerController.getLiveCurrentTime() - player.currentTime()) <=
        (tolerance || LIVE_TOLERANCE)
      );
    },
  };
  return playerController;
};

export default BrightCoveControllerFactory;
