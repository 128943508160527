import { Button } from 'antd';
import React, { FC, useEffect, useRef } from 'react';

import styles from './styles.module.scss';

interface LoadingButtonProps {
  duration: number;
  onEventTrigger: () => void;
  testId?: string;
  hasAnimation?: boolean;
  setAnimationStatus: (state: boolean) => void;
}

const LoadingButton: FC<LoadingButtonProps> = ({
  duration,
  onEventTrigger,
  hasAnimation = true,
  testId,
  children,
  setAnimationStatus,
}) => {
  const btnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const buttonElement = btnRef.current;

    const handleAnimationEnd = () => {
      setAnimationStatus(true);
      onEventTrigger();
    };

    if (buttonElement) {
      buttonElement.addEventListener('animationend', handleAnimationEnd);
      setAnimationStatus(false);
      return () => {
        buttonElement.removeEventListener('animationend', handleAnimationEnd);
      };
    }
    return undefined;
  }, [onEventTrigger, setAnimationStatus]);

  return (
    <Button
      type="primary"
      ref={btnRef}
      className={hasAnimation ? styles.nextEpisodeBtn : ''}
      style={{ '--duration': `${duration}s` } as React.CSSProperties}
      onClick={onEventTrigger}
      data-testid={testId}
    >
      {children}
    </Button>
  );
};

export default LoadingButton;
