// eslint-disable-next-line import/prefer-default-export
import youbora from 'youboralib';
import { isNil } from 'ramda';

export class YouboraPlayerConfig {
  public username: string | undefined;

  public content_transactionCode: string | undefined;

  public content_type: string | undefined;

  public content_title: string | undefined;

  public content_program: string | undefined;

  public content_resource: string | undefined;

  public content_season: number | null | undefined;

  public content_episodeTitle: number | null | undefined;

  public content_channel: string | undefined;

  public content_id: string | undefined;

  public content_genre: string | undefined;

  public content_contractedResolution: string | undefined;

  public content_duration: number | undefined;

  public content_metadata: object | undefined;

  public content_isLive: boolean | undefined;

  public content_account_number: string | undefined;

  public getOptions(): object {
    return {
      username: isNil(this.username) ? '' : this.username,
      'content.transactionCode': isNil(this.content_transactionCode)
        ? ''
        : this.content_transactionCode,
      'content.isLive': !!this.content_isLive,
      'content.type': isNil(this.content_type) ? '' : this.content_type,
      'content.title': isNil(this.content_title) ? '' : this.content_title,
      'content.program': isNil(this.content_program) ? '' : this.content_program,
      'content.resource': isNil(this.content_resource) ? '' : this.content_resource,
      'content.season': isNil(this.content_season) ? null : this.content_season,
      'content.episodeTitle': isNil(this.content_episodeTitle) ? null : this.content_episodeTitle,
      'content.channel': isNil(this.content_channel) ? '' : this.content_channel,
      'content.id': isNil(this.content_id) ? '' : this.content_id,
      'content.genre': isNil(this.content_genre) ? '' : this.content_genre,
      'content.contractedResolution': isNil(this.content_contractedResolution)
        ? ''
        : this.content_contractedResolution,
      'content.duration': isNil(this.content_duration) ? null : this.content_duration,
      'content.customDimension.7': isNil(this.content_account_number)
        ? null
        : this.content_account_number,
      'app.name': 'Sky Go 2.0',
      'app.releaseVersion': process.env.APP_VERSION,
    };
  }
}

const getAccountCode = () => {
  return {
    accountCode: process.env.ENV === 'PRODUCTION' ? 'skynzpr' : 'skynzde',
  };
};

export const youboraPlugin = new youbora.Plugin(getAccountCode());
