import React from 'react';
import classnames from 'classnames';
import { WhatsOnTVTabType } from '@skytvnz/sky-app-store/lib/types/enums/WhatsOnTVTabType';
import styles from './styles.module.scss';

interface Props {
  isSelected: boolean;
  viewingContext: WhatsOnTVTabType;
  onClick: (viewingContext: WhatsOnTVTabType) => void;
}

const ViewingContextButton: React.FC<Props> = props => {
  const { isSelected, viewingContext, onClick } = props;

  return (
    <button
      type="button"
      className={classnames(styles.viewingContextButton, {
        [styles.selectedViewingContext]: isSelected,
      })}
      onClick={() => onClick(viewingContext)}
      data-testid={`viewingContext-button-${viewingContext}`}
      name={`Viewing context ${viewingContext} button`}
    >
      {viewingContext}
    </button>
  );
};

export default ViewingContextButton;
