import { useCallback, useMemo } from 'react';
import { hooks, actions } from '@/Store';
import { selectors } from '@skytvnz/sky-app-store';
import { useSelector, useDispatch } from 'react-redux';
import { TextTrack } from '@skytvnz/sky-app-store/lib/types/types/TextTrack';

export const SUBTITLE_SETTING_OVERLAY = {
  SUBTITLES: 'Subtitles',
  OFF: 'Off',
};

const usePlayerSettings = (assetId: string) => {
  const textTracks = useSelector(selectors.playback.getTextTracksByMediaAssetId)(assetId);
  const { selectedTextTrack } = hooks.usePlayerSettings(assetId);
  const profileId = useSelector(selectors.customer.selectedProfileId);

  const selectedTextTrackByProfileId = useSelector(
    selectors.customer.getSelectedTextTrackByProfileId,
  )(profileId);

  const subtitleSectionData = useMemo(() => {
    if (textTracks) {
      return {
        title: SUBTITLE_SETTING_OVERLAY.SUBTITLES,
        data: [
          {
            label: SUBTITLE_SETTING_OVERLAY.OFF,
            value: [],
            selected: !selectedTextTrack,
          },
          ...textTracks.map(textTrack => {
            return {
              label: textTrack.label,
              value: textTrack,
              selected: selectedTextTrack && selectedTextTrack.language === textTrack.language,
            };
          }),
        ],
      };
    }
    return {};
  }, [textTracks, selectedTextTrack]);

  const dispatch = useDispatch();

  const handleTextTrackChange = useCallback(
    (textTrack: TextTrack) => {
      dispatch(
        actions.customer.setSelectedPlayerSettings(profileId, {
          selectedTextTrack: textTrack || {},
        }),
      );
    },
    [dispatch, profileId],
  );

  return {
    selectedTextTrack,
    subtitleSectionData,
    selectedTextTrackByProfileId,
    handleTextTrackChange,
  };
};

export default usePlayerSettings;
