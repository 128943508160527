import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { createGlobalState } from 'react-use';
import { useLocation } from 'react-router-dom';

interface PageHeader {
  title?: string;
  description?: string;
}
const PROD_SEO_DOMAIN = 'https://www.skygo.co.nz';
export const DEFAULT_TITLE = 'Sky Go';
export const DEFAULT_DESCRIPTION =
  'Watch Sky online with Sky Go. Live sports, news, box sets, catch up movies, shows and entertainment. For Sky TV customers, anywhere in New Zealand.';

const useHeader = createGlobalState<PageHeader>({});

export const usePageHeader = (pageHeader: PageHeader) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setPageHeader] = useHeader();
  const { title, description } = pageHeader;
  useEffect(() => {
    setPageHeader({ title: title ? `${title} | ${DEFAULT_TITLE}` : undefined, description });
    return () => {
      setPageHeader({});
    };
    // we need to add the separated dependency for title and description here,
    // to avoid the cases when different object passed with the same value.
  }, [title, description, setPageHeader]);
};

const AppHeader: FC = () => {
  const location = useLocation();
  const [pageHeader] = useHeader();
  const title = pageHeader?.title || DEFAULT_TITLE;
  const description = pageHeader?.description || DEFAULT_DESCRIPTION;

  // we have other domains for PROD which are used for Akamai, need to hide them from SEO
  // https://developers.google.com/search/docs/advanced/crawling/consolidate-duplicate-urls
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={`${PROD_SEO_DOMAIN}${location.pathname}${location.search}`} />
    </Helmet>
  );
};

export default AppHeader;
