import dayjs from 'dayjs';

// TODO fn needs to be moved to app-store
export const getTimeTextRelativeToNow = (time: dayjs.Dayjs) => {
  const today = dayjs().startOf('day');
  const yesterday = dayjs().add(-1, 'd').startOf('day');
  if (dayjs(time).isAfter(today)) {
    if (dayjs(time).format('mm') === '00') {
      return `at ${dayjs(time).format('ha')}`;
    }
    return `at ${dayjs(time).format('h:mma')}`;
  }
  // eslint-ignore
  if (dayjs(time).isAfter(yesterday)) {
    return 'yesterday';
  }

  return `${dayjs().endOf('day').diff(time, 'day')} days ago`;
};

export const formatEndedAtText = (endedTime: dayjs.Dayjs) => {
  return `Ended ${getTimeTextRelativeToNow(endedTime)}`;
};

export const formatStartedAtText = (startedTime: dayjs.Dayjs) => {
  return `Started ${getTimeTextRelativeToNow(startedTime)}`;
};
