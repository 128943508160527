import React, { FC } from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

interface SpinnerProps {
  className?: string;
}
const Spinner: FC<SpinnerProps> = ({ className }) => (
  <div className={classnames(styles.spinner, className)} data-testid="loading-spinner" />
);

export default Spinner;
