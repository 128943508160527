import React, { FC, memo, useState, useRef, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectors, utils } from '@/Store';
import classnames from 'classnames';
import throttle from 'lodash.throttle';
import debounce from 'lodash.debounce';
import { PLAYER_MODE } from '@/Utils/PageLocation';
import BackPanel from './BackPanel';
import ProgressPanel from './ProgressPanel';
import PlaybackPanel from './PlaybackPanel';
import VolumePanel from './VolumePanel';
import TitlePanel from './TitlePanel';
import ResourcePanel from './ResourcePanel';
import SettingsPanel from './SettingsPanel';
import CaptionsPanel from './CaptionsPanel';
import FullscreenPanel from './FullscreenPanel';
import { MediaInfo, DrawerCommonProps } from '../VideoTypes';
import { Panel } from './CaptionsPanel/Constant';
import styles from './styles.module.scss';

interface Props extends Partial<DrawerCommonProps> {
  isShow?: boolean;
  isLivePlayer?: boolean;
  isLive?: boolean;
  isSport?: boolean;
  assetId: string;
  onIsInteractingChange: (newIsInteracting: boolean) => void;

  mediaInfo?: MediaInfo;

  // Back Panel
  isShowBackButton: boolean;
  onBackButtonClick: () => void;

  // Progress Panel
  isSeeking: boolean;
  bufferRanges: TimeRanges | undefined;
  currentTime: number;
  duration: number;
  onCurrentTimeChanging: (currentTime: number) => void;
  onCurrentTimeChanged: (currentTime: number) => void;

  // Playback Panel
  durationJump: number;
  isPlaying: boolean;
  onIsPlayingChange: (newIsPlaying: boolean) => void;
  onSubTitleSelection: (value?: any) => void;
  onPlayBack: () => void;
  onPlayForward: () => void;
  hasPlayerPlaybackDataLoaded: boolean;

  // Volume Panel
  isMuted: boolean;
  onIsMutedChange: (newIsMuted: boolean) => void;
  volume: number;
  onVolumeChange: (volume: number) => void;
  onVolumeUp: () => void;
  onVolumeDown: () => void;
  onLiveTagClick: () => void;

  // Title panel
  playerMode: string;

  // Resource Panel
  isShowResourceButton: boolean;

  // Fullscreen Panel
  isFullScreen: boolean;
  onFullScreenChange: (newIsFullScreen: boolean) => void;
}

const ControlPanel: FC<Props> = ({
  // root,
  isShow = true,
  isLivePlayer = false,
  isSport = false,
  isLive = true,
  onIsInteractingChange,
  assetId = '',
  mediaInfo,
  drawerTabs = [],
  drawerContentList = [],
  isDrawerContentLoading = false,
  selectedTabId,
  selectedContentId,
  onDrawerTabChange,
  onDrawerContentSelect,
  onDrawerOpen,

  isShowBackButton,
  onBackButtonClick,

  isSeeking,
  bufferRanges,
  currentTime = 0,
  duration = 0,
  onCurrentTimeChanging,
  onCurrentTimeChanged,

  durationJump,
  isPlaying,
  onIsPlayingChange,
  onSubTitleSelection,
  onPlayBack,
  onPlayForward,
  hasPlayerPlaybackDataLoaded = false,

  isMuted,
  onIsMutedChange,
  volume,
  onVolumeChange,
  onVolumeUp,
  onVolumeDown,
  onLiveTagClick,

  playerMode,

  isShowResourceButton,

  isFullScreen,
  onFullScreenChange,
}) => {
  const controlPanelRef = useRef<HTMLDivElement>(null);
  const controlGridRef = useRef<HTMLDivElement>(null);

  const [isVolumeControlActive, setIsVolumeControlActive] = useState(false);
  const [isProgressBarActive, setIsProgressBarActive] = useState(false);
  const currentSlot = useSelector(selectors.channels.selectedChannelSlot);
  const trackingInfo = {
    title: mediaInfo?.title,
    channelId: assetId,
    slotId: currentSlot?.id,
  };
  const isControlsActive = isVolumeControlActive || isProgressBarActive;

  const [isMouseOrKeyInteracting, setIsMouseOrKeyInteracting] = useState(false);

  const isControlPanelInteracting = isControlsActive || isMouseOrKeyInteracting;
  const [activePanel, changeActivePanel] = useState(Panel.NO_PANEL);
  const hasTextTracksByMediaAssetId = useSelector(selectors.playback.hasTextTracksByMediaAssetId);

  const hasTextTracks = hasTextTracksByMediaAssetId(assetId);
  const showCaptions = isLive
    ? hasTextTracks && utils.slot.hasCaptions(currentSlot)
    : hasTextTracks;
  const setActivePanel = useCallback(
    panel => {
      switch (panel) {
        case Panel.CAPTIONS_PANEL:
          changeActivePanel(
            activePanel === Panel.CAPTIONS_PANEL ? Panel.NO_PANEL : Panel.CAPTIONS_PANEL,
          );
          break;
        case Panel.RESOURCE_PANEL:
          changeActivePanel(
            activePanel === Panel.RESOURCE_PANEL ? Panel.NO_PANEL : Panel.RESOURCE_PANEL,
          );
          break;
        case Panel.NO_PANEL:
        default:
          changeActivePanel(Panel.NO_PANEL);
          break;
      }
    },
    [changeActivePanel, activePanel],
  );

  useEffect(() => {
    onIsInteractingChange(isControlPanelInteracting);
  }, [isControlPanelInteracting, onIsInteractingChange]);

  useEffect(() => {
    if (activePanel === Panel.RESOURCE_PANEL) {
      onDrawerOpen?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePanel]);

  const calculateControlGirdEdges = useCallback((): DOMRect => {
    const { current: controlGrid } = controlGridRef;
    if (!controlGrid) {
      return new DOMRect();
    }

    return controlGrid.getBoundingClientRect();
  }, []);

  // For keyboard events
  const onPlayBackPress = useCallback(throttle(onPlayBack, 500), [onPlayBack]);
  const onPlayForwardPress = useCallback(throttle(onPlayForward, 500), [onPlayForward]);

  const mouseDownRef = useRef(false);
  const setIsMouseOrKeyInteractingTrueDebounce = useCallback(
    debounce(() => setIsMouseOrKeyInteracting(true), 500, { leading: true, trailing: false }),
    [],
  );
  const setIsMouseOrKeyInteractingFalseDebounce = useCallback(
    debounce(() => setIsMouseOrKeyInteracting(false), 500, { leading: false, trailing: true }),
    [],
  );
  useEffect(() => {
    return () => {
      setIsMouseOrKeyInteractingTrueDebounce.cancel();
      setIsMouseOrKeyInteractingFalseDebounce.cancel();
    };
  }, [setIsMouseOrKeyInteractingFalseDebounce, setIsMouseOrKeyInteractingTrueDebounce]);

  const handleMouseDown = useCallback(() => {
    mouseDownRef.current = true;
    setIsMouseOrKeyInteracting(true);
  }, [setIsMouseOrKeyInteracting]);

  const handleMouseUp = useCallback(() => {
    mouseDownRef.current = false;
    setIsMouseOrKeyInteracting(false);
  }, [setIsMouseOrKeyInteracting]);

  const handleMouseMove = useCallback(() => {
    setIsMouseOrKeyInteractingFalseDebounce.cancel();
    setIsMouseOrKeyInteractingTrueDebounce();
    if (mouseDownRef.current !== true) {
      setIsMouseOrKeyInteractingFalseDebounce();
    }
  }, [setIsMouseOrKeyInteractingFalseDebounce, setIsMouseOrKeyInteractingTrueDebounce]);

  const clickEventHandler = useCallback(() => {
    if (!isShow) {
      return;
    }
    onIsPlayingChange(!isPlaying);
  }, [isShow, onIsPlayingChange, isPlaying]);

  const dblclickEventHandler = useCallback(() => {
    onFullScreenChange(!isFullScreen);
  }, [isFullScreen, onFullScreenChange]);

  const activateControlPanel = useCallback(() => {
    setIsMouseOrKeyInteractingFalseDebounce.cancel();
    setIsMouseOrKeyInteracting(true);
    setIsMouseOrKeyInteractingFalseDebounce();
  }, [setIsMouseOrKeyInteractingFalseDebounce]);

  const keydownEventHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.defaultPrevented) {
        return;
      }
      switch (event.key) {
        // For automation test to show controls
        case '~':
          activateControlPanel();
          break;
        case 'f':
          activateControlPanel();
          onFullScreenChange(!isFullScreen);
          break;
        case 'm':
          activateControlPanel();
          onIsMutedChange(!isMuted);
          break;
        case 'k':
        case ' ':
          activateControlPanel();
          onIsPlayingChange(!isPlaying);
          break;
        case 'ArrowUp':
          activateControlPanel();
          onVolumeUp();
          break;
        case 'ArrowDown':
          activateControlPanel();
          onVolumeDown();
          break;
        case 'ArrowLeft':
          activateControlPanel();
          onPlayBackPress();
          break;
        case 'ArrowRight':
          activateControlPanel();
          onPlayForwardPress();
          break;
        default:
          return;
      }
      event.preventDefault();
    },
    [
      isPlaying,
      onIsPlayingChange,
      onPlayBackPress,
      onPlayForwardPress,
      isMuted,
      onIsMutedChange,
      onVolumeUp,
      onVolumeDown,
      isFullScreen,
      onFullScreenChange,
      activateControlPanel,
    ],
  );

  useEffect(() => {
    const { current: controlPanel } = controlPanelRef;

    // Key Commands events
    document.addEventListener('keydown', keydownEventHandler);

    // User Interacting events
    controlPanel?.addEventListener('mousedown', handleMouseDown, true);
    controlPanel?.addEventListener('mousemove', handleMouseMove, true);
    controlPanel?.addEventListener('mouseup', handleMouseUp, true);
    controlPanel?.addEventListener('touchstart', handleMouseDown, true);
    controlPanel?.addEventListener('touchmove', handleMouseMove, true);
    controlPanel?.addEventListener('touchend', handleMouseUp, true);

    return () => {
      // Key Commands events
      document.removeEventListener('keydown', keydownEventHandler);

      // User Interacting events
      controlPanel?.removeEventListener('mousedown', handleMouseDown, true);
      controlPanel?.removeEventListener('mousemove', handleMouseMove, true);
      controlPanel?.removeEventListener('mouseup', handleMouseUp, true);
      controlPanel?.removeEventListener('touchstart', handleMouseDown, true);
      controlPanel?.removeEventListener('touchmove', handleMouseMove, true);
      controlPanel?.removeEventListener('touchend', handleMouseUp, true);
    };
  }, [
    keydownEventHandler,
    clickEventHandler,
    dblclickEventHandler,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
  ]);

  const stopPropagation = useCallback(e => {
    e.stopPropagation();
  }, []);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div
      data-testid="video-player-control-panel"
      className={classnames(styles.controlPanel, { [styles.hide]: !isShow })}
      ref={controlPanelRef}
      onClick={clickEventHandler}
      onDoubleClick={dblclickEventHandler}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div
        className={classnames(styles.controlGridWrapper)}
        onClick={stopPropagation}
        onDoubleClick={stopPropagation}
      >
        <div className={styles.controlGrid} ref={controlGridRef}>
          <BackPanel isShow={isShowBackButton} onBackButtonClick={onBackButtonClick} />
          <ProgressPanel
            isShow={isProgressBarActive || !isVolumeControlActive}
            isLivePlayer={isLivePlayer}
            isLive={isLive}
            onActiveChange={setIsProgressBarActive}
            requestControlGridEdges={calculateControlGirdEdges}
            isSeeking={isSeeking}
            bufferRanges={bufferRanges}
            currentTime={currentTime}
            duration={duration}
            onCurrentTimeChanging={onCurrentTimeChanging}
            onCurrentTimeChanged={onCurrentTimeChanged}
          />
          <PlaybackPanel
            isLivePlayer={isLivePlayer}
            durationJump={durationJump}
            isPlaying={isPlaying}
            onIsPlayingChange={onIsPlayingChange}
            onPlayBack={onPlayBack}
            onPlayForward={onPlayForward}
          />
          <VolumePanel
            isDisabled={isProgressBarActive}
            onActiveChange={setIsVolumeControlActive}
            isShow={isShow}
            isMuted={isMuted}
            onIsMutedChange={onIsMutedChange}
            volume={volume}
            onVolumeChange={onVolumeChange}
          />
          {mediaInfo && (
            <TitlePanel
              mediaInfo={mediaInfo}
              isLivePlayer={isLivePlayer}
              isLive={isLive}
              onLiveTagClick={onLiveTagClick}
              hasPlayerPlaybackDataLoaded={hasPlayerPlaybackDataLoaded}
            />
          )}
          {playerMode !== PLAYER_MODE.linearSlot && (
            <ResourcePanel
              isShow={isShowResourceButton}
              isDisabled={isProgressBarActive || isVolumeControlActive}
              isLivePlayer={isLivePlayer}
              drawerTabs={drawerTabs}
              drawerContentList={drawerContentList}
              isDrawerContentLoading={isDrawerContentLoading}
              selectedTabId={selectedTabId}
              selectedContentId={selectedContentId}
              onDrawerTabChange={onDrawerTabChange}
              onDrawerContentSelect={onDrawerContentSelect}
              isSport={isSport}
              activePanel={activePanel}
              setActivePanel={setActivePanel}
              showPanel={activePanel === Panel.RESOURCE_PANEL}
            />
          )}
          <SettingsPanel isShow />

          {showCaptions && (
            <CaptionsPanel
              assetId={assetId}
              activePanel={activePanel}
              setActivePanel={setActivePanel}
              showPanel={activePanel === Panel.CAPTIONS_PANEL}
              onSubTitleSelection={onSubTitleSelection}
              trackingInfo={trackingInfo}
            />
          )}

          <FullscreenPanel isFullScreen={isFullScreen} onFullScreenClick={onFullScreenChange} />
        </div>
      </div>
    </div>
  );
};

export default memo(ControlPanel);
