import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { isNil, isEmpty } from 'ramda';
import Cell from '@/Layouts/containers/Grid/Cell';
import Grid from '@/Layouts/containers/Grid';
import Track from './Track';
import styles from './styles.module.scss';

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  album: PropTypes.shape({ id: PropTypes.string.isRequired, tracks: PropTypes.array.isRequired }),
  isPlayBtnEnabled: PropTypes.bool,
  currentTrack: PropTypes.string,
  playbackProgress: PropTypes.number,
  isPlaying: PropTypes.bool,
  onPlayPause: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool,
  onSeek: PropTypes.func.isRequired,
  isRewindInProgress: PropTypes.bool.isRequired,
};

const defaultProps = {
  album: null,
  isPlayBtnEnabled: false,
  currentTrack: null,
  playbackProgress: 0,
  isPlaying: false,
  isExpanded: false,
};

const Tracks: React.FC<InferProps<typeof propTypes>> = props => {
  const {
    album,
    isPlayBtnEnabled,
    onPlayPause,
    currentTrack,
    playbackProgress,
    isPlaying,
    isExpanded,
    onSeek,
    isRewindInProgress,
  } = props;

  return (
    <Grid rowGap>
      {!isNil(album) &&
        !isEmpty(album.tracks) &&
        album.tracks.slice(0, isExpanded ? album.tracks.length - 1 : 8).map(track => {
          return (
            <Cell
              key={track.spotifyUri}
              span={12}
              spanM={4}
              spanL={3}
              className={styles.soundtrack}
              testid="soundtrack-track-container"
            >
              <Track
                uri={track.spotifyUri}
                name={track.name}
                artist={(!isEmpty(track.artists) && track.artists[0].name) || ''}
                isPlayBtnEnabled={isPlayBtnEnabled}
                playbackProgress={playbackProgress}
                isPlaying={(currentTrack === track.spotifyUri && isPlaying) || false}
                isCurrent={currentTrack === track.spotifyUri || false}
                onPlayPause={onPlayPause}
                onSeek={onSeek}
                isRewindInProgress={isRewindInProgress}
              />
            </Cell>
          );
        })}
    </Grid>
  );
};
Tracks.propTypes = propTypes;
Tracks.defaultProps = defaultProps;
export default Tracks;
