import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createGlobalState } from 'react-use';

import SlotDetails from '@/Pages/TVGuide/SlotDetails/SlotDetails';
import { useModal } from 'react-modal-hook';
import { useHistory } from 'react-router';
import { LinearChannel, LinearSlot } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import { tvGuideProgramDetailsViewed } from '@/Analytics/Segment';
import { utils } from '@/Store';

interface GlobalSlotViewState {
  slot: LinearSlot;
  channel: LinearChannel;
  selectedChannelGroup?: string;
  weekday?: string;
  daysFromToday?: number;
}

export const useGlobalSlotView = createGlobalState<GlobalSlotViewState | null>();

const useSlotDetails = () => {
  const history = useHistory();
  const [isVisible, setVisible] = useState(false);
  const [, setGlobalSlotView] = useGlobalSlotView();

  const slotRef = useRef<LinearSlot>();
  const channelRef = useRef<LinearChannel>();
  const selectedChannelGroupRef = useRef<string>();
  const weekdayRef = useRef<string>();
  const daysFromTodayRef = useRef<number>();

  const [showModal, hideModal] = useModal(() => {
    const { current: slot } = slotRef;
    const { current: channel } = channelRef;
    const { current: selectedChannelGroup } = selectedChannelGroupRef;
    const { current: weekday } = weekdayRef;
    const { current: daysFromToday } = daysFromTodayRef;

    return (
      <SlotDetails
        slotStartTime={slot?.start}
        channel={channel}
        onClose={() => {
          setGlobalSlotView(null);
          setVisible(false);
        }}
        history={history}
        selectedChannelGroup={selectedChannelGroup}
        weekday={weekday}
        daysFromToday={daysFromToday}
      />
    );
  });

  const openDetails = useCallback(
    (
      slot: LinearSlot,
      channel: LinearChannel,
      selectedChannelGroup: string | undefined,
      weekday: string | undefined,
      daysFromToday: number | undefined,
    ) => {
      slotRef.current = slot;
      channelRef.current = channel;
      selectedChannelGroupRef.current = selectedChannelGroup;
      weekdayRef.current = weekday;
      daysFromTodayRef.current = daysFromToday;
      setVisible(true);

      tvGuideProgramDetailsViewed({
        content_id: slot?.programme?.id,
        content_title: utils.analytics.getSegmentSlotTitle(slot),
        content_type: utils.analytics.getContentTypeBySlot(slot),
        channel: channel.title,
        livestream: slot?.live,
        on_air: utils.slot.isSlotOnAir(slot),
      });
    },
    [],
  );

  useEffect(() => {
    if (isVisible) {
      showModal();
    } else {
      hideModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return { openDetails };
};

export default useSlotDetails;
