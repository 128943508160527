import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions, hooks } from '@/Store';
import { useParams } from 'react-router';
import { usePageHeader } from '@/Components/AppHeader/AppHeader';
import pageViewedAnalytics from '@/Analytics/Page';
import useMedia from '@/Hooks/useMedia';
import useAuth from '@/Hooks/useAuth';
import usePagination from '@/Hooks/usePagination';
import redirectToLogin from '@/Utils/redirectToLogin';
import { getImageType } from '@/Utils/VideoImage';
import { PageType } from '@/Components/HeroBanner';
import { recordMyListAdd, recordMyListRemove } from '@/Analytics/Hero';
import { DetailType } from '@/Pages/Detail/types';

const useBrandDetails = (detailType: string) => {
  const { brandId = '' } = useParams<any>();
  const isShow = detailType !== DetailType.Movie;
  const auth = useAuth();
  const dispatch = useDispatch();
  const { railPageSize } = usePagination();
  const {
    isLoading,
    brand,
    isSport,
    defaultSelectedSeason,
    highlights,
    onHighlightsPaginate: defaultOnHighlightsPaginate,
  } = hooks.useBrandDetailsData({ brandId, isShow, initialRailSize: railPageSize.current });
  const pageTitle = brand?.title;
  const description = brand?.synopsis || '';

  usePageHeader({
    title: pageTitle,
    description,
  });

  useEffect(() => {
    if (pageTitle) {
      pageViewedAnalytics('Content Details');
    }
  }, [pageTitle]);

  const { isMediaS } = useMedia();
  const imageType = getImageType(PageType.Home, isMediaS);
  const image = brand?.[imageType]?.uri || '';

  useEffect(() => {
    if (brandId) {
      dispatch(actions.spotify.fetchBrandAlbums(brandId, isShow));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandId, isShow, dispatch]);

  const onMyListToggle = useCallback(() => {
    if (auth?.isAuthenticated) {
      if (brand) {
        if (brand.onMyList) {
          dispatch(actions.titles.removeFromMyList(brandId));
          recordMyListRemove(brandId, brand.title);
        } else {
          dispatch(actions.titles.addToMyList(brandId));
          recordMyListAdd(brandId, brand.title);
        }
      }
    } else {
      redirectToLogin();
    }
  }, [auth, brand, brandId, dispatch]);

  const onHighlightsPaginate = useCallback(() => {
    defaultOnHighlightsPaginate(railPageSize.current);
  }, [defaultOnHighlightsPaginate, railPageSize]);

  return {
    brand,
    isSport,
    selectedSeason: defaultSelectedSeason,
    image,
    imageType,
    brandId,
    brandIsLoading: isLoading,
    onMyListToggle,
    highlights,
    onHighlightsPaginate,
  };
};

export default useBrandDetails;
