import React from 'react';

import Truncate from 'react-truncate';
import Cast from '@/Components/Cast';
import { Maybe, Character } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import useMedia from '@/Hooks/useMedia';
import classnames from 'classnames';
import { isEmpty, isNil } from 'ramda';

interface Props {
  synopsis: string | null | undefined;
  cast: Maybe<Character>[] | undefined;
  className?: string;
}

const Synopsis: React.FC<Props> = props => {
  const { isMediaM } = useMedia();
  const { synopsis, cast, className } = props;
  const truncLines = isMediaM ? 5 : 6;
  return (
    <div className={classnames(className)} data-testid="synopsis">
      <Truncate lines={truncLines} trimWhitespace>
        {synopsis}
      </Truncate>
      {!isNil(cast) && !isEmpty(cast) && <Cast cast={cast} />}
    </div>
  );
};

export default Synopsis;
