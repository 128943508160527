import React, { FC } from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  percent?: number;
  dataTestIdOuter?: string;
  dataTestIdInner?: string;
}

const Progress: FC<Props> = ({ className, percent, dataTestIdOuter, dataTestIdInner }) => {
  return typeof percent === 'number' && percent > 0 ? (
    <span className={classnames(styles.progressBar, className)} data-testid={dataTestIdOuter}>
      <span className={classnames(styles.progressBarOuter)}>
        <span
          data-testid={dataTestIdInner}
          className={classnames(styles.progressBarInner)}
          style={{ width: `${percent}%` }}
        />
      </span>
    </span>
  ) : null;
};

export default Progress;
