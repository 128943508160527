import React from 'react';
import classnames from 'classnames';

import Select, { components, StyleConfig } from 'react-select';
import variables from 'Styles/variables';
import { isNil, mergeDeepRight } from 'ramda';
import styles from './styles.module.scss';

export interface DropDownItem {
  label: string;
  value: string;
}

export interface DropDownProps {
  selectedValue?: string;
  options?: DropDownItem[];
  onChange?: (selectedItem: DropDownItem) => void;
  fontColor?: string;
  backgroundColor?: string;
  focusBackgroundColor?: string;
  className?: string;
  testId?: string;
  customStyles?: StyleConfig;
  customComponents?: components;
}

const DropDown: React.FC<DropDownProps> = props => {
  const {
    testId,
    className,
    selectedValue,
    options,
    onChange,
    fontColor = 'white',
    backgroundColor = variables.colorPaletteStorm90,
    focusBackgroundColor = variables.colorPalettePurpleDark,
    customStyles,
    customComponents,
  } = props;

  const defaultStyles = {
    menu: provided => ({
      ...provided,
      width: '100%',
      boxShadow: ' 0px 4px 10px rgba(0, 0, 0, 0.25)',
      borderRadius: '6px',
      backgroundColor,
      color: fontColor,
      marginTop: '0px',
      zIndex: 99,
      padding: '0px',
    }),

    menuList: provided => ({
      ...provided,
      color: fontColor,
      height: 'auto',
    }),

    control: provided => ({
      ...provided,
      border: '1px solid rgba(255, 255, 255, 0.6)',
      borderRadius: '6px',
      backgroundColor,
      '&:hover': { borderColor: 'rgba(255, 255, 255, 0.6)' },
      boxShadow: 'none',
    }),

    singleValue: provided => ({
      ...provided,
      color: fontColor,
    }),

    dropdownIndicator: provided => ({
      ...provided,
      color: fontColor,
    }),

    indicatorSeparator: provided => ({
      ...provided,
      visibility: 'hidden',
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused && focusBackgroundColor,
      color: fontColor,
      height: '38px',
    }),
  };

  const dropDownStyle = customStyles ? mergeDeepRight(defaultStyles, customStyles) : defaultStyles;

  const selectedOption = options?.find(({ value }) => selectedValue === value);

  const dropDownOptions = customStyles ? options?.filter(i => selectedValue !== i.value) : options;

  const DropdownIndicator = indicatorProps => {
    return (
      components.DropdownIndicator && (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <components.DropdownIndicator {...indicatorProps}>
          {/* eslint-disable-next-line react/destructuring-assignment */}
          {indicatorProps.selectProps.menuIsOpen ? (
            <components.DownChevron transform="rotate(180)" />
          ) : (
            <components.DownChevron />
          )}
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <Select
      data-testid={testId}
      components={isNil(customComponents) ? { DropdownIndicator } : customComponents}
      styles={dropDownStyle}
      className={classnames(styles.dropDown, className)}
      options={dropDownOptions}
      onChange={onChange}
      isSearchable={false}
      value={selectedOption}
      classNamePrefix="react-select"
    />
  );
};

export default DropDown;
