import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import pluralize from 'pluralize';
import { isEmpty, isNil } from 'ramda';
import dayjs from 'dayjs';
import { convertDuration } from '@/Utils/DurationFormatter';
import getTimeRange from '@/Utils/TimeFormat';
import { formatEndedAtText, formatStartedAtText } from '@/Utils/DateTime';
import { formatRating } from '@/Utils/Rating';
import TileSize from '@/Components/Rail/Tiles/TileSize';
import VideoImage from '@/Components/VideoImage';
import Progress from '@/Components/Progress';
import Divider from '@/Components/Divider';
import { getWatchPath, getDetailPathByType } from '@/Utils/PageLocation';
import calculateProgress from '@/Utils/CalculateProgress';

import {
  GroupLayout,
  WatchProgress,
  Image,
  LinearSlot,
  Episode,
  Rating,
} from '@skytvnz/sky-app-store/lib/types/graph-ql';

import ContentType from '@/Utils/ContentTypes';
import { getGenres } from '@/Utils/Genres';
import { getVideoImageType } from '@/Utils/VideoImage';
import { utils } from '@/Store';
import { ReactComponent as InstantReplay } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_instant_replay.color.svg';
import styles from './styles.module.scss';

export interface Props {
  image?: Image;
  imageType?: string;
  id: string;
  episode?: Episode;
  title?: string;
  genres?: string[];
  year?: number;
  numberOfSeasons?: number;
  duration?: string;
  watchProgress?: WatchProgress;
  type: ContentType;
  slot?: LinearSlot;
  defaultEpisode?: Episode;
  rating?: Rating;

  layout?: GroupLayout;
  size?: TileSize;

  linkSearchQuery?: {};
  onClick?: () => void;
}

// BrandedProps are supposed to be only used by the component overrides unde 'src/brandedModules'
interface BrandedProps extends Props {
  showTextOnHover: boolean;
}
export const DIVIDER = ' • ';
const MediaTile: React.FC<BrandedProps> = props => {
  const {
    image,
    id,
    episode,
    showTextOnHover,
    title,
    genres,
    year = 0,
    numberOfSeasons = 0,
    duration,
    watchProgress,
    type,
    slot,
    defaultEpisode,
    rating,
    layout = GroupLayout.RailLandscape,
    size = TileSize.Small,
    linkSearchQuery,
    onClick = () => undefined,
  } = props;
  const history = useHistory();

  const isContinueWatchingTile = layout === GroupLayout.ContinueWatching;
  const isChannelTile = type === ContentType.LinearChannel;
  const isEpisodeTile = type === ContentType.Episode;
  const isLinearSlotTile = type === ContentType.LinearSlot;
  const isCollectionTile = type === ContentType.Collection || type === ContentType.BrowseCollection;
  const imageType = getVideoImageType(layout);
  const formattedDuration = convertDuration(duration);
  const contentOfferDate = episode?.schedule?.dateRange?.start;
  const formatcontentOfferDate = dayjs(contentOfferDate).format('ddd D MMM');

  let link = '';
  let watchPercent = 0;
  if (isLinearSlotTile) {
    // Linear slot click is handled from onTileClick
  } else if (isContinueWatchingTile) {
    link = getWatchPath(history, id, defaultEpisode?.id, 'Continue Watching');
    if (type === 'Movie') {
      watchPercent = calculateProgress(duration, watchProgress);
    } else {
      watchPercent = calculateProgress(defaultEpisode?.duration, defaultEpisode?.watchProgress);
    }
  } else {
    link = getDetailPathByType(type, id, linkSearchQuery, episode?.id);
  }

  const HoverMeta = useCallback(() => {
    let mediaInfo: any = null;
    const showRating = isContinueWatchingTile && !!rating;
    if (type === 'Movie') {
      mediaInfo = (
        <>
          {year > 0 && <span data-testid="media-tile-year">{year}</span>}
          {formattedDuration && (
            <>
              {year > 0 && <Divider />}
              <span data-testid="media-tile-duration">{formattedDuration}</span>
            </>
          )}
        </>
      );
    } else if (isContinueWatchingTile) {
      const episodeNumber = utils.title.getSeasonEpisodeNumber(
        defaultEpisode?.season?.number,
        defaultEpisode?.number,
      );
      mediaInfo = episodeNumber ? (
        <span data-testid="media-tile-episode">{episodeNumber}</span>
      ) : null;
    } else if (numberOfSeasons > 0) {
      mediaInfo = (
        <span data-testid="media-tile-seasons">{pluralize('Season', numberOfSeasons, true)}</span>
      );
    }
    return (
      <div
        className={classnames(styles.posterMeta, {
          [styles.posterMetaWithProgressBar]: !!watchPercent,
        })}
      >
        <div className={styles.detailsTitle}>
          <h2 data-testid="media-tile-hover-title">{title}</h2>
        </div>
        <div className={styles.detailsAttributes}>
          {!isNil(genres) && !isEmpty(genres) && (
            <span className={styles.detailsGenres} data-testid="media-tile-genres">
              {getGenres(genres, 3)}
            </span>
          )}
          <div className={styles.detailsNotGenres}>
            {(year > 0 || numberOfSeasons > 0 || isContinueWatchingTile) && (
              <>
                {!isNil(genres) && !isEmpty(genres) && mediaInfo && <Divider />}
                {mediaInfo}
              </>
            )}
            {showRating && (
              <>
                <Divider />
                <span data-testid="hero-rating">{formatRating(rating)}</span>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }, [
    year,
    title,
    genres,
    numberOfSeasons,
    defaultEpisode,
    type,
    formattedDuration,
    isContinueWatchingTile,
    rating,
    watchPercent,
  ]);
  const getSubTitle = (episodeData: any) => {
    if (!episodeData) return null;

    const details: any = [];

    if (isLinearSlotTile) {
      details.push(formatStartedAtText(dayjs(episodeData?.start)));
      if (rating) {
        details.push(rating);
      }
    } else if (episodeData.liveEvent) {
      details.push(formatEndedAtText(dayjs(episode?.liveEvent?.endTime)));
    } else if (formatcontentOfferDate) {
      details.push(formatcontentOfferDate);
      if (formattedDuration) {
        details.push(formattedDuration);
      }
    }
    if (episodeData.rating) {
      details.push(formatRating(episodeData.rating));
    }

    return details.join(DIVIDER);
  };

  return (
    <div className={classnames(styles[`tile${size === TileSize.Large ? 'L' : 'S'}`])}>
      <Link
        to={link}
        className={styles.link}
        data-testid="media-tile-button"
        onClick={() => onClick?.()}
      >
        <div className={styles.poster} data-testid="media-tile-poster">
          <VideoImage
            className={styles.tileImage}
            lazyload
            src={image?.uri}
            overlayImage={image?.overlayImages?.[0]}
            type={imageType}
            whiteBackground={isChannelTile}
            alt={`The poster of "${title}"`}
            data-testid="media-tile-img"
            icon={isEpisodeTile && episode?.asset?.instantReplay && InstantReplay}
          />
          {!!watchPercent && (
            <Progress
              className={styles.progressBar}
              percent={watchPercent}
              dataTestIdOuter={`continue-watching-progress-bar-outer-${id}`}
              dataTestIdInner={`continue-watching-progress-bar-inner-${id}`}
            />
          )}
          {!isChannelTile && !isCollectionTile && showTextOnHover && <HoverMeta />}
        </div>
        <h3
          className={classnames(styles.meta, {
            [styles.extraInfo]: isChannelTile || isEpisodeTile || isLinearSlotTile,
          })}
          title={title}
          data-testid="media-tile-title"
        >
          <span>{slot?.programme?.title || title}</span>
          {isChannelTile && (
            <span className={styles.subTitle}>{getTimeRange(slot?.start, slot?.end)}</span>
          )}
          {(isLinearSlotTile || isEpisodeTile) && (
            <span className={styles.subTitle}>{getSubTitle(episode || slot)}</span>
          )}
        </h3>
      </Link>
    </div>
  );
};

export default MediaTile;
