import React from 'react';

import TileSynopsis from '@/Pages/Detail/Seasons/Episodes/TileSynopsis';
import { Maybe, Episode } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import styles from '@/Pages/Detail/Tiles/styles.module.scss';
import Availability from '@/Pages/Detail/Seasons/Episodes/Availability';
import EpisodeSubtitle from '@/Pages/Detail/Tiles/EpisodeSubtitle';
import EpisodeTileImage from '@/Pages/Detail/Tiles/EpisodeTileImage';
import { utils } from '@/Store';

interface Props {
  brandId: string;
  episode: Maybe<Episode>;
  isSport: boolean;
}

const RecentEpisodeTile: React.FC<Props> = props => {
  const { episode, brandId, isSport } = props;

  return (
    <div className={styles.tile} data-testid={`episode-container-episode-nr-${episode?.number}`}>
      <EpisodeTileImage episode={episode} brandId={brandId} playbackOrigin="Content Recent" />
      <p className={styles.tileTitle} data-testid={`episode-title-nr-${episode?.number}`}>
        {!isSport &&
          `${utils.title.getSeasonEpisodeNumber(episode?.season?.number, episode?.number)} • `}
        {`${episode?.title}`}
      </p>
      <EpisodeSubtitle episode={episode} />
      <Availability id={episode?.number} endDate={episode?.mySchedule?.dateRange?.end} />
      <TileSynopsis text={episode?.synopsis} id={episode?.number} />
    </div>
  );
};

export default RecentEpisodeTile;
