import React from 'react';
import { isNil } from 'ramda';
import { withTransaction } from '@elastic/apm-rum-react';

import { BrandType } from '@skytvnz/sky-app-store/lib/types/enums/BrandType';

import { PageType } from '@/Components/HeroBanner';
import Preloader from '@/Layouts/containers/Preloader';
import MovieHeroBanner from '@/Components/HeroBanner/MovieHeroBanner';
import useBrandDetails from '@/Hooks/useBrandDetails';
import Soundtrack from '@/Pages/Detail/Soundtrack';
import WhatsOnTv from '@/Pages/Detail/WhatsOnTv';
import useMedia from '@/Hooks/useMedia';
import ControllingChannel from '@/Components/HeroBanner/ControllingChannel';
import ErrorMessage from '@/Components/Message';
import Synopsis from '@/Components/Synopsis';
import synopsisStyles from '@/Components/Synopsis/styles.module.scss';
import Content from '@/Layouts/containers/Content';

import styles from './styles.module.scss';

const MovieDetail: React.FC = () => {
  const { brand: movie, image, imageType, onMyListToggle, brandIsLoading } = useBrandDetails(
    'MovieDetail',
  );
  const { isMediaM } = useMedia();
  return (
    <Preloader isLoading={isNil(movie) && brandIsLoading}>
      {!isNil(movie) && movie.__typename === BrandType.Movie ? (
        <>
          <MovieHeroBanner
            className={styles.heroBanner}
            movie={movie}
            image={image}
            imageType={imageType}
            pageType={PageType.Detail}
            onMyListToggle={onMyListToggle}
          />
          {!isMediaM && (
            <Content className={synopsisStyles.synopsis}>
              <Synopsis synopsis={movie.synopsis} cast={movie.characters} />
            </Content>
          )}
          {/* <Trailers />*/}
          {!isMediaM && <ControllingChannel channels={movie.brands} />}
          <WhatsOnTv brand={movie} />
          <Soundtrack selectedAlbumId={movie.soundtrack?.id} brandId={movie.id} />
          {/* <Similar />*/}
        </>
      ) : (
        <ErrorMessage />
      )}
    </Preloader>
  );
};

export default withTransaction('MovieDetail', 'component')(MovieDetail);
