/* eslint-disable no-console */
import { PlaybackDevice } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import * as SentryBrowser from '@sentry/browser';
import {
  DRMFormat,
  DRM_TYPE,
  isBrowserWithWidevineSupport,
  VideoRobustnessEMELevels,
} from '@/Components/VideoPlayer/DRM';
import Fingerprint2 from 'fingerprintjs2';
import { F, T } from 'ramda';
import { UAParser } from 'ua-parser-js';
import { USER_AGENT } from './Browser';

export interface BrowserDeviceInfo {
  id: string;
  deviceFamily: string;
  model: string;
}

const deviceLocalStorageKey = 'skyDeviceInfo-v2';

export const getMinusVersionUserAgent = (ua?: string) => {
  const parser = new UAParser(ua || USER_AGENT); // https://github.com/faisalman/ua-parser-js
  return `${parser.getOS().name}|${parser.getBrowser().name}`;
};

const options = {
  preprocessor: (key, value) => {
    if (key === 'userAgent') {
      return getMinusVersionUserAgent(value);
    }
    return value;
  },
  excludes: {
    hardwareConcurrency: true,
    pixelRatio: true,
    sessionStorage: true,
    localStorage: true,
    indexedDb: true,
    addBehavior: true,
    openDatabase: true,
    doNotTrack: true,
    plugins: true,
    adBlock: true,
    fontsFlash: true,
    audio: true,
    enumerateDevices: true,
    screenResolution: true,
  },
};

export const getDeviceFingerPrint = async (): Promise<BrowserDeviceInfo> => {
  const components = await Fingerprint2.getPromise(options);

  const values = components.map(component => component.value);

  const userAgent = components.find(component => {
    return component.key === 'userAgent';
  })?.value;

  const hashedValue = Fingerprint2.x64hash128(values.join(''), 31);
  const deviceInfo: BrowserDeviceInfo = {
    id: hashedValue,
    deviceFamily: 'Browser',
    model: userAgent,
  };
  return deviceInfo;
};

const getDeviceInfoFromLocalStorage = () => localStorage.getItem(deviceLocalStorageKey);

export const generateDeviceFingerPrint = async (): Promise<void> => {
  const browserDeviceInfo = getDeviceInfoFromLocalStorage();
  if (!browserDeviceInfo) {
    try {
      const deviceInfo = await getDeviceFingerPrint();
      if (deviceInfo) {
        localStorage.setItem(deviceLocalStorageKey, JSON.stringify(deviceInfo));
      }
    } catch (e) {
      console.error('Failed to load device fingerprint', e);
      SentryBrowser.captureException(e);
    }
  }
};

export const getDeviceInfo = (): BrowserDeviceInfo => {
  let deviceInfo;
  const browserDeviceInfo = getDeviceInfoFromLocalStorage();

  if (browserDeviceInfo) {
    try {
      deviceInfo = JSON.parse(browserDeviceInfo as string);
      return deviceInfo;
    } catch (e) {
      console.error('Failed to parse device info', e);
      SentryBrowser.captureException(e);
    }
  }
  return deviceInfo;
};

export const getDeviceId = () => getDeviceInfo()?.id || getMinusVersionUserAgent();

const drmCapabilitiesConfig = robustness => {
  return [
    {
      videoCapabilities: [
        {
          // This video codec is supported by all browsers - https://videojs.com/html5-video-support/
          contentType: 'video/mp4;codecs="avc1.42E01E"',
          robustness,
        },
      ],
    },
  ];
};

/**
 * Event though  we derive a browser has Hardware DRM level support (HW_SECURE_DECODE or HW_SECURE_ALL)
 * We need to ensure we are able to create mediaKeys with the given keySystem. Only then we can guarantee
 * that video will be decrypted and decoded at Hardware Level
 *
 * Refer: https://websites.fraunhofer.de/video-dev/enabling-hardware-drm-on-android-chrome-using-the-encrypted-media-extensions/
 */
const isWidevineHardwareDRMSupported = emeLevel => {
  return window.navigator
    .requestMediaKeySystemAccess(DRMFormat.Widevine, drmCapabilitiesConfig(emeLevel))
    .then(keySystemAccess => {
      return keySystemAccess.createMediaKeys();
    })
    .then(() => T)
    .catch(() => F);
};

/**
 *  References to derive DRM Level for Widevine
 *  https://www.radiantmediaplayer.com/blog/detecting-eme-cdm-browser.html
 *  https://developers.google.com/web/updates/2017/09/chrome-62-media-updates
 *  https://websites.fraunhofer.de/video-dev/enabling-hardware-drm-on-android-chrome-using-the-encrypted-media-extensions/
 *
 */

const getSupportedWindevineDrmLevel = async () => {
  try {
    const hasEmeSupport =
      'MediaKeys' in window || 'WebKitMediaKeys' in window || 'MSMediaKeys' in window;

    const hasRequestMediaKeySystemAccess = 'requestMediaKeySystemAccess' in window.navigator;

    if (hasEmeSupport && hasRequestMediaKeySystemAccess) {
      const supportedDrmLevels = Object.keys(VideoRobustnessEMELevels).map(emeLevel => {
        return window.navigator
          .requestMediaKeySystemAccess(DRMFormat.Widevine, drmCapabilitiesConfig(emeLevel))
          .then(() => emeLevel)
          .catch(() => undefined);
      });
      const result = await Promise.all(supportedDrmLevels).then(results => results.filter(Boolean));

      // Check for topmost security (Widevine L1) if available
      if (
        result?.includes(VideoRobustnessEMELevels.HW_SECURE_ALL) &&
        isWidevineHardwareDRMSupported(VideoRobustnessEMELevels.HW_SECURE_ALL)
      ) {
        return VideoRobustnessEMELevels.HW_SECURE_ALL;
      }

      if (
        result?.includes(VideoRobustnessEMELevels.HW_SECURE_DECODE) &&
        isWidevineHardwareDRMSupported(VideoRobustnessEMELevels.HW_SECURE_DECODE)
      ) {
        return VideoRobustnessEMELevels.HW_SECURE_DECODE;
      }
    }
  } catch (e) {
    // Ignore errors.
  }
  // For all other cases, just return L3 equivalent
  return VideoRobustnessEMELevels.SW_SECURE_DECODE;
};

export const getPlaybackDeviceInfo = async (): Promise<PlaybackDevice> => {
  let drmLevel;

  if (isBrowserWithWidevineSupport()) {
    drmLevel = await getSupportedWindevineDrmLevel();
  }

  // TODO include support for Playready in future. See https://skynz.atlassian.net/browse/SAAA-4680

  const parser = new UAParser(USER_AGENT);
  return {
    platform: parser.getOS().name ?? 'UNKNOWN',
    osVersion: parser.getOS().version ?? '0',
    drmType: DRM_TYPE,
    drmLevel,
  };
};
