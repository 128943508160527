import { useCallback, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';

import RoutePath from '@/Routes/RoutePath';
import { actions, selectors } from '@/Store';
import { persistor } from '@/Store/configureStore';
import auth0Config from '@/Config/Auth0Config';
import { recordSignOut } from '@/Analytics/Auth';
import redirectToLogin from '@/Utils/redirectToLogin';

const getTokenSilentlyOptions = {
  audience: auth0Config.audience,
  ignoreCache: false,
};

export const getUserContext = userInfo => userInfo?.[auth0Config.contextClaimNameSpace];
export const getUserProfiles = userInfo => userInfo?.[auth0Config.profileClaimNameSpace];
export const getUserActiveProfile = userInfo => getUserProfiles(userInfo)?.[0]; // currently we use the first as active
export const getSpotifyAccessToken = userInfo =>
  userInfo?.[auth0Config.spotifyClaimNameSpace]?.access_token;

const useAuth = () => {
  const dispatch = useDispatch();
  const {
    logout,
    buildAuthorizeUrl,
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    user,
    error,
  } = useAuth0();
  const authConfigFromBE = useSelector(selectors.auth.config);
  const authConfig: any = useMemo(
    () => (authConfigFromBE ? { ...auth0Config, ...authConfigFromBE } : auth0Config),
    [authConfigFromBE],
  );

  const loadToken = useCallback(
    async (ignoreCache = false) => {
      let token;
      try {
        token = await getAccessTokenSilently({ ...getTokenSilentlyOptions, ignoreCache });
        // Set token in the store, to be able to retrive account number in the getDecodeConfig selector
        dispatch(actions.auth.setAccessToken(token));
      } catch (err) {
        redirectToLogin();
        throw err;
      }

      return token;
    },
    [getAccessTokenSilently, dispatch],
  );

  const login = useCallback(
    async (returnTo = RoutePath.home, errorDescription?) => {
      const loginUrl = await buildAuthorizeUrl({
        appState: { returnTo },
        connection: authConfig?.connectionId,
        error_description: errorDescription,
      });
      window.location.replace(loginUrl);
    },
    [buildAuthorizeUrl, authConfig],
  );

  const register = useCallback(
    async (returnTo = RoutePath.home) => {
      const registerUrl = await buildAuthorizeUrl({
        appState: { returnTo },
        screen_hint: 'signup',
        connection: authConfig?.connectionId,
      });
      window.location.replace(registerUrl);
    },
    [buildAuthorizeUrl, authConfig],
  );

  const signOut = useCallback(
    async (returnTo: string = RoutePath.home, silently = false) => {
      if (!silently) {
        recordSignOut(user?.email);
      }
      persistor.purge();
      logout({ returnTo: `${window.location.origin}${returnTo}` });
    },
    [logout, user],
  );

  return {
    loadToken,
    login,
    register,
    signOut,
    isAuthenticated,
    authConfig,
    isLoading,
    error,
  };
};

export default useAuth;
