import React from 'react';
import Fade from 'react-reveal/Fade';
import { isEmpty, isNil } from 'ramda';

import { Collection, GroupLayout } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import { ImageAssetType } from '@skytvnz/sky-app-store/lib/types/enums/ImageAssetType';

import ContentType from '@/Utils/ContentTypes';
import contentCardClickedAnalytics from '@/Analytics/ContentCard';
import MediaTile from 'ComponentOverrides/Rail/Tiles/MediaTile';
import { formatGenres } from '@/Utils/Genres';
import formatLayout from '@/Utils/LayoutFormatter';

import styles from './styles.module.scss';

export const REFER_COLLECTION_NAME_SEARCH_KEY = 'refer-name';
export const REFER_COLLECTION_ID_SEARCH_KEY = 'refer-id';

interface Props {
  collection: Collection;
  referCollectionId?: string;
  referCollectionName?: string;
  type: string;
}

const CollectionTable: React.FC<Props> = ({
  collection,
  referCollectionId,
  referCollectionName,
  type,
}) => {
  const content = collection?.contentPage?.content;

  return (
    <div className={styles.collectionTable} data-testid="collection-table">
      {!isNil(content) &&
        !isEmpty(content) &&
        content
          .filter(asset => !isNil(asset))
          .map((asset: any, index) => {
            let searchQuery: any = null;
            const assetType = asset?.__typename;
            const isCollection = assetType === ContentType.Collection;

            // If come to collection again, need to pass current collection as the next page's refer
            if (isCollection && referCollectionId && referCollectionName) {
              searchQuery = {
                [REFER_COLLECTION_ID_SEARCH_KEY]: referCollectionId,
                [REFER_COLLECTION_NAME_SEARCH_KEY]: referCollectionName,
              };
            }

            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`${asset?.id}-${index}`} className={styles.tileContainer}>
                <Fade>
                  <MediaTile
                    data-testid={`collection-media-tile-${index}`}
                    image={asset?.[ImageAssetType.ContentTileHorizontal] || null}
                    id={asset?.id}
                    title={asset?.title}
                    genres={formatGenres(asset?.primaryGenres)}
                    year={asset?.year}
                    numberOfSeasons={asset?.numberOfSeasons}
                    slot={asset?.slots?.[0] || null}
                    duration={asset?.duration}
                    layout={GroupLayout.RailLandscape}
                    linkSearchQuery={searchQuery}
                    type={isCollection ? ContentType.BrowseCollection : assetType}
                    onClick={() => {
                      contentCardClickedAnalytics(
                        asset?.id as string,
                        asset?.title as string,
                        type === ContentType.BrowseCollection ? 'Browse' : 'Collection',
                        index + 1,
                        collection.title,
                        assetType,
                        content?.filter(x => !isNil(x)).map(x => x?.id),
                        formatLayout(GroupLayout.Grid),
                      );
                    }}
                  />
                </Fade>
              </div>
            );
          })}
    </div>
  );
};

export default CollectionTable;
