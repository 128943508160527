import React, { FC, useCallback, useRef, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ImageAssetType } from '@skytvnz/sky-app-store/lib/types/enums/ImageAssetType';
import { Movie, Episode } from '@skytvnz/sky-app-store/lib/types/graph-ql';

import { actions, selectors, utils } from '@/Store';
import { VideoEventType } from '@/Analytics/Video';
import { convertDuration, convertDurationToSeconds } from '@/Utils/DurationFormatter';
import calculateProgress from '@/Utils/CalculateProgress';
import getPlayStartTime from '@/Utils/GetPlayStartTime';
import usePersistCallback from '@/Hooks/usePersistCallback';
import { getVideoImg } from '@/Utils/VideoImage';
import useCurrent from '@/Hooks/useCurrent';
import { useVodRequiredSubscriptions } from '@/Hooks/useSubscription';
import { contentDrawerOpened } from '@/Analytics/Segment';
import { hasPinExpired } from '@skytvnz/sky-app-store/lib/modules/parentalPin/selectors';
import auth0Config from '@/Config/Auth0Config';

import { MediaInfo } from './VideoTypes';
import { YouboraPlayerConfig } from './Core/YouboraPlayerConfig';
import { PlayerController } from './Core/PlayerTypes';
import useVodData from './DataLoader/useVodData';
import VideoPlayer, { VideoProps } from './VideoPlayer';
import PlayerContainer from './PlayerContainer';

export const PLAYER_HEARTBEAT_INTERVAL =
  (process.env.PLAYBACK_HEARTBEAT_INTERVAL_SECONDS
    ? parseInt(process.env.PLAYBACK_HEARTBEAT_INTERVAL_SECONDS, 10)
    : 10) * 1000; // in milliseconds

interface Props extends VideoProps {
  brandId: string;
  isShow: boolean;
  episodeId?: string;
  playbackOrigin?: string;
  playerReferrer?: string;
  onVideoMetaLoad?: (episode: Episode | Movie) => void;
}

const VodVideoPlayer: FC<Props> = props => {
  const {
    // self props
    brandId,
    episodeId,
    playbackOrigin,
    playerReferrer,
    isShow,
    onVideoMetaLoad,
    ...otherProps
  } = props;

  const dispatch = useDispatch();
  const accountNumber = useSelector(selectors.auth.getDecodeConfig)(
    auth0Config.accountNumberClaimNameSpace,
  );
  const pinExpiry = useSelector(selectors.parentalPin.pinExpiry);

  const [startTimeState, setStartTimeState] = useState(0);
  const [episodeIdState, setEpisodeIdState] = useState(episodeId);
  const [isParentalRestrictionResolved, setParentalRestrictionResolved] = useState(
    !hasPinExpired(pinExpiry),
  );

  const {
    episode,
    brand,
    seasons,
    selectedSeason,
    mediaAssetId,
    playbackMeta,
    isPlaybackError,
    isParentalForbidden,
    isConcurrencyLimitReached,
    playbackPosition,
    onPlaybackRetry,
    clearVodPlayback,
    selectSeason,
    captureVideoContentEvent,
  } = useVodData(brandId, episodeIdState, isShow, isParentalRestrictionResolved, playbackOrigin);

  const currentEpisode = useCurrent(episode);
  const currentBrand = useCurrent(brand);
  const currentMediaAssetId = useCurrent(mediaAssetId);
  const currentEpisodeId = currentEpisode.current?.id;

  const [currentPlaybackPosition, setCurrentPlaybackPosition] = useState(0);
  const [isPlayingFromDrawer, setIsPlayingFromDrawer] = useState(false);

  const brandImage =
    brand &&
    getVideoImg(ImageAssetType.HeroLandingWide, brand[ImageAssetType.HeroLandingWide]?.uri);

  const isSport = utils.title.isSport(currentBrand.current);

  const contentClassification = isShow
    ? currentEpisode.current?.rating?.classification
    : currentBrand.current?.rating?.classification;

  useEffect(() => {
    setStartTimeState(playbackPosition ? getPlayStartTime(playbackPosition) : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaAssetId, playbackPosition]);

  const [mediaInfo, setMediaInfo] = useState<MediaInfo>({ title: '' });

  useEffect(() => {
    if (brand) {
      setMediaInfo({
        title: utils.title.getVodTitle(brand, episode) || '',
        duration: isShow
          ? convertDurationToSeconds(episode?.duration)
          : convertDurationToSeconds((brand as Movie).duration),
      });
    }
  }, [brand, episode, isShow, isSport]);

  const playbackPositionLastReportTimeRef = useRef<number>();

  const savePlaybackPosition = usePersistCallback((player: PlayerController) => {
    if (!player) {
      return;
    }
    const playbackCurrentTime = Math.floor(player.currentTime());
    if (!(mediaAssetId && playbackCurrentTime)) {
      return;
    }
    dispatch(actions.titles.savePlaybackPosition(mediaAssetId, playbackCurrentTime));
  });

  const onPlaybackPositionChange = usePersistCallback((player: PlayerController) => {
    savePlaybackPosition(player);
  });

  const onVideoFirstPlay = usePersistCallback((player: PlayerController) => {
    savePlaybackPosition(player);
    if (!isPlayingFromDrawer) {
      captureVideoContentEvent(VideoEventType.Started, player.currentTime());
    }
  });

  const onVideoPlay = usePersistCallback(() => {
    captureVideoContentEvent(
      VideoEventType.Started,
      convertDurationToSeconds(playbackPosition?.position),
      isPlayingFromDrawer ? 'Player' : undefined,
    );
  });

  const onVideoPause = usePersistCallback((player: PlayerController) => {
    savePlaybackPosition(player);
    captureVideoContentEvent(
      VideoEventType.Stopped,
      player.currentTime(),
      isPlayingFromDrawer ? 'Player' : undefined,
    );
  });

  const onVideoEnd = usePersistCallback((player: PlayerController, isPlaying: boolean) => {
    if (isConcurrencyLimitReached || !playbackMeta) {
      return;
    }
    if (isPlaying) {
      captureVideoContentEvent(
        VideoEventType.Stopped,
        player.currentTime(),
        isPlayingFromDrawer ? 'Player' : undefined,
      );
    }
    savePlaybackPosition(player);
  });

  const sendPlaybackHeartBeat = usePersistCallback((player: PlayerController) => {
    const currentSystemTime = Date.now();

    const { current: lastReportTime } = playbackPositionLastReportTimeRef;
    if (
      lastReportTime === undefined ||
      currentSystemTime - lastReportTime >= PLAYER_HEARTBEAT_INTERVAL
    ) {
      if (lastReportTime !== undefined) {
        savePlaybackPosition(player);
      }

      playbackPositionLastReportTimeRef.current = currentSystemTime;
    }
  });

  const onTimeUpdate = usePersistCallback((player: PlayerController) => {
    setCurrentPlaybackPosition(player.currentTime());
    sendPlaybackHeartBeat(player);
  });

  const drawerTabs = useMemo(() => {
    return seasons?.map(_season => ({
      id: _season?.id || '',
      title: `Season ${_season?.number}`,
    }));
  }, [seasons]);

  const drawerContentList = useMemo(() => {
    return selectedSeason?.episodes?.map(_episode => {
      if (_episode) {
        const {
          duration,
          id,
          image,
          title,
          number,
          rating,
          synopsis,
          watchProgress,
          mySchedule,
          schedule,
        } = _episode;

        return {
          id,
          image: image.uri,
          progress: watchProgress ? calculateProgress(duration, watchProgress) : 0,
          title: isSport ? title : `${number}. ${title}`,
          subTitle: convertDuration(duration) || '',
          rating,
          synopsis: synopsis || '',
          requiredSubscriptions: mySchedule ? [] : schedule?.subscriptions,
        };
      }
      return null;
    });
  }, [selectedSeason, isSport]);

  const handleDrawerTabChange = useCallback(
    (_seasonId: string) => {
      const season = seasons?.find(_season => _season?.id === _seasonId);
      if (season) {
        selectSeason(season);
      }
    },
    [seasons, selectSeason],
  );

  const handleDrawerContentSelect = usePersistCallback(_episodeId => {
    captureVideoContentEvent(
      VideoEventType.Stopped,
      currentPlaybackPosition,
      isPlayingFromDrawer ? 'Player' : undefined,
    );
    setIsPlayingFromDrawer(true);
    setEpisodeIdState(_episodeId);
  });

  // use deep compare,
  // because, episode will load twice, one from fetchEpisode, the other from fetchSeasons
  useEffect(() => {
    if (episode) {
      onVideoMetaLoad?.(episode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [episode?.id]);

  useEffect(() => {
    // When un-mount live player
    return () => clearVodPlayback(currentMediaAssetId?.current, false);
  }, [clearVodPlayback, currentMediaAssetId]);

  const [youboraPlayerConfig, setYouboraConfig] = useState<YouboraPlayerConfig>();

  const userProfileId = useSelector(selectors.customer.selectedProfileId);
  const user = useSelector(selectors.auth.user);
  const youboraConfig = useCallback(() => {
    const config = new YouboraPlayerConfig();

    config.username = userProfileId;
    config.content_transactionCode = user?.sub;
    config.content_id = isShow ? currentEpisode.current?.id : currentBrand.current?.id;
    config.content_title = utils.analytics.getYouboraVodTitle(currentBrand.current);
    config.content_genre = utils.analytics.getYouboraBrandGenres(currentBrand.current);
    config.content_episodeTitle = utils.analytics.getVodEpisodeNumber(currentEpisode.current);
    config.content_season = utils.analytics.getVodSeasonNumber(currentEpisode.current?.season);
    config.content_metadata = {
      year: currentBrand.current?.year,
      rating: currentBrand.current?.rating,
    };
    config.content_duration = isShow
      ? currentEpisode.current?.duration &&
        convertDurationToSeconds(currentEpisode.current?.duration)
      : (currentBrand.current as Movie)?.duration &&
        convertDurationToSeconds((currentBrand.current as Movie)?.duration);
    config.content_program = utils.analytics.getYouboraVodProgram(
      currentBrand.current,
      currentEpisode.current,
    );
    config.content_type = utils.analytics.getContentTypeByBrand(currentBrand.current);
    config.content_channel = utils.analytics.getYouboraControllingChannel(currentBrand.current);
    config.content_account_number = accountNumber || undefined;
    setYouboraConfig(config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow, isSport, user, userProfileId, accountNumber]);

  const handleChannelDrawerOpen = useCallback(() => {
    contentDrawerOpened({
      category_name: 'Seasons & Episodes',
      view_name: 'Content Details',
    });
    // Fetch brand detail like WatchProgress again
    if (brandId) {
      dispatch(actions.titles.fetchBrand({ brandId, isShow }));
    }
  }, [brandId, dispatch, isShow]);

  useEffect(() => {
    youboraConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEpisodeId]);

  useEffect(() => {
    setParentalRestrictionResolved(!hasPinExpired(pinExpiry));
  }, [pinExpiry, currentEpisodeId]);

  // Entitlement subscription check
  const getRequiredSubscriptions = useVodRequiredSubscriptions();
  const requiredSubscriptions = getRequiredSubscriptions(brandId, episodeId);

  return (
    <PlayerContainer
      isParentalForbidden={isParentalForbidden}
      subscriptions={requiredSubscriptions}
      isConcurrencyLimitReached={isConcurrencyLimitReached}
      defaultBackgroundImage={brandImage || undefined}
      contentClassification={contentClassification}
      isPlaybackError={isPlaybackError}
      // when the player Referrer cannot be determined is taking 'Content Details' by default
      // playerReferrer could be null when the user just paste the video url on the browser
      playerReferrer={playerReferrer || 'Content Details'}
      playbackOrigin={playbackOrigin}
      youboraPlayerConfiguration={youboraPlayerConfig}
      onPlaybackRetry={onPlaybackRetry}
      onParentalResolved={() => setParentalRestrictionResolved(true)}
    >
      <VideoPlayer
        mediaInfo={mediaInfo}
        playbackMeta={playbackMeta}
        startTime={startTimeState}
        onVideoFirstPlay={onVideoFirstPlay}
        onVideoPlay={onVideoPlay}
        onVideoPause={onVideoPause}
        onVideoEnd={onVideoEnd}
        onTimeUpdate={onTimeUpdate}
        onPlaybackPositionChange={onPlaybackPositionChange}
        isConcurrencyLimitReached={isConcurrencyLimitReached}
        assetId={mediaAssetId}
        isShowResourceButton={isShow}
        isSport={isSport}
        drawerTabs={drawerTabs}
        drawerContentList={drawerContentList}
        selectedTabId={selectedSeason?.id}
        selectedContentId={episodeId}
        onDrawerTabChange={handleDrawerTabChange}
        onDrawerContentSelect={handleDrawerContentSelect}
        onDrawerOpen={handleChannelDrawerOpen}
        data-testid="vod-video-player"
        youboraPlayerConfiguration={youboraPlayerConfig}
        brandId={brandId}
        episodeId={episodeId}
        onAutoPlayNextEpisode={setEpisodeIdState}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
      />
    </PlayerContainer>
  );
};

export default VodVideoPlayer;
