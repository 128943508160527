import React, { FC, useState, useRef, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { useGesture } from 'react-use-gesture';

import { toPlayTime } from '@/Utils/DurationFormatter';
import { numberIn0To1 } from '@/Utils/NumberInRange';

import styles from './styles.module.scss';

export interface Props {
  isShow?: boolean;
  isLivePlayer?: boolean;
  isLive?: boolean;
  onActiveChange: (active: boolean) => void;
  requestControlGridEdges: () => DOMRect;

  isSeeking: boolean;
  bufferRanges: TimeRanges | undefined;
  currentTime: number;
  duration: number;
  onCurrentTimeChanging: (currentTime: number) => void;
  onCurrentTimeChanged: (currentTime: number) => void;
}

const ProgressPanel: FC<Props> = ({
  isShow = true,
  isLivePlayer = false,
  isLive = false,
  onActiveChange,
  requestControlGridEdges,
  isSeeking,
  bufferRanges,
  currentTime,
  duration,
  onCurrentTimeChanging,
  onCurrentTimeChanged,
}) => {
  const adjustedDuration = Math.floor(duration);

  const [currentTimeLocal, setCurrentTimeLocal] = useState<number>(currentTime);
  const [hoverPositionTime, setHoverPositionTime] = useState<number>(NaN);
  const [hoverPositionTimeBetweenEdges, setHoverPositionTimeBetweenEdges] = useState<number>(NaN);
  const [isProgressBarDragging, setIsProgressBarDragging] = useState(false);
  const currentTimeChangingRef = useRef<number>(NaN);
  const progressBarRef = useRef<HTMLDivElement>(null);
  const progressTipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    onActiveChange(isProgressBarDragging);
  }, [isProgressBarDragging, onActiveChange]);

  const calculatePercentByEventX = useCallback((x: number): number => {
    const { current: progressBar } = progressBarRef;
    if (!progressBar) {
      return 0;
    }

    const { left: progressBarLeft, width: progressBarWidth } = progressBar.getBoundingClientRect();

    return numberIn0To1((x - progressBarLeft) / progressBarWidth);
  }, []);

  const setHoverPositionTimeByEventX = useCallback(
    (x: number, newTimeAtMousePosition: number) => {
      setHoverPositionTime(newTimeAtMousePosition);

      const { current: progressTip } = progressTipRef;
      if (progressTip) {
        const { width: progressTipWidth } = progressTip.getBoundingClientRect();
        const progressTipLeft = x - progressTipWidth / 2;
        const progressTipRight = x + progressTipWidth / 2;

        const { left, right } = requestControlGridEdges();

        if (progressTipLeft >= left && progressTipRight <= right) {
          setHoverPositionTimeBetweenEdges(newTimeAtMousePosition);
        }
      }
    },
    [requestControlGridEdges],
  );

  const bindProgressDrag = useGesture({
    onMove: ({ xy: [x], dragging }) => {
      if (!dragging) {
        const newTimeAtHoverPosition = adjustedDuration * calculatePercentByEventX(x);
        setHoverPositionTimeByEventX(x, newTimeAtHoverPosition);
      }
    },
    onDragStart: ({ xy: [x] }) => {
      setIsProgressBarDragging(true);

      const newTimeAtDragPosition = adjustedDuration * calculatePercentByEventX(x);
      setCurrentTimeLocal(newTimeAtDragPosition);
    },
    onDrag: ({ xy: [x] }) => {
      const newTimeAtDragPosition = adjustedDuration * calculatePercentByEventX(x);
      setCurrentTimeLocal(newTimeAtDragPosition);

      setHoverPositionTimeByEventX(x, newTimeAtDragPosition);

      const { current: currentTimeChangingInRef } = currentTimeChangingRef;
      if (newTimeAtDragPosition !== currentTimeChangingInRef) {
        currentTimeChangingRef.current = newTimeAtDragPosition;
        onCurrentTimeChanging(newTimeAtDragPosition);
      }
    },
    onDragEnd: () => {
      onCurrentTimeChanged(currentTimeLocal);
      setIsProgressBarDragging(false);
    },
  });

  useEffect(() => {
    if (isProgressBarDragging || isSeeking) {
      return;
    }
    setCurrentTimeLocal(currentTime);
  }, [adjustedDuration, currentTime, isProgressBarDragging, isSeeking]);

  return (
    <div
      className={classnames(styles.progressPanel, {
        [styles.hide]: !isShow,
        [styles.progressPanelActive]: isProgressBarDragging,
        [styles.isLive]: isLive,
      })}
    >
      {!isLivePlayer && (
        <div
          data-testid="video-player-progress-time"
          className={classnames(styles.progressTime, styles.currentTime)}
        >
          {toPlayTime(Math.floor(isLivePlayer ? adjustedDuration * -1 : currentTimeLocal))}
        </div>
      )}
      <div
        data-testid="video-player-progress-bar"
        className={classnames(styles.progressBar, {
          [styles.progressBarActive]: isProgressBarDragging,
        })}
        ref={progressBarRef}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...bindProgressDrag()}
      >
        <div className={styles.progressBarOuter}>
          {bufferRanges &&
            new Array(bufferRanges.length).fill(0).map((_, i) => {
              return (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  className={classnames(styles.progressBarInner, styles.progressBuffer)}
                  style={{
                    left: `${numberIn0To1(bufferRanges.start(i) / adjustedDuration) * 100}%`,
                    width: `${
                      numberIn0To1(
                        (bufferRanges.end(i) - bufferRanges.start(i)) / adjustedDuration,
                      ) * 100
                    }%`,
                  }}
                />
              );
            })}
          <div
            data-testid="video-player-progress-play"
            className={classnames(styles.progressBarInner, styles.progressPlay)}
            style={{ width: `${numberIn0To1(currentTimeLocal / adjustedDuration) * 100}%` }}
          />
          <div
            className={classnames(styles.progressBarInner, styles.progressPointer)}
            style={{
              left: `${numberIn0To1(hoverPositionTime / adjustedDuration) * 100}%`,
            }}
          />
        </div>
        <div
          className={styles.progressTip}
          style={{
            display: Number.isNaN(hoverPositionTime) ? 'none' : undefined,
            left: `${numberIn0To1(hoverPositionTimeBetweenEdges / adjustedDuration) * 100}%`,
          }}
          ref={progressTipRef}
          data-testid="video-player-progress-tip"
        >
          <span className={styles.progressTipTime} data-testid="video-player-progress-tip-time">
            {toPlayTime(Math.floor(hoverPositionTime - (isLivePlayer ? adjustedDuration : 0)))}
          </span>
        </div>
        <div
          className={classnames(styles.progressHandle, {
            [styles.progressHandleActive]: isProgressBarDragging,
          })}
          style={{ left: `${numberIn0To1(currentTimeLocal / adjustedDuration) * 100}%` }}
          data-testid="video-player-progress-handle"
        />
      </div>
      {!isLivePlayer && (
        <div
          className={classnames(styles.progressTime, styles.remainTime)}
          data-testid="video-player-time-left"
        >
          {isLivePlayer
            ? 'On Air'
            : toPlayTime(Math.ceil(currentTimeLocal - adjustedDuration), true)}
        </div>
      )}
    </div>
  );
};

export default ProgressPanel;
