import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import throttle from 'lodash.throttle';

import { isEmpty, isNil } from 'ramda';
import { useDeepCompareEffect, usePrevious } from 'react-use';

import { ImageAssetType } from '@skytvnz/sky-app-store/lib/types/enums/ImageAssetType';
import { LinearChannel, LinearSlot } from '@skytvnz/sky-app-store/lib/types/graph-ql';

import { getVideoImg } from '@/Utils/VideoImage';
import { actions, selectors, utils } from '@/Store';
import usePersistCallback from '@/Hooks/usePersistCallback';
import getTimeRange from '@/Utils/TimeFormat';
import useLiveData from '@/Components/VideoPlayer/DataLoader/useLiveData';
import { YouboraPlayerConfig } from '@/Components/VideoPlayer/Core/YouboraPlayerConfig';
import useCurrent from '@/Hooks/useCurrent';
import { useChannelSubscriptions } from '@/Hooks/useSubscription';
import { contentDrawerOpened } from '@/Analytics/Segment';
import { hasPinExpired } from '@skytvnz/sky-app-store/lib/modules/parentalPin/selectors';
import dayjs from 'dayjs';
import { VideoEventType } from '@/Analytics/Video';
import auth0Config from '@/Config/Auth0Config';
import { getDeviceId } from '@/Utils/Device';
import { DrawerContentItem, MediaInfo } from './VideoTypes';
import VideoPlayer, { VideoProps } from './VideoPlayer';
import { PlayerController } from './Core/PlayerTypes';
import PlayerContainer from './PlayerContainer';
import { getEncodingType } from './DRM';

const THROTTLE_TIMEOUT_MS = 3000;

interface LiveVideoPlayerProps extends VideoProps {
  // Only for the usage of Sport Live
  channelId?: string;
  playerReferrer?: string;
  onVideoMetaLoad?: (channel: LinearChannel, isFirstLoad: boolean) => void;
  playerMode?: string;
  watchFromStart?: boolean;
  startPosition?: string;
}

const getPlaybackPositionTime = (player: PlayerController) => {
  const liveRewindTimeOffset = Math.floor(player.getLiveCurrentTime() - player.currentTime());
  return dayjs().subtract(liveRewindTimeOffset, 'second');
};

const LiveVideoPlayer: FC<LiveVideoPlayerProps> = ({
  // self props
  channelId,
  playerReferrer,
  onVideoMetaLoad,
  playerMode,
  watchFromStart = false,
  startPosition,
  // core props
  ...props
}) => {
  const dispatch = useDispatch();
  const accountNumber = useSelector(selectors.auth.getDecodeConfig)(
    auth0Config.accountNumberClaimNameSpace,
  );

  const pinExpiry = useSelector(selectors.parentalPin.pinExpiry);

  const playerRef = useRef<any>();
  const startTimeRef = useRef(0);
  const previousChannelId = usePrevious(channelId);

  const [channelContentList, setChannelContentList] = useState<DrawerContentItem[]>([]);
  const [youboraPlayerConfig, setYouboraConfig] = useState<YouboraPlayerConfig>();
  const [isParentalRestrictionResolved, setParentalRestrictionResolved] = useState(
    !hasPinExpired(pinExpiry),
  );

  const {
    selectedCategoryId,
    selectedChannel,
    isFirstLoad,
    playbackMeta,
    isPlaybackError,
    isConcurrentLimitReached,
    onPlaybackRetry,
    mediaAssetId,
    currentSlot,
    categories,
    channels,
    channelsIsLoading,
    isParentalForbidden,
    handleSelectCategory,
    handleSelectChannel,
    clearChannelPlaybackMeta,
    sendVideoContentEvent,
    previousSlot,
  } = useLiveData(
    channelId,
    isParentalRestrictionResolved,
    playerMode,
    startPosition,
    playerReferrer,
  );

  const currentSelectedChannel = useCurrent(selectedChannel);

  const [currentSlotPosition, setCurrentSlotPosition] = useState(0);
  const previousSlotPosition = usePrevious(currentSlotPosition);

  const throttledSendVideoContentStopEvent = useCallback(
    throttle(
      (slotPosition: number, slot?: LinearSlot | null) =>
        sendVideoContentEvent(VideoEventType.Stopped, slotPosition, slot),
      THROTTLE_TIMEOUT_MS,
    ),
    [],
  );

  const throttledSendVideoContentStartEvent = useCallback(
    throttle(
      (slotPosition: number, slot?: LinearSlot | null) =>
        sendVideoContentEvent(VideoEventType.Started, slotPosition, slot),
      THROTTLE_TIMEOUT_MS,
    ),
    [],
  );

  const currentSlotImage = useMemo(() => {
    const content = currentSlot?.programme;
    const brand = content?.__typename === 'Episode' ? content.show : content;
    return (
      (brand &&
        getVideoImg(ImageAssetType.HeroLandingWide, brand[ImageAssetType.HeroLandingWide]?.uri)) ||
      ''
    );
  }, [currentSlot]);

  useEffect(() => {
    setParentalRestrictionResolved(!hasPinExpired(pinExpiry));
  }, [pinExpiry, currentSlot]);

  const onVideoFirstPlay = useCallback((player: PlayerController) => {
    playerRef.current = player;
  }, []);

  // Load categories every time when channels drawer reopen
  const handleChannelDrawerOpen = useCallback(() => {
    contentDrawerOpened({
      category_name: 'Channels',
      view_name: 'Live TV',
    });
    // Loading everything for all the channels

    dispatch(
      actions.channels.fetchCategories(true, true, {
        deviceId: getDeviceId(),
        encoding: getEncodingType(),
      }),
    );
  }, [dispatch]);

  const getCurrentSlotPosition = useCallback(
    (player: PlayerController) => {
      const playheadPositionTime = getPlaybackPositionTime(player);
      return dayjs(playheadPositionTime).diff(currentSlot?.start, 'second');
    },
    [currentSlot],
  );

  const isInCurrentSlotRange = useCallback(
    (player: PlayerController) => {
      const playheadPositionTime = getPlaybackPositionTime(player);
      return (
        dayjs(playheadPositionTime).isAfter(currentSlot?.start) &&
        dayjs(playheadPositionTime).isBefore(currentSlot?.end)
      );
    },
    [currentSlot],
  );

  const onPlaybackPositionChange = usePersistCallback((player: PlayerController) => {
    startTimeRef.current = player.isAtLiveEdge()
      ? 0
      : player.getLiveCurrentTime() - player.currentTime();
    dispatch(actions.channels.updateNewSlot(player.currentTime(), player.getLiveCurrentTime()));
  });

  const onLiveTimeUpdate = useCallback(
    throttle((player: PlayerController) => {
      setCurrentSlotPosition(getCurrentSlotPosition(player));
      dispatch(actions.channels.updateNewSlot(player.currentTime(), player.getLiveCurrentTime()));
    }, THROTTLE_TIMEOUT_MS),
    [],
  );

  const onVideoEnd = usePersistCallback((player: PlayerController, isPlaying: boolean) => {
    if (isConcurrentLimitReached || !playbackMeta) {
      return;
    }
    if (isPlaying) {
      throttledSendVideoContentStopEvent(getCurrentSlotPosition(player), currentSlot);
    }
  });

  const mediaInfo = useMemo<MediaInfo>(() => {
    const newMediaInfo: MediaInfo = {
      channelName: selectedChannel?.title || '',
      logoUrl: getVideoImg(ImageAssetType.ContentTileHorizontal, selectedChannel?.tileImage?.uri),
      title: utils.slot.getSlotTitle(currentSlot),
    };
    if (currentSlot) {
      newMediaInfo.timeRange = getTimeRange(currentSlot?.start, currentSlot?.end);
    }
    return newMediaInfo;
  }, [selectedChannel, currentSlot]);

  // Not trigger event when playback recover from buffer, so using Play instead of Playing
  // It will only trigger when initial play or resume from pause
  const onVideoPlay = usePersistCallback((player: PlayerController) => {
    // If current playback position is still in the range of current Slot
    // Then trigger the start event
    // Otherwise, skip it and wait for the new EPG request call
    if (isInCurrentSlotRange(player)) {
      throttledSendVideoContentStartEvent(getCurrentSlotPosition(player), currentSlot);
    }
  });

  const onVideoPause = usePersistCallback((player: PlayerController) => {
    throttledSendVideoContentStopEvent(getCurrentSlotPosition(player), currentSlot);
  });

  const handleDrawerContentSelect = usePersistCallback(_channelId => {
    handleSelectChannel(_channelId);
    startTimeRef.current = 0;
  });

  useDeepCompareEffect(() => {
    setChannelContentList(
      channels?.map(channel => {
        const { id, slot, slots, mySchedule, schedule } = channel;
        const channelSlot = slot || slots?.[0];
        const content = channelSlot?.programme;

        const analyticsParams: any = {
          contentId: channel?.id,
          contentTitle: utils.analytics.getSegmentSlotTitle(channelSlot),
          channelName: channel?.title,
          contentType: utils.analytics.getContentTypeBySlot(channelSlot),
        };
        return {
          id,
          image: getVideoImg(ImageAssetType.ContentTileHorizontal, channel?.tileImage?.uri),
          title: utils.slot.getSlotTitle(channelSlot),
          subTitle: getTimeRange(channelSlot?.start, channelSlot?.end),
          rating: channelSlot?.rating,
          requiredSubscriptions: mySchedule ? [] : schedule?.subscriptions,
          live: channelSlot?.live,
          synopsis: utils.title.getProgrammeSynopsis(content, content?.show),
          analyticsParams,
        };
      }),
    );
  }, [channels]);

  const isChannelsLoading = channelsIsLoading && (isNil(channels) || isEmpty(channels));

  const userProfileId = useSelector(selectors.customer.selectedProfileId);
  const user = useSelector(selectors.auth.user);

  const updateYouboraConfig = useCallback(
    currentChannel => {
      const config = new YouboraPlayerConfig();

      config.content_isLive = true;
      config.username = userProfileId;
      config.content_transactionCode = user?.sub;
      config.content_id = currentSlot?.programme?.id;
      config.content_channel = utils.analytics.getYouboraSlotChannel(currentChannel);
      config.content_title = utils.analytics.getYouboraSlotTitle(currentSlot);
      config.content_program = utils.analytics.getYouboraSlotProgram(currentSlot);
      config.content_metadata = { rating: currentSlot?.rating };
      config.content_episodeTitle = utils.analytics.getSlotEpisodeNumber(
        currentSlot,
        utils.slot.isSport(currentSlot),
      );
      config.content_season = utils.analytics.getSlotSeasonNumber(currentSlot);
      config.content_duration = dayjs(currentSlot?.end).diff(currentSlot?.start, 'second');
      config.content_genre = utils.analytics.getYouboraSlotGenres(currentSlot);
      config.content_type = utils.analytics.getContentTypeBySlot(currentSlot);
      config.content_account_number = accountNumber || undefined;
      setYouboraConfig(config);
    },
    [user, userProfileId, currentSlot, accountNumber],
  );

  useEffect(() => {
    // When the first time of entering the LiveTV page, if has provide a channel id
    // don't select the previous selected channel
    if (!previousChannelId && channelId) {
      return;
    }
    if (selectedChannel) {
      onVideoMetaLoad?.(selectedChannel, isFirstLoad);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChannel, channelId]);

  useEffect(() => {
    // When un-mount live player
    return () => {
      clearChannelPlaybackMeta(false);
    };
  }, [clearChannelPlaybackMeta]);

  useEffect(() => {
    updateYouboraConfig(currentSelectedChannel.current);
    // Each time when playbackMeta or slots update, we fire a video content stopped event
    // for the previous slot.
    throttledSendVideoContentStopEvent(previousSlotPosition || 0, previousSlot);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlot?.start]);

  useEffect(() => {
    // When the next slot starts we need to fire a video content start event but NOT if
    // * it the parental lock kicks in
    // Using the video Play event will avoid the very first play duplicating (e.g. channel switch or entering the player the first time)
    // User manually forward/backwards will not trigger this effect unless EPG changes
    if (isParentalForbidden === false && playerRef.current) {
      // TODO: consider the initial startTime for the future Sport Event
      const player = playerRef.current;
      throttledSendVideoContentStartEvent(
        player ? getCurrentSlotPosition(player) : dayjs().diff(currentSlot?.start, 'second'),
        currentSlot,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlot?.start, isParentalForbidden, playerRef.current]);

  // Entitlement subscription check
  const requiredSubscriptions = useChannelSubscriptions()(selectedChannel?.id);

  return (
    <PlayerContainer
      isLivePlayer
      isParentalForbidden={isParentalForbidden}
      subscriptions={requiredSubscriptions}
      channelId={channelId}
      isConcurrencyLimitReached={isConcurrentLimitReached}
      defaultBackgroundImage={currentSlotImage}
      isPlaybackError={isPlaybackError}
      contentClassification={currentSlot?.rating?.classification}
      // when the player Referrer cannot be determined is taking 'Content Details' by default
      // playerReferrer could be null when the user just paste the video url on the browser
      playerReferrer={playerReferrer}
      youboraPlayerConfiguration={youboraPlayerConfig}
      onPlaybackRetry={onPlaybackRetry}
      onParentalResolved={() => setParentalRestrictionResolved(true)}
    >
      <VideoPlayer
        isLivePlayer
        mediaInfo={mediaInfo}
        startTime={startTimeRef.current}
        playerReferrer={playerReferrer}
        playerMode={playerMode}
        startPosition={startPosition}
        watchFromStart={watchFromStart}
        playbackMeta={playbackMeta}
        isConcurrencyLimitReached={isConcurrentLimitReached}
        assetId={mediaAssetId}
        onVideoFirstPlay={onVideoFirstPlay}
        onVideoPlay={onVideoPlay}
        onVideoPause={onVideoPause}
        onVideoEnd={onVideoEnd}
        onTimeUpdate={onLiveTimeUpdate}
        onPlaybackPositionChange={onPlaybackPositionChange}
        isShowResourceButton
        drawerTabs={categories}
        drawerContentList={channelContentList}
        selectedTabId={selectedCategoryId}
        selectedContentId={selectedChannel?.id}
        isDrawerContentLoading={isChannelsLoading}
        onDrawerTabChange={handleSelectCategory}
        onDrawerContentSelect={handleDrawerContentSelect}
        onDrawerOpen={handleChannelDrawerOpen}
        data-testid="live-video-player"
        youboraPlayerConfiguration={youboraPlayerConfig}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </PlayerContainer>
  );
};

export default LiveVideoPlayer;
