import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { Button } from 'antd';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Icon from '@ant-design/icons';

import { BrandType } from '@skytvnz/sky-app-store/lib/types/enums/BrandType';
import { Show, Movie } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import { utils, selectors } from '@/Store';

import { ReactComponent as Details } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_details.svg';

import { Hero } from '@/Analytics';
import MyListButton from '@/Components/HeroBanner/MyListBtn';
import RecordButton from '@/Components/RecordButton';
import useMedia from '@/Hooks/useMedia';
import { getViewPage } from '@/Utils/PageLocation';
import { useCheckVodOrChannelEntitled } from '@/Hooks/useSubscription';

import PlayButton from '@/Components/HeroBanner/PlayButton';
import { PageType } from './HeroTypes';

import styles from './styles.module.scss';

interface HeroButtonsProps {
  brand: Show | Movie;
  onMyListToggle?: any;
  isHome: boolean;
}

const HeroButtons: FC<HeroButtonsProps> = ({ brand, isHome, onMyListToggle }) => {
  const { isMediaM } = useMedia();
  const history = useHistory();
  const heroes = useSelector(selectors.home.heroes);

  const brandType = brand.__typename;
  const linkUrl = brandType === BrandType.Show ? 'show' : 'movie';

  const playBtnSetting = utils.hero.getPlayButtonSetting(brand);
  const watchlistBtnSetting = utils.hero.getWatchlistButtonSetting();
  const recordBtnSetting = utils.hero.getRecordButtonSetting(brand);

  // Entitlement subscription check
  const checkVodOrChannelEntitled = useCheckVodOrChannelEntitled();
  const isEntitled = isHome || checkVodOrChannelEntitled(brand);

  const HeroRecordButton = useCallback(() => {
    const firstAvailableSlot = utils.title.getFirstAvailableSlot(brand);
    const requiredSubscriptions = isEntitled
      ? undefined
      : utils.entitlements.getSlotSubscriptions(
          firstAvailableSlot === null ? undefined : firstAvailableSlot,
        );
    const analyticContentTitle = utils.analytics.getSegmentSlotTitleWithBrand(
      firstAvailableSlot,
      utils.title.isSport(brand),
      brand.title,
    );
    return recordBtnSetting.visible ? (
      <RecordButton
        testId="hero-record-button"
        brandTitle={brand.title}
        className={styles.heroButton}
        slot={firstAvailableSlot}
        channel={firstAvailableSlot?.channel || null}
        requiredSubscriptions={requiredSubscriptions}
        isSecordary={playBtnSetting.visible}
        subscriptionAnalyticParams={{
          categoryName: 'Upcoming',
          contentId: firstAvailableSlot?.programme?.id || '',
          contentTitle: analyticContentTitle,
          contentType: utils.analytics.getContentTypeByBrand(brand),
          viewName: getViewPage(history),
          channelName: utils.analytics.getSegmentSlotChannel(firstAvailableSlot?.channel),
        }}
        remoteRecordAnalyticParams={{
          categoryName: 'Upcoming',
          contentTitle: analyticContentTitle,
          history,
          genres: utils.analytics.getSegmentBrandGenres(brand),
          contentType: utils.analytics.getContentTypeByBrand(brand),
          channelName: firstAvailableSlot?.channel?.title,
        }}
      />
    ) : null;
  }, [brand, playBtnSetting, recordBtnSetting, history, isEntitled]);

  const HomePageButtons = useCallback(() => {
    const onDetailBtnClick = () => {
      Hero.recordDetailsButtonClick(
        brand.id,
        brand.title,
        utils.analytics.getContentTypeByBrand(brand) || '',
        PageType.Home,
        heroes?.map(h => h.id),
      );
    };
    return isMediaM ? (
      <div className={styles.buttonRow}>
        {playBtnSetting.visible && (
          <PlayButton
            brand={brand}
            isEntitled={isEntitled}
            isHome
            isVod={playBtnSetting.isVod}
            videoContent={playBtnSetting.videoContent}
          />
        )}
        <HeroRecordButton />
        <Link to={`/${linkUrl}/${brand.id}`} onClick={onDetailBtnClick}>
          <Button
            type="primary"
            className={classnames(styles.heroButton, styles.borderlessButton)}
            data-testid="hero-banner-play-details-button"
            name="Hero banner details button"
          >
            <Icon className={styles.heroButtonIcon} component={Details as React.FC} />
            <span className={styles.heroButtonText}>DETAILS</span>
          </Button>
        </Link>
      </div>
    ) : (
      <Link
        to={`/${linkUrl}/${brand.id}`}
        className={styles.buttonMask}
        onClick={onDetailBtnClick}
      />
    );
  }, [isMediaM, linkUrl, brand, isEntitled, playBtnSetting, heroes]);

  const DetailsPageButtons = useCallback(() => {
    let buttons: any = null;
    if (isMediaM) {
      buttons = (
        <div className={styles.buttonRow}>
          {playBtnSetting.visible && (
            <PlayButton
              brand={brand}
              isEntitled={isEntitled}
              isHome={false}
              isVod={playBtnSetting.isVod}
              videoContent={playBtnSetting.videoContent}
            />
          )}
          <HeroRecordButton />
          {watchlistBtnSetting.visible && onMyListToggle && (
            <MyListButton onMyList={brand.onMyList} onToggle={onMyListToggle} />
          )}
        </div>
      );
    } else if (playBtnSetting.visible) {
      buttons = (
        <PlayButton
          brand={brand}
          isEntitled={isEntitled}
          isHome={false}
          isIconOnly
          isVod={playBtnSetting.isVod}
          videoContent={playBtnSetting.videoContent}
        />
      );
    }
    return buttons;
  }, [isMediaM, isEntitled, onMyListToggle, playBtnSetting, watchlistBtnSetting, brand]);

  return isHome ? <HomePageButtons /> : <DetailsPageButtons />;
};

export default HeroButtons;
