import queryString from 'query-string';

// eslint-disable-next-line import/prefer-default-export
export const updatePlaybackSource = (src: string, startPosition: string) => {
  const parsedUrl = queryString.parseUrl(src);
  const params = { ...parsedUrl.query };
  params.start = startPosition;

  // Construct the new src using stringifyUrl
  const updatedSrc = queryString.stringifyUrl({ url: parsedUrl.url, query: params });

  return updatedSrc;
};
