import React, { useCallback } from 'react';
import { useModal } from 'react-modal-hook';
import { actions } from '@/Store';
import { useDispatch } from 'react-redux';
import { isNil } from 'ramda';
import { useSubscriptionModal } from '@/Hooks/useSubscription';

import AiringSlotModalDetails from './AiringSlotModalDetails';

const useAiringSlotModal = () => {
  const dispatch = useDispatch();
  const showUpgradeSubscriptionModal = useSubscriptionModal();

  const [showModal, hideModal] = useModal(() => {
    return (
      <AiringSlotModalDetails
        onClose={() => {
          hideModal();
          dispatch(actions.channels.clearSelectedChannelSlot());
        }}
      />
    );
  });

  const openWatchFromStartModal = useCallback(
    async assetId => {
      const result = await dispatch(actions.channels.fetchLinearSlotById(assetId));
      const selectedSlot = result.payload?.linearChannelSlot;
      if (isNil(selectedSlot.channel?.mySchedule)) {
        showUpgradeSubscriptionModal(selectedSlot.channel?.schedule?.subscriptions);
        return;
      }
      showModal();
    },
    [dispatch, showModal, showUpgradeSubscriptionModal],
  );

  return { openWatchFromStartModal };
};

export default useAiringSlotModal;
