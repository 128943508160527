import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Auth0Lock } from 'auth0-lock';
import { message } from 'antd';

import { selectors, actions } from '@/Store';
import RoutePath from '@/Routes/RoutePath';
import history from '@/Routes/History';
import { getSearchParams } from '@/Utils/URLParams';
import Sentry from '@/Utils/Sentry';
import { Spotify as SpotifyAnalytics } from '@/Analytics';
import auth0Config from '@/Config/Auth0Config';

import useAuth from './useAuth';

export enum AccountType {
  Spotify = 'spotify',
  SkyDth = 'skydth',
}

const USER_LINKED_TYPE = 'customerType-linked';
const USER_CUSTOMER_GROUP_TYPE = 'https://idm.sky.co.nz/customer_group_type';

const AUTH_PARAMS_NOT_EXIST_ERROR = 'auth_params_not_exist';

const getAuth0LockOptions = (accountType: AccountType, redirectUrl: string) => {
  return {
    allowedConnections: [accountType],
    rememberLastLogin: false,
    auth: {
      responseType: 'token id_token',
      redirectUrl,
    },
    theme: {
      logo: 'https://skynz.akamaized.net/iop-theme/release/latest/images/logo/iop-sky-dark.svg',
      primaryColor: '#00B1EB',
    },
    languageDictionary: {
      title: 'Link to Sky Account',
    },
  };
};

const getRedirectCallbackPageUrl = (redirectPath?: string) => {
  // current path by default
  const currentPath = `${window.location.pathname}${window.location.search}${window.location.hash}`;
  const redirectUrl = redirectPath || currentPath;
  return `${window.location.origin}${RoutePath.linkAccountCallback}?redirect=${encodeURIComponent(
    redirectUrl,
  )}`;
};

const onRedirectCallback = () => {
  const redirectPath = getSearchParams().redirect || '/';
  history.push(redirectPath);
};

const useLinkAccount = () => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const user = useSelector(selectors.auth.user);

  const isAccountLinked = useCallback(
    (accountType: AccountType) => {
      if (accountType === AccountType.Spotify) {
        const linkedAccounts = user[auth0Config.linkedAccountClaimNameSpace];
        return linkedAccounts?.some(account => account.connection === accountType);
      }

      if (accountType === AccountType.SkyDth) {
        const customerGroupType: string[] = user?.[USER_CUSTOMER_GROUP_TYPE];
        if (customerGroupType?.includes?.(USER_LINKED_TYPE)) {
          return true;
        }
        return false;
      }
      return false;
    },
    [user],
  );

  const linkSecondaryAccountToPrimaryAccount = useCallback(
    async secondaryAccountAuthResult => {
      const { idToken } = secondaryAccountAuthResult;
      const isLinked = isAccountLinked(AccountType.Spotify);
      if (!isLinked) {
        // Link the account
        await dispatch(actions.auth.linkAccount(idToken));

        SpotifyAnalytics.recordLinkAccount();

        // Force refresh the current user
        auth?.loadToken(true);
      }
    },
    [auth, dispatch, isAccountLinked],
  );

  const handleLinkAccountAuthentication = useCallback(
    async (lock, shouldFailWhenNoParamInHash = false) => {
      const handleAuth = () =>
        new Promise((resolve, reject) => {
          lock.on('authenticated', res => {
            resolve(res);
          });
          lock.on('authorization_error', err => {
            reject(new Error(err));
          });
          // Only use by the url redirect callback
          if (shouldFailWhenNoParamInHash) {
            lock.on('hash_parsed', resOrErr => {
              // No result or error means the no auth param in the url hash
              if (!resOrErr) {
                reject(new Error(AUTH_PARAMS_NOT_EXIST_ERROR));
              }
            });
          }
        });

      try {
        // Step 1: Check if the authentication of the secondary social account is successful and get the result
        const authResult = await handleAuth();
        // Step 2: link the the authenticated secondary social account to the primary Sky account
        await linkSecondaryAccountToPrimaryAccount(authResult);
        // TODO: show an account successful linked message
      } catch (e) {
        // Error in Step 1 (Authentication failed from Auth0) and Step 2 (Link failed from User Service)
        message.error('Error while was trying to auth user and link account.', 10);
        Sentry.captureMessage(`Error while was trying to auth user and link account ${e}`);
      }
    },
    [linkSecondaryAccountToPrimaryAccount],
  );

  const linkAccount = useCallback(
    (accountType: AccountType, redirectPathWithQueryAndHash?: string) => {
      const lock = new Auth0Lock(
        auth0Config.clientId,
        auth0Config.managementDomain,
        getAuth0LockOptions(accountType, getRedirectCallbackPageUrl(redirectPathWithQueryAndHash)),
      );

      handleLinkAccountAuthentication(lock);

      lock.show();
    },
    [handleLinkAccountAuthentication],
  );

  const linkAccountRedirectCallback = useCallback(async () => {
    const lock = new Auth0Lock(auth0Config.clientId, auth0Config.managementDomain);

    try {
      await handleLinkAccountAuthentication(lock, true);
    } finally {
      onRedirectCallback();
    }
  }, [handleLinkAccountAuthentication]);

  return { linkAccount, linkAccountRedirectCallback, isAccountLinked };
};

export default useLinkAccount;
