import React from 'react';
import { isNil } from 'ramda';
import classnames from 'classnames';
import pluralize from 'pluralize';

import { ImageAssetType } from '@skytvnz/sky-app-store/lib/types/enums/ImageAssetType';
import { Show } from '@skytvnz/sky-app-store/lib/types/graph-ql';

import { utils } from '@/Store';
import { formatGenres, getGenres } from '@/Utils/Genres';
import { PageType } from '@/Components/HeroBanner/HeroTypes';
import { formatRating } from '@/Utils/Rating';

import SlotProgress from '@/Components/SlotProgress/SlotProgress';
import Availability from '@/Pages/Detail/Seasons/Episodes/Availability';
import useMedia from '@/Hooks/useMedia';
import synopsisStyles from '@/Components/Synopsis/styles.module.scss';

import Synopsis from '@/Components/Synopsis';
import HeroBox from './HeroBox';
import HeroButtons from './HeroButtons';
import ControllingChannel from './ControllingChannel';
import LiveTag from '../LiveTag';

import styles from './styles.module.scss';

const NUMBER_OF_GENRES_TO_DISPLAY = 2;

interface Props {
  show: Show;
  image: string;
  imageType: ImageAssetType;
  pageType: PageType;
  className?: string;
  onMyListToggle?: any;
}

const ShowHeroBanner: React.FC<Props> = props => {
  const { show, pageType, imageType, image, className, onMyListToggle } = props;
  const isHome = pageType === PageType.Home;

  const firstAvailableSlot = utils.title.getFirstAvailableSlot(show);
  const isSport = utils.title.isSport(show);
  const shouldHeroDisplaySlotDetail = utils.hero.shouldDisplaySlotDetail(show);
  const isFirstAvailableSlotOnAir = utils.slot.isSlotOnAir(firstAvailableSlot);
  const heroImage = utils.hero.getImage(show, image);
  // Get Synopsis firstly from the first available upcoming slot
  const synopsis = utils.hero.getSynopsis(show);
  const HeroDetails = () => {
    const { isMediaM } = useMedia();
    const genresList = getGenres(formatGenres(show.allGenres), NUMBER_OF_GENRES_TO_DISPLAY);
    return (
      <>
        <div className={styles.textContentContainer}>
          <h1 data-testid="hero-title">{show.title}</h1>
          <div className={styles.rowContainer}>
            {genresList && <span data-testid="hero-genres">{genresList}</span>}
            <div className={styles.secondaryAttributes}>
              {!isNil(show.numberOfSeasons) && show.numberOfSeasons > 0 && (
                <span className={styles.delimiter} data-testid="hero-seasons">
                  {pluralize('Season', show.numberOfSeasons, true)}
                </span>
              )}
              {show.year && (
                <span
                  className={classnames({ [styles.delimiter]: show.rating })}
                  data-testid="hero-year"
                >
                  {show.year}
                </span>
              )}
              {show.rating && <span data-testid="hero-rating">{formatRating(show.rating)}</span>}
            </div>
            {isSport && firstAvailableSlot && (
              <span className={styles.sportTitle} data-testid="sport-episode-title">
                {firstAvailableSlot.programme?.title}
              </span>
            )}
            {shouldHeroDisplaySlotDetail && (
              <>
                <div className={styles.upcomingSlot} data-testid="sport-upcoming-slot">
                  <LiveTag
                    className={styles.bigLiveTag}
                    testId="hero-live-tag"
                    slot={firstAvailableSlot}
                  />
                  <Availability
                    id={0}
                    startDate={firstAvailableSlot?.start}
                    channelName={firstAvailableSlot?.channel?.title}
                    className={styles.availability}
                  />
                </div>
              </>
            )}
          </div>
          {isMediaM && (
            <Synopsis
              synopsis={synopsis}
              cast={show.defaultEpisode?.characters}
              className={synopsisStyles.synopsis}
            />
          )}
          {shouldHeroDisplaySlotDetail && isFirstAvailableSlotOnAir && (
            <SlotProgress
              slotStart={firstAvailableSlot?.start}
              slotEnd={firstAvailableSlot?.end}
              channelTitle={firstAvailableSlot?.channel.title}
            />
          )}
          <HeroButtons brand={show} isHome={isHome} onMyListToggle={onMyListToggle} />
        </div>
        {(isHome || isMediaM) && <ControllingChannel isHome={isHome} channels={show.brands} />}
      </>
    );
  };
  return (
    <HeroBox
      imageTitle={show.title}
      image={heroImage}
      imageType={imageType}
      className={className}
      testId="hero-banner-show"
    >
      <HeroDetails />
    </HeroBox>
  );
};

export default ShowHeroBanner;
