import React, { useEffect } from 'react';

import Content from '@/Layouts/containers/Content';
import { usePageHeader } from '@/Components/AppHeader/AppHeader';
import pageViewedAnalytics from '@/Analytics/Page';

import styles from './styles.module.scss';

// TODO: Need design
const NotFound: React.FC = () => {
  usePageHeader({
    title: 'Page Not Found',
  });
  useEffect(() => {
    pageViewedAnalytics('Not Found');
  }, []);

  return <Content className={styles.notFound}>Page Not Found</Content>;
};

export default NotFound;
