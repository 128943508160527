import React from 'react';
import { Maybe, Episode } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import TileSynopsis from '@/Pages/Detail/Seasons/Episodes/TileSynopsis';
import EpisodeSubtitle from '@/Pages/Detail/Tiles/EpisodeSubtitle';
import EpisodeTileImage from '@/Pages/Detail/Tiles/EpisodeTileImage';
import styles from './styles.module.scss';

interface Props {
  episode: Maybe<Episode>;
  brandId: string;
  tileTitle: string;
  playbackOrigin: string;
  isRecent?: boolean;
}

const EpisodeTile: React.FC<Props> = props => {
  const { episode, brandId, tileTitle, isRecent, playbackOrigin } = props;

  return (
    <div className={styles.tile} data-testid={`episode-container-episode-nr-${episode?.number}`}>
      <EpisodeTileImage
        episode={episode}
        brandId={brandId}
        isRecent={isRecent}
        playbackOrigin={playbackOrigin}
      />
      <p className={styles.tileTitle} data-testid={`episode-title-nr-${episode?.number}`}>
        {tileTitle}
      </p>
      <EpisodeSubtitle episode={episode} />
      <TileSynopsis id={episode?.number} text={episode?.synopsis} />
    </div>
  );
};

export default EpisodeTile;
