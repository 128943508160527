import React from 'react';

import styles from '@/Pages/Detail/Tiles/styles.module.scss';

interface Props {
  id: number | null | undefined;
  text: string | null | undefined;
}

const TileSynopsis: React.FC<Props> = props => {
  const { id, text } = props;
  return text ? (
    <p className={styles.synopsis} data-testid={`tile-synopsis-${id}`}>
      {text}
    </p>
  ) : null;
};

export default TileSynopsis;
