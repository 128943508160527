import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { deviceRegistered, permittedDeviceLimitReached } from '@/Analytics/Segment';
import useDevice from '@/Hooks/useDevice';
import { actions } from '@/Store';
import { getDeviceId, getDeviceInfo, getPlaybackDeviceInfo } from '@/Utils/Device';
import { DeviceFamily } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import useAuth from '@/Hooks/useAuth';

const RegisterDevice: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const {
    isLoading,
    isDeviceRegistrationLimitExceeded,
    isNewlyRegisteredDevice,
    showDeviceRegistrationLimitModal,
  } = useDevice();
  const dispatch = useDispatch();
  const deviceInfo = getDeviceInfo();
  const deviceId = getDeviceId();

  const registerDevice = useCallback(() => {
    if (deviceInfo) {
      dispatch(
        actions.devices.registerDevice({
          deviceId,
          model: deviceInfo?.model,
          family: DeviceFamily.Browser,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const setPlaybackDeviceInfo = useCallback(async (): Promise<void> => {
    const playbackDeviceInfo = await getPlaybackDeviceInfo();
    dispatch(actions.devices.setPlaybackDevice(playbackDeviceInfo));
  }, [dispatch]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isNewlyRegisteredDevice) {
      deviceRegistered({
        device_id: deviceId,
      });
    } else if (isDeviceRegistrationLimitExceeded) {
      permittedDeviceLimitReached({
        device_id: deviceId,
      });
      showDeviceRegistrationLimitModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isDeviceRegistrationLimitExceeded, isNewlyRegisteredDevice]);

  useEffect(() => {
    if (isAuthenticated) {
      registerDevice();
      setPlaybackDeviceInfo();
    }
  }, [isAuthenticated, registerDevice, setPlaybackDeviceInfo]);

  return null;
};

export default RegisterDevice;
