import React, { forwardRef, Ref } from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

export enum AspectRatio {
  RatioFree = 'ratiofree',
  Ratio1by1 = 'ratio1by1',
  Ratio2by3 = 'ratio2by3',
  Ratio4by3 = 'ratio4by3',
  Ratio16by9 = 'ratio16by9',
}

interface Props {
  ratio: AspectRatio;
  className?: string;
  ref?: Ref<HTMLDivElement>;
  testid?: string;
}

const AspectRatioBox: React.FC<Props> = forwardRef(
  ({ children, ratio, className, testid }, ref) => {
    return (
      <div
        className={classnames(styles.aspectRatioBox, { [styles[ratio]]: true }, className)}
        ref={ref}
        data-testid={testid}
      >
        <div className={classnames(styles.aspectRatioBoxInner)}>{children}</div>
      </div>
    );
  },
);

export default AspectRatioBox;
