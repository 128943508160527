import { useLocation } from 'react-router-dom';

import { isProfilePage } from '@/Routes/RoutePath';

const useIsProfilePages = () => {
  const location = useLocation();

  return isProfilePage(location.pathname);
};

export default useIsProfilePages;
