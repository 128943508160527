import { isNil, isEmpty } from 'ramda';
import queryString from 'query-string';

import { ImageAssetType } from '@skytvnz/sky-app-store/lib/types/enums/ImageAssetType';

// keep the file all less than 10kB, so they will be compiled as base64 string,
// so that don't need to send http request for them
import heroLandingWideDefaultImage from '@/Resources/images/heroLandingWide.jpg';
import heroLandingSquareDefaultImage from '@/Resources/images/heroLandingSquare.jpg';
import contentTileHorizontalDefaultImage from '@/Resources/images/contentTileHorizontal.jpg';
import contentTileVerticalDefaultImage from '@/Resources/images/contentTileVertical.jpg';
import contentDetailDefaultImage from '@/Resources/images/contentDetail.jpg';
import { ImageAsset } from '@skytvnz/sky-app-store/lib/types/models/ImageAsset';
import { GroupLayout } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import { PageType } from '@/Components/HeroBanner/HeroTypes';

// Image Specification
// https://skynz.atlassian.net/wiki/spaces/SO/pages/19890349/Imagery+Mapping

const DefaultVideoImages = {
  [ImageAssetType.HeroLandingWide]: heroLandingWideDefaultImage,
  [ImageAssetType.HeroLandingSquare]: heroLandingSquareDefaultImage,
  [ImageAssetType.ContentTileHorizontal]: contentTileHorizontalDefaultImage,
  [ImageAssetType.ContentTileVertical]: contentTileVerticalDefaultImage,
  [ImageAssetType.ContentDetail]: contentDetailDefaultImage,
};

const VideoImagesImWidth = {
  [ImageAssetType.HeroLandingWide]: 1280,
  [ImageAssetType.HeroLandingSquare]: 768,
  [ImageAssetType.ContentTileHorizontal]: 286,
  [ImageAssetType.ContentTileVertical]: 286,
  [ImageAssetType.ContentDetail]: 750,
};

export function getDefaultVideoImage(type: ImageAssetType) {
  return DefaultVideoImages[type];
}

export const getVideoImage = (images: ImageAsset[] | undefined, type: ImageAssetType): string => {
  if (isNil(images) || isEmpty(images)) {
    return getDefaultVideoImage(type);
  }

  // TODO: the images array has duplicated items with the same type, use the first one for now.
  // and also has items with `null` type
  const img = images.find(i => i.type === type);

  return img?.url || getDefaultVideoImage(type);
};

const getImageWidth = (type: ImageAssetType) => VideoImagesImWidth[type] || 286;

export const getVideoImg = (type: ImageAssetType, image?: string): string => {
  const imwidth = getImageWidth(type);
  const imdensity = window.devicePixelRatio ? Math.round(window.devicePixelRatio) : 1;
  return image
    ? queryString.stringifyUrl({
        url: image,
        query: { impolicy: type, imdensity, imwidth },
      })
    : getDefaultVideoImage(type);
};

export const getImageType = (page: PageType, isMediaM: boolean) => {
  if (page === PageType.Home) {
    return isMediaM ? ImageAssetType.HeroLandingWide : ImageAssetType.HeroLandingSquare;
  }
  return ImageAssetType.ContentDetail;
};

export const getVideoImageType = (layout: GroupLayout) =>
  layout === GroupLayout.RailPortrait
    ? ImageAssetType.ContentTileVertical
    : ImageAssetType.ContentTileHorizontal;
