import React, { FC, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';

import { LinearSlot, LinearChannel, Maybe } from '@skytvnz/sky-app-store/lib/types/graph-ql';

import SlotCell from '../SlotCell';

import styles from './styles.module.scss';

interface SlotsRowProps {
  slots: Maybe<LinearSlot>[];
  channel: LinearChannel;
  firstStartTime: dayjs.Dayjs;
  scrollOffset: number;
  scrollOffsetY: number;
  openSlotDetail(slot: LinearSlot, channel: LinearChannel): void;
  currentTime: dayjs.Dayjs;
}

const SlotsRow: FC<SlotsRowProps> = ({
  scrollOffsetY,
  scrollOffset,
  slots,
  channel,
  firstStartTime,
  openSlotDetail,
  currentTime,
}) => {
  const rowRef = useRef<HTMLDivElement>(null);

  const [shouldRenderSlots, setShouldRenderSlots] = useState(false);

  useEffect(() => {
    if (rowRef.current) {
      const screenHeight = window.innerHeight;
      const { top, bottom } = rowRef.current.getBoundingClientRect();
      const isInsideViewPort = bottom >= -screenHeight / 2 && top <= screenHeight * 1.5;

      if (shouldRenderSlots !== isInsideViewPort) setShouldRenderSlots(isInsideViewPort);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollOffsetY]);

  return (
    <div className={styles.channelRow} ref={rowRef}>
      {shouldRenderSlots && (
        <>
          {slots.length > 0 ? (
            slots.map(
              slot =>
                slot && (
                  <SlotCell
                    key={`cell_${slot.start}_${slot.end}_${slot.programme?.id}`}
                    slot={slot}
                    channel={channel}
                    firstStartTime={firstStartTime as dayjs.Dayjs}
                    scrollOffset={scrollOffset as number}
                    onClick={openSlotDetail}
                    currentTime={currentTime}
                  />
                ),
            )
          ) : (
            <span className={styles.emptyRow}>Schedule Unavailable</span>
          )}
        </>
      )}
    </div>
  );
};

export default SlotsRow;
