import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { Fn } from 'react-use-gesture/dist/types';
import dayjs from 'dayjs';

import { ReactComponent as Right } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_chevron_forward.svg';
import { ReactComponent as Left } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_chevron_back.svg';

import usePersistCallback from '@/Hooks/usePersistCallback';
import useMedia from '@/Hooks/useMedia';
import { MAX_BLOCKS_WEEKLY, MINUTES_PER_UNIT, MAX_BLOCKS_DAILY } from '@/Pages/TVGuide/constants';
import useHorizontalDrag from '@/Hooks/useHorizontalDrag';
import TimeBlock from '@/Pages/TVGuide/Timeline/TimeBlock';

import { useTimeWidthUnit } from '../SlotsGrid/useTimeWidthUnit';

import styles from './styles.module.scss';

interface Props {
  newTimePoint: dayjs.Dayjs;
  initialTime: dayjs.Dayjs;
  scrollTimeOffset: number;
  minuteSlotsPerScreen: number;
  onTimelineScroll(scrollMinutes: number): void;
}

export const getFirstShiftTime = (initialTime: dayjs.Dayjs) => {
  const minutes = initialTime.minute();
  const shiftTime = initialTime.subtract(minutes > 30 ? minutes - 30 : minutes, 'minute');
  return shiftTime.second(0);
};

const getMinutesTimeToFirstShift = () => {
  const minutes = dayjs().minute();
  return (minutes >= MINUTES_PER_UNIT ? 60 : MINUTES_PER_UNIT) - minutes;
};

const Timeline: React.FC<Props> = ({
  onTimelineScroll,
  initialTime,
  newTimePoint,
  minuteSlotsPerScreen,
  scrollTimeOffset,
}) => {
  const { isMediaM } = useMedia();
  const widthUnit = useTimeWidthUnit();

  const timelineElement = useRef<HTMLDivElement>(null);
  const intervalIdRef = useRef<any>(null);
  const [isRightScrollDisabled, setRightScrollDisabled] = useState(false);
  const [isLeftScrollDisabled, setLeftScrollDisabled] = useState(false);
  // Infinite virtual list for rendering
  const [timeBlocks, setTimeBlocks] = useState<any[]>([]);

  const onPageChange = usePersistCallback((isForward: boolean) => {
    const blocksShift = minuteSlotsPerScreen * (isForward ? 1 : -1);
    if (blocksShift !== 0) {
      onTimelineScroll(blocksShift * MINUTES_PER_UNIT);
    }
  });

  const { swipeAndDrag } = useHorizontalDrag(onPageChange, timelineElement);

  useEffect(() => swipeAndDrag() as Fn, [swipeAndDrag]);

  useEffect(() => {
    onPageChange(0);
  }, [isMediaM, onPageChange]);

  useEffect(() => {
    // Direct mutate the dom style, better performance
    if (timelineElement.current?.style) {
      timelineElement.current.style.transform = `translate3d(${-scrollTimeOffset}px, 0, 0)`;
    }
    // Timeline button visibility status
    setLeftScrollDisabled(scrollTimeOffset <= 0);
    setRightScrollDisabled(
      scrollTimeOffset >= (MAX_BLOCKS_WEEKLY - minuteSlotsPerScreen) * widthUnit,
    );
  }, [scrollTimeOffset, widthUnit, minuteSlotsPerScreen]);

  useEffect(() => {
    // Generating new infinite virtual list by new time point
    const blockList: any[] = [];
    // Get rounded time point
    const startTimePoint = getFirstShiftTime(newTimePoint);
    const startTime = startTimePoint.subtract(minuteSlotsPerScreen * 30, 'minute');
    for (let i = 0; i < MAX_BLOCKS_DAILY / 2; i += 1) {
      blockList[i] = startTime.add(i * MINUTES_PER_UNIT, 'minute');
    }
    setTimeBlocks(blockList);
  }, [newTimePoint, minuteSlotsPerScreen]);

  useEffect(() => {
    const timeToFirstUpdate = getMinutesTimeToFirstShift();
    // Every 30 mins, automate scroll 30 mins offset
    const timeoutId = setTimeout(() => {
      intervalIdRef.current = setInterval(() => {
        onTimelineScroll(MINUTES_PER_UNIT);
      }, MINUTES_PER_UNIT * 60 * 1000);
      onTimelineScroll(MINUTES_PER_UNIT);
    }, timeToFirstUpdate * 60 * 1000);

    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalIdRef.current);
    };
  }, [initialTime, onTimelineScroll]);

  return (
    <div className={styles.timelinesCont}>
      <button
        type="button"
        className={classnames(styles.scrollButton, styles.scrollButtonLeft, {
          [styles.leftScrollDisabled]: isLeftScrollDisabled,
        })}
        onClick={() => onPageChange(false)}
        data-testid="scroll-time-left-button"
        name="TV guide timeline scroll left button"
      >
        <Left className={styles.scrollButtonIcon} />
      </button>
      <button
        type="button"
        className={classnames(styles.scrollButton, styles.scrollButtonRight, {
          [styles.rightScrollDisabled]: isRightScrollDisabled,
        })}
        onClick={() => onPageChange(true)}
        data-testid="scroll-time-right-button"
        name="TV guide timeline scroll right button"
      >
        <Right className={styles.scrollButtonIcon} />
      </button>
      <div className={styles.timelines} ref={timelineElement}>
        <div className={styles.timeBlocks}>
          {timeBlocks.map(blockTime => (
            <TimeBlock
              key={`timeline_${blockTime.unix()}`}
              blockTime={blockTime}
              initialTime={initialTime}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Timeline;
