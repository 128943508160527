import { useMemo } from 'react';
import { usePrevious } from 'react-use';

const useIsLoaded = isLoading => {
  const previousIsLoading = usePrevious(isLoading);
  return useMemo(() => {
    // TODO this is a temp solution for check whether the content is loaded or not. Ideally, we should set a different initial value in app-store
    return isLoading === false && previousIsLoading === true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);
};

export default useIsLoaded;
