import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';

import { ReactComponent as Left } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_chevron_back.svg';
import { ReactComponent as Right } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_chevron_forward.svg';

import useScrollList from '@/Hooks/useScrollList';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  buttonClassName?: string;
  isShow?: boolean;
  children: any;
  selectedTabIndex: any;
}

const TabsList: React.FC<Props> = ({
  children,
  isShow,
  className,
  buttonClassName,
  selectedTabIndex,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollingElement = useRef<HTMLDivElement>(null);

  const {
    isLeftScrollDisabled,
    isRightScrollDisabled,
    onPaginate,
    initScrollButtons,
    onInitialLoad,
  } = useScrollList(containerRef, scrollingElement);

  // Need calculate position again once visibility changes
  useEffect(() => {
    initScrollButtons();
    onInitialLoad(selectedTabIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow, children, initScrollButtons, selectedTabIndex]);
  return (
    <div ref={containerRef} className={classnames(styles.scrollContainer, className)}>
      <div
        ref={scrollingElement}
        className={styles.scrollList}
        data-testid="scrollable-list-content"
      >
        {children}
      </div>

      <button
        type="button"
        className={classnames(buttonClassName, styles.scrollButton, styles.scrollButtonLeft, {
          [styles.scrollButtonDisabled]: isLeftScrollDisabled,
        })}
        onClick={() => onPaginate(false)}
        data-testid="scroll-left-button"
        name="Tabs scroll left button"
      >
        <Left className={styles.scrollButtonIcon} />
      </button>

      <button
        type="button"
        className={classnames(buttonClassName, styles.scrollButton, styles.scrollButtonRight, {
          [styles.scrollButtonDisabled]: isRightScrollDisabled,
        })}
        onClick={() => onPaginate(true)}
        data-testid="scroll-right-button"
        name="Tabs scroll right button"
      >
        <Right className={styles.scrollButtonIcon} />
      </button>
    </div>
  );
};

export default TabsList;
