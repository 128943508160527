import React, { FC, useCallback, useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { Button } from 'antd';

import { parentalPinCreated, parentalPinCreationInitiated } from '@/Analytics/Segment';
import { Classification } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import { actions } from '@/Store';
import Preloader from '@/Layouts/containers/Preloader';
import { formatClassification } from '@/Utils/Rating';
import useSimpleModal from '@/Components/Modal/useSimpleModal';

import { CreatePinStage, initialPin, PIN_INPUT_NUMBER } from './ParentalTypes';
import PinInputControl from './PinInputControl/PinInputControl';

import styles from './styles.module.scss';

export interface CreatePinPanelProps {
  onPinCreated(): void;
  contentClassification?: Classification;
  playerReferrer?: string;
}

const PIN_CREATE_ERROR = 'There was a problem saving your new PIN. Please try again later.';
export const PIN_NOT_MATCH_ERROR =
  'The PINs you entered do not match. Please start over and create a new PIN';

const CreatePinPanel: FC<CreatePinPanelProps> = ({
  playerReferrer,
  onPinCreated,
  contentClassification,
}) => {
  const { openModal } = useSimpleModal();

  const dispatch = useDispatch();

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const [isSubmitting, setSubmitting] = useState(false);
  const [isPinCompleted, setPinCompleted] = useState(true);
  const [pinError, setPinError] = useState(false);
  const [pin, setPin] = useState(initialPin);
  const [previousPin, setPreviousPin] = useState(initialPin);
  const [stage, setStage] = useState<CreatePinStage>(CreatePinStage.create);

  const handleCreatePin = useCallback(
    async (pinCode: string) => {
      // Call API request
      const res = await dispatch(actions.parentalPin.setParentalPin(pinCode));

      if (res.error) {
        setSubmitting(false);
        openModal({
          content: PIN_CREATE_ERROR,
        });
      } else {
        // Call upper component that PIN is created
        onPinCreated();

        parentalPinCreated({
          category_name: 'New',
          view_name: playerReferrer,
        });

        // Re-fetch the parental setting to get the latest control status
        dispatch(actions.parentalPin.fetchParentalSettings());
      }
    },
    [dispatch, onPinCreated, openModal, playerReferrer],
  );

  const handlePinSubmit = useCallback(
    async event => {
      event.preventDefault();

      // If pin is only completed
      if (isPinCompleted) {
        // On Create stage
        if (stage === CreatePinStage.create) {
          setPin(initialPin);
          setPreviousPin(pin);
          setStage(CreatePinStage.confirm);
        }
        // On Confirm stage
        else if (stage === CreatePinStage.confirm) {
          const pinCode = pin.join('');

          // Check if Pin is matched
          if (previousPin.join('') === pinCode) {
            setSubmitting(true);
            // PIN creating process
            handleCreatePin(pinCode);
          } else {
            setPinError(true);
            setPin(initialPin);
            setPreviousPin(initialPin);
            setStage(CreatePinStage.create);
          }
        }
      }
    },
    [isPinCompleted, stage, pin, previousPin, handleCreatePin],
  );

  const handlePinChange = useCallback(newPin => {
    setPin(newPin);
    if (newPin.every(Boolean)) {
      submitButtonRef.current?.focus();
    }
  }, []);

  useEffect(() => {
    const isCompleted = pin.every(Boolean);
    setPinCompleted(isCompleted);
    // hide the error if entered all the PINs
    if (isCompleted) {
      setPinError(false);
    }
  }, [pin]);

  useEffect(() => {
    parentalPinCreationInitiated();
  }, []);

  return (
    <form className={styles.pinFormPanel} onSubmit={handlePinSubmit}>
      <h1 data-testid="create-pin-panel-title">
        {stage === CreatePinStage.create ? 'Create a new PIN' : 'Confirm your new PIN'}
      </h1>
      <div className={styles.description}>
        {`This content is rated as ${formatClassification(
          contentClassification,
        )}+. Please create your Parental Control PIN.
        You can adjust it any time via My Account settings on `}
        <strong>sky.co.nz</strong>
      </div>
      <PinInputControl
        className={styles.control}
        pin={pin}
        pinTotal={PIN_INPUT_NUMBER}
        onPinChange={handlePinChange}
        error={pinError && PIN_NOT_MATCH_ERROR}
      />
      <div className={styles.actionButtons}>
        <Button
          ref={submitButtonRef}
          className={classnames(styles.button, {
            [styles.buttonDisabled]: !isPinCompleted,
          })}
          type="primary"
          htmlType="submit"
          data-testid="parental-control-submit-button"
          name="Parental control submit button"
        >
          let&apos;s go
        </Button>
      </div>
      <Preloader isLoading={isSubmitting} />
    </form>
  );
};

export default CreatePinPanel;
