import React from 'react';

import { Maybe, Season } from '@skytvnz/sky-app-store/lib/types/graph-ql';

import TabSelector, { TabButton } from '@/Components/TabSelector';
import { SeasonItem } from '../types';

interface Props {
  seasons: Maybe<Season>[];
  selectedSeason: Season | null | undefined;
  itemType: SeasonItem; // Denotes whether the season is for normal tvShow or Spotify soundtrack.
  onSeasonChange: (season: Season) => void;
}

const SeasonSelector: React.FC<Props> = props => {
  const { seasons, selectedSeason, itemType, onSeasonChange } = props;

  const isSelected = clickedSeason => {
    return clickedSeason.id === selectedSeason?.id;
  };

  const onClickHandler = clickedSeason => {
    if (isSelected(clickedSeason)) {
      return;
    }

    onSeasonChange(clickedSeason);
  };

  return (
    <TabSelector>
      {seasons.map(
        season =>
          season && (
            <TabButton
              key={`seasons_${season?.id}`}
              id={season.id}
              text={`Season ${season.number}`}
              isSelected={isSelected(season)}
              testId={`${itemType}-season-button-season-nr-${season.number}`}
              name={`${itemType} season ${season.number} button`}
              onClick={() => onClickHandler(season)}
            />
          ),
      )}
    </TabSelector>
  );
};

export default SeasonSelector;
