import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isNil } from 'ramda';

import {
  Maybe,
  Movie,
  Schedule,
  Show,
  SkySubscription,
} from '@skytvnz/sky-app-store/lib/types/graph-ql';
import { BrandType } from '@skytvnz/sky-app-store/lib/types/enums/BrandType';
import externalApplicationLinksConfig from '@/Config/ExternalApplicationLinksConfig';

import { selectors, utils } from '@/Store';
import useLinkAccount, { AccountType } from '@/Hooks/useLinkAccount';
import useSimpleModal from '@/Components/Modal/useSimpleModal';
import {
  recordUpgradeEncountered,
  recordUpgradeMessageDismissed,
  recordUpgradePursued,
  recordLinkAccountOrUpgradeEncountered,
  recordAccontLinkPursued,
} from '@/Analytics/SubscriptionUpgrade';
import { ButtonStyle } from '@/Components/Modal/SimpleModal';
import useAuth from '@/Hooks/useAuth';

export const LINK_OR_SUBSCRIBE_TITLE = 'Please Link or Subscribe';
export const getLinkOrSubscribeSubTitle = (showRecordMessage?: boolean | undefined) => (
  <p>
    To {showRecordMessage ? 'record' : 'watch'} this content, please link your Sky account or
    subscribe on <strong>sky.co.nz</strong>
  </p>
);
export const LINK_ACCOUNT_TEXT = 'LINK Sky ACCOUNT';
export const SUBSCRIBE_SKY_TEXT = 'SUBSCRIBE TO Sky';

export const SUBSCRIPTIONS_MODAL_TITLE = 'Upgrade your Sky Subscription';
export const UPGRADE_TEXT = 'Upgrade To Watch';
export const LOGIN_OR_REGISTER_TEXT = 'LOGIN OR REGISTER';

export const createSubscriptionsModalContent = (subscriptions: Maybe<SkySubscription>[]) => `
  Sorry this content isn’t included in your current Sky subscription package. \n
  Please upgrade to ${subscriptions
    .reduce<string[]>((acc, subscription) => {
      if (subscription) {
        acc.push(subscription.title);
      }
      return acc;
    }, [])
    .join(' or ')} package to watch.
`;

interface ModalParameters {
  categoryName: string | undefined;
  contentId: string;
  contentTitle: string | undefined;
  contentType: string | undefined;
  viewName: string | undefined;
  channelName?: string[];
}

export const getTitleFromSubscription = (subscriptions: Maybe<SkySubscription>[]): string[] => {
  return subscriptions.reduce<string[]>((acc, subscription) => {
    if (subscription) {
      acc.push(subscription.title);
    }
    return acc;
  }, []);
};

export const useLinkOrSubscribeModal = () => {
  const { openModal } = useSimpleModal();
  const userSubscriptions = useSelector(selectors.subscription.subscriptions);

  const showLinkOrSubscribeModal = useCallback(
    (
      subscriptions?: Maybe<SkySubscription>[],
      params?: ModalParameters,
      showRecordMessage?: boolean,
    ) => {
      const subscriptionTitles = getTitleFromSubscription(userSubscriptions);
      const requiredSubscriptionTitles = getTitleFromSubscription(
        subscriptions as SkySubscription[],
      );

      if (params) {
        recordLinkAccountOrUpgradeEncountered(
          params.categoryName,
          params.contentId,
          params.contentTitle,
          params.contentType,
          params.viewName,
          subscriptionTitles,
          requiredSubscriptionTitles,
          params.channelName,
        );
      }

      openModal({
        title: LINK_OR_SUBSCRIBE_TITLE,
        content: getLinkOrSubscribeSubTitle(showRecordMessage),
        okText: LINK_ACCOUNT_TEXT,
        cancelable: true,
        cancelText: SUBSCRIBE_SKY_TEXT,
        cancelButtonStyle: ButtonStyle.PRIMARY,
        onClose: () => {},
        onCancel: () => {
          recordUpgradePursued(subscriptionTitles, requiredSubscriptionTitles);
          window.open(externalApplicationLinksConfig.subscribeUrl);
        },
        onOk: () => {
          recordAccontLinkPursued();
          window.open(externalApplicationLinksConfig.linkAccountUrl);
        },
      });
    },
    [openModal, userSubscriptions],
  );
  return showLinkOrSubscribeModal;
};

export const useSubscriptionModal = () => {
  const { openModal } = useSimpleModal();
  const showLinkOrSubscribeModal = useLinkOrSubscribeModal();
  const { isAccountLinked } = useLinkAccount();
  const userSubscriptions = useSelector(selectors.subscription.subscriptions);

  const showUpgradeSubscriptionModal = useCallback(
    (
      subscriptions?: Maybe<SkySubscription>[],
      params?: ModalParameters,
      showRecordMessage?: boolean,
    ) => {
      if (subscriptions) {
        const subscriptionTitles = getTitleFromSubscription(userSubscriptions);
        const requiredSubscriptionTitles = getTitleFromSubscription(subscriptions);
        if (!isAccountLinked(AccountType.SkyDth)) {
          showLinkOrSubscribeModal(subscriptions, params, showRecordMessage);
          return;
        }

        if (params) {
          recordUpgradeEncountered(
            params.categoryName,
            params.contentId,
            params.contentTitle,
            params.contentType,
            params.viewName,
            subscriptionTitles,
            requiredSubscriptionTitles,
            params.channelName,
          );
        }

        openModal({
          title: SUBSCRIPTIONS_MODAL_TITLE,
          content: createSubscriptionsModalContent(subscriptions),
          okText: UPGRADE_TEXT,
          cancelable: true,
          // TODO: Upgrade to watch action
          onOk: () => {
            recordUpgradePursued(subscriptionTitles, requiredSubscriptionTitles);
            window.open(externalApplicationLinksConfig.upgradeSubscriptionUrl);
          },
          onCancel: () => {
            recordUpgradeMessageDismissed(subscriptionTitles, requiredSubscriptionTitles);
          },
          onClose: () => {
            recordUpgradeMessageDismissed(subscriptionTitles, requiredSubscriptionTitles);
          },
        });
      }
    },
    [openModal, isAccountLinked, showLinkOrSubscribeModal, userSubscriptions],
  );

  return showUpgradeSubscriptionModal;
};

export const useVodRequiredSubscriptions = () => {
  const auth = useAuth();
  const getShowEpisode = useSelector(selectors.titles.getShowEpisode);
  const getMovie = useSelector(selectors.titles.getMovie);

  return (brandId: string, episodeId?: string) => {
    let schedule: Maybe<Schedule> | undefined;

    if (!auth?.isAuthenticated) {
      return undefined;
    }

    if (episodeId) {
      const episode = getShowEpisode(brandId, episodeId);
      if (episode?.mySchedule) {
        return undefined;
      }
      schedule = episode?.schedule;
    } else {
      const movie = getMovie(brandId);
      if (movie?.mySchedule) {
        return undefined;
      }
      schedule = movie?.schedule;
    }

    return schedule?.subscriptions;
  };
};

export const useChannelSubscriptions = () => {
  const auth = useAuth();

  const getChannel = useSelector(selectors.channels.getChannel);
  return (channelId?: string) => {
    if (!auth?.isAuthenticated) {
      return undefined;
    }

    if (channelId) {
      const channel = getChannel(channelId);
      return utils.entitlements.getChannelSubscription(channel);
    }
    return undefined;
  };
};

export const useSlotSubscriptions = () => {
  const auth = useAuth();

  return slot => {
    if (!auth?.isAuthenticated) {
      return undefined;
    }
    return utils.entitlements.getSlotSubscriptions(slot);
  };
};

const isLinearOnlyMovieOrShow = (brand: Movie | Show): boolean => {
  if (brand.__typename === BrandType.Movie && isNil(brand.asset)) {
    return true;
  }

  if (brand.__typename === BrandType.Show && (isNil(brand.seasons) || isEmpty(brand.seasons))) {
    return true;
  }

  return false;
};

export const useCheckVodOrChannelEntitled = () => {
  const requiresSubscriptions = useVodRequiredSubscriptions();
  return (brand: Movie | Show): boolean => {
    const firstAvailableSlot = utils.title.getFirstAvailableSlot(brand);
    const isSlotLive = utils.slot.isSlotOnAir(firstAvailableSlot);
    let isEntitled = false;

    if (isSlotLive || isLinearOnlyMovieOrShow(brand)) {
      // Entitlement subscription check for live tv
      isEntitled = !utils.entitlements.getSlotSubscriptions(
        isNil(firstAvailableSlot) ? undefined : firstAvailableSlot,
      );
    } else if (brand.__typename === BrandType.Movie) {
      // Entitlement subscription check for vod movie
      isEntitled = !requiresSubscriptions(brand.id);
    } else if (brand.__typename === BrandType.Show) {
      // Entitlement subscription check for vod show
      isEntitled = !requiresSubscriptions(brand.id, brand.defaultEpisode?.id);
    }
    return isEntitled;
  };
};

export const usePlaybackSubscriptions = () => {
  const getSubscriptions = useSelector(selectors.playback.getSubscriptions);
  return (mediaAssetId?: string) => {
    if (mediaAssetId) {
      return getSubscriptions(mediaAssetId);
    }
    return null;
  };
};

export const useUserSubscriptions = () => {
  const userSubscriptions = useSelector(selectors.subscription.subscriptions);
  return userSubscriptions;
};
