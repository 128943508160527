export enum EventType {
  Upcoming = 'PRE_EVENT',
  Live = 'LIVE_EVENT',
  Post = 'POST_EVENT',
}

export enum PageType {
  Home = 'Home',
  Detail = 'Detail',
}
