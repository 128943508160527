import React from 'react';
import { Button } from 'antd';
import { isNil } from 'ramda';
import classnames from 'classnames';

import { ReactComponent as SpotifyLogo } from '@skytvnz/sky-app-style/lib/assets/svg/logos/icon_spotify.svg';
import { ReactComponent as Plus } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_plus.svg';
import { ReactComponent as Tick } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_tick.svg';

import Content from '@/Layouts/containers/Content';

import styles from './styles.module.scss';

interface Props {
  albumId: string;
  onAlbumSave: any;
  isAlbumSaved: boolean;
  isLoggedIn: boolean;
}

const Footer: React.FC<Props> = props => {
  const { albumId, onAlbumSave, isAlbumSaved, isLoggedIn } = props;

  return (
    <Content className={styles.soundtrackFooter}>
      {isLoggedIn && !isNil(albumId) && !isNil(isAlbumSaved) && (
        <Button
          className={classnames(styles.linkBtn, styles.addToListBtn)}
          type="link"
          onClick={() => onAlbumSave(albumId)}
        >
          {isAlbumSaved ? (
            <Tick className={styles.footerBtnIcon} />
          ) : (
            <Plus className={styles.footerBtnIcon} />
          )}
          <span className={styles.footerBtnText}>{isAlbumSaved ? 'ADDED' : 'ADD'} TO SPOTIFY</span>
        </Button>
      )}

      {isLoggedIn && (
        <SpotifyLogo className={classnames(styles.spotifyLogo, styles.spotifyLogoLogin)} />
      )}
    </Content>
  );
};

export default Footer;
