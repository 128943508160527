import React from 'react';
import classnames from 'classnames';
import styles from '@/Pages/Detail/Tiles/styles.module.scss';
import { convertDuration } from '@/Utils/DurationFormatter';
import { formatClassification, joinAdvisories } from '@/Utils/Rating';
import { Episode, Maybe } from '@skytvnz/sky-app-store/lib/types/graph-ql';

interface Props {
  episode: Maybe<Episode>;
}

const EpisodeSubtitle: React.FC<Props> = props => {
  const { episode } = props;

  return (
    <p
      className={classnames([styles.episodeDuration, styles.subtitle])}
      data-testid={`episode-duration-episode-nr-${episode?.number}`}
    >
      <span>
        {convertDuration(episode?.duration)}
        {episode?.rating?.classification &&
          ` • ${formatClassification(episode?.rating?.classification)}`}
        {episode?.rating?.advisories &&
          episode?.rating?.advisories.length > 0 &&
          ` • ${joinAdvisories(episode?.rating?.advisories)}`}
      </span>
    </p>
  );
};

export default EpisodeSubtitle;
