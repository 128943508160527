import React from 'react';
import classnames from 'classnames';

import { ReactComponent as Logo } from '@skytvnz/sky-app-style/lib/assets/svg/logos/logo_skygo.color.svg';
import { ReactComponent as AppStoreLogo } from '@skytvnz/sky-app-style/lib/assets/svg/logos/icon_app_store_download.color.svg';
import { ReactComponent as PlayStoreLogo } from '@skytvnz/sky-app-style/lib/assets/svg/logos/icon_play_store_download.color.svg';

import { ANDROID_APP_LINK, IOS_APP_LINK } from '@/Utils/StoreLinks';
import Grid from '@/Layouts/containers/Grid';
import Cell from '@/Layouts/containers/Grid/Cell';
import styles from './styles.module.scss';

const FOOTER_LINKS = [
  [
    {
      id: 'footer-about-sky-go',
      text: 'About Sky Go',
      link: 'https://help.sky.co.nz/s/all-about-your-sky-go',
    },
    {
      id: 'footer-support',
      text: 'Support',
      link: 'https://help.sky.co.nz/s/sky-go',
    },
    {
      id: 'footer-contact-us',
      text: 'Contact Us',
      link: 'https://help.sky.co.nz/s/sky-go-contact-support',
    },

    { id: 'footer-sky-open', text: 'Sky Open', link: 'https://www.sky.co.nz/skyopen' },
  ],
  [
    { id: 'footer-sky', text: 'Sky', link: 'http://www.sky.co.nz' },
    {
      id: 'footer-terms-of-use',
      text: 'Terms of Use',
      link: 'https://www.sky.co.nz/digital-content-terms',
    },
    {
      id: 'footer-privacy-policy',
      text: 'Privacy Policy',
      link: 'https://www.sky.co.nz/privacy-policy',
    },

    { id: 'footer-sky-sport', text: 'Sky Sport', link: 'https://www.sky.co.nz/discover/sky-sport' },
  ],
  [
    { id: 'footer-my-account', text: 'My Account', link: 'https://account.sky.co.nz/' },
    {
      id: 'footer-feedback',
      text: 'Feedback',
      link: 'https://help.sky.co.nz/s/sky-go-contact-support',
    },
    {
      id: 'footer-remote-record',
      text: 'Remote Record',
      link: 'https://help.sky.co.nz/s/article/Remote-Record-to-your-Sky-box-from-Sky-Go',
    },
    { id: 'footer-tv-guide', text: 'TV Guide', link: 'https://tvguide.sky.co.nz/' },
  ],
];
const Footer: React.FC = () => {
  return (
    <footer className={styles.appFooter}>
      <Grid rowGap>
        <Cell span={12} spanS={12} spanM={2} className={classnames(styles.cell, styles.logoCell)}>
          <a href="/" data-testid="footer-logo">
            <Logo className={styles.logo} />
          </a>
        </Cell>
        {FOOTER_LINKS.map((group, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Cell key={index} span={6} spanS={4} spanM={2} className={styles.cell}>
            {group.map(({ id, text, link }) => (
              <span key={id} className={styles.skyLink}>
                <a href={link} target="_blank" rel="noopener noreferrer" data-testid={id}>
                  {text}
                </a>
              </span>
            ))}
          </Cell>
        ))}

        <Cell
          span={12}
          spanS={12}
          spanM={4}
          className={classnames(styles.cell, styles.storeLogoCell)}
        >
          <a
            href={IOS_APP_LINK}
            data-testid="footer-app-store-logo"
            className={styles.storeLogoLink}
          >
            <AppStoreLogo className={styles.storelogo} />
          </a>
          <a
            href={ANDROID_APP_LINK}
            data-testid="footer-play-store-logo"
            className={styles.storeLogoLink}
          >
            <PlayStoreLogo className={styles.storelogo} />
          </a>
          <span
            className={classnames(styles.skyLink, styles.copyright)}
            data-testid="footer-copyright"
          >
            Copyright &copy; {new Date().getFullYear()} Sky Network Television Limited
          </span>
        </Cell>
      </Grid>
    </footer>
  );
};

export default Footer;
