import { Button } from 'antd';
import classnames from 'classnames';
import React, { FC } from 'react';

import { ReactComponent as MoreEpisodes } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_episodes.svg';
import styles from './styles.module.scss';

interface MoreEpisodesButtonProps {
  onClick(): void;
  isSport: boolean;
}

const MoreEpisodesButton: FC<MoreEpisodesButtonProps> = ({ onClick, isSport }) => (
  <Button
    type="primary"
    className={classnames(styles.moreEpisodes, styles.controlButton)}
    onClick={onClick}
    data-testid="more-episodes-button"
    name="More episodes button"
  >
    <MoreEpisodes className={styles.controlButtonIcon} />
    <span className={styles.controlButtonSpan}>{isSport ? 'View More' : 'More Episodes'}</span>
  </Button>
);

export default MoreEpisodesButton;
