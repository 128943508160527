import { useDispatch } from 'react-redux';
import { actions } from '@/Store';
import { useCallback } from 'react';

const useUserResource = () => {
  const dispatch = useDispatch();

  return useCallback(async () => {
    // Fetch login user's current subscriptions
    dispatch(actions.subscription.fetchSubscriptions());
    // Fetch current user's set top box decoders
    dispatch(actions.remoteRecord.fetchDecoders());
    // Load user attributes, could be an async request later
    await dispatch(actions.userAttributes.fetchUserAttributes());
  }, [dispatch]);
};

export default useUserResource;
