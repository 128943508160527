import React from 'react';
import useCustomerProfile from '@/Hooks/useCustomerProfile';
import useAuth from '@/Hooks/useAuth';
import Background, { BackgroundType } from '@/Layouts/containers/Background';
import ManageProfiles from '@/Pages/CustomerProfile/ManageProfiles';
import useIsProfilePages from '@/Hooks/useIsProfilePages';
import { SELECT_PROFILE, MANAGE_PROFILE } from '../CustomerProfile/constants';
import UserSplitTreatments from '../FeatureToggle/UserSplitTreatments';

interface Props {
  children?: any;
}

const ProfileManager: React.FC<Props> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { selectedProfile } = useCustomerProfile();
  const PROFILE_FEATURE_NAME = 'customer-profiles-web';
  const isProfilePage = useIsProfilePages();

  return (
    <UserSplitTreatments featureNames={[PROFILE_FEATURE_NAME]}>
      {({ treatments, isReady }) => {
        const profileTrat = treatments[PROFILE_FEATURE_NAME]?.treatment;
        if (isReady) {
          return profileTrat === 'on' &&
            ((isAuthenticated && !selectedProfile) || isProfilePage) ? (
            <Background type={BackgroundType.Default}>
              <ManageProfiles pageType={!selectedProfile ? SELECT_PROFILE : MANAGE_PROFILE} />
            </Background>
          ) : (
            <React.Fragment key={selectedProfile?.id || undefined}>{children}</React.Fragment>
          );
        }
        return null;
      }}
    </UserSplitTreatments>
  );
};

export default ProfileManager;
