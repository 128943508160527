import React, { FC } from 'react';
import classnames from 'classnames';
import Fade from 'react-reveal/Fade';
import { isNil, isEmpty } from 'ramda';

import { ImageAssetType } from '@skytvnz/sky-app-store/lib/types/enums/ImageAssetType';
import Content from '@/Layouts/containers/Content';
import VideoImage from '@/Components/VideoImage';

import styles from './styles.module.scss';

const NUMBER_OF_GENRES_TO_DISPLAY = 2;

interface HeroProps {
  image: string;
  imageType: ImageAssetType;
  imageTitle: string;
  className?: string;
  testId?: string;
}

export const getGenres = genreList => {
  if (!isNil(genreList) && !isEmpty(genreList)) {
    return genreList.slice(0, NUMBER_OF_GENRES_TO_DISPLAY).join(', ');
  }
  return '';
};

const HeroBox: FC<HeroProps> = ({ children, className, image, imageType, imageTitle, testId }) => {
  return (
    <Fade>
      <div className={classnames(styles.heroBanner, className)} data-testid={testId}>
        <div className={styles.imageContainer} data-test-id="hero-image-container">
          <VideoImage
            className={styles.image}
            src={image}
            type={imageType}
            alt={`The poster of "${imageTitle}"`}
            data-testid="hero-image"
          />
        </div>
        <div className={styles.mask} />
        <Content>{children}</Content>
      </div>
    </Fade>
  );
};

export default HeroBox;
