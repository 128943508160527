import React, {
  FC,
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useLayoutEffect,
} from 'react';
import throttle from 'lodash.throttle';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useGesture } from 'react-use-gesture';

import { LinearChannel, LinearSlot, Maybe } from '@skytvnz/sky-app-store/lib/types/graph-ql';

import { actions, selectors } from '@/Store';
import Content from '@/Layouts/containers/Content';
import usePersistCallback from '@/Hooks/usePersistCallback';
import Preloader from '@/Layouts/containers/Preloader';
import useSlotDetails, { useGlobalSlotView } from '@/Pages/TVGuide/SlotDetails/useSlotDetails';

import { createGlobalState } from 'react-use';
import { isNil, isEmpty } from 'ramda';
import Timeline, { getFirstShiftTime } from '../Timeline';
import TimeMarker from '../TimeMarker';
import ChannelColumn from './ChannelColumn';
import { MARKER_UPDATE_FREQUENCY_MIN, MINUTES_PER_UNIT, TIMELINE_BUTTON_WIDTH } from '../constants';
import { useTimeWidthUnit } from './useTimeWidthUnit';
import SlotsRow from './SlotsRow';

import styles from './styles.module.scss';

interface SlotsGridProps {
  categoryId: string;
  firstStartTime: dayjs.Dayjs;
  onChannelIconClick?: (channel: Omit<LinearChannel, 'slots'>, channelIds: string[]) => void;
  onDateShift?: any;
  selectedChannelGroup?: string;
  weekday?: string;
  daysFromToday?: number;
}
const useGlobalScrollTimeOffset = createGlobalState<number>(0);
const useGlobalNewTimePoint = createGlobalState<dayjs.Dayjs>(dayjs());
export const useGlobalInitialTime = createGlobalState<dayjs.Dayjs>();

const SlotsGrid: FC<SlotsGridProps> = ({
  categoryId,
  firstStartTime,
  onChannelIconClick,
  onDateShift,
  selectedChannelGroup,
  weekday,
  daysFromToday,
}) => {
  const dispatch = useDispatch();
  const { openDetails } = useSlotDetails();
  const [globalSlotView, setGlobalSlotView] = useGlobalSlotView();

  const widthUnit = useTimeWidthUnit();

  /**
   * Variables
   */
  const firstShiftStartTime = useMemo(() => getFirstShiftTime(firstStartTime), [firstStartTime]);

  const gridContainer = useRef<HTMLDivElement>(null);
  const gridElement = useRef<HTMLDivElement>(null);

  /**
   * State variables
   */
  // How many 30 minutes slots in one screen viewport
  const [minuteSlotsPerScreen, setMinuteSlotsPerScreen] = useState(0);
  // Latest reachable time point user can scroll to
  const [maxScrollTime, setMaxScrollTime] = useState(dayjs().add(7, 'd'));
  // The new time point after scroll
  const [newTimePoint, setNewTimePoint] = useGlobalNewTimePoint();
  // Scrolling time offset for translate position
  const [scrollTimeOffset, setScrollTimeOffset] = useGlobalScrollTimeOffset();
  // Scrolling Y offset of the page window
  const [scrollOffsetY, setScrollOffsetY] = useState(0);

  const [initialTime, setInitialTime] = useGlobalInitialTime();
  // Store current time in state for slot mask automation
  const [currentTime, setCurrentTime] = useState(dayjs());

  const rewindLoadingTimeRange = minuteSlotsPerScreen * MINUTES_PER_UNIT;
  const forwardLoadingTimeRange = (minuteSlotsPerScreen * 2 + 1) * MINUTES_PER_UNIT;

  /**
   * Ref constant variables
   */
  // The very first initial time point, will never been changed, use for calculating layout position
  const firstTimePointRef = useRef(firstShiftStartTime);
  // Previous start time value
  const currentTimePointRef = useRef(firstShiftStartTime);
  // Previous translate position value
  const currentTranslatePosition = useRef(scrollTimeOffset || 0);
  // Is Grid being swiping, to avoid open Slot Detail
  const isSwipingRef = useRef(false);
  // Scroll total minutes for fetching API
  const scrollTotalMinutesData = useRef(0);
  // Scroll total minutes for rendering slots
  const scrollTotalMinutesRendering = useRef(0);

  /**
   * Data relating variables
   */
  const channels = useSelector(selectors.channels.getChannelsByCategoryId)(categoryId);
  const slotsIsLoading = useSelector(selectors.epg.slotsByChannelIdIsLoading);
  // Rendering Slots is from previous one screen size hrs + next 2 screen size hrs
  const [channelSlots, setChannelSlots] = useState<Maybe<LinearSlot>[][]>();
  const getChannelSlots = useSelector(selectors.epg.getSlotsByTimeRange);

  /**
   * Callback function
   */

  useEffect(() => {
    if (isNil(initialTime) && !isNil(firstShiftStartTime)) {
      setInitialTime(firstShiftStartTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstShiftStartTime, initialTime]);

  useEffect(() => {
    const slots = getChannelSlots(
      categoryId,
      (newTimePoint as dayjs.Dayjs).subtract(rewindLoadingTimeRange, 'minute').toISOString(),
      (newTimePoint as dayjs.Dayjs).add(forwardLoadingTimeRange, 'minute').toISOString(),
    );
    setChannelSlots(slots);
  }, [categoryId, newTimePoint, rewindLoadingTimeRange, forwardLoadingTimeRange, getChannelSlots]);

  const fetchSlotsByTimeRange = usePersistCallback((from, to) => {
    dispatch(actions.epg.fetchSlotsByTimeRange(from.toISOString(), to.toISOString()));
  });

  // Moving grid by specific minutes or distance
  const scrollTimeline = usePersistCallback((scrollMinutes: number, distance?: number) => {
    let translatePosition =
      currentTranslatePosition.current +
      (distance !== undefined ? distance : (scrollMinutes / MINUTES_PER_UNIT) * widthUnit);
    let nextTimePoint = currentTimePointRef.current.add(scrollMinutes, 'minute');

    if (nextTimePoint.isSame(maxScrollTime) || nextTimePoint.isBefore(maxScrollTime)) {
      if (translatePosition < 0) {
        translatePosition = 0;
        nextTimePoint = firstTimePointRef.current;
        currentTranslatePosition.current = 0;
      }

      currentTranslatePosition.current = translatePosition;
      setScrollTimeOffset(translatePosition);

      if (!nextTimePoint.isSame(currentTimePointRef.current, 'day')) {
        onDateShift(
          nextTimePoint,
          nextTimePoint.isAfter(currentTimePointRef.current, 'day') ? +1 : -1,
        );
      }
      currentTimePointRef.current = nextTimePoint;

      // Check if beyond the time range for next fetching
      scrollTotalMinutesData.current += scrollMinutes;
      scrollTotalMinutesRendering.current += scrollMinutes;

      if (Math.abs(scrollTotalMinutesRendering.current) >= rewindLoadingTimeRange) {
        setNewTimePoint(nextTimePoint);
        scrollTotalMinutesRendering.current =
          (scrollTotalMinutesRendering.current < 0 ? -1 : 1) *
          (Math.abs(scrollTotalMinutesRendering.current) - rewindLoadingTimeRange);
      }

      if (Math.abs(scrollTotalMinutesData.current) >= rewindLoadingTimeRange * 2) {
        if (scrollTotalMinutesData.current > 0) {
          // Forward timeline, fetch the next one screen size time range
          fetchSlotsByTimeRange(
            nextTimePoint.add(rewindLoadingTimeRange * 2, 'minute'),
            nextTimePoint.add(forwardLoadingTimeRange * 2, 'minute'),
          );
        } else {
          // Rewind timeline, fetch the past one screen size time range
          fetchSlotsByTimeRange(
            nextTimePoint.subtract(rewindLoadingTimeRange * 2, 'minute'),
            nextTimePoint,
          );
        }
        scrollTotalMinutesData.current =
          (scrollTotalMinutesData.current < 0 ? -1 : 1) *
          (Math.abs(scrollTotalMinutesData.current) - rewindLoadingTimeRange * 2);
      }
    }
  });

  const onSlotChannelItemClick = useCallback(
    channel => {
      onChannelIconClick?.(
        channel,
        channels.map(c => c.id),
      );
    },
    [onChannelIconClick, channels],
  );

  const openSlotDetail = useCallback(
    (slot, channel) => {
      // If grid is swiping by gesture, do not open the slot modal
      // DragEnd will also trigger MouseEnd & TouchEnd, so SlotCell Click will be triggered
      if (isSwipingRef.current) return;

      setGlobalSlotView({
        slot,
        channel,
        selectedChannelGroup,
        weekday,
        daysFromToday,
      });

      openDetails(slot, channel, selectedChannelGroup, weekday, daysFromToday);
    },
    [openDetails, setGlobalSlotView, selectedChannelGroup, weekday, daysFromToday],
  );

  /**
   * Life cycle
   */

  const throttleScrollingHandler = useCallback(
    throttle(() => {
      setScrollOffsetY(window.pageYOffset);
    }, 400),
    [],
  );

  useEffect(() => {
    window.addEventListener('scroll', throttleScrollingHandler);
    return () => window.removeEventListener('scroll', throttleScrollingHandler);
  }, [throttleScrollingHandler]);

  useEffect(() => {
    // Direct mutate the dom style, better performance
    if (gridElement.current?.style) {
      gridElement.current.style.transform = `translate3d(${-(scrollTimeOffset as number)}px, 0, 0)`;
    }
  }, [scrollTimeOffset]);

  // Fetch slots when minuteSlotsPerScreen changes
  useEffect(() => {
    const { current: currentTimePoint } = currentTimePointRef;
    if (minuteSlotsPerScreen > 0) {
      fetchSlotsByTimeRange(
        currentTimePoint.subtract(rewindLoadingTimeRange * 2, 'minute'),
        currentTimePoint.add(forwardLoadingTimeRange * 2, 'minute'),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minuteSlotsPerScreen, fetchSlotsByTimeRange]);

  // Handling Date filter selected date changes
  useEffect(() => {
    const timeDiffInSeconds = firstShiftStartTime.diff(currentTimePointRef.current, 'second');
    // Only if firstStartTime changed except the initial case
    if (Math.abs(timeDiffInSeconds) > 10) {
      fetchSlotsByTimeRange(
        firstShiftStartTime.subtract(rewindLoadingTimeRange * 2, 'minute'),
        firstShiftStartTime.add(forwardLoadingTimeRange * 2, 'minute'),
      );

      setNewTimePoint(firstShiftStartTime);

      const translatePosition =
        currentTranslatePosition.current + (timeDiffInSeconds / 60 / MINUTES_PER_UNIT) * widthUnit;
      currentTranslatePosition.current = translatePosition;
      currentTimePointRef.current = firstShiftStartTime;

      setScrollTimeOffset(translatePosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstShiftStartTime]);

  // Measure the screen size when page resize.
  useLayoutEffect(() => {
    let newMinutesPerScreen;
    if (gridContainer.current) {
      const gridWith = gridContainer.current.offsetWidth - TIMELINE_BUTTON_WIDTH;
      newMinutesPerScreen = Math.floor(gridWith / widthUnit);
    } else {
      newMinutesPerScreen = 0;
    }
    setMinuteSlotsPerScreen(newMinutesPerScreen);

    const newMaxScrollTime = dayjs()
      .startOf('d')
      .add(7, 'd')
      .subtract(newMinutesPerScreen * MINUTES_PER_UNIT, 'm');
    setMaxScrollTime(newMaxScrollTime);
  }, [widthUnit]);

  // Loading SlotView if there is global state when initializing
  useEffect(() => {
    if (globalSlotView) {
      const { slot, channel } = globalSlotView;
      openDetails(slot, channel, selectedChannelGroup, weekday, daysFromToday);
    }
    // globalSlotView can not be the dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDetails]);

  /**
   * Gesture support
   */

  const [isTransition, setIsTransition] = useState(true);

  // Gesture handlers
  const bindSwipe = useGesture({
    onDragStart: () => {
      isSwipingRef.current = false;
      setIsTransition(false);
    },
    onDrag: ({ movement: [x] }) => {
      if (x !== 0) isSwipingRef.current = true;

      const scrollMinutes = -((x * 1.5) / widthUnit) * MINUTES_PER_UNIT;
      const nextTimePoint = currentTimePointRef.current.add(scrollMinutes, 'minute');
      if (nextTimePoint.isSame(maxScrollTime) || nextTimePoint.isBefore(maxScrollTime)) {
        const translatePosition = currentTranslatePosition.current - x;
        if (translatePosition >= 0) {
          if (gridElement.current?.style) {
            gridElement.current.style.transform = `translate3d(${-translatePosition}px, 0, 0)`;
          }
        }
      }
    },
    onDragEnd: ({ movement: [x] }) => {
      setIsTransition(true);

      const distance = x * 1.5;
      const scrollMinutes = -(distance / widthUnit) * MINUTES_PER_UNIT;

      scrollTimeline(scrollMinutes, -distance);
    },
  });

  useEffect(() => {
    // Automate mask and arrow update every 1 minute
    const intervalId = setInterval(() => {
      setCurrentTime(dayjs());
    }, MARKER_UPDATE_FREQUENCY_MIN * 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Content className={styles.slotsGrid}>
      {channels && (
        <ChannelColumn channels={channels} onChannelIconClick={onSlotChannelItemClick} />
      )}

      <div className={styles.gridView} data-testid="slots-grid" ref={gridContainer}>
        <div className={styles.timeLinesSticky}>
          {initialTime && (
            <Timeline
              minuteSlotsPerScreen={minuteSlotsPerScreen}
              onTimelineScroll={scrollTimeline}
              newTimePoint={newTimePoint as dayjs.Dayjs}
              initialTime={initialTime as dayjs.Dayjs}
              scrollTimeOffset={scrollTimeOffset as number}
            />
          )}
        </div>

        {slotsIsLoading ? null : (
          <TimeMarker
            initialTime={initialTime as dayjs.Dayjs}
            scrollTimeOffset={scrollTimeOffset as number}
            currentTime={currentTime}
          />
        )}
        <div className={styles.viewListContainer}>
          <div
            className={classnames(styles.viewList, {
              [styles.viewListTransition]: isTransition,
            })}
            ref={gridElement}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...bindSwipe()}
          >
            {initialTime &&
              channels &&
              channelSlots?.map((slots, index) => {
                // hide the row when it's empty during loading
                if (slotsIsLoading && isEmpty(slots)) {
                  return null;
                }
                return (
                  <SlotsRow
                    key={`row_${channels[index]?.id}_${index}`}
                    slots={slots}
                    channel={channels[index]}
                    firstStartTime={initialTime as dayjs.Dayjs}
                    scrollOffset={scrollTimeOffset as number}
                    scrollOffsetY={scrollOffsetY}
                    openSlotDetail={openSlotDetail}
                    currentTime={currentTime}
                  />
                );
              })}
          </div>
        </div>

        <Preloader isLoading={slotsIsLoading} />
      </div>
    </Content>
  );
};

export default SlotsGrid;
