import React from 'react';

import { ImageAssetType } from '@skytvnz/sky-app-store/lib/types/enums/ImageAssetType';
import { Movie } from '@skytvnz/sky-app-store/lib/types/graph-ql';

import { utils } from '@/Store';
import { convertDuration } from '@/Utils/DurationFormatter';
import { formatGenres, getGenres } from '@/Utils/Genres';
import { PageType } from '@/Components/HeroBanner/HeroTypes';
import { formatRating } from '@/Utils/Rating';
import SlotProgress from '@/Components/SlotProgress/SlotProgress';
import Availability from '@/Pages/Detail/Seasons/Episodes/Availability';
import useMedia from '@/Hooks/useMedia';
import Synopsis from '@/Components/Synopsis';
import synopsisStyles from '@/Components/Synopsis/styles.module.scss';
import HeroBox from './HeroBox';
import HeroButtons from './HeroButtons';
import ControllingChannel from './ControllingChannel';

import styles from './styles.module.scss';

const NUMBER_OF_GENRES_TO_DISPLAY = 6;

interface Props {
  movie: Movie;
  image: string;
  imageType: ImageAssetType;
  pageType: PageType;
  className?: string;
  onMyListToggle?: any;
}

const MovieHeroBanner: React.FC<Props> = props => {
  const { movie, pageType, imageType, image, className, onMyListToggle } = props;
  const isHome = pageType === PageType.Home;
  const firstAvailableSlot = utils.title.getFirstAvailableSlot(movie);
  const isSlotOnAir = utils.slot.isSlotOnAir(firstAvailableSlot);
  const shouldHeroDisplaySlotDetail = utils.hero.shouldDisplaySlotDetail(movie);
  const synopsis = utils.hero.getSynopsis(movie);
  const heroImage = utils.hero.getImage(movie, image);

  const HeroDetails = () => {
    const genresList = getGenres(formatGenres(movie.allGenres), NUMBER_OF_GENRES_TO_DISPLAY);
    const duration = convertDuration(movie.duration);
    const { isMediaM } = useMedia();

    return (
      <>
        <div className={styles.textContentContainer}>
          <h1 data-testid="hero-title">{movie.title}</h1>
          <div className={styles.rowContainer}>
            {genresList && <span data-testid="hero-genres">{genresList}</span>}
            {shouldHeroDisplaySlotDetail && (
              <Availability id={0} startDate={firstAvailableSlot?.start} />
            )}
            <div className={styles.secondaryAttributes}>
              {duration && (
                <span className={styles.delimiter} data-testid="hero-duration">
                  {duration}
                </span>
              )}
              {movie.year && (
                <span className={styles.delimiter} data-testid="hero-year">
                  {movie.year}
                </span>
              )}
              {movie.rating && <span data-testid="hero-rating">{formatRating(movie.rating)}</span>}
            </div>
          </div>
          {isMediaM && (
            <Synopsis
              synopsis={synopsis}
              cast={movie.characters}
              className={synopsisStyles.synopsis}
            />
          )}
          {shouldHeroDisplaySlotDetail && isSlotOnAir && (
            <SlotProgress
              slotStart={firstAvailableSlot?.start}
              slotEnd={firstAvailableSlot?.end}
              channelTitle={firstAvailableSlot?.channel.title}
            />
          )}
          <HeroButtons brand={movie} isHome={isHome} onMyListToggle={onMyListToggle} />
        </div>
        {(isHome || isMediaM) && <ControllingChannel isHome={isHome} channels={movie.brands} />}
      </>
    );
  };

  return (
    <HeroBox
      className={className}
      imageTitle={movie.title}
      image={heroImage}
      imageType={imageType}
      testId="hero-banner-movie"
    >
      <HeroDetails />
    </HeroBox>
  );
};

export default MovieHeroBanner;
