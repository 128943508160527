import React, { useCallback, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import PropTypes, { InferProps } from 'prop-types';
import Icon from '@ant-design/icons';
import Fade from 'react-reveal/Fade';
import classnames from 'classnames';
import { isNil } from 'ramda';
import debounce from 'lodash.debounce';

import { ReactComponent as Play } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_play.svg';
import { ReactComponent as Pause } from '@skytvnz/sky-app-style/lib/assets/svg/icons/icon_pause.svg';
import useMedia from '@/Hooks/useMedia';
import styles from './styles.module.scss';

const propTypes = {
  isPlayBtnEnabled: PropTypes.bool,
  isCurrent: PropTypes.bool.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  uri: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  artist: PropTypes.string.isRequired,
  playbackProgress: PropTypes.number,
  onPlayPause: PropTypes.func.isRequired,
  onSeek: PropTypes.func.isRequired,
  isRewindInProgress: PropTypes.bool.isRequired,
};

const defaultProps = {
  isPlayBtnEnabled: false,
  playbackProgress: 0,
};

const Track: React.FC<InferProps<typeof propTypes>> = props => {
  const {
    isPlaying,
    isCurrent,
    uri,
    name,
    artist,
    playbackProgress,
    onPlayPause,
    onSeek,
    isPlayBtnEnabled,
    isRewindInProgress,
  } = props;

  const [isDraggingRewind, setIsDraggingRewind] = useState(false);
  const [progress, setProgress] = useState(playbackProgress);
  const [position, setPosition] = useState(0);

  const progressBarRef = useRef<HTMLDivElement>(null);
  const { isMediaM } = useMedia();

  const onSeekDebounce = useCallback(
    // Debounce will always have the last update.
    // But throttle will ignore some of the update during the time period
    debounce((newPercent: number) => {
      onSeek(newPercent);
    }, 500),
    [],
  );

  const updatePlaybackProgress = useCallback(newPosition => {
    const width = progressBarRef?.current?.getBoundingClientRect()?.width;
    let newProgress = 0;
    if (!isNil(width)) {
      newProgress = (100 / width) * newPosition;
      setPosition(newPosition);
      setProgress(newProgress);
    }
    return newProgress;
  }, []);

  const onRewindingStart = useCallback(() => {
    setIsDraggingRewind(true);
  }, []);

  const onRewindingStop = useCallback(
    (mouseEvent, rewindEvent) => {
      mouseEvent.stopPropagation();
      mouseEvent.preventDefault();
      setIsDraggingRewind(false);
      const newPercent = updatePlaybackProgress(rewindEvent.x);
      onSeekDebounce(newPercent);
    },
    [onSeekDebounce, updatePlaybackProgress],
  );

  const onRewinding = useCallback(
    (mouseEvent, rewindEvent) => {
      updatePlaybackProgress(rewindEvent.x);
    },
    [updatePlaybackProgress],
  );

  const onProgressBarClick = useCallback(
    event => {
      event.preventDefault();
      const progressBar = progressBarRef?.current?.getBoundingClientRect();
      if (progressBar && event.pageX) {
        const newPercent = updatePlaybackProgress(event.pageX - progressBar.x);
        onSeekDebounce(newPercent);
      }
    },
    [onSeekDebounce, updatePlaybackProgress],
  );

  useEffect(() => {
    const width = progressBarRef?.current?.getBoundingClientRect()?.width;
    if (width) {
      const newPosition = (width / 100) * (playbackProgress || 0);
      if (!isRewindInProgress && !isDraggingRewind) {
        setPosition(newPosition);
        setProgress(playbackProgress);
      }
    }
  }, [isRewindInProgress, isDraggingRewind, playbackProgress]);

  return (
    <Fade>
      <div className={styles.track}>
        <div className={classnames(styles.title, styles.metadata)}>{name}</div>
        <div className={classnames(styles.artist, styles.metadata)}>{artist}</div>
        {isPlayBtnEnabled && (
          <button
            type="button"
            data-testid="soundtrack-play-pause-button"
            name="Soundtrack play/pause button"
            className={styles.playBtn}
            onClick={() => onPlayPause(uri)}
          >
            {isPlaying ? (
              <Icon
                className={styles.playBtnIcon}
                data-testid="soundtrack-pause-button-icon"
                component={Pause}
              />
            ) : (
              <Icon
                className={styles.playBtnIcon}
                data-testid="soundtrack-play-button-icon"
                component={Play}
              />
            )}
          </button>
        )}
        {isCurrent && (
          <div
            ref={progressBarRef}
            aria-label="rewind-click"
            role="button"
            data-testid="soundtrack-progress-bar"
            tabIndex={-1}
            onKeyDown={() => {
              return null;
            }}
            onClick={onProgressBarClick}
            className={classnames(styles.progressBar, styles.progressBarOuter)}
          >
            <div
              className={classnames(styles.progressBar, styles.progressBarInner)}
              style={{ width: `${progress}%` }}
            />
            <Draggable
              axis="x"
              bounds="parent"
              onStart={onRewindingStart}
              onStop={onRewindingStop}
              onDrag={onRewinding}
              scale={1}
              position={{ x: position, y: 0 }}
            >
              <span
                aria-label="rewind-drag"
                role="button"
                data-testid="soundtrack-progress-bar-button"
                tabIndex={0}
                onMouseDown={() => setIsDraggingRewind(true)}
                onMouseUp={() => setIsDraggingRewind(false)}
                className={classnames(styles.progressDot, {
                  [styles.progressDotRewind]: isDraggingRewind || !isMediaM,
                })}
              />
            </Draggable>
          </div>
        )}
      </div>
    </Fade>
  );
};
Track.propTypes = propTypes;
Track.defaultProps = defaultProps;
export default Track;
