import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTransaction } from '@elastic/apm-rum-react';
import { isEmpty, isNil } from 'ramda';

import { actions, selectors } from '@/Store';
import Preloader from '@/Layouts/containers/Preloader';
import pageViewedAnalytics from '@/Analytics/Page';
import ErrorMessage from '@/Components/Message';
import MovieHeroBanner from '@/Components/HeroBanner/MovieHeroBanner';
import { GroupLayout, Movie } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import useMedia from '@/Hooks/useMedia';
import useIsLoaded from '@/Hooks/useIsLoaded';
import { getImageType } from '@/Utils/VideoImage';
import Rail from '@/Components/Rail';
import TileType from '@/Components/Rail/Tiles/TileType';
import TileSize from '@/Components/Rail/Tiles/TileSize';
import ShowHeroBanner from '@/Components/HeroBanner/ShowHeroBanner';
import Carousel from '@/Components/Carousel';
import { PageType } from '@/Components/HeroBanner/HeroTypes';
import useIsNewHistory from '@/Hooks/useIsNewHistory';
import usePagination from '@/Hooks/usePagination';

import styles from './styles.module.scss';

// Common data store for different components, No need to trigger re-render, so won't use CreateGlobalState Hooks
const LastPaginatedRail = { id: '', index: 0 };

const Home: React.FC = () => {
  const dispatch = useDispatch();
  const isNewHistory = useIsNewHistory();

  const isLoading = useSelector(selectors.home.isLoading);
  const heroes = useSelector(selectors.home.heroes);
  const heroesDuration: number = useSelector(selectors.home.heroesDuration);
  const rails = useSelector(selectors.home.rails);
  const { isMediaM } = useMedia();
  const { railPageSize } = usePagination();

  const isLoaded = useIsLoaded(isLoading);
  // If there are already some Rails or Hero, not show loading
  const isContentLoading = isNil(heroes) && (isNil(rails) || isEmpty(rails)) && isLoading;
  const isContentLoadingError = isNil(heroes) && (isNil(rails) || isEmpty(rails)) && isLoaded;

  const imageType = getImageType(PageType.Home, isMediaM);

  const onTileClick = useCallback(
    (railId: string, positionIndex: number) => {
      const isContinueWatchingRail = rails?.some(
        rail => rail.id === railId && rail.layout === GroupLayout.ContinueWatching,
      );
      if (isContinueWatchingRail) {
        LastPaginatedRail.id = '';
        LastPaginatedRail.index = 0;
      } else {
        LastPaginatedRail.id = railId;
        LastPaginatedRail.index = positionIndex;
      }
    },
    [rails],
  );

  const onTilesPaginate = useCallback(
    id => {
      setTimeout(() => {
        dispatch(actions.home.fetchRailById(id, PageType.Home.toLowerCase(), railPageSize.current));
        // the timeout value equals to the paginate transition duration
      }, 600);
    },
    [dispatch, railPageSize],
  );

  useEffect(() => {
    dispatch(
      actions.home.fetchContent({
        initialRailSize: railPageSize.current,
        reloadRailId: isNewHistory ? undefined : LastPaginatedRail.id,
      }),
    );
  }, [dispatch, isNewHistory, railPageSize]);

  useEffect(() => {
    pageViewedAnalytics('Home');
  }, []);

  return (
    <Preloader isLoading={isContentLoading}>
      {isContentLoadingError ? (
        <ErrorMessage
          buttonText="Reload"
          onButtonClick={() => {
            window.location.reload();
          }}
        />
      ) : (
        <>
          {heroes && (
            <Carousel className={styles.heroBanner} duration={heroesDuration}>
              {heroes.map(hero => {
                const image = hero?.[imageType].uri || '';
                return hero.__typename === 'Show' ? (
                  <ShowHeroBanner
                    show={hero}
                    image={image}
                    imageType={imageType}
                    pageType={PageType.Home}
                  />
                ) : (
                  <MovieHeroBanner
                    movie={hero as Movie}
                    image={image}
                    imageType={imageType}
                    pageType={PageType.Home}
                  />
                );
              })}
            </Carousel>
          )}
          {rails &&
            rails
              .filter(rail => rail.contentPage)
              .map(({ contentPage, ...rail }) => (
                <Rail
                  key={`rail_${rail.id}`}
                  id={rail.id}
                  heading={rail.title || ''}
                  subTitle={rail.subtitle || ''}
                  type={TileType.Media}
                  assets={contentPage.content}
                  layout={rail.layout || GroupLayout.RailLandscape}
                  size={TileSize.Small}
                  hasNextPage={contentPage.pageInfo?.hasNextPage}
                  hasError={!!rail.error}
                  isTilesLoading={rail.isLoading}
                  initialIndex={
                    !isNewHistory && LastPaginatedRail.id === rail.id ? LastPaginatedRail.index : 0
                  }
                  tileClickHandler={onTileClick}
                  tilesLoadHandler={onTilesPaginate}
                />
              ))}
        </>
      )}
    </Preloader>
  );
};

export default withTransaction('Home', 'component')(Home);
