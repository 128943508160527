import React, { FC, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { withTransaction } from '@elastic/apm-rum-react';
import { useDispatch, useSelector } from 'react-redux';

import { actions, selectors } from '@/Store';
import pageViewedAnalytics from '@/Analytics/Page';
import { contentCardClicked } from '@/Analytics/Segment';
import useMedia from '@/Hooks/useMedia';
import Preloader from '@/Layouts/containers/Preloader';
import Content from '@/Layouts/containers/Content';
import MediaTile from 'ComponentOverrides/Rail/Tiles/MediaTile';
import ContentType from '@/Utils/ContentTypes';
import { GroupLayout } from '@skytvnz/sky-app-store/lib/types/graph-ql';
import formatLayout from '@/Utils/LayoutFormatter';

import ErrorMessage from '@/Components/Message';
import { isEmpty } from 'ramda';
import useIsLoaded from '@/Hooks/useIsLoaded';
import styles from './styles.module.scss';

const Browse: FC = () => {
  const browseCategories = useSelector(selectors.collections.getBrowseCategories);
  const browseCategoriesIsLoading = useSelector(selectors.collections.browseCategoriesIsLoading);
  const isLoaded = useIsLoaded(browseCategoriesIsLoading);

  const { isMediaM } = useMedia();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.collections.fetchBrowseCategories(true));
  }, [dispatch]);

  useEffect(() => {
    pageViewedAnalytics('Browse');
  }, []);

  return (
    <div data-testid="browse-container">
      {isEmpty(browseCategories) && isLoaded ? (
        <ErrorMessage />
      ) : (
        <Content>
          <Preloader isLoading={browseCategoriesIsLoading}>
            <div className={styles.browseHeaderSection}>
              <h1 className={styles.title} data-testid="browse-all-title">
                Browse All
              </h1>
            </div>
            <div className={styles.browseTable} data-testid="browse-table">
              {browseCategories.map(item => {
                return (
                  <div
                    key={item?.id}
                    className={isMediaM ? styles.tileContainerM : styles.tileContainer}
                  >
                    <Fade>
                      <MediaTile
                        id={item?.id}
                        image={item?.tileImage || null}
                        title={item?.title}
                        type={ContentType.BrowseCollection}
                        layout={GroupLayout.RailLandscape}
                        onClick={() => {
                          contentCardClicked({
                            view_name: 'Browse',
                            category_name: item?.title,
                            style: formatLayout(GroupLayout.RailLandscape),
                          });
                        }}
                        linkSearchQuery={{ title: item?.title }}
                      />
                    </Fade>
                  </div>
                );
              })}
            </div>
          </Preloader>
        </Content>
      )}
    </div>
  );
};

export default withTransaction('Browse', 'component')(Browse);
