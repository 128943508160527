import React from 'react';
import { Link } from 'react-router-dom';

import { GroupLayout } from '@skytvnz/sky-app-store/lib/types/graph-ql';

import styles from './styles.module.scss';

// Random background and text position is temporary until we clarify what are the modes
const randomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i += 1) {
    const number = Math.floor(Math.random() * 16);
    color += letters[number];
  }
  return color;
};

interface Props {
  url: string;
  imageUrl?: string;
  text?: string;
  layout?: GroupLayout;
}

const ServiceTile: React.FC<Props> = props => {
  const { imageUrl, url, text, layout = GroupLayout.RailLandscape } = props;

  return (
    <Link to={url} data-testid="service-tile-button">
      <div
        className={styles[`serviceTile_${layout}`]}
        style={{
          backgroundImage: imageUrl
            ? `url(${imageUrl})`
            : `linear-gradient(to bottom right, ${randomColor()}, ${randomColor()})`,
        }}
      >
        {text && <span className={styles.serviceTileText}>{text}</span>}
      </div>
    </Link>
  );
};

export default ServiceTile;
