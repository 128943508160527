import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

const KEYCODE_BACKSPACE = 8;
const KEYCODE_DELETE = 46;

export interface Props {
  value: string;
  index: number;
  isFocus?: boolean;
  onDelete(pinIndex: number): void;
  onChange(value: string, pinIndex: number): void;
}

const numberReg = /\d/;

const PinInputField: FC<Props> = ({ value, index, isFocus, onDelete, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEntering, setEntering] = useState(false);

  const handleKeyDown = useCallback(
    event => {
      const { key, keyCode } = event;
      if (keyCode === KEYCODE_BACKSPACE || keyCode === KEYCODE_DELETE) {
        onDelete(index);
      }
      // Only allow enter number only character
      else if (!numberReg.test(key)) {
        event.preventDefault();
      }
    },
    [index, onDelete],
  );

  const handleChange = useCallback(
    event => {
      onChange(event.target.value, index);
    },
    [index, onChange],
  );

  const handleFocus = useCallback(() => {
    if (value) {
      onChange('', index);
    }
    setEntering(true);
  }, [value, index, onChange]);

  useEffect(() => {
    if (isFocus) {
      inputRef.current?.focus();
    }
  }, [index, isFocus]);

  useEffect(() => {
    if (value) {
      inputRef.current?.blur();
    }
  }, [value]);

  return (
    <div
      className={classnames(styles.pinInputField, {
        [styles.isEntering]: isEntering,
      })}
    >
      <input
        className={styles.pinInput}
        type="number"
        min={0}
        max={9}
        maxLength={1}
        value={value}
        ref={inputRef}
        onBlur={() => setEntering(false)}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        data-testid="pin-field-input"
      />
      {value && <div className={styles.pinCircleMask} data-testid="pin-field-circle-mask" />}
    </div>
  );
};

export default PinInputField;
