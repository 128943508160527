import React, { useCallback } from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  isSelected: boolean;
  id: string;
  text: string;
  name?: string;
  testId?: string;
  onClick: (selectedId: string, text: string) => void;
}

const TabButton: React.FC<Props> = props => {
  const { className, id, text, name, testId, isSelected, onClick } = props;

  const handleClick = useCallback(() => {
    onClick(id, text);
  }, [id, text, onClick]);

  return (
    <button
      type="button"
      className={classnames(styles.tabButton, className, { [styles.selectedTab]: isSelected })}
      name={name}
      data-testid={testId}
      onClick={handleClick}
    >
      {text}
    </button>
  );
};

export default TabButton;
