import { useRef } from 'react';
import useMedia from './useMedia';

const usePagination = () => {
  const { isMediaM } = useMedia();
  // Not trigger the new fetch if screen size has been modified
  const railPageSize = useRef(6);
  if (isMediaM) {
    railPageSize.current = 12;
  }
  return {
    railPageSize,
  };
};

export default usePagination;
