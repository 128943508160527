const AUTH0_COMMON_CLAIM_NAMESPACE = process.env.AUTH0_COMMON_CLAIM_NAMESPACE || '';

const auth0Config = {
  managementDomain: process.env.AUTH0_MGMT_DOMAIN || '',
  customDomain: process.env.AUTH0_CUSTOM_DOMAIN || '',

  clientId: process.env.AUTH0_CLIENT_ID || '',
  audience: process.env.AUTH0_AUDIENCE || '',
  skyboxAuthKey: process.env.AUTH0_SKYBOX_AUTH_KEY || '',

  profileClaimNameSpace: process.env.AUTH0_PROFILE_CLAIM_NAMESPACE || '',
  commonClaimNameSpace: `${AUTH0_COMMON_CLAIM_NAMESPACE}`,
  contextClaimNameSpace: `${AUTH0_COMMON_CLAIM_NAMESPACE}/context`,
  spotifyClaimNameSpace: `${AUTH0_COMMON_CLAIM_NAMESPACE}/spotify`,
  linkedAccountClaimNameSpace: `${AUTH0_COMMON_CLAIM_NAMESPACE}/connections`,
  accountNumberClaimNameSpace: process.env.AUTH0_ACCOUNT_NUMBER_CLAIM_NAMESPACE || '',

  idTokenRefreshInterval: process.env.AUTH0_ID_TOKEN_REFRESH_INTERVAL || '',
};

export default auth0Config;
