import React from 'react';
import classnames from 'classnames';
import TileSize from '@/Components/Rail/Tiles/TileSize';
import styles from './styles.module.scss';

export interface Props {
  size?: TileSize;
}

const SkeletonTile: React.FC<Props> = ({ size = TileSize.Small }) => (
  <div className={classnames(styles[`tile${size === TileSize.Large ? 'L' : 'S'}`])}>
    <div className={styles.skeleton} data-testid="media-tile-skeleton" />
  </div>
);

export default SkeletonTile;
