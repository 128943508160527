import React, { FC, useCallback, useState } from 'react';
import { useParams, useHistory, generatePath } from 'react-router-dom';
import { withTransaction } from '@elastic/apm-rum-react';
import { isNil } from 'ramda';
import qs from 'query-string';

import RoutePath from '@/Routes/RoutePath';
import useHistoryBack from '@/Hooks/useHistoryBack';
import useQueryString from '@/Hooks/useQueryString';
import { usePageHeader } from '@/Components/AppHeader/AppHeader';
import { VodVideoPlayer } from '@/Components/VideoPlayer';
import { PLAYER_REFERRER_QUERY_KEY } from '@/Utils/PageLocation';

const PLAYBACK_ORIGIN_QUERY_KEY = 'playbackOrigin';

const Watch: FC = () => {
  const { brandId = '', episodeId = '' } = useParams<any>();
  const isShow = !isNil(episodeId) && episodeId !== '';
  const goBack = useHistoryBack();
  const onClose = useCallback(() => {
    goBack();
    return false;
  }, [goBack]);

  const [pageTitle, setPageTitle] = useState('');
  usePageHeader({
    title: pageTitle,
  });

  const history = useHistory();
  const query = useQueryString();

  const playbackOrigin = query[PLAYBACK_ORIGIN_QUERY_KEY] as string | undefined;
  const playerReferrer = query[PLAYER_REFERRER_QUERY_KEY] as string | undefined;

  const handleVideoLoad = useCallback(
    episode => {
      setPageTitle(episode?.title);

      const watchPageUrl = qs.stringifyUrl({
        url: generatePath(RoutePath.watch, {
          brandId,
          episodeId: episode?.id,
        }),
        query: {
          playbackOrigin,
          playerReferrer,
        },
      });
      history.replace(watchPageUrl);
    },
    [brandId, history, playbackOrigin, playerReferrer],
  );

  return (
    <VodVideoPlayer
      brandId={brandId}
      episodeId={episodeId}
      isShow={isShow}
      playbackOrigin={playbackOrigin}
      playerReferrer={playerReferrer}
      fullWindow
      autoPlay
      exitFullWindowOnPlayEnd
      onExitFullWindow={onClose}
      onVideoMetaLoad={handleVideoLoad}
    />
  );
};

export default withTransaction('Watch', 'component')(Watch);
