import { useHistory } from 'react-router-dom';

const useIsNewHistory = () => {
  const history = useHistory();

  const isNewHistory = history.action !== 'POP';

  return isNewHistory;
};

export default useIsNewHistory;
