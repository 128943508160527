import {
  accountNotLinkedOrSubscriptionUpgradeEncountered,
  accountLinkPursued,
  subscriptionUpgradeEncountered,
  subscriptionUpgradeMessageDismissed,
  subscriptionUpgradePursued,
} from '@/Analytics/Segment';

export const recordUpgradeEncountered = (
  categoryName: string | undefined,
  contentId: string,
  contentTitle: string | undefined,
  contentType: string | undefined,
  viewName: string | undefined,
  subscription?: string[],
  subscriptionRequired?: string[],
  channelName?: string[],
) => {
  subscriptionUpgradeEncountered({
    category_name: categoryName,
    content_id: contentId,
    content_title: contentTitle,
    content_type: contentType,
    view_name: viewName,
    subscription,
    subscription_required: subscriptionRequired,
    channel: channelName,
  });
};

export const recordUpgradeMessageDismissed = (
  subscription?: string[],
  subscriptionRequired?: string[],
) => {
  subscriptionUpgradeMessageDismissed({
    subscription,
    subscription_required: subscriptionRequired,
  });
};

export const recordUpgradePursued = (subscription?: string[], subscriptionRequired?: string[]) => {
  subscriptionUpgradePursued({
    subscription,
    subscription_required: subscriptionRequired,
  });
};

export const recordLinkAccountOrUpgradeEncountered = (
  categoryName: string | undefined,
  contentId: string,
  contentTitle: string | undefined,
  contentType: string | undefined,
  viewName: string | undefined,
  subscription?: string[],
  subscriptionRequired?: string[],
  channelName?: string[],
) => {
  accountNotLinkedOrSubscriptionUpgradeEncountered({
    category_name: categoryName,
    content_id: contentId,
    content_title: contentTitle,
    content_type: contentType,
    view_name: viewName,
    subscription,
    subscription_required: subscriptionRequired,
    channel: channelName,
  });
};

export const recordAccontLinkPursued = () => {
  accountLinkPursued();
};
