/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { includes } from 'ramda';
import config from '@skytvnz/sky-app-store/lib/config';
import { LicenseType } from '@skytvnz/sky-app-store/lib/types/enums/LicenseType';
import {
  FairplayLicense,
  WidevineLicense,
  PlayreadyLicense,
  PlaybackSource,
  VodPlaybackSources,
  DrmType,
  EmeHeader,
  LinearVideoAsset,
  VideoAssetEncoding,
} from '@skytvnz/sky-app-store/lib/types/graph-ql';
import { KeySystems } from '@skytvnz/sky-app-store/lib/types/models/PlaybackMeta';

import { IS_IOS, IS_FIREFOX, IS_CHROME, IS_EDGE, IE_VERSION } from '@/Utils/Browser';
import { PlaybackAsset } from './Core/PlayerTypes';

export enum DRMFormat {
  FairPlay = 'com.apple.fps.1_0',
  PlayReady = 'com.microsoft.playready',
  Widevine = 'com.widevine.alpha',
}

export enum VideoRobustnessEMELevels {
  SW_SECURE_CRYPTO = 'SW_SECURE_CRYPTO', // Widevine level 3
  SW_SECURE_DECODE = 'SW_SECURE_DECODE', // Widevine level 3
  HW_SECURE_CRYPTO = 'HW_SECURE_CRYPTO', // Widevine level 2
  HW_SECURE_DECODE = 'HW_SECURE_DECODE', // Widevine level 1
  HW_SECURE_ALL = 'HW_SECURE_ALL', // Widevine level 1
}

export enum RenditionType {
  HLS = 'application/vnd.apple.mpegurl',
  HLS_X = 'application/x-mpegURL',
  DASH = 'application/dash+xml',
}

export const isSafariWithFairplaySupport = () => {
  return !!(
    (window as any).GestureEvent &&
    (window as any).WebKitMediaKeys &&
    (window as any).WebKitMediaKeys.isTypeSupported(DRMFormat.FairPlay, 'video/mp4')
  );
};

export const isBrowserWithWidevineSupport = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  return (
    window.navigator.requestMediaKeySystemAccess && !IS_IOS && (IS_FIREFOX || IS_CHROME || IS_EDGE)
  );
};

const getDrmType = () => {
  if (IS_IOS || isSafariWithFairplaySupport()) {
    return DrmType.Fairplay;
  }
  if (isBrowserWithWidevineSupport()) {
    return DrmType.Widevine;
  }
  if (IE_VERSION) {
    return DrmType.Playready;
  }
  return null;
};

export const DRM_TYPE = getDrmType();

export const isUrlHTTPS = url => includes('https://', url);

export const isHLS = type => type === RenditionType.HLS || type === RenditionType.HLS_X;

export const isDASH = type => type === RenditionType.DASH;

export const getEncodingType = () => {
  if (IS_IOS) {
    return VideoAssetEncoding.Hls;
  }
  return VideoAssetEncoding.Dash;
};

const getFairPlayCertificate = (certificateUrl: string) => async (_, callback) => {
  try {
    const response = await axios.get(certificateUrl, {
      responseType: 'arraybuffer',
    });
    callback(null, new Uint8Array(response.data));
  } catch (error) {
    callback(error);
  }
};

const getEmeHeaders = (emeHeaders: Array<EmeHeader>, licenseUri?: string, accessToken?: string) => {
  const emeHeader = emeHeaders?.reduce((acc, cur) => ({ ...acc, [cur.name]: cur.value }), {});
  if (licenseUri && licenseUri.startsWith(config.EXP_API_URL) && !emeHeader['BCOV-Auth']) {
    emeHeader['BCOV-Auth'] = `Bearer ${accessToken}`;
  }
  return emeHeader;
};

const getLicenseFromAcquisitionUri = async (licenseUrl, keyMessage, callback) => {
  try {
    const response = await axios.post(licenseUrl, keyMessage, {
      responseType: 'arraybuffer',
      headers: {
        'Content-type': 'application/octet-stream',
      },
    });
    callback(null, response.data);
  } catch (error) {
    callback(error);
  }
};
/**
 * Parses the EME key message XML to extract HTTP headers and the Challenge element to use
 * in the PlayReady license request.
 *
 * @param {ArrayBuffer} message key message from EME
 * @return {Object} an object containing headers and the message body to use in the
 * license request
 */
const getPlayReadyMessageChallenge = (message: number[]) => {
  const xml = new (window as any).DOMParser().parseFromString(
    // TODO do we want to support UTF-8?
    String.fromCharCode.apply(null, new Uint16Array(message) as any),
    'application/xml',
  );
  const headersElement = xml.getElementsByTagName('HttpHeaders')[0];
  const challengeElement = xml.getElementsByTagName('Challenge')[0];
  const headers = {};
  let challenge = '';

  if (headersElement) {
    const headerNames: any[] = headersElement.getElementsByTagName('name');
    const headerValues: any[] = headersElement.getElementsByTagName('value');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < headerNames.length; i++) {
      headers[headerNames[i].childNodes[0].nodeValue] = headerValues[i].childNodes[0].nodeValue;
    }
  }
  if (challengeElement) {
    challenge = window.atob(challengeElement.childNodes[0].nodeValue);
  }

  return {
    headers,
    challenge,
  };
};

const getFairPlayLicense = (licenseUrl: string) => (_source, _key, keyMessage, callback) =>
  getLicenseFromAcquisitionUri(licenseUrl, keyMessage, callback);

const getWidevineLicense = (licenseUrl: string) => (_source, keyMessage, callback) =>
  getLicenseFromAcquisitionUri(licenseUrl, keyMessage, callback);

const getPlayReadyLicense = (licenseUrl: string) => async (_source, buffer, callback) => {
  const { headers, challenge } = getPlayReadyMessageChallenge(buffer);
  try {
    const response = await axios.post(licenseUrl, challenge, {
      responseType: 'arraybuffer',
      headers,
    });
    callback(null, response.data);
  } catch (error) {
    callback(error);
  }
};

/**
 * The logic is only for override the BrightCove internal implementation for FP license.
 * BrightCove hard code the Certificate & License request base on the Vendor of CastLabs
 */
export const generateFairplaySources = (playbackSource: PlaybackSource) => {
  const { streamUri, drmLicense } = playbackSource;
  if (isUrlHTTPS(streamUri) && drmLicense) {
    const { certificateUri, licenseUri } = drmLicense as FairplayLicense;
    return {
      src: streamUri,
      emeHeaders: getEmeHeaders(playbackSource?.emeHeaders),
      keySystems: {
        [DRMFormat.FairPlay]: {
          getCertificate: getFairPlayCertificate(certificateUri),
          getLicense: getFairPlayLicense(licenseUri),
        },
      },
    };
  }
  return null;
};

export const generateWidevinePlayReadySources = (playbackSource: PlaybackSource) => {
  const { streamUri, drmLicense } = playbackSource;
  if (isUrlHTTPS(streamUri) && drmLicense) {
    const { licenseUri } = drmLicense;
    const keySystems: KeySystems = {};

    if ((drmLicense as WidevineLicense).__typename === LicenseType.Widevine) {
      keySystems[DRMFormat.Widevine] = {
        getLicense: getWidevineLicense(licenseUri),
      };
    } else if ((drmLicense as PlayreadyLicense).__typename === LicenseType.Playready) {
      keySystems[DRMFormat.PlayReady] = {
        getLicense: getPlayReadyLicense(licenseUri),
      };
    }

    return {
      src: streamUri,
      emeHeaders: getEmeHeaders(playbackSource?.emeHeaders),
      keySystems,
    };
  }
  return null;
};

const generateVodEMEPlaybackSource = (playbackSource: PlaybackSource, accessToken?: string) => {
  let drmFormat;

  if ((playbackSource.drmLicense as FairplayLicense).__typename === LicenseType.Fairplay) {
    drmFormat = DRMFormat.FairPlay;
  } else if ((playbackSource.drmLicense as WidevineLicense).__typename === LicenseType.Widevine) {
    drmFormat = DRMFormat.Widevine;
  } else if ((playbackSource.drmLicense as PlayreadyLicense).__typename === LicenseType.Playready) {
    drmFormat = DRMFormat.PlayReady;
  } else {
    // Invalid Drm License type so return null
    return null;
  }
  const mimeType = drmFormat === DRMFormat.FairPlay ? RenditionType.HLS : RenditionType.DASH;
  const { licenseUri } = playbackSource.drmLicense;
  return {
    sources: [
      {
        src: playbackSource.streamUri,
        emeHeaders: getEmeHeaders(playbackSource?.emeHeaders, licenseUri, accessToken),
        key_systems: {
          [drmFormat]: {
            ...(drmFormat === DRMFormat.FairPlay
              ? {
                  key_request_url: licenseUri,
                  certificate_url: (playbackSource.drmLicense as FairplayLicense).certificateUri,
                }
              : { license_url: licenseUri }),
          },
        },
        type: mimeType,
      },
    ],
  };
};

const generateLiveEMEPlaybackSource = (playbackSource: LinearVideoAsset, accessToken?: string) => {
  const { licenseUri, streamUri, certificateUri } = playbackSource;
  return {
    sources: [
      {
        src: streamUri,
        emeHeaders: {
          'BCOV-Auth': `Bearer ${accessToken}`,
        },
        key_systems: {
          [DRMFormat.FairPlay]: {
            key_request_url: licenseUri,
            certificate_url: certificateUri,
          },
        },
        type: RenditionType.HLS,
      },
    ],
  };
};

export const transformEMEPlayback = (playbackMeta: PlaybackAsset, accessToken?: string) => {
  if ((playbackMeta as VodPlaybackSources)?.__typename === `VodPlaybackSources`) {
    return generateVodEMEPlaybackSource(
      (playbackMeta as VodPlaybackSources)?.playbackSource,
      accessToken,
    );
  }
  return generateLiveEMEPlaybackSource(playbackMeta as LinearVideoAsset, accessToken);
};

export const transformShakaLivePlayback = (playbackAsset: PlaybackAsset) => {
  const { streamUri, licenseUri } = playbackAsset as LinearVideoAsset;
  if (isUrlHTTPS(streamUri) && licenseUri) {
    return {
      src: streamUri,
      drm: {
        servers: {
          [DRMFormat.Widevine]: licenseUri,
        },
      },
    };
  }
  return {};
};
